<template lang="">
  <div
    class="fixed z-30 max-sm:top-0 fold:top-0 flex w-screen h-full sm:bg-black sm:bg-opacity-50 bg-[#f2f4f5] bg-opacity-80 px-6 sm:px-0"
  >
    <div :class="tStyle.inner">
      <h2 class="sm:text-[30px] mt-[30px] text-[20px] max-sm:dark:text-[#e5e5e5] fold:dark:text-[#e5e5e5]">
        {{ $t('antiphishing.antiphishing') }}
      </h2>
      <input
        type="text"
        ref="Input"
        :placeholder="$t('mypage.authcenter.word.text1')"
        maxLength=10
        class="border border-[#e5e5e5] dark:border-[#606060] p-2 mt-[30px] sm:mt-[50px] sm:rounded text-[15px] w-full sm:dark:bg-[#3b3b43] dark:bg-[#1c1d22] max-sm:dark:placeholder:text-[#9f9f9f] max-sm:dark:text-[#e5e5e5] fold:dark:placeholder:text-[#9f9f9f] fold:dark:text-[#e5e5e5]"
      />
      <div :class="tStyle.bt_box">
        <button :class="tStyle.cancel" @click="setModalControl">
          {{ $t('common.cancel') }}
        </button>
        <button :class="tStyle.confirm" @click="sendMyCode">
          {{ $t('common.ok') }}
        </button>
      </div>

      <button :class="tStyle.close_bt" @click="setModalControl">
        <img src="../../assets/image/group2322.png" alt="" />
      </button>
    </div>
  </div>
</template>
<script>
import {
  nationNumModal,
  modalControl,
  isLogin,
  apiLanguage
} from '@/store/store';
import AesEncrypt from '@/components/Security/AesEncrypt';
import Request from '@/utils/Request';
import create from 'vue-zustand';

export default {
  name: 'AntiPhishing',
  data() {
    return {
      tStyle: {
        inner:
          'bg-white w-full sm:w-[440px] sm:h-[320px] m-auto sm:rounded sm:border border-[#C9C9C9] sm:p-[30px] px-6 relative dark:bg-[#1c1d22] dark:border-[#606060] dark:text-[#c9c9c9] text-center',
        close_bt:
          'absolute right-[20px] top-[18px] font-light text-[30px] max-sm:hidden fold:hidden',
        bt_box: 'w-full flex mt-[35px] sm:mb-0 mb-6 text-center text-[15px]',
        cancel:
          'basis-1/2 sm:h-[60px] py-1 sm:py-0 text-[15px] sm:text-[22px] border border-[#cfe4fe] sm:rounded-[3px] sm:dark:bg-[#fff8e8] dark:text-[#333] max-sm:dark:border-[#fff] fold:dark:border-[#fff] dark:bg-[#fff]',
        confirm:
          'basis-1/2 sm:h-[60px] py-1 sm:py-0 text-[15px] sm:text-[22px] bg-[#cfe4fe] sm:rounded-[3px] ml-[10px] dark:bg-[#fdd26a] dark:text-[#333]'
      }
    };
  },
  setup() {
    const useNationModal = create(nationNumModal);
    const useModalControl = create(modalControl);
    const useIsLogin = create(isLogin);
    const { setModalClose } = useNationModal();
    const { setModalControl, setModalName, setAlertData, setAlertOpen } =
      useModalControl();
    const { setLogout } = useIsLogin();
    const useApiLanguage = create(apiLanguage);
    const { apiLangCode } = useApiLanguage();

    return {
      setModalClose,
      setModalControl,
      setLogout,
      setModalName,
      setAlertData,
      setAlertOpen,
      apiLangCode
    };
  },
  methods: {
    async sendMyCode() {
      let { t } = this.$i18n;
      let code = this.$refs.Input.value;

      if (!code || code === '') {
        this.openAlertModal(
          t('antiphishing.antiphishing'),
          t('antiphishing.checkinput')
        );
        return;
      } else if (code.length > 10) {
        this.openAlertModal(
          t('antiphishing.antiphishing'),
          t('antiphishing.lengthover')
        );
        return;
      }

      let data = JSON.stringify({
        data: AesEncrypt({
          code: code
        })
      });

      let result = await Request(
        '/api/exs/mypage/v1/send_mycode',
        'post',
        this.apiLangCode[this.$i18n.locale],
        data
      );
      if (!result) {
        this.openAlertModal(t('antiphishing.change'), t('antiphishing.fail'));
        return;
      }

      let { API_CODE } = result;

      if (API_CODE.status === 'L402') {
        this.setAlertData({
              title: '',
              desc: this.$t('login.msg15')
            });
            this.setAlertOpen();

        localStorage.removeItem('fubit_token');
        localStorage.removeItem('fubit_rtoken');
        this.goMainPage();
        this.setLogout();
      } else if (API_CODE.status === '0000') {
        //success
        let { mycode } = result;
        this.openAlertModal('', t('antiphishing.success'), true);
      } else {
        this.openAlertModal(API_CODE.title, API_CODE.message);
      }
    },
    openAlertModal(title, desc, isReload) {
      this.setAlertData({ title, desc, isReload });
      this.setAlertOpen();
    },
    goMainPage() {
      this.$router.push({
        name: 'MainHome'
      });
    }
  }
};
</script>
<style lang=""></style>
