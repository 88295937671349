<template lang="">
  <div class="fixed z-10 flex w-screen h-full bg-black bg-opacity-50">
    <div :class="tStyle.inner">
      <div class="absolute left-[-1px] top-[-1px]">
        <img src="../../assets/image/path1703.png" alt="" class="dark:hidden">
        <img src="../../assets/image/path1703_d.png" alt="" class="opacity-0 dark:opacity-100">
      </div>
      <h1 :class="tStyle.title">{{ $t('password.change') }}</h1>
      <div :class="tStyle.contents">
        <div>
          <div class="pl-[10px] mb-[5px]">{{ $t('password.now') }}</div> 
          <input type="text" :placeholder= " $t('password.now_password') "  :class="tStyle.account_input">
        </div>
        <div>
          <div class="pl-[10px] mb-[5px]">{{ $t('password.new') }}</div> 
          <input type="text" :placeholder= " $t('password.new_password') "  :class="tStyle.account_input">
        </div>
        <div>
          <div class="pl-[10px] mb-[5px]">{{ $t('password.new2') }}</div> 
          <input type="text" :placeholder= " $t('password.new_password') "  :class="tStyle.account_input">
        </div>
        
        <div>
          <div class="pl-[10px] mb-[10px]">
            {{ $t('password.security') }}
        </div>
        <div class="flex">
          <div :class="tStyle.check_google">{{ $t('common.otp_auth') }}</div>
          <div :class="tStyle.check_email">{{ $t('common.email_auth') }}</div>
          <div :class="tStyle.check_phonenum">{{ $t('common.phone_auth') }}</div>
        </div>
          
        </div>
      </div>
      <div :class="tStyle.bt_box">
        <button :class="tStyle.cancel">{{ $t('password.resend') }}</button>
        <button :class="tStyle.confirm">{{ $t('common.ok') }}</button>
      </div>
      

      <button :class="tStyle.close_bt">
        <img src="../../assets/image/group2322.png" alt="">
      </button>
    </div>
  </div>
</template>
<script>
import { nationNumModal } from "@/store/store";
import create from "vue-zustand";

export default {
  name: "PasswordEmail",
  data() {
    return {
      tStyle: {
        inner: 'bg-white w-[550px] h-[700px] m-auto rounded border border-[#C9C9C9] px-[20px] py-[15px] relative dark:bg-[#1c1d22] dark:border-[#606060] dark:text-[#c9c9c9]',
        title: 'text-center text-[30px] mt-[40px]',
        contents: 'w-full mt-[32px] text-[22px] ',
        account_input: 'w-full h-[60px] border pl-[10px] mb-[20px] text-[20px] rounded-[3px] dark:placeholder:text-[#fff] dark:bg-[#3b3b43] dark:border-[#606060]',
        check_google: 'flex items-center justify-center leading-[24px] w-[160px] h-[60px] inline-block bg-[#cfe4ff] rounded-[3px] text-center text-[#7f7f7f] text-[20px]  dark:bg-[#fdd26a] dark:text-[#333]',
        check_email: 'w-[160px] h-[60px] inline-block bg-[#005cd3] rounded-[3px] text-center text-[#fff] text-[20px] leading-[60px] mx-[14px] dark:bg-[#ffb500] dark:text-[#333]',
        check_phonenum: 'w-[160px] h-[60px] inline-block border rounded-[3px] text-center text-[#7f7f7f] text-[20px] leading-[60px] dark:bg-[#fff] dark:text-[#333]',

        bt_box: ' h-[35px] mt-[35px] text-center text-[15px]',
        cancel: 'w-[249px] h-[60px] border border-[#cfe4fe] rounded-[3px] text-[22px] dark:bg-[#fff8e8] dark:text-[#333] dark:border-[#fff8e8]',
        confirm: 'w-[249px] h-[60px] bg-[#cfe4fe] rounded-[3px] ml-[10px] text-[22px] dark:bg-[#fdd26a] dark:text-[#333]',
        
        close_bt: 'absolute right-[20px] top-[18px] font-light text-[30px]',


      },
    };
  },
  setup() {
    const useNationModal = create(nationNumModal);
    const { setModalClose } = useNationModal();

    return{
      setModalClose,
    }
  },
};
</script>
<style lang=""></style>
