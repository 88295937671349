<template lang="">
  <div
    class="fixed z-10 flex w-screen h-full bg-white sm:bg-black sm:bg-opacity-50"
  >
    <div
      class="fixed top-0 z-10 flex items-center justify-between w-full px-4 py-3 sm:hidden bg-white dark:bg-[#111119]"
    >
      <button @click="setModalControl">
        <img
          src="@/assets/image/ion_chevron_back_outline.png"
          alt=""
          class="w-[30px] dark:hidden"
        />
        <img
          src="@/assets/image/ion_chevron_back_outline_d.png"
          alt=""
          class="w-[30px] opacity-0 dark:opacity-100 mt-[-30px] dark:mt-0"
        />
      </button>
      <div class="text-[25px] text-center text-[#444] dark:text-[#e5e5e5]">
        {{ $t('common.phone_auth') }}
      </div>
      <div class="w-[30px]"></div>
    </div>
    <div :class="tStyle.inner">
      <div :class="tStyle.point">
        <img src="../../assets/image/path1703.png" alt="" class="dark:hidden" />
        <img
          src="../../assets/image/path1703_d.png"
          alt=""
          class="opacity-0 dark:opacity-100"
        />
      </div>

      <div :class="tStyle.title">
        <h2 class="text-[30px] font-bold max-sm:hidden fold:hidden">
          {{ $t('common.phone_auth') }}
        </h2>
        <div
          class="sm:hidden text-center mt-[56px] sm:mt-0 flex justify-center"
        >
          <img
            src="@/assets/image/thmb_user.png"
            alt=""
            class="w-[20%] inline mt-[25px] sm:mt-0 dark:hidden"
          />
          <img
            src="@/assets/image/thmb_user_d.png"
            alt=""
            class="w-[20%] inline -ml-[20%] dark:ml-0 mt-[25px] sm:mt-0 opacity-0 dark:opacity-100"
          />
        </div>
        <h5
          class="sm:text-[20px] text-[14px] mt-[10px] inline-block px-6 sm:px-[100px] sm:py-1 rounded-full border border-[#c9c9c9] dark:border-[#606060] max-sm:dark:text-[#e5e5e5] fold:dark:text-[#e5e5e5]"
        >
          {{ alertData.auth?.newValue }}
        </h5>
        <p
          class="sm:text-[20px] text-[15px] mt-[10px] max-sm:leading-[18px] fold:leading-[18px] sm:font-light max-sm:dark:text-[#e5e5e5] fold:dark:text-[#e5e5e5]"
        >
          {{ $t('phone.text') }}
        </p>

        <div :class="tStyle.num_box">
          <input
            type="text"
            ref="input"
            maxLength="6"
            v-model="inputNum"
            class="test absolute text-white w-[600px] h-[500px] mt-[-410px] cursor-pointer opacity-0 z-[0] focus:outline-none"
          />

          <input
            disabled
            @click="focusInput"
            type="text"
            v-for="(a, i) in 6"
            :key="i"
            v-model="inArr[i]"
            maxLength="1"
            :class="[
              inputNum.length === i
                ? tStyle.numY
                : this.isFail
                ? tStyle.fail_num
                : tStyle.num
            ]"
          />
          <!--
          <div :class="tStyle.num"></div>
          <div :class="tStyle.num"></div>
          <div :class="tStyle.num"></div>
          <div :class="tStyle.num"></div>
          <div :class="tStyle.num"></div>
          <div :class="tStyle.num"></div>-->
        </div>

        <p
          v-if="isFail"
          :class="tStyle.fail"
          class="max-sm:hidden fold:hidden"
          v-html="text"
        ></p>
      </div>

      <div class="justify-between max-sm:flex fold:flex">
        <p
          v-if="isFail"
          :class="tStyle.fail"
          class="sm:hidden"
          v-html="text"
        ></p>
        <button :class="tStyle.confirm" @click="requestAuth">
          <span class="max-sm:hidden fold:hidden">{{
            $t('password.resend')
          }}</span>
          <span class="sm:hidden">{{ $t('password.resend2') }}</span>
        </button>
      </div>

      <button :class="tStyle.close_bt" @click="closeModal">
        <img src="../../assets/image/group2322.png" alt="" />
      </button>
    </div>
  </div>
</template>
<script>
import {
  nationNumModal,
  modalControl,
  isLogin,
  apiLanguage
} from '@/store/store';
import CryptoJS from 'crypto-js';
import AesEncrypt from '../Security/AesEncrypt';
import Request from '@/utils/Request';
import create from 'vue-zustand';

export default {
  name: 'PhoneNumber',
  data() {
    return {
      inputNum: '',
      inArr: [],
      isFail: false,
      text: '',
      tStyle: {
        inner:
          'bg-white w-full h-full sm:w-[600px] sm:h-[540px] m-auto sm:rounded sm:border border-[#C9C9C9] px-6 sm:px-[40px] pt-[56px] sm:py-[15px] relative sm:dark:bg-[#1c1d22] dark:border-[#606060] dark:text-[#c9c9c9] dark:bg-[#111119]',
        title: 'text-center sm:mt-[50px]',
        point: 'absolute left-[-1px] top-[-1px] max-sm:hidden fold:hidden',
        num_box:
          'w-full h-[80px] flex justify-between mt-[10px] sm:mt-[88px] children:last-child:border-[#005CB9]',
        numInput:
          'disabled:bg-white cursor-pointer sm:w-[90px] sm:h-[90px] w-[14.6666%] h-[13.5vw] border border-[#C9C9C9] border-b-[2px] border-b-[#626060] text-center dark:bg-[#1c1d22] dark:border-[#606060]',
        num: 'sm:w-[90px] sm:h-[90px] w-[14.6666%] h-[13.5vw] border border-b-[2px] border-b-[#626060] inline-block dark:bg-[#1c1d22] dark:border-[#606060] text-center',
        numY: 'disabled:bg-white cursor-pointer sm:w-[90px] sm:h-[90px] w-[14.6666%] h-[13.5vw] border border-[#C9C9C9] border-b-[#005CD3] dark:border-b-[#ffb500] dark:border-[#606060] border-b-[4px] text-center dark:bg-[#1c1d22]',
        numInputFail:
          'disabled:bg-white cursor-pointer sm:w-[90px] sm:h-[90px] w-[14.6666%] h-[13.5vw] border border-[#C9C9C9] border-b-[2px] border-b-[#FC0D1B] text-center',

        fail_num:
          'sm:w-[90px] sm:h-[90px] w-[14.6666%] h-[13.5vw] border border-b-[4px] border-b-[#fc0d1b] inline-block dark:border-[#606060] text-center dark:bg-[#1c1d22] dark:border-[#606060] dark:border-b-[#606060] dark:bg-[#1c1d22]',
        fail: 'text-center text-[#e7224b] sm:text-[22px] text-[15px] fold:text-[14px]',
        confirm:
          'sm:w-full sm:h-[60px] max-sm:text-[15px] fold:text-[14px] sm:no-underline underline sm:bg-[#cfe4fe] rounded-[3px] sm:mt-[80px] sm:dark:bg-[#ffb500] sm:dark:text-[#333] max-sm:ml-auto fold:ml-auto dark:text-[#e5e5e5]',

        close_bt:
          'absolute right-[20px] top-[18px] font-light text-[30px] max-sm:hidden fold:hidden'
      }
    };
  },
  setup() {
    const useNationModal = create(nationNumModal);
    const useModalControl = create(modalControl);
    const useIsLogin = create(isLogin);
    const { setModalClose } = useNationModal();
    const { alertData, setAlertOpen, setAlertData, setModalControl } =
      useModalControl();
    const { setLogout } = useIsLogin();
    const useApiLanguage = create(apiLanguage);
    const { apiLangCode } = useApiLanguage();

    return {
      setModalClose,
      setAlertData,
      alertData,
      setAlertOpen,
      setLogout,
      setModalControl,
      apiLangCode
    };
  },
  mounted() {
    this.focusInput();
  },
  watch: {
    inputNum(a, b) {
      var blankCheck = /[\s]/g;
      if (isNaN(a) == true) {
        this.inputNum = b;
      } else if (blankCheck.test(a) == true) {
        this.inputNum = b;
      } else {
        this.inArr = Array.from(this.inputNum);

        if (this.inputNum.length === 6) {
          this.confirmAuth(this.inputNum);
        } else if (this.inputNum.length < 6) {
          this.tStyle.numInput =
            'disabled:bg-white cursor-pointer w-[90px] h-[90px] border border-[#C9C9C9] border-b-4 border-b-[#626060] text-center';
        }
      }
    }
  },
  methods: {
    focusInput() {
      this.$refs.input.focus();
    },
    async confirmAuth(uauth) {
      let { newValue, authType } = this.alertData.auth;

      let data = JSON.stringify({
        data: AesEncrypt({
          type: 'phone',
          auth_type: authType,
          new_value: newValue,
          uauth: uauth
        })
      });

      let result = await Request(
        '/api/exs/mypage/v1/confirm_auth',
        'post',
        this.apiLangCode[this.$i18n.locale],
        data
      );

      if (!result) {
        this.isFail = true;
        this.text = this.$t('phone.fail');
        return;
      }

      let { API_CODE } = result;

      if (API_CODE.status === 'L402') {
        this.sessionClosed();
      } else if (API_CODE.status === '0000') {
        this.openAlertModal('', this.$t('phone.authsuccess', true));
        this.setModalControl()
      } else {
        this.isFail = true;
        this.text = API_CODE.message;
      }
    },
    async requestAuth() {
      this.inputNum = '';

      let { newValue, authType } = this.alertData.auth;

      let data = JSON.stringify({
        data: AesEncrypt({
          type: 'phone',
          auth_type: authType,
          new_value: newValue
        })
      });

      let result = await Request(
        '/api/exs/mypage/v1/request_auth',
        'post',
        this.apiLangCode[this.$i18n.locale],
        data
      );

      if (!result) {
        this.openAlertModal('', this.$t('phone.resendfail'));
        return;
      }

      let { status } = result.API_CODE;

      if (status === 'L402') {
        this.sessionClosed();
      } else if (status === '0000') {
        this.inputNum = '';
      } else {
        this.openAlertModal('', this.$t('phone.resendfail'));
      }
    },
    sessionClosed() {
      let { t } = this.$i18n;
      this.setAlertData({
        title: '',
        desc: this.$t('login.msg15')
      });
      this.setAlertOpen();
      localStorage.removeItem('fubit_token');
      localStorage.removeItem('fubit_rtoken');
      this.setLogout();
      this.goMainPage();
    },
    goMainPage() {
      this.$router.push({
        name: 'MainHome'
      });
    },
    openAlertModal(title, desc, isReload) {
      this.setAlertData({ title, desc, isReload });
      this.setAlertOpen();
    },
    closeModal() {
      this.setAlertData({});
      this.setModalControl();
    }
  }
};
</script>
<style lang=""></style>
