export default {
  "common": {
    "nation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нация"])},
    "nation_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите страну"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск"])},
    "search2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите поисковый запрос."])},
    "search3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Монета"])},
    "asset_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление активами"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сегодня"])},
    "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1W"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["месяц"])},
    "1month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1М"])},
    "3month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3М"])},
    "mywallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кошелек"])},
    "viewwallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обзор"])},
    "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Депозит"])},
    "exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конвертация"])},
    "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вывод"])},
    "margin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маржа"])},
    "operate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действие"])},
    "deposit2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Депозит"])},
    "exchange2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конвертация"])},
    "withdraw2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вывод"])},
    "trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Торговля"])},
    "coinsearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск монет доступных для депозита"])},
    "networkselection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сеть"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время"])},
    "division": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип"])},
    "condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
    "completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завершено"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить"])},
    "progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ожидает выполнения"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена"])},
    "commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссия"])},
    "invest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История"])},
    "recentorder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otkrytyy"])},
    "orderhistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Актив"])},
    "profitloss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PNL"])},
    "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Символ"])},
    "leverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Плечо"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фильтр"])},
    "invest_limits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лимит"])},
    "invest_marketprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рыночный"])},
    "invest_conditional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условный"])},
    "orderprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена"])},
    "orderquantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["объём"])},
    "contract_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выполнено"])},
    "contract_amount2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество закрепления"])},
    "contract_Average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Среднее"])},
    "contract_Average2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Средняя цена"])},
    "target_loss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TP / SL"])},
    "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Направление"])},
    "indefinitely": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бессрочный"])},
    "crossbuy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кросс длинный"])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправлено"])},
    "part_contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Частично заполнено"])},
    "contract_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Детали"])},
    "contract_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заключение"])},
    "trigger-clue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["трюк"])},
    "trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["трюк"])},
    "trigger_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена триггера"])},
    "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Событие"])},
    "event_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Детали события"])},
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приглашение друга"])},
    "usercenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help Center"])},
    "guidebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide Book"])},
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Объявление"])},
    "contact us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить запрос"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заголовок"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
    "writedate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
    "mypage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аккаунт"])},
    "usersetting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Профиль"])},
    "setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать"])},
    "resetting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["мэйл"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])},
    "authcenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Безопасность"])},
    "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Активность"])},
    "phone_auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверка по СМС"])},
    "email_auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["мэйл заверение"])},
    "auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аутентификация"])},
    "google2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTP"])},
    "otp_auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTP аутентификатор"])},
    "change_nickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить никнейм"])},
    "uuid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите UUID"])},
    "retouch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Исправить"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить"])},
    "deauthorization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["деавторизация"])},
    "certification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Установка"])},
    "removeauthfail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аутентификация не удалась."])},
    "removeauthsuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аутентификация отозвана."])},
    "certificated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завершено"])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отклонено"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баланс фьючерсов"])},
    "30deal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Объем торгов за 30 дней"])},
    "google_auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTP проверка"])},
    "notice2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомление"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продолжить"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти"])},
    "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
    "whole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["все"])},
    "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["символ"])},
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["английский"])},
    "korean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["корейский"])},
    "initialization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инициализация"])},
    "delet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
    "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мой кошелек"])},
    "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не удалось."])},
    "failfetch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось загрузить данные."])},
    "user_level_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General member"])},
    "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подписка"])},
    "findby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Найти по"])},
    "googlecert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google аутентификация"])},
    "nodata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные недоступны."])},
    "stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["акции"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Далее"])},
    "eventword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Событие"])},
    "referer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реферал"])}
  },
  "login": {
    "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация"])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите информацию для регистрации."])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как минимум один символ в нижнем/верхнем регистре и одна цифра."])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как минимум один специальный символ."])},
    "msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минимальная длина 8 символов."])},
    "re": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["повторить"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Идентификатор приглашения"])},
    "msg5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите код приглашения."])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать"])},
    "msg6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я прочитал и согласен с"])},
    "msg7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конфиденциальность"])},
    "msg8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Создать аккаунт"])},
    "msg9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный формат адреса электронной почты"])},
    "msg10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный формат пароля"])},
    "msg11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароли не совпадают"])},
    "msg12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Этот адрес электронной почты доступен."])},
    "msg13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный пароль"])},
    "msg14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Этот сервис доступен после входа в систему."])},
    "msg15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время вашей сессии истекло."])},
    "msg16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваша регистрация завершена."])},
    "msg17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войдите, чтобы начать транзакцию."])},
    "msg18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вход в систему"])},
    "msg19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешный вход в систему"])},
    "msg20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код аутентификации недействителен!"])},
    "msg21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время аутентификации истекло."])},
    "msg22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация завершена."])},
    "msg23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторите пароль"])},
    "msg24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы не зарегистрированы или используете другой идентификатор или пароль."])},
    "loginword1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить идентификатор"])},
    "msg25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный формат номера мобильного телефона."])},
    "msg26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условия и политики"])}
  },
  "topmenu": {
    "procenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Промоакции"])},
    "mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тема"])},
    "sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Звук"])},
    "layout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расположение"])}
  },
  "footer": {
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Торговля криптовалютными деривативами связана с огромными рисками."])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Операции с цифровыми активами могут быть необратимыми, и соответственно, убытки"])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["в результате мошеннических или случайных операций могут быть не восстановлены."])},
    "introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О нас"])},
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Объявления"])},
    "center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Центр защиты инвестиций"])},
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Часто задаваемые вопросы"])},
    "kakao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Связаться с нами через KakaoTalk"])},
    "inquiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Связаться с нами 1:1"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Компания"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Служба поддержки клиентов"])},
    "word1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서울시 강남구 테헤란로 1길 23, 4층"])},
    "word2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주식회사 푸빗"])},
    "word3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대표 김푸빗"])},
    "word4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사업자 등록번호 123-45-67890"])},
    "word5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가상 사업자 등록번호 123-45-67890"])},
    "word6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["После входа в систему на Fu-bit вы сможете воспользоваться различными услугами."])}
  },
  "mainhome": {
    "word": {
      "startdeal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начать торговлю"])},
      "populardeal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Популярные"])},
      "bigchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Топ объема"])},
      "highprofit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Топ роста"])},
      "marketstatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рынок"])},
      "newcoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новые листинги"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена (USDT)"])},
      "ratechange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменение (24ч)"])},
      "highprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Макс (24ч)"])},
      "lowprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мин (24ч)"])},
      "viewmore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Больше"])},
      "guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide Book"])},
      "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Депозит"])},
      "trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фьючерсы"])},
      "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24/7 Поддержка"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])},
      "inspect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Инспектировать"])}
    },
    "msg": {
      "mainbannermsg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Торгуйте с Fubit"])},
      "mainbannermsg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Быстро и безопасно зарабатывайте."])},
      "mainbannermsg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Присоединяйтесь сейчас и получите 30 USDT"])},
      "middlebannermsg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Простой и быстрый обмен монет"])},
      "middlebannermsg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Простой и быстрый обмен цифровых активов от информации о монете до ордера"])},
      "bottomtit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Начните прямо сейчас! Зарабатывайте на торговле"])},
      "bottom1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "bottom1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Понятно для каждого"])},
      "bottom2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подарок 10 USDT за первый депозит"])},
      "bottom2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "bottom3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Призовой фонд 100 USDT,"])},
      "bottom3_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Присоединяйтесь и начните"])},
      "bottom4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спросите нас что угодно"])},
      "bottom4_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мы работаем для вас круглосуточно."])},
      "modalmsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не показывать снова."])},
      "inputtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите ваш E-Mail."])}
    }
  },
  "trade": {
    "modal": {
      "margin_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Бессрочный режим маржи"])},
      "cross": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кросс"])},
      "isolated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["изоляция"])},
      "cross2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кросс режим маржи"])},
      "isolated2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["изоляция режим маржи"])},
      "adjust": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настроить маржу"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
      "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимально добавляемое"])},
      "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Размер"])},
      "est": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ожидаемая цена ликвидации"])},
      "profit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тейк-профит"])},
      "loss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стоп-лосс"])},
      "entryprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена входа"])},
      "liq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена ликвидации"])},
      "size2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Размер"])},
      "pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ожидаемая PNL"])},
      "basis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите базисную цену"])},
      "tp_sl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TP/SL для всей позиции"])},
      "markprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена маркировки"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скачать"])},
      "liq2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть позицию"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество ордеров"])}
    },
    "msg": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переключение режима маржинальности применяется только к выбранному контракту."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все кросс-позиции под одним активом маржинальности делят общий баланс кросс-маржи."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В случае ликвидации, весь баланс маржи и любые оставшиеся открытые позиции под активом могут быть конфискованы."])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управляйте риском на отдельных позициях, ограничивая количество выделяемой маржи на каждую из них."])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если коэффициент маржинальности позиции достигает 100%, позиция будет ликвидирована. Маржу можно добавлять или удалять из позиций, используя этот режим."])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если позиция открыта, вы не можете изменить режим маржинальности."])}
    }
  },
  "mywallet": {
    "walletview": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ДОМ > Кошелек > Обзор"])},
      "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скрыть"])},
      "totalasset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общий баланс"])},
      "checkboxtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не поддерживаются транзакции / скрытие малых активов"])},
      "loss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нереализованная PNL"])},
      "possibleasset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баланс"])},
      "inkindasset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Монеты"])},
      "coin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всего"])},
      "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступно"])},
      "freez": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В ордерах"])},
      "bitcoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Биткоин"])},
      "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пополнить"])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вывести"])},
      "forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["акти́в фьючерса"])},
      "mobilelist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["подарка"])},
      "mobilelist2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История акции"])},
      "futurelist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История кошелька фьючерсов"])}
    },
    "depositwallet": {
      "word": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ДОМ > Кошелёк > Депозит"])},
        "assetselection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выберите монету"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес депозита"])},
        "precautions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Примечание"])},
        "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
        "destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назначение"])},
        "depositdetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вклад список"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество депозита"])},
        "copy2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Копирование завершено"])},
        "inoutlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Депозит и вывод"])}
      },
      "msg": {
        "precautions_msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Выберите актив и сеть, на которую вы хотите внести депозит, и убедитесь, что они верны."])},
        "precautions_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Сделайте снимок адреса депозита в виде QR-кода или вставьте его в свой кошелек для вывода и запросите вывод."])},
        "precautions_msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Пожалуйста, дождитесь подтверждения передачи блокчейн-сети."])},
        "precautions_msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. Fubit отправляет активы на адрес кошелька после подтверждения передачи блокчейн-сетью."])},
        "precautions_msg5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["※ У вас может быть актив, для которого помимо адресов требуются дополнительные данные или теги назначения."])},
        "precautions_msg6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["※ Fu bit не несет ответственности за любые проблемы, вызванные неправильным вводом информации о депозите."])},
        "address_msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "address_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скопируйте и введите адрес депозита"])},
        "alertmsg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата окончания не может быть раньше даты начала."])}
      }
    },
    "exchange": {
      "word": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ДОМ > Кошелек > Конвертировать"])},
        "before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["От"])},
        "exchange_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["объём"])},
        "after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["До"])},
        "possible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступно"])},
        "expect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы получите"])},
        "coinprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена"])},
        "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите"])},
        "amount_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лимит на 24 часа"])},
        "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["замена список"])},
        "exchanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выполнено"])},
        "price_check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотр конвертации"])},
        "countLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["секунд"])},
        "countlimit2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить после"])}
      },
      "msg": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обмен произведен успешно."])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка обмена."])},
        "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите количество."])},
        "notenough": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недостаточно обмениваемых активов."])}
      }
    },
    "withdraw": {
      "word": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ДОМ > Кошелек > Вывод"])},
        "location2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ДОМ > Кошелек > Вывод > Управление адресами"])},
        "address_manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление адресами"])},
        "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать монету"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление адресами"])},
        "address_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["адрес"])},
        "coinsearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск выводимых монет"])},
        "address2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать адресную книгу"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сумма"])},
        "withdrawpossible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступная сумма для вывода"])},
        "withdrawpossible2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["доступная сумма"])},
        "withdrawdetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вывода список"])},
        "precautions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Меры предосторожности при выводе"])},
        "minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минимальная сумма для вывода"])},
        "amount_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ограничение на вывод в течение 24 часов"])},
        "asset2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать монету"])},
        "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес для вывода"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прямой ввод"])},
        "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сеть"])},
        "minimum2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минимальная сумма для вывода: 0,001"])},
        "24withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимально возможная сумма вывода за 24 часа"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Название адреса"])},
        "withdrawaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес для вывода"])},
        "adresslist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Детали адреса"])},
        "adressplus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить адрес"])},
        "noadress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не зарегистрировано ни одного адреса."])},
        "adressmsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите от 4 до 5 символов"])},
        "mylevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мой уровень вывода"])},
        "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LV"])},
        "levelbtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вы́ше"])},
        "destination_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите код назначения"])},
        "addr_err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адресная ошибка"])},
        "io_check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["обслуживание"])},
        "over_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аномальные активы"])},
        "inquiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["подать запрос"])},
        "notag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тэги не введены"])},
        "treble": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверить правильность дерева"])}
      },
      "msg": {
        "withdraw_msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Пожалуйста, убедитесь, что выбранная для вывода монета и введенный адрес для вывода соответствуют той же монете."])},
        "withdraw_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Выбранную монету для вывода можно получить только на адрес, куда возможно внесение этой монеты."])},
        "withdraw_msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- После завершения заявки на вывод, обычно требуется около часа, чтобы проверить детали в кошельке, куда был получен депозит."])},
        "withdraw_msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Обработка вывода может задерживаться в зависимости от обстоятельств, таких как сеть блокчейна."])},
        "withdraw_msg5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Если вы выводите на кошелек другой биржи, обязательно проверьте политику этой биржи."])},
        "withdraw_msg6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- После завершения заявки на вывод, последующие процедуры обрабатываются в блокчейн-сети."])},
        "withdraw_msg7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Из-за природы блокчейна, после начала вывода средств его нельзя отменить."])},
        "withdraw_msg8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Поскольку выводы обрабатываются в системе Fubit, адрес вывода может изменяться в зависимости от ситуации."])},
        "withdraw_msg9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Все убытки, вызванные неправильным вводом информации о выводе или непроверкой политики получающего кошелька или биржи, несет пользователь, и если подозреваются мошеннические или аномальные операции, Fubit может ограничить вывод."])},
        "withdraw_msg10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите адрес вывода."])},
        "withdraw_msg11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недостаточно активов для вывода."])}
      }
    }
  },
  "invest": {
    "recentorder": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ДОМ > История > Otkrytyy"])}
    },
    "orderhistory": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ДОМ > История > Активы"])}
    },
    "profitloss": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ДОМ > История > PNL"])},
      "position_clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ликвидация позиции"])},
      "position_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка позиции"])},
      "average_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена входа"])},
      "liquidation_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена"])},
      "loss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PNL"])}
    }
  },
  "promotion": {
    "event": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ДОМ > Продвижение > Событие"])},
      "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приветственный подарок"])},
      "bonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выплата бонуса за регистрацию участника"])},
      "bonus_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выплата бонуса за депозиты на сумму $10 000 и выше в августе"])},
      "mision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["начало миссии"])},
      "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выполняется"])},
      "acquire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получение"])},
      "acquire_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Награда за миссию была внесена в подарочный кошелек."])},
      "completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Миссия завершена"])},
      "participate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Участие миссии"])},
      "participate_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Теперь мы начинаем миссию."])},
      "event_compensation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приз за событие"])},
      "mision_clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Миссия выполнена"])},
      "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условия"])},
      "terms1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условия использования"])},
      "terms2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Личная информация"])},
      "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
      "noticedetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробности события"])},
      "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В виде списка"])},
      "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотр"])},
      "rewarded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "rewarded2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["был зачислен на ваш кошелек в качестве компенсации."])},
      "unlimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["неограниченный"])},
      "term1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Событие Fubit предназначено для зарегистрированных участников."])},
      "term2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы участвуете в событии в необычном или незаконном режиме, вы можете быть исключены из выбора победителя и даже после победы могут быть отменены."])},
      "term3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Награды будут выплачиваться в центре продвижения целевой группе после того, как событие начнется или завершится. Компенсация должна быть получена вручную."])},
      "term4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вознаграждение, которое вы получаете за приглашение друга, будет автоматически выплачиваться в 00:00 (UTC) следующего дня."])},
      "term5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все участники должны соблюдать условия использования Fubit. Fubit оставляет за собой право дисквалифицировать участников за мошеннические или нарушающие деятельность во время события, включая регистрацию пакетного аккаунта для получения дополнительных бонусов и других действий, связанных с незаконными, незаконными и вредоносными целями."])},
      "term6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Награды могут быть использованы только для торговли фьючерсами и не могут быть выведены напрямую."])},
      "term7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Награды могут использоваться в качестве маржи, а также для оплаты комиссий и убытков."])},
      "term8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Награды могут использоваться для вывода всей выручки, накопленной в результате успешной транзакции."])},
      "term9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы имеете право изменять правила этого мероприятия без предварительного уведомления от Fubit."])},
      "term10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fubit имеет окончательное толкование данного мероприятия. Если у вас есть вопросы, пожалуйста, свяжитесь с службой поддержки."])}
    },
    "invite": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ДОМ > ПРОДВИЖЕНИЕ > РЕФЕРАЛЬНАЯ ПРОГРАММА"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мой реферальный статус"])},
      "info_msg_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете приглашать друзей и получать вознаграждение за их транзакции."])},
      "info_msg_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На основе комиссии, взимаемой с транзакций вашего приглашенного друга, вы и ваш друг получите вознаграждение."])},
      "info_msg_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код приглашения друга устанавливает процент вознаграждения, которое вы и ваш друг получите."])},
      "info_msg_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для получения дополнительной информации нажмите 'Управление кодом приглашения'."])},
      "ranking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Награда за рейтинг приглашения друзей"])},
      "ranking_msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это рейтинг человека, который пригласил наибольшее количество друзей за один месяц."])},
      "ranking_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Награды за места с 1 по 10 в рейтинге 'Пригласите друга' будут автоматически начислены на ваш подарочный кошелек в 00:00 (UTC) 1-го числа следующего месяца."])},
      "ranking_msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Награды, выплачиваемые за рейтинг, можно использовать как маржу и оплату комиссий при торговле, но их нельзя вывести внешне."])},
      "expect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вознаграждение"])},
      "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Монеты"])},
      "compensation2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общее вознаграждение"])},
      "person2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реферальная ссылка"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Реферальный код"])},
      "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поделиться"])},
      "invite_rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Таблица лидеров"])},
      "rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ранг"])},
      "rank2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-й"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "influencer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявка на статус инфлюенсера"])},
      "influencerreview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверка инфлюенсера"])},
      "influencerinquire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск инфлюенсера"])},
      "compensation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вознаграждение"])},
      "invite_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приглашенные друзья"])},
      "invite_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата"])},
      "compensation3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вознаграждение за сегодня."])},
      "invite_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время"])},
      "invite_friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID пользователя"])},
      "reward_join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Награда за регистрацию"])},
      "reward_deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Награда за депозит"])},
      "reward_deal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Награда за сделки"])},
      "reward_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Монеты"])},
      "title_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приглашение друзей"])},
      "info_msg_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка или код скопированы."])},
      "code_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, установите его в управлении кодами приглашения."])}
    }
  },
  "usercenter": {
    "guidebook": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ДОМ > Help Center > Guide Book"])},
      "location2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ДОМ > Help Center > Guide Book > Подробности руководства"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск руководства"])},
      "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробности руководства"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cведения"])},
      "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Список просмотра"])},
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Просмотреть изображение"])},
      "list_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Как пополнить активы"])},
      "nodata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарегистрированное руководство не существует."])},
      "linkcopysuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скопировано в буфер обмена."])}
    },
    "notice": {
      "view": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ДОМ > Help Center > Объявления"])}
      },
      "detail": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ДОМ > Help Center > Объявления"])}
      },
      "nonotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарегистрированных уведомлений нет."])}
    },
    "inquiry": {
      "list": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ДОМ > Help Center > Отправить запрос"])},
        "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запросы"])},
        "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои запросы"])},
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип запроса"])},
        "situation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус"])},
        "answer_wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ожидание ответа"])},
        "answer_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ответ получен"])},
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аккаунт"])},
        "nolist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нет запросов."])},
        "answer_wait_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ожидание вашего ответа."])}
      },
      "reg": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ДОМ > Help Center > Отправить запрос > Мои запросы"])},
        "usercenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help Center"])},
        "guidebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Руководство пользователя"])},
        "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Объявления"])},
        "inquiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Связаться с нами"])},
        "inquirylist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запрос"])},
        "inquiry_regist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои запросы"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать тип"])},
        "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, выберите тип."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заголовок"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запрос"])},
        "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете написать не более 30 символов."])},
        "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете ввести более 30 и не более 500 символов."])},
        "att_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прикрепить файл"])},
        "att_file2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вложение"])},
        "find_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["файл"])},
        "regist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запрос был успешно отправлен."])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка."])},
        "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["выбрать элемент"])},
        "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ответ"])},
        "msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите заголовок."])},
        "msg5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите текст запроса."])}
      }
    }
  },
  "mypage": {
    "usersetting": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ДОМ > Аккаунт > Профиль"])},
      "location2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ДОМ > Аккаунт > Профиль > Настройка"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить пароль"])},
      "password2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Восстановление пароля"])},
      "google_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Authenticator"])},
      "google_not_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отвязать Google"])},
      "login_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Верификация входа"])},
      "recent_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время последнего входа"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["регистрация"])},
      "mycommission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мои комиссии"])},
      "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["скидка на комиссию за транзакции"])},
      "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Базовая плата"])},
      "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рейтинг скидки"])},
      "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["процент скидки на комиссию"])},
      "discount_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Базовая плата будет применяться к транзакции, а скидочная сумма будет зачислена на кошелек подарков в 0:00 (UTC) на следующий день после даты транзакции."])},
      "screen_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расположение элементов на экране"])},
      "notification_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки уведомлений"])},
      "sound_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки звука"])},
      "setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["настройки"])},
      "removegooglelink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы действительно хотите отключить связь с Google?"])},
      "removeotp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ссылка Google отключена."])},
      "toggle": {
        "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Объявление"])},
        "darkmode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Темная тема"])},
        "whitemode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Светлая тема"])},
        "marketstatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус рынка"])},
        "favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Избранное"])},
        "recentdeal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Транзакции"])},
        "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказ"])},
        "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баланс"])},
        "e_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вход по электронной почте"])},
        "e_liquidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Принудительная ликвидация по электронной почте"])},
        "e_withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вывод по электронной почте"])},
        "e_deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Депозит по электронной почте"])},
        "sms_wd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уведомления по SMS о входящих/исходящих транзакциях"])},
        "ordercomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказ отправлен"])},
        "trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Триггерный заказ"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена заказа"])},
        "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказ выполнен"])},
        "conclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказ выполнен"])},
        "liquidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Принудительная ликвидация"])}
      }
    },
    "authcenter": {
      "word": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ДОМ > Аккаунт > Безопасность"])},
        "accountlevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уровень безопасности аккаунта: "])},
        "safe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Безопасный"])},
        "normal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обычный"])},
        "danger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Высокий"])},
        "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTP Authenticator"])},
        "anti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анти-фишинг код"])},
        "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить"])},
        "anti2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Анти-фишинг код: "])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не более 10 символов"])}
      },
      "msg": {
        "level_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для безопасности аккаунта, пожалуйста, настройте аутентификацию безопасности."])},
        "level_msg_m1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для безопасного аккаунта"])},
        "level_msg_m2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, добавьте аутентификацию безопасности."])},
        "email_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свяжите свой адрес электронной почты с вашей учетной записью для восстановления пароля и подтверждения вывода средств"])},
        "phone_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Свяжите свой мобильный номер с вашей учетной записью, чтобы получать коды подтверждения через SMS."])},
        "google_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTP используется в качестве проверки безопасности для входа, вывода или изменения настроек безопасности."])},
        "usdt_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарегистрируйте свое удостоверение и получите 50 USDT в качестве вознаграждения."])},
        "security_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправляемые электронные письма будут содержать ваш код анти-фишинга для защиты от фишинговых атак."])},
        "login_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, убедитесь, что ваш веб-браузер показывает один из правильных SSL-URL-адресов."])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аутентификационный номер был отправлен."])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось отправить код подтверждения."])},
        "noemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарегистрированный адрес электронной почты не существует."])},
        "nophone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарегистрированный мобильный телефон не существует."])}
      }
    },
    "activity": {
      "word": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ДОМ > Аккаунт > Активность"])},
        "record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История последних входов"])},
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выйти со всех устройств"])},
        "logout2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выйти"])},
        "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время"])},
        "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Модель"])},
        "browser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Браузер"])},
        "ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP-адрес"])},
        "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Местоположение"])},
        "model2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Модель/браузер"])},
        "ip2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Расположение/IP"])},
        "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена регистрации"])},
        "withdrawfail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось отменить регистрацию."])},
        "withdraw2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить регистрацию"])},
        "android": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Android"])},
        "korea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Корея"])},
        "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы не можете зарегистрироваться с той же информацией в течение 1 месяца после отмены регистрации. \nВы хотите продолжить отмену регистрации?"])}
      },
      "msg": {
        "logout_msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это история входа в ваш аккаунт на Fubit."])},
        "logout_msg1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы заметили подозрительную активность,"])},
        "logout_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["немедленно измените пароль и выйдите со всех устройств."])},
        "logout_msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "logout_msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "withdraw_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вся информация об аккаунте будет немедленно удалена и не может быть восстановлена."])}
      }
    }
  },
  "Influencer": {
    "application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подать заявку на статус инфлюенсера"])},
    "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Результаты проверки инфлюенсера"])},
    "referer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Менеджер рефералов"])},
    "referer2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["рефералов"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Данные заявителя"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рекомендательный код инфлюенсера"])},
    "entercode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите реферальный код"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация о заявке"])},
    "enterinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите информацию о заявке"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подать заявку"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена заявки"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторно подать заявку"])},
    "noresult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причина отказа"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["успешно"])},
    "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не удалось"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])}
  },
  "nickname": {
    "change_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите желаемый никнейм."])},
    "nickname_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это доступный никнейм."])},
    "phone_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступные номера телефонов."])},
    "email_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Этот адрес электронной почты доступен."])},
    "authemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Этот адрес электронной почты уже подписан."])},
    "authphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Этот номер телефона уже аутентифицирован."])},
    "authfail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аутентификация не удалась."])},
    "nicknamefail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось изменить никнейм."])},
    "nicknamesuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш никнейм был изменен."])},
    "emailtypefail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверный формат адреса электронной почты."])},
    "emptyemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите адрес электронной почты для аутентификации."])},
    "emptyphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите номер телефона для аутентификации."])},
    "auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аутентифицировать"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["адрес электронной почты"])},
    "beforeauthphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, завершите проверку мобильного телефона."])},
    "beforeauthmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, выполните проверку электронной почты."])}
  },
  "contractdetail": {
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Детали крепления"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liq. Цена"])}
  },
  "withdraworder": {
    "cancel_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы уверены, что хотите отменить заказ?"])}
  },
  "feeinfo": {
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация о комиссии"])},
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уровень"])},
    "maker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссия за ордера ожидающие выполнения"])},
    "taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссия за исполненные ордера"])}
  },
  "authgoogle": {
    "auth_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прежде чем продолжить, проверьте."])},
    "auth_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Установлено приложение Google OTP APP?"])},
    "googleplay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Play"])},
    "appstore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App Store"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти через Google"])},
    "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка запроса Google OTP."])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP соединение"])}
  },
  "googleqr": {
    "qr_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сканируйте QR-код с помощью Google OTP."])},
    "qr_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если сканирование невозможно, введите код вручную."])},
    "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аутентификация Google"])}
  },
  "googlenumber": {
    "number_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите 6-значный код из приложения Google Authenticator."])},
    "fail_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!! Неверный код подтверждения"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка аутентификации Google OTP завершена."])}
  },
  "googlecomplete": {
    "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аутентификация Google OTP завершена"])},
    "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройка аутентификации Google OTP"])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["завершена."])}
  },
  "password": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электронная почта"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер телефона"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить пароль"])},
    "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Старый пароль"])},
    "now_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите старый пароль"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новый пароль"])},
    "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
    "new2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждение нового пароля"])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждение"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переотправить"])},
    "resend2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переотправить"])},
    "authcheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["проверено."])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сброс пароля завершен."])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, войдите, чтобы начать транзакцию."])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильный формат пароля."])},
    "msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не похоже на новый пароль."])},
    "msg5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, проверьте текущий пароль."])},
    "msg6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, проверьте новый пароль."])},
    "msg7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, проверьте пароль."])}
  },
  "layout": {
    "setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройки макета"])}
  },
  "email": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите 6-значный код верификации, полученный по электронной почте."])},
    "auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["секунд"])},
    "auth1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступно через"])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите 6-значный код аутентификации, отправленный на ваш мобильный телефон."])},
    "authsuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверено."])}
  },
  "phone": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите 6-значный код верификации, полученный по телефону."])},
    "authsuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждено."])},
    "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка."])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить повторно"])},
    "resendfail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка отправки."])}
  },
  "passwordforgot": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забыли пароль?"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите свой зарегистрированный адрес электронной почты."])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введенная информация о членстве не найдена."])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сброс пароля ограничит снятие средств на 24 часа."])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забыли пароль"])},
    "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите новый пароль."])},
    "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите новый пароль"])},
    "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите новый пароль еще раз."])},
    "text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забыли пароль? Введите свой зарегистрированный номер мобильного телефона."])},
    "text8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправить код аутентификации"])},
    "text9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось отправить код аутентификации. Пожалуйста, попробуйте еще раз."])},
    "text10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы не зарегистрированный пользователь или у вас другой идентификатор или пароль. Проверьте и попробуйте еще раз."])},
    "text11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забыли пароль? Введите свой зарегистрированный адрес электронной почты."])},
    "text12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите свой зарегистрированный мобильный телефон."])}
  },
  "orderconfirm": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждение заказа"])},
    "deal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сделка"])},
    "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заказ"])},
    "market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рынок"])},
    "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лимит"])},
    "c_market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условный рыночный ценник"])},
    "c_designation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условный лимитный ценник"])},
    "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Целевая цена"])},
    "lender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стоп-лимит"])},
    "liquidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Принудительная ликвидация"])},
    "liquidation2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["принуждение расплата"])},
    "trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Триггер"])}
  },
  "signup": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация"])},
    "promotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Промо-код или Рекомендация (Выберите)"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условия использования и Политика конфиденциальности"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Я подтверждаю и соглашаюсь с"])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уже есть аккаунт?"])},
    "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введите ваш адрес электронной почты."])},
    "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Этот адрес электронной почты уже подписан."])},
    "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неправильный формат электронной почты"])},
    "text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите ваш номер мобильного телефона."])},
    "text8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это мобильный телефон, который уже зарегистрирован."])},
    "text9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введенная информация не соответствует формату номера телефона"])},
    "text10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите пароль."])},
    "text11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль отличается от проверки пароля."])},
    "text12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введенный пароль не соответствует формату"])},
    "text13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите новый пароль еще раз."])},
    "text14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль отличается от проверки пароля."])},
    "text15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Введенный пароль не соответствует формату"])},
    "text16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код недоступен"])},
    "text17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить фото профиля"])},
    "text18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Это имя пользователя уже используется."])},
    "text19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя пользователя"])},
    "text20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Промо-код или Рекомендация (Выберите)"])}
  },
  "withdraw_detail": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Детали заявки на вывод"])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сеть"])},
    "commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссия за сеть"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полученная сумма бюджета"])},
    "execution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выполнение вывода"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Имя"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявка завершена"])},
    "successdesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваша заявка на вывод была успешно зарегистрирована."])},
    "authFail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номера аутентификации не совпадают."])},
    "getQty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получение суммы"])}
  },
  "loginconfirm": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вход"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для доступа к этому элементу необходимо войти в систему."])},
    "desc2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хотите войти в систему?"])}
  },
  "deal": {
    "closeLong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открыть Шорт"])},
    "closeShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открыть Лонг"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баланс"])},
    "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Индекс"])},
    "24change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменение 24H"])},
    "countdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["счет счетов"])},
    "funding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["бюджет"])},
    "fundingfee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Комиссия за финансирование"])},
    "valume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Объем"])},
    "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Высокий"])},
    "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hизкое"])},
    "marketprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рыночная"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Монеты"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все"])},
    "trades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сделки"])},
    "scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Масштаб"])},
    "scale2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Масштаб"])},
    "posopen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открыть"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Закрыть"])},
    "market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рынок"])},
    "trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Триггер"])},
    "cross": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кросс"])},
    "isolate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["изоляция"])},
    "long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лонг"])},
    "short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шорт"])},
    "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кол-во"])},
    "margin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маржа"])},
    "balange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баланс"])},
    "available2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступный"])},
    "pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нереализованная PNL"])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Единица"])},
    "openshort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шорт"])},
    "openlong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лонг"])},
    "openlong2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Покупка"])},
    "openshort2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продажа"])},
    "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Депозит"])},
    "convert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Конвертировать"])},
    "favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Избранное"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поиск"])},
    "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Символы"])},
    "lastprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Последняя цена"])},
    "24h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24ч"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Размер"])},
    "available3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступный"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена"])},
    "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Позиция"])},
    "pnl2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PNL"])},
    "openorder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Открытые ордеры"])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["История ордеров"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Актив"])},
    "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Символ"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Действие"])},
    "tpsl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TP/SL"])},
    "pnl3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PNL(ROE%)"])},
    "liq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена ликвидации"])},
    "side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сторона"])},
    "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рыночная цена"])},
    "leverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рычаг"])},
    "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Размер"])},
    "entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена входа"])},
    "leverage2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рычаг"])},
    "leverage3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Коэффициент кредитного плеча"])},
    "perpetual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перпетуум"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редактировать"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поделиться"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Время"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тип"])},
    "allcancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить все"])},
    "shortselling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Купить лонг"])},
    "shortsale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продать шорт"])},
    "market2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рыночная цена"])},
    "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лимит"])},
    "averagemarket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Средняя рыночная цена"])},
    "fillprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена ликвидации"])},
    "trigger2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Триггер"])},
    "liq2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лимит"])},
    "immediate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рынок"])},
    "conditionalmarket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условная рыночная цена"])},
    "conditionallimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условный лимит"])},
    "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Целевая цена"])},
    "sl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стоп-лосс"])},
    "liquidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ликвидация"])},
    "orderprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цена ордера"])},
    "trigger3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Триггер"])},
    "completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Завершение"])},
    "spotwallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Кошелек Spot"])},
    "future": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фьючерс"])},
    "freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заморозить"])},
    "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переместить"])},
    "exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обмен"])},
    "deposit2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["внесение"])},
    "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вывод"])},
    "ordercondition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условия ордера"])},
    "trigger4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Триггер"])},
    "completion2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Детали"])},
    "liquidation2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ликвидация"])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нереализованный доход и потери, а также доходность на акции основаны на средней рыночной цене."])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При достижении целевой цены и цены остановки контракт устанавливается. Вы можете установить целевую цену, задавая детали (целевая цена основана на средней рыночной цене)."])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите количество."])},
    "msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите цену."])},
    "msg5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недостаточно средств для ордера."])},
    "msg6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите цену вызова."])},
    "msg7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ордеры были размещены."])},
    "msg8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш ордер был отменен."])},
    "msg9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите Целевую Цену / Остановку Убытков"])},
    "msg10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В случае короткой позиции целевая цена должна быть выше недавней цены заключения."])},
    "msg11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В случае короткой позиции цена остановки должна быть ниже недавней цены заключения."])},
    "msg12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В случае длинной позиции целевая цена должна быть ниже недавней цены закрытия."])},
    "msg13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В случае длинной позиции цена остановки должна быть выше недавней цены закрытия."])},
    "msg14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["У нас недостаточно ликвидных активов для ликвидации контракта."])},
    "msg15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общая стоимость активов = баланс кошелька + маржа + Неопределенная прибыль / убыток."])},
    "msg16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Указывает сумму, использованную для установки неопределенного фьючерсного контракта пользователем."])},
    "msg17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Указывает баланс кошелька, в настоящее время доступный для исполнения контракта."])},
    "msg18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неопределенная прибыль и убыток и доходность на акции основаны на средней рыночной цене."])},
    "msg19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Недостаточное количество доступно для заказа."])},
    "chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["график"])},
    "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["заказ"])},
    "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевод"])},
    "order2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Статус заказа"])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправлено"])},
    "orderFail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ошибка заказа"])},
    "msg20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контракт использует USDT в качестве маржи, но представлен количеством активов на бирже."])},
    "msg21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Указывает маржу, выделенную для контракта."])},
    "msg22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Средняя цена покупки / продажи, по которой был заключен контракт."])},
    "msg23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если коэффициент положительный, то длинный трейдер будет платить проценты за финансирование короткой позиции. Если коэффициент отрицательный, то короткий трейдер будет платить проценты за финансирование длинной позиции. Проценты за финансирование рассчитываются каждые восемь часов."])},
    "msg24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если средняя рыночная цена достигнет этой цены, контракт будет ликвидирован."])},
    "msg25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контракт немедленно прекращается по средней рыночной цене."])},
    "msg26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Максимальный размер позиции для текущего кредитного плеча"])}
  },
  "alert": {
    "orderSuccess": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешный заказ"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешный заказ"])}
    },
    "positionOrder": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Войти"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для использования этой услуги необходимо войти в систему."])}
    },
    "favoriteAdd": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистрация прошла успешно"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы зарегистрированы в качестве избранного."])}
    },
    "favoriteRemove": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить регистрацию"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваши избранные элементы были отключены."])}
    }
  },
  "coin": {
    "btc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Биткоин"])},
    "xrp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Рипл"])},
    "eth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Эфириум"])},
    "sol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Солана"])},
    "clay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Клейтн"])},
    "matic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полигон"])},
    "avax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аваланч"])},
    "usdt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тетер"])},
    "doge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Догекоин"])}
  },
  "level": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уровень вывода средств"])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["При выводе всех активов лимит вывода ограничивается в зависимости от уровня аутентификации учетной записи."])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете увеличить лимит вывода, повысив уровень аутентификации своей учетной записи."])},
    "lev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уровень"])},
    "standard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Критерии сертификации"])},
    "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["лимит ежедневного вывода"])},
    "stage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Этап"])},
    "emailor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["электронная почта или мобильный телефон"])},
    "emailphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электронная почта, мобильный телефон."])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждение личности"])},
    "idfail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось подтвердить личность"])},
    "failtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причина отказа"])},
    "levelup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повышение уровня"])}
  },
  "invitemodal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код приглашения друга"])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы можете установить до пяти кодов приглашений."])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скидка будет начислена на каждый подарочный кошелек в 00:00 (UTC) следующего дня и может использоваться только в качестве депозита или комиссии за транзакции."])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код"])},
    "myyoupercent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ваш/друзей процент"])},
    "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление"])},
    "revise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Удалить"])},
    "plus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
    "code_revise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изменить код приглашения"])},
    "code_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавить код приглашения"])},
    "code2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Управление кодом"])},
    "mypercent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["процент, который вы получаете"])},
    "youpercent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["процент, по которому друг получает скидку"])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регулируемые скидки могут достигать до 30%."])}
  },
  "influencer_result": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Результаты проверки инфлюенсера"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заявитель"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код рекомендации"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация о заявке"])},
    "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Причины возврата"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отмена заявки"])},
    "re": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подать заявку заново"])}
  },
  "idcard": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтверждение личности"])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["с помощью вашего ID"])},
    "msg1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, загрузите фото, на котором видно ваше лицо"])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузить фото ID"])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарегистрируйте файл."])},
    "regist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["регистрация фото"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Загрузить фото для проверки"])},
    "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подтвердить"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запрос на подтверждение личности отправлен."])},
    "msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сфотографируйте свой ID и подайте заявку на утверждение у администратора."])},
    "cert_picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фото подтверждения"])},
    "id_picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фото ID"])},
    "before_id_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, загрузите фотографию вашего удостоверения личности."])},
    "before_cert_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, загрузите фотографию для проверки подлинности."])}
  },
  "antiphishing": {
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запрос на изменение"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код охраны рыболовства был изменен."])},
    "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось изменить Код охраны рыболовства."])},
    "antiphishing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код охраны рыболовства"])},
    "lengthover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код защиты от рыболовных сайтов не должен превышать 10 символов."])},
    "checkinput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите Код охраны рыболовства."])}
  },
  "moneytransfer": {
    "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевод"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отправитель"])},
    "kind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Спот"])},
    "gift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фьючерсы"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Баланс"])},
    "get": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Получатель"])},
    "property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Монета"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Количество"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Все"])},
    "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Внутренние переводы бесплатны."])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перевод выполнен."])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вы не можете переводить активы, полученные в качестве компенсации за мероприятия и приглашения друзей."])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если вы перемещаете активы, удерживая кросс-позицию, позиция может быть ликвидирована, и вы не сможете переместить все активы. Пожалуйста, введите обмениваемый актив точно."])}
  },
  "addressmanage": {
    "addsuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адрес кошелька был добавлен."])},
    "addfail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось зарегистрировать адрес кошелька."])},
    "removesuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ваш адрес кошелька был удален."])},
    "removefail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не удалось удалить адрес кошелька."])},
    "addname_null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите название адреса."])},
    "outputkey_null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите адрес для вывода средств."])},
    "destination_null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пожалуйста, введите код места назначения."])}
  }
}