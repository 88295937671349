import {createStore} from 'zustand/vanilla'

export const assetList = createStore(set=>({
  assets:{
    
  },
  setAssets(data){
    set(() => ({
      assets: data
    }));
  }
}))

// 입금하기 자산선택 심볼 store
export const depositSymbol = createStore(set=>({
  fromWallet:false,
  depositSymbolState:'eth',
  setDepositSymbol(data){
    set(()=>({
      depositSymbolState: data
    }))
  },
  setFromWallet(){
    set(()=>({
      fromWallet: true
    }))
  },
  removeFromWallet(){
    set(()=>({
      fromWallet: false
    }))
  }
}))

export const profitSumStore = createStore(set=>({
  profitStoreData:'',
  setProfitStore(data){
    set(()=>({
      profitStoreData: data
    }))
  }
}))

// 출금신청 상세정보 모달 데이터 전달
export const withdrawDetail = createStore(set=>({
  withdrawData:{},
  setWithdrawData(data){
    set(()=>({
      withdrawData: data
    }))
  }
}))

// 출금신청 인증 타입 저장
export const withdrawAuthType = createStore(set=>({
  authType: '',
  authUid: '',
  //패스워드 인증도 같이 쓸껀데 타입 구분
  pwChangeData: null,
  setAuthType(data){
    set(()=>({
      authType : data
    }))
  },
  setAuthUid(data){
    set(()=>({
      authUid : data
    }))
  },
  pwChangeDataSet(data){
    set(()=>({
      pwChangeData: data
    }))
  }
}))

