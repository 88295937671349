<template lang="">
  <div class="fixed z-10 flex w-screen h-full bg-black bg-opacity-50">
    <div :class="tStyle.inner">
      <div :class="tStyle.point">
        <img src="../../assets/image/path1703.png" alt="" class="dark:hidden">
        <img src="../../assets/image/path1703_d.png" alt="" class="opacity-0 dark:opacity-100">
      </div>
      <h1 :class="tStyle.title">{{ $t('feeinfo.info') }}</h1>
        <table :class="tStyle.contract_table">
          <tr :class="tStyle.table_list">
            <th :class="tStyle.order_list">{{ $t('feeinfo.level') }}</th>
            <th :class="tStyle.order_list">{{ $t('common.balance') }} (USDT)</th>
            <th :class="tStyle.order_list">{{ $t('common.30deal') }} (USDT)</th>
            <th :class="tStyle.order_list">{{ $t('feeinfo.maker') }} (%)</th>
            <th :class="tStyle.order_list">{{ $t('feeinfo.taker') }} (%)</th>
          </tr>
          <!--
          <tr v-for="info in alertData" v-bind:key="info.level" :class="tStyle.table_list">
            <td>{{ info.level }}</td>
            <td>{{ info.maker }}</td>
            <td>1,000</td>
            <td>1,000</td>
            <td>0.5</td>
          </tr>
          -->
          <tr :class="tStyle.table_list">
            <td>1</td>
            <td>1,000</td>
            <td>1,000</td>
            <td>1,000</td>
            <td>0.5</td>
          </tr>
          <tr :class="tStyle.table_list">
            <td>2</td>
            <td>1,000</td>
            <td>1,000</td>
            <td>1,000</td>
            <td>0.5</td>
          </tr>
          <tr :class="tStyle.table_list">
            <td>3</td>
            <td>1,000</td>
            <td>1,000</td>
            <td>1,000</td>
            <td>0.5</td>
          </tr>
          <tr :class="tStyle.table_list">
            <td>4</td>
            <td>1,000</td>
            <td>1,000</td>
            <td>1,000</td>
            <td>0.5</td>
          </tr>
          <tr :class="tStyle.table_list">
            <td>5</td>
            <td>1,000</td>
            <td>1,000</td>
            <td>1,000</td>
            <td>0.5</td>
          </tr>
          <tr :class="tStyle.table_list">
            <td>1</td>
            <td>1,000</td>
            <td>1,000</td>
            <td>1,000</td>
            <td>0.5</td>
          </tr>
          
        </table>

      

        <button :class="tStyle.close_bt" v-on:click="test">
        <img src="../../assets/image/group2322.png" alt="" />
      </button>
    </div>
  </div>
</template>
<script>
import { nationNumModal, modalControl } from "@/store/store";
import create from "vue-zustand";

export default {
  name: "FeeInformation",
  data() {
    return {
      tStyle: {
        inner: 'bg-white w-[1098px] h-[430px] m-auto rounded border border-[#C9C9C9] px-[40px] py-[15px] relative dark:bg-[#1c1d22] dark:border-[#606060] dark:text-[#c9c9c9]',
        point: 'absolute left-[-1px] top-[-1px]',
        title: 'mt-[10px] text-[30px]',
        contract_table: 'mt-[30px]',
        overflow_box: 'w-full h-[320px] mt-[20px] text-[13px] text-center text-[#9f9f9f]',
        order_list: 'w-[203.2px] font-normal bg-[#f7f8fa] h-[44px] border-b border-b-[#c9c9c9] dark:text-[#9f9f9f] dark:bg-[#111119] dark:border-[#606060]',
        table_list: 'h-[44px] border-b border-b-[#e5e5e5] text-[13px] text-center text-[#9f9f9f] dark:border-[#606060] dark:bg-[#25252c] dark:text-[#fff]',
        

        
        close_bt: 'absolute right-[20px] top-[18px] font-light text-[30px]',


      },
    };
  },
  setup() {
    const useNationModal = create(nationNumModal);
    const useModalControl = create(modalControl);
    const { setModalClose } = useNationModal();
    const { setModalControl, alertData } = useModalControl();

    return{
      setModalClose,
      setModalControl,
      alertData
    }
  },
  methods: {
    test(){
      this.setModalControl();
    }
  }
};
</script>
<style lang=""></style>
