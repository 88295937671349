<template lang="">
  <div class="flex fixed z-10 bg-black bg-opacity-50 w-screen h-full">
    <div :class="tStyle.inner">
      <div :class="tStyle.point">
        <img src="../../assets/image/path1703.png" alt="" class="dark:hidden" />
        <img
          src="../../assets/image/path1703_d.png"
          alt=""
          class="opacity-0 dark:opacity-100"
        />
      </div>
      <div :class="tStyle.title">
        <h2 class="text-[30px] font-bold">{{ $t('common.phone_auth') }}</h2>
        <h5 class="text-[20px] mt-[40px]">+82 10-1234-1234</h5>
        <p class="text-[20px] mt-[10px] font-light">
          {{ $t('phone.text') }}
        </p>
      </div>
      <div :class="tStyle.num_box">
        <div :class="tStyle.num"></div>
        <div :class="tStyle.num"></div>
        <div :class="tStyle.num"></div>
        <div :class="tStyle.num"></div>
        <div :class="tStyle.num"></div>
        <div :class="tStyle.num"></div>
      </div>
      <p :class="tStyle.fail">{{ $t('googlenumber.fail_msg') }}</p>

      <div :class="tStyle.bt_box">
        <button :class="tStyle.cancel">{{ $t('password.resend') }}</button>
        <button :class="tStyle.confirm">{{ $t('common.ok') }}</button>
      </div>

      <button :class="tStyle.close_bt">
        <img src="../../assets/image/group2322.png" alt="" />
      </button>
    </div>
  </div>
</template>
<script>
import { nationNumModal } from '@/store/store';
import create from 'vue-zustand';

export default {
  name: 'PhoneFail',
  data() {
    return {
      tStyle: {
        inner:
          'bg-white w-[600px] h-[540px] m-auto rounded border border-[#C9C9C9] px-[40px] py-[15px] relative text-center dark:bg-[#1c1d22] dark:border-[#606060] dark:text-[#c9c9c9]',
        title: 'mt-[50px]',
        point: 'absolute left-[-1px] top-[-1px]',
        num_box: 'mx-auto mt-[50px]',
        num: 'w-[80px] h-[80px] border border-b-[2px] border-b-[#fc0d1b] inline-block mx-[3px] dark:bg-[#3b3b43] dark:border-[#606060] dark:border-b-[#fc0d1b]',
        fail: 'text-center text-[#e7224b] text-[22px]',

        bt_box: ' mt-[48px] text-center text-[22px] dark:text-[#333]',
        cancel:
          'w-[249px] h-[60px] border border-[#cfe4fe] rounded-[3px] dark:bg-[#fff8e8] dark:border-[#fff8e8] ',
        confirm:
          'w-[249px] h-[60px] bg-[#cfe4fe] rounded-[3px] ml-[10px] dark:bg-[#ffb500]',

        close_bt: 'absolute right-[20px] top-[18px] font-light text-[30px]'
      }
    };
  },
  setup() {
    const useNationModal = create(nationNumModal);
    const { setModalClose } = useNationModal();

    return {
      setModalClose
    };
  }
};
</script>
<style lang=""></style>
