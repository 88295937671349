<template lang="">
  <div class="fixed z-10 flex w-screen h-full bg-black bg-opacity-50">
    <div :class="tStyle.inner">
      <div :class="tStyle.point">
        <img src="../../assets/image/path1707.png" alt="" class="dark:hidden" />
        <img
          src="../../assets/image/path1707_d.png"
          alt=""
          class="opacity-0 dark:opacity-100"
        />
      </div>
      <h1 :class="tStyle.title">
        {{ $t('mypage.usersetting.sound_setting') }}
      </h1>
      <div :class="tStyle.contents">
        <div :class="tStyle.slide_bt_box">
          <div>{{ $t('mypage.usersetting.toggle.ordercomplete') }}</div>
          <div
            :class="tStyle.slide_bt_bg"
            v-if="submitComplete"
            @click="submitComplete = false"
          >
            <div :class="tStyle.circle"></div>
          </div>
          <div
            :class="tStyle.slide_bt_bg2"
            v-else
            @click="submitComplete = true"
          >
            <div :class="tStyle.circle2"></div>
          </div>
        </div>
        <div :class="tStyle.slide_bt_box">
          <div>{{ $t('mypage.usersetting.toggle.cancel') }}</div>
          <div
            :class="tStyle.slide_bt_bg"
            v-if="orderCancel"
            @click="orderCancel = false"
          >
            <div :class="tStyle.circle"></div>
          </div>
          <div :class="tStyle.slide_bt_bg2" v-else @click="orderCancel = true">
            <div :class="tStyle.circle2"></div>
          </div>
        </div>
        <div :class="tStyle.slide_bt_box">
          <div>{{ $t('mypage.usersetting.toggle.complete') }}</div>
          <div
            :class="tStyle.slide_bt_bg"
            v-if="orderComplete"
            @click="orderComplete = false"
          >
            <div :class="tStyle.circle"></div>
          </div>
          <div
            :class="tStyle.slide_bt_bg2"
            v-else
            @click="orderComplete = true"
          >
            <div :class="tStyle.circle2"></div>
          </div>
        </div>
        <div :class="tStyle.slide_bt_box">
          <div>{{ $t('mypage.usersetting.toggle.trigger') }}</div>
          <div
            :class="tStyle.slide_bt_bg"
            v-if="trigger"
            @click="trigger = false"
          >
            <div :class="tStyle.circle"></div>
          </div>
          <div :class="tStyle.slide_bt_bg2" v-else @click="trigger = true">
            <div :class="tStyle.circle2"></div>
          </div>
        </div>
        <div :class="tStyle.slide_bt_box">
          <div>{{ $t('mypage.usersetting.toggle.liquidation') }}</div>
          <div
            :class="tStyle.slide_bt_bg"
            v-if="orderExecution"
            @click="orderExecution = false"
          >
            <div :class="tStyle.circle"></div>
          </div>
          <div
            :class="tStyle.slide_bt_bg2"
            v-else
            @click="orderExecution = true"
          >
            <div :class="tStyle.circle2"></div>
          </div>
        </div>
      </div>
      <div :class="tStyle.bt_box">
        <button :class="tStyle.confirm" @click="soundSettSubmit">
          {{ $t('common.ok') }}
        </button>
      </div>

      <button :class="tStyle.close_bt" @click="setModalControl">
        <img src="../../assets/image/group2322.png" alt="" />
      </button>
    </div>
  </div>
</template>

<script>
import { modalControl, isLogin, apiLanguage, userSetting ,refresh } from '@/store/store';
import create from 'vue-zustand';
import Request from '@/utils/Request';
import AesEncrypt from '../Security/AesEncrypt';

export default {
  name: 'SoundSetting',
  data() {
    return {
      submitComplete: false,
      orderCancel: false,
      orderComplete: false,
      orderExecution: false,
      trigger: false,

      tStyle: {
        inner:
          'bg-white w-[284px] h-[370px] m-auto rounded border border-[#C9C9C9] px-[20px] py-[15px] relative dark:bg-[#1c1d22] dark:border-[#606060] dark:text-[#c9c9c9]',
        point: 'absolute left-[-1px] top-[-1px]',
        title: 'text-center text-[20px]',
        contents: 'w-full mt-[32px] text-[15px] leading-[42px]',
        slide_bt_box: 'flex items-center justify-between',
        slide_bt_bg:
          'w-[35px] h-[14px] bg-[#cfe4ff] rounded-full flex items-center px-[2px] dark:bg-[#fff8e8] cursor-pointer',
        slide_bt_bg2:
          'w-[35px] h-[14px] bg-[#c9c9c9] rounded-full flex items-center px-[2px] dark:bg-[#111119] cursor-pointer',
        circle:
          'w-[10px] h-[10px] rounded-full bg-[#005cd3] dark:bg-[#ffb500] ml-auto',
        circle2: 'w-[10px] h-[10px] rounded-full bg-white dark:bg-[#c9c9c9]',
        bt_box: 'h-[35px] text-center text-[15px] mt-[20px]',
        cancel:
          'w-[117px] h-[35px] bg-[#e8eff7] rounded-[3px] mx-[2px] dark:text-[#333333] dark:bg-[#fff8e8]',
        confirm:
          'w-[117px] h-[35px] bg-[#cfe4fe] rounded-[3px] mx-[2px] dark:text-[#333333] dark:bg-[#ffb500]',

        close_bt: 'absolute right-[20px] top-[18px] font-light text-[30px]'
      }
    };
  },
  setup() {
    const useModalControl = create(modalControl);
    const useIsLogin = create(isLogin);
    const { setModalControl, isAlertModal, setAlertOpen } = useModalControl();
    const { setLogout } = useIsLogin();
    const useApiLanguage = create(apiLanguage);
    const { apiLangCode } = useApiLanguage();
    const useUserSetting = create(userSetting);
    const { setOption } = useUserSetting();
    const useRefresh = create(refresh)
    const { setRefresh } = useRefresh()

    return {
      setModalControl,
      setLogout,
      apiLangCode,
      isAlertModal,
      setAlertOpen,
      setOption,
      setRefresh
    };
  },
  mounted() {
    this.getOptionInfo();
  },
  methods: {
    async getOptionInfo() {
      let result = await Request(
        '/api/exs/mypage/v1/request_option',
        'post',
        this.apiLangCode[this.$i18n.locale]
      );

      if (!result) return;

      let { API_CODE } = result;

      if (API_CODE.status === '0000') {
        let options = result.option;
        this.setOption(options);
        //사운드 설정
        this.submitComplete = options.soundSendYn === 'Y' ? true : false;
        this.orderCancel = options.soundCancelYn === 'Y' ? true : false;
        this.orderComplete = options.soundOrderYn === 'Y' ? true : false;
        this.trigger = options.soundTriggerYn === 'Y' ? true : false;
        this.orderExecution = options.soundForceYn === 'Y' ? true : false;
      }
    },
    async setOptionInfo() {
      let optList = [
        {
          option: 'soundSendYn',
          onoff_yn: this.submitComplete ? 'Y' : 'N'
        },
        {
          option: 'soundCancelYn',
          onoff_yn: this.orderCancel ? 'Y' : 'N'
        },
        {
          option: 'soundOrderYn',
          onoff_yn: this.orderComplete ? 'Y' : 'N'
        },
        {
          option: 'soundTriggerYn',
          onoff_yn: this.trigger ? 'Y' : 'N'
        },
        {
          option: 'soundForceYn',
          onoff_yn: this.orderExecution ? 'Y' : 'N'
        }
      ];
      let data = JSON.stringify({
        data: AesEncrypt({
          list: optList
        })
      });

      let result = await Request(
        '/api/exs/mypage/v1/update_option',
        'post',
        this.apiLangCode[this.$i18n.locale],
        data
      );

      if (!result) return;

      let { status, message } = result.API_CODE;

      if (status === 'L402') {
        this.sessionClosed();
      } else if (status === '0000') {
        this.getOptionInfo()
        this.setModalControl();
        this.setRefresh('soundSetting')
        return
      } else {
        this.setAlertData({
        title: '',
        desc: message
      });
      this.setAlertOpen();
      }
    },
    sessionClosed() {
      let { t } = this.$i18n;
      this.setAlertData({
        title: '',
        desc: this.$t('login.msg15')
      });
      this.setAlertOpen();
      localStorage.removeItem('fubit_token');
      localStorage.removeItem('fubit_rtoken');
      this.setLogout();
    },
    soundSettSubmit() {
      this.setOptionInfo();
    }
  }
};
</script>
<style lang=""></style>
