<template lang="">
  <div class="fixed z-10 flex w-screen h-full sm:bg-black sm:bg-opacity-50 bg-[#f2f4f5] bg-opacity-80 px-6 sm:px-0">
    <div :class="tStyle.inner">
      <h2 class="sm:text-[30px] sm:mt-[30px] mt-[10px] text-[20px]">{{ $t('promotion.invite.info') }}</h2>
      <div class="sm:text-[18px] text-[15px] mt-[20px] leading-[26px] fold:leading-[20px] text-left">
        <p>
        {{ $t('promotion.invite.info_msg_1') }}
      </p>
      <p>
        {{ $t('promotion.invite.info_msg_2') }}
      </p>
      <p>
        {{ $t('promotion.invite.info_msg_3') }}
      </p>
      <p>
        {{ $t('promotion.invite.info_msg_4') }}
      </p>
      </div>
      
        
      <div :class="tStyle.bt_box">
        <button :class="tStyle.confirm" @click="setModalControl">{{ $t('common.ok') }}</button>
      </div>


      <button :class="tStyle.close_bt" @click="setModalControl">
        <img src="../../assets/image/group2322.png" alt="">
      </button>
    </div>
    
  </div>
</template>
<script>
import { nationNumModal, modalControl, isLogin } from '@/store/store';
import create from 'vue-zustand';

export default {
  name: 'InviteInfo',
  data() {
    return {
      tStyle: {
        inner:
          'bg-white w-full sm:w-[440px] m-auto sm:rounded sm:border border-[#C9C9C9] sm:p-[30px] p-6 relative dark:bg-[#1c1d22] dark:border-[#606060] dark:text-[#c9c9c9] text-center',
        close_bt: 'absolute right-[20px] top-[18px] font-light text-[30px] max-sm:hidden fold:hidden',
        bt_box: 'w-full flex mt-[35px] text-center text-[15px]',
        confirm: 'basis-full sm:h-[60px] text-[15px] py-1 sm:py-0 sm:text-[22px] bg-[#cfe4fe] rounded-[3px] dark:bg-[#fdd26a] dark:text-[#333]',
        
      }
    };
  },
  setup() {
    const useNationModal = create(nationNumModal);
    const useModalControl = create(modalControl);
    const useIsLogin = create(isLogin);
    const { setModalClose } = useNationModal();
    const { setModalControl, setModalName, setAlertData,setAlertOpen } = useModalControl();
    const { setLogout } = useIsLogin();

    return {
      setModalClose,
      setModalControl,
      setLogout,
      setModalName,
      setAlertData,
      setAlertOpen
    };
  },
  methods: {

  }
};
</script>
<style lang=""></style>
