<template lang="">
  <div class="fixed z-10 flex w-screen h-full bg-black bg-opacity-50">
    <div
      class="fixed top-0 z-30 flex items-center justify-between w-full px-4 py-3 sm:hidden bg-white dark:bg-[#111119]"
    >
      <button
        @click="
          () => {
            if (etcMobile) {
              setRefresh('proInvite');
            }
            setModalControl();
          }
        "
      >
        <img
          src="@/assets/image/ion_chevron_back_outline.png"
          alt=""
          class="w-[30px] dark:hidden"
        />
        <img
          src="@/assets/image/ion_chevron_back_outline_d.png"
          alt=""
          class="w-[30px] opacity-0 dark:opacity-100 mt-[-30px] dark:mt-0"
        />
      </button>
      <div
        class="text-[25px] fold:text-[20px] text-center text-[#444] dark:text-[#e5e5e5]"
      >
        {{ $t('invitemodal.code2') }}
      </div>
      <div class="w-[30px]"></div>
    </div>
    <div
      class="bg-white w-full pt-[56px] sm:w-[900px] max-sm:h-full fold:h-full m-auto sm:rounded sm:border border-[#C9C9C9] sm:px-[40px] px-4 sm:pt-[60px] relative sm:dark:bg-[#1c1d22] dark:bg-[#111119] dark:border-[#606060] dark:text-[#c9c9c9]"
    >
      <!-- point image -->
      <div class="absolute left-[-1px] top-[-1px] max-sm:hidden fold:hidden">
        <img src="../../assets/image/path1703.png" alt="" class="dark:hidden" />
        <img
          src="../../assets/image/path1703_d.png"
          alt=""
          class="opacity-0 dark:opacity-100"
        />
      </div>
      <div class="max-sm:hidden fold:hidden">
        <h2 class="text-[38px]">{{ $t('invitemodal.title') }}</h2>
        <p class="text-[#9f9f9f] text-[18px] mt-4">
          {{ $t('invitemodal.msg1') }}
        </p>
        <p class="text-[#9f9f9f] text-[18px] -mt-1">
          {{ $t('invitemodal.msg2') }}
        </p>
      </div>
      <div
        class="w-full text-center sm:text-[16px] text-[15px] sm:text-[#9f9f9f] text-[#606060] mt-5 sm:my-10"
      >
        <div
          class="w-[100%] bg-[#f7f8fa] font-regular max-sm:text-[14px] fold:text-[14px] sm:border-b border-[#c9c9c9] sm:dark:bg-[#111119] dark:border-[#606060] flex justify-between dark:bg-[#1c1d22] max-sm:dark:text-[#e5e5e5] fold:dark:text-[#e5e5e5]"
        >
          <div class="sm:w-1/3 py-0.5 w-1/2 fold:w-1/3">
            {{ $t('invitemodal.code') }}
          </div>
          <div class="w-1/3 py-0.5">{{ $t('invitemodal.myyoupercent') }}</div>
          <div class="sm:w-1/3 py-0.5 w-1/3">
            {{ $t('invitemodal.manage') }}
          </div>
        </div>

        <!-- 초대코드 관리 -->
        <div
          v-for="(item, i) in inviteList"
          :key="i"
          class="w-full flex items-center border-b border-[#e5e5e5] dark:border-[#606060] dark:bg-[#25252c]"
        >
          <div class="w-1/2 py-2 sm:w-1/3">
            <div>
              <div class="flex items-center justify-between ml-4">
                <div
                  class="w-4 h-4 border border-[#e5e5e5] rounded-full text-[#cfe4ff] max-sm:dark:text-[#e5e5e5] fold:dark:text-[#e5e5e5] cursor-pointer fold:w-3 fold:h-3"
                  :class="[
                    item.seq !== undefined && defaultSlot == item.slot
                      ? 'bg-[#005cd3] dark:bg-[#ffb500]'
                      : ''
                  ]"
                  @click="setDefaultSlot(item.slot)"
                  v-if="item.seq !== undefined"
                ></div>
                <div class="flex items-center sm:mr-[50px]">
                  <span
                    class="ml-2 max-sm:text-[13px] fold:text-[12px]"
                    v-if="item.code !== undefined"
                    >{{ item.code }}</span
                  >

                  <button
                    class="ml-2 sm:border border-[#e5e5e5] flex items-center rounded px-1 fold:px-0.5 sm:text-[12px] fold:text-[11px] text-[14px] sm:no-underline underline underline-offset-2 h-6 leading-none dark:text-[#e5e5e5]"
                    @click="codeCopy(item.code)"
                    v-if="item.code !== undefined"
                  >
                    <img
                      src="../../assets/image/icon_copy.png"
                      alt=""
                      class="inline max-sm:hidden fold:hidden"
                    />
                    {{ $t('invitemodal.copy') }}
                  </button>
                </div>
              </div>
              <span v-if="item.seq == undefined">-</span>
            </div>
          </div>

          <div class="w-1/3 py-2 text-center fold:text-[11px]">
            <span v-if="item.code !== undefined"
              >{{ item.myPercent }}% / {{ item.downPercent }}%</span
            >
            <span v-else>-</span>
          </div>
          <div
            class="flex flex-wrap sm:w-1/3 w-1/3 items-center justify-center sm:px-10 sm:text-[12px] text-[14px] fold:text-[11px] fold:leading-[16px]"
            v-if="item.seq !== undefined"
          >
            <button
              class="sm:border sm:no-underline underline underline-offset-2 sm:rounded sm:h-6 px-1 flex items-center mr-2 dark:text-[#e5e5e5]"
              @click="
                openModal(
                  'edit',
                  item.slot,
                  item.seq,
                  item.myPercent,
                  item.downPercent,
                  item.defaultYn
                )
              "
            >
              {{ $t('invitemodal.revise') }}
            </button>
            <button
              class="sm:border sm:no-underline underline underline-offset-2 sm:rounded sm:h-6 px-1 flex items-center border-[#e5e5e5] text-[#e5e5e5] dark:border-[#606060] sm:dark:text-[#606060] dark:text-[#e5e5e5]"
              @click="removeInviteCode(item.seq)"
            >
              {{ $t('invitemodal.delete') }}
            </button>
          </div>
          <div
            class="flex w-1/3 items-center justify-center sm:px-10 sm:text-[12px] text-[14px] fold:text-[11px]"
            v-else
          >
            <button
              class="sm:border sm:no-underline underline underline-offset-2 sm:rounded sm:h-6 px-1 flex items-center mr-2 dark:text-[#e5e5e5]"
              @click="openModal('add', i + 1)"
            >
              <span class="max-sm:hidden fold:hidden">+</span
              >{{ $t('invitemodal.plus') }}
            </button>
          </div>
        </div>
        <!-- 5개 까지 추가 가능 -->
        <!-- <div
          class="border-b flex border-[#e5e5e5] dark:border-[#606060] dark:bg-[#25252c] py-3 max-sm:dark:bg-[#111119] max-sm:dark:text-[#e5e5e5]"
          v-for="(a, i) in 5 - inviteList.length"
          :key="i"
        >
          <div class="py-2 sm:w-1/3 max-sm:w-1/2 max-sm:text-left max-sm:pl-2">
            -
          </div>
          <div class="w-1/3 py-2 text-center">-</div>
          <div
            class="flex sm:w-1/3 max-sm:w-1/3 items-center justify-center max-sm:justify-center px-10 max-sm:px-4 text-[12px] max-sm:text-[14px]"
          >
            <button
              class="sm:border max-sm:underline max-sm:underline-offset-2 sm:rounded sm:h-6 px-1 flex items-center mr-2 dark:text-[#e5e5e5]"
              @click="openModal('add', i + 1)"
            >
              <span class="max-sm:hidden">+</span>{{ $t('invitemodal.plus') }}
            </button>
          </div>
        </div> -->
      </div>
      <div
        class="flex my-10 text-[#9f9f9f] dark:text-[#333] max-sm:hidden fold:hidden"
      >
        <button
          class="w-full bg-[#cfe4ff] mr-2 rounded py-3 dark:bg-[#ffb500] dark:text-[#333]"
          @click="inviteCodeSubmit"
        >
          {{ $t('common.ok') }}
        </button>
      </div>

      <!-- close button -->
      <button
        class="absolute right-[20px] top-[18px] font-light text-[30px] max-sm:hidden fold:hidden"
        @click="setModalControl"
      >
        <img src="../../assets/image/group2322.png" alt="" />
      </button>
    </div>
  </div>
</template>
<script>
import {
  nationNumModal,
  modalControl,
  isLogin,
  apiLanguage,
  inviteCodeStore,
  refresh,
  checkMobile
} from '@/store/store';
import Request from '@/utils/Request';
import create from 'vue-zustand';
import AesEncrypt from '../Security/AesEncrypt';

export default {
  name: 'InviteCode',
  data() {
    return {
      defaultSlot: null,
      inviteList: [{}, {}, {}, {}, {}],
      tStyle: {}
    };
  },
  setup() {
    const useNationModal = create(nationNumModal);
    const useModalControl = create(modalControl);
    const useIsLogin = create(isLogin);
    const { setModalClose } = useNationModal();
    const {
      setModalControl,
      setModalName,
      setAlertData,
      alertData,
      setAlertOpen
    } = useModalControl();
    const { setLogout } = useIsLogin();
    const useApiLanguage = create(apiLanguage);
    const { apiLangCode } = useApiLanguage();
    const useInviteCodeStore = create(inviteCodeStore);
    const { setCodeState } = useInviteCodeStore();
    const useRefresh = create(refresh);
    const { setRefresh } = useRefresh();
    const useMobile = create(checkMobile);
    const { etcMobile } = useMobile();

    return {
      setModalClose,
      setModalControl,
      setLogout,
      setModalName,
      setAlertData,
      alertData,
      apiLangCode,
      setAlertOpen,
      setCodeState,
      setRefresh,
      etcMobile
    };
  },
  mounted() {
    this.requestInviteList();
  },
  methods: {
    codeCopy(a) {
      window.navigator.clipboard.writeText(a).then(() => {
        this.setAlertData({
          title: '',
          desc: this.$t('usercenter.guidebook.linkcopysuccess')
        });
        this.setAlertOpen();
      });
    },
    async requestInviteList() {
      let { t } = this.$i18n;

      let result = await Request(
        '/api/exs/promo/v1/request_invite_list',
        'post',
        this.apiLangCode[this.$i18n.locale]
      );

      if (!result) return;

      let { status } = result.API_CODE;
      if (status === 'L402') {
        this.setAlertData({
          title: '',
          desc: this.$t('login.msg15')
        });
        this.setAlertOpen();
        localStorage.removeItem('fubit_token');
        localStorage.removeItem('fubit_rtoken');
        this.setLogout();
        this.goMainPage();
      } else if (status === '0000') {
        // this.inviteList = result.invite_list || [];
        for (let i = 1; i < 6; i++) {
          result.invite_list.map((d) => {
            if (d.slot == i) {
              this.inviteList.splice(i - 1, 1, d);
            }
            if (d.defaultYn == 'Y') {
              this.defaultSlot = d.slot;
            }
          });
        }
      }
    },
    async removeInviteCode(seq) {
      let { t } = this.$i18n;
      let data = JSON.stringify({
        data: AesEncrypt({
          seq: seq
        })
      });

      let result = await Request(
        '/api/exs/promo/v1/remove_invite_code',
        'post',
        this.apiLangCode[this.$i18n.locale],
        data
      );

      if (!result) return;

      let { status } = result.API_CODE;

      if (status === 'L402') {
        this.setAlertData({
          title: '',
          desc: this.$t('login.msg15')
        });
        this.setAlertOpen();
        localStorage.removeItem('fubit_token');
        localStorage.removeItem('fubit_rtoken');
        this.setLogout();
        this.goMainPage();
      } else if (status === '0000') {
        //success
        this.inviteList = [{}, {}, {}, {}, {}];
        this.defaultSlot = null;
        this.requestInviteList();
      }
    },
    openModal(mode, slot, seq, my_percent, down_percent, default_yn) {
      this.setCodeState({
        type: mode,
        slot: slot,
        seq: seq,
        me: my_percent,
        you: down_percent,
        default: default_yn
      });
      this.setModalName('InvitecodeRevise');
    },
    goMainPage() {
      this.$router.push({
        name: 'MainHome'
      });
    },
    async inviteCodeSubmit() {
      let selectList = this.inviteList[this.defaultSlot - 1];
      if (selectList !== undefined) {
        let data = JSON.stringify({
          data: AesEncrypt({
            seq: selectList.seq,
            default_yn: 'Y'
          })
        });

        let result = await Request(
          '/api/exs/promo/v1/update_invite_code',
          'post',
          this.apiLangCode[this.$i18n.locale],
          data
        );

        if (!result) {
          this.setAlertData({
            title: '',
            desc: this.$t('common.fail')
          });
          return;
        }

        let { status } = result.API_CODE;
        if (status === 'L402') {
          this.setAlertData({
            title: '',
            desc: this.$t('login.msg15')
          });
          this.setAlertOpen();
          localStorage.removeItem('fubit_token');
          localStorage.removeItem('fubit_rtoken');
          this.setLogout();
          this.goMainPage();
        } else if (status === '0000') {
          //success
          this.setModalControl();
          this.setRefresh('proInvite');
        } else {
          this.setAlertData({
            title: '',
            desc: this.$t('common.fail')
          });
        }
      } else {
        this.setModalControl();
        this.setRefresh('proInvite');
      }
    },
    setDefaultSlot(a) {
      this.defaultSlot = a;
    }
  }
};
</script>
<style lang=""></style>
