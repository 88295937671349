<template lang="">
  <div
    class="fixed z-10 flex w-screen h-full sm:bg-black sm:bg-opacity-50 bg-[#f2f4f5] bg-opacity-80"
  >
    <div :class="tStyle.inner">
      <div :class="tStyle.point">
        <img src="../../assets/image/path1703.png" alt="" class="dark:hidden" />
        <img
          src="../../assets/image/path1703_d.png"
          alt=""
          class="opacity-0 dark:opacity-100"
        />
      </div>
      <div :class="tStyle.title">
        <h2
          class="text-[30px] my-[30px] font-bold max-sm:hidden fold:hidden text-center"
        >
          {{ $t('promotion.invite.share') }}
        </h2>
        <div
          class="sm:w-[500px] sm:h-[500px] max-sm:bg-no-repeat max-sm:bg-cover fold:bg-no-repeat fold:bg-cover w-[90vw] mx-auto h-[90vw] border-none bg-cover text-[#ffffff] relative"
          :class="bgImg"
          ref="canvas"
          id="mycanvas"
        >
          <div
            class="flex items-center w-full justify-between border-none sm:top-[1%] absolute top-[3.5%]"
          >
            <div
              class="sm:pl-[46px] pl-[9%] sm:mt-[4px] max-sm:mt-[3vw] sm:text-[16px] text-xs fold:text-[6px] font-bold border-none"
              :class="[
                shareData.dest == 'short'
                  ? 'dark:text-[#FF4C4C] text-[#3373CC]'
                  : 'text-[#C23E3E] dark:text-[#00CC88]'
              ]"
              id="leverage"
            >
              {{ shareData.dest == 'long' ? 'Long ' : 'Short ' }}
              {{ shareData.leverage }}X
            </div>
            <div
              class="text-[#ffffff] sm:pt-2 font-extrabold sm:text-[80px] text-[50px] fold:text-[45px] sm:leading-[75px] text-right pr-2 opacity-20 border-none"
              id="profitSum"
            >
              {{ profitSum > 0 ? '+' : '' }} {{ profitSum }}%
            </div>
          </div>

          <div
            class="border-none flex items-baseline w-full absolute top-[15%] sm:top-[16.5%]"
            id="symbolWrap"
          >
            <div
              class="sm:pl-[36px] pl-[7%] font-bold sm:text-[40px] text-[30px] fold:text-[24px] text-[#ffffff] tracking-normal sm:leading-[25px] leading-none border-none"
              id="symbol"
            >
              {{ shareData.symbol.toUpperCase() }}
            </div>
            <div
              class="sm:text-[25px] text-[20px] fold:text-[14px] font-bold border-none"
              id="usdt1"
            >
              USDT
            </div>
          </div>

          <div
            class="border-none flex items-baseline w-full absolute top-[38.5%] sm:top-[40%] fold:top-[37%]"
            id="setAverageWrap"
          >
            <div
              class="sm:pl-[38px] pl-[7%] sm:text-[25px] text-[18px] fold:text-[14px] font-bold border-none"
              id="average"
            >
              {{ setAverage }}
            </div>
            <div
              class="ml-2 sm:text-[18px] text-[14px] fold:text-[10px] font-bold border-none"
              id="usdt2"
            >
              USDT
            </div>
          </div>
          <div
            class="border-none flex items-baseline w-full absolute top-[50.5%] sm:top-[52.5%] fold:top-[49%]"
            id="recentWrap"
          >
            <div
              class="sm:pl-[38px] pl-[7%] sm:text-[25px] text-[18px] fold:text-[14px] font-bold border-none"
              id="recent"
            >
              {{ shareData.recent }}
            </div>
            <div
              class="ml-2 sm:text-[18px] text-[14px] fold:text-[10px] font-bold border-none"
              id="usdt3"
            >
              USDT
            </div>
          </div>
          <div
            class="sm:left-[40px] left-[7%] sm:text-[20px] text-[14px] fold:text-[12px] font-semibold tracking-wider border-none absolute top-[60%] sm:top-[61.5%]"
            id="today"
          >
            {{ today }}
          </div>
          <div
            class="sm:left-[36px] left-[7%] sm:text-[60px] text-[32px] fold:text-[26px] font-extrabold border-none absolute top-[71%] sm:top-[73%]"
            :class="[
              profitSum < 0
                ? 'dark:text-[#FF4C4C] text-[#3373CC]'
                : 'text-[#C23E3E] dark:text-[#00CC88]'
            ]"
            id="profitSum2"
          >
            {{ profitSum > 0 ? '+' : '' }}{{ profitSum }}%
          </div>
          <div class="h-[50px] border-none"></div>
        </div>
      </div>

      <div :class="tStyle.confirm">
        <button
          class="basis-1/2 sm:rounded dark:bg-[#fff] py-1 sm:py-0 border border-[#e8eff7] sm:dark:bg-[#fff8e9] dark:text-[#333] bg-white"
          @click="setModalControl"
        >
          {{ $t('common.cancel') }}
        </button>
        <button
          class="basis-1/2 sm:rounded dark:bg-[#fdd26a] py-1 sm:py-0 bg-[#cfe4fe] sm:dark:bg-[#ffb500] dark:text-[#333] ml-[10px]"
          id="downClick"
          @click="imgDownloading"
        >
          {{ $t('trade.modal.download') }}
        </button>
      </div>

      <button :class="tStyle.close_bt" @click="setModalControl">
        <img src="../../assets/image/group2322.png" alt="" />
      </button>
    </div>
  </div>
</template>
<script>
import { modalControl } from '@/store/store';
import { stopLimit } from '@/store/positionStore';
import create from 'vue-zustand';
import domtoimage from 'dom-to-image-more';

export default {
  name: 'ShareDownload',
  data() {
    return {
      today: '',
      shareData: {},
      bgImg: '',
      tStyle: {
        inner:
          'bg-[#f8f9fb] max-sm:w-full fold:w-full m-auto sm:rounded sm:border border-[#C9C9C9] max-sm:p-0 sm:px-[40px] sm:py-[15px] relative dark:bg-[#1c1d22] dark:border-[#606060] dark:text-[#c9c9c9]',
        title: 'max-sm:text-left fold:text-left',
        point: 'absolute left-[-1px] top-[-1px] max-sm:hidden fold:hidden',
        confirm:
          'sm:w-full w-[90vw] mx-auto sm:h-[60px] flex sm:my-[30px] max-sm:text-[15px] fold:text-[15px] p-4 sm:p-0 max-sm:bg-[#fff] fold:bg-[#fff]',

        close_bt:
          'absolute right-[20px] top-[18px] font-light text-[30px] max-sm:hidden fold:hidden'
      }
    };
  },
  setup() {
    const useModalControl = create(modalControl);
    const { setModalControl } = useModalControl();
    const useStopLimit = create(stopLimit);
    const { stopLimitData } = useStopLimit();

    return {
      setModalControl,
      stopLimitData
    };
  },
  created() {
    this.shareData = this.stopLimitData;
    const a = this.shareData;
    if (a.type == 'profit') {
      const p = (a.quantity / a.margin) * 100
      if (0 <= p && p < 50) {
          this.bgImg = 'bg-shareUp33';
        } else if (50 <= p && p < 100) {
          this.bgImg = 'bg-shareUp22';
        } else if (100 <= p) {
          this.bgImg = 'bg-shareUp11';
        } else if (p < 0 && p > -30) {
          this.bgImg = 'bg-shareDown11';
        } else if (p <= -30) {
          this.bgImg = 'bg-shareDown22';
        }
    } else {
      if (a.dest == 'long') {
        const b = (((a.recent - a.average) * a.quantity) / a.margin) * 100;
        if (0 <= b && b < 50) {
          this.bgImg = 'bg-shareUp3';
        } else if (50 <= b && b < 100) {
          this.bgImg = 'bg-shareUp2';
        } else if (100 <= b) {
          this.bgImg = 'bg-shareUp1';
        } else if (b < 0 && b > -30) {
          this.bgImg = 'bg-shareDown1';
        } else if (b <= -30) {
          this.bgImg = 'bg-shareDown2';
        }
      } else {
        const c = (((a.average - a.recent) * a.quantity) / a.margin) * 100;
        if (0 <= c && c < 50) {
          this.bgImg = 'bg-shareUp3';
        } else if (50 <= c && c < 100) {
          this.bgImg = 'bg-shareUp2';
        } else if (100 <= c) {
          this.bgImg = 'bg-shareUp1';
        } else if (c < 0 && c > -30) {
          this.bgImg = 'bg-shareDown1';
        } else if (c <= -30) {
          this.bgImg = 'bg-shareDown2';
        }
      }
    }

    this.getDate();
  },
  methods: {
    getDate() {
      const newDate = new Date();
      const year = String(newDate.getFullYear());
      const month = String(newDate.getMonth() + 1).padStart(2, '0');
      const date = String(newDate.getDate()).padStart(2, '0');
      this.today = year + '-' + month + '-' + date;
    },
    async imgDownloading() {
      const imgCanvas = this.$refs.canvas;
      domtoimage.toPng(imgCanvas).then((dataUrl) => {
        let link = document.createElement('a');
        link.download = 'profit-share.jpeg';
        link.href = dataUrl;
        link.click();
      });
    },
    setDecimal(num, i) {
      const number = parseFloat(num);
      const place = Math.pow(10, i);
      return (Math.floor(number * place) / place).toFixed(i);
    }
  },
  computed: {
    profitSum() {
      const a = this.shareData;
      if(a.type == 'profit'){
        const p = (a.quantity / a.margin) * 100
        if((0 < p && p < 1) || (0 > p && -1 < p)){
          return this.setDecimal(p , 2)
        } else {
          return parseInt(p)
        }
      } else {
        if (a.dest == 'long') {
        const b = (((a.recent - a.average) * a.quantity) / a.margin) * 100;
        if ((0 < b && b < 1) || (0 > b && -1 < b)) {
          return b.toFixed(2);
        } else {
          return parseInt(b);
        }
      } else {
        const c = (((a.average - a.recent) * a.quantity) / a.margin) * 100;
        if ((0 < c && c < 1) || (0 > c && -1 < c)) {
          return c.toFixed(2);
        } else {
          return parseInt(c);
        }
      }
      }
      
    },
    setAverage() {
      const coin = this.shareData.symbol;
      const avg = this.shareData.average;
      if (coin == 'btc') {
        return this.setDecimal(avg, 2);
      } else if (coin == 'eth') {
        return this.setDecimal(avg, 2);
      } else if (coin == 'xrp') {
        return this.setDecimal(avg, 4);
      } else if (coin == 'matic') {
        return this.setDecimal(avg, 5);
      } else if (coin == 'doge') {
        return this.setDecimal(avg, 6);
      } else if (coin == 'arb') {
        return this.setDecimal(avg, 6);
      } else if (coin == 'sei') {
        return this.setDecimal(avg, 6);
      } else if (coin == 'sol') {
        return this.setDecimal(avg, 6);
      } else if (coin == 'stx') {
        return this.setDecimal(avg, 6);
      } else if (coin == 'avax') {
        return this.setDecimal(avg, 6);
      } else if (coin == 'gas') {
        return this.setDecimal(avg, 6);
      } else if (coin == 'axs') {
        return this.setDecimal(avg, 6);
      } else if (coin == 'pol') {
        return this.setDecimal(avg, 5);
      }
    }
  },
  mounted() {
    // let canvas = this.$refs.canvas;
    // let context = canvas.getContext('2d');
    // context.fillStyle = '#fc0d1b';
    // context.font = 'bold 12px serif'
    // context.letterSpacing = '5px'
    // context.fillText(`${this.shareData.dest}`, 20 ,20);
  }
};
</script>

<style scoped>
@media (min-width: 768px) and (max-width: 820px) {
  #leverage {
    font-size: 20px;
    margin-top: 4.5vw;
  }
  #profitSum {
    font-size: 100px;
    margin-right: 40px;
  }
  #symbolWrap {
    top: 18%;
  }
  #symbol {
    font-size: 60px;
  }
  #usdt1 {
    font-size: 25px;
  }
  #setAverageWrap {
    top: 41%;
  }
  #average {
    font-size: 30px;
  }
  #usdt2 {
    font-size: 25px;
  }
  #recentWrap {
    top: 53%;
  }
  #recent {
    font-size: 30px;
  }
  #usdt3 {
    font-size: 25px;
  }
  #today {
    font-size: 25px;
    top: 62%;
  }
  #profitSum2 {
    font-size: 80px;
    top: 73%;
  }
}
</style>
