<template lang="">
  <div
    class="w-full bg-[#fff] dark:bg-[#1c1d22] border-t border-[#c9c9c9] dark:border-[#606060] mt-[1px]"
  >
    <div class="sm:w-[1396px] mx-auto bg-[#fff] dark:bg-[#1c1d22]">
      <div
        class="w-full flex justify-between dark:border-b-[#606060] py-[40px]"
      >
        <div>
          <div class="float-left mr-[40px]">
            <img
              src="../assets/image/group1203.png"
              alt=""
              class="dark:hidden"
            />
            <img
              src="../assets/image/group1203_d.png"
              alt=""
              class="opacity-0 dark:opacity-100"
            />
          </div>
          <div class="text-[13px] text-[#9f9f9f] inline-block">
            <div
              class="leading-[16px] mt-[5px] text-[#606060] dark:text-[#c9c9c9]"
            >
              {{ $t('footer.msg1') }} <br />{{ $t('footer.msg2') }}<br />{{
                $t('footer.msg3')
              }}
            </div>
            <div class="text-[11px] font-thin">
              Copyright © Fu bit All Rights Reserved
              <span
                class="ml-[12px] underline cursor-pointer"
                @click="goTermPage"
                >{{ $t('signup.text1') }}</span
              >
            </div>
          </div>
        </div>
        <!-- <ul class="flex">
          <li
            class="w-[27px] h-[20px] mr-[20px] cursor-pointer"
            @click="goOutPage('https://twitter.com/Fubit_official')"
          >
            <img src="../assets/image/path403.png" alt="" class="dark:hidden" />
            <img
              src="../assets/image/path403_d.png"
              alt=""
              class="opacity-0 dark:opacity-100"
            />
          </li>
          <li class="w-[20px] h-[20px] mr-[20px]">
            <img src="../assets/image/path402.png" alt="" class="dark:hidden" />
            <img
              src="../assets/image/path402_d.png"
              alt=""
              class="opacity-0 dark:opacity-100"
            />
          </li>
          <li class="w-[20px] h-[20px] mr-[20px] ">
            <img
              src="../assets/image/group1204.png"
              alt=""
              class="dark:hidden"
            />
            <img
              src="../assets/image/group1204_d.png"
              alt=""
              class="opacity-0 dark:opacity-100"
            />
          </li>
          <li class="w-[20px] h-[20px] mr-[20px] ">
            <img src="../assets/image/path398.png" alt="" class="dark:hidden" />
            <img
              src="../assets/image/path398_d.png"
              alt=""
              class="opacity-0 dark:opacity-100"
            />
          </li>
          <li class="w-[14px] h-[20px] mr-[20px]">
            <img src="../assets/image/path404.png" alt="" class="dark:hidden" />
            <img
              src="../assets/image/path404_d.png"
              alt=""
              class="opacity-0 dark:opacity-100"
            />
          </li>
        </ul> -->
      </div>
    </div>
  </div>
</template>
<script>
import { modalControl } from '@/store/store';
import create from 'vue-zustand';
export default {
  name: 'MainFooter',
  data() {
    return {};
  },
  methods: {
    goOutPage(a) {
      window.open(a, '_blank');
    },
    goTermPage() {
      this.setModalName('TermPolicy');
      this.setModalControl();
    }
  },
  setup() {
    const useModalControl = create(modalControl);
    const { setModalName, setModalControl } = useModalControl();

    return {
      setModalName,
      setModalControl
    };
  }
};
</script>
<style lang=""></style>
