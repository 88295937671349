import { createStore } from 'zustand/vanilla';
import produce from 'immer';

//leverage 데이터
export const leverage = createStore((set) => ({
  longLeverage: '25',
  shortLeverage: '25',
  setLongLev(data) {
    set(() => ({
      longLeverage: data
    }));
  },
  setShortLev(data) {
    set(() => ({
      shortLeverage: data
    }));
  }
}));

//증거금 방식 margin 데이터
export const margin = createStore((set) => ({
  marginType: 'cross',
  setMarginType(data) {
    set(() => ({
      marginType: data
    }));
  }
}));

//aggTrade 소켓 데이터 여기다 다 저장
export const aggSocketData = createStore((set) => ({
  btcAggData: {},
  ethAggData: {},
  xrpAggData: {},
  maticAggData: {},
  dogeAggData: {},
  arbAggData: {},
  seiAggData: {},
  solAggData: {},
  stxAggData: {},
  avaxAggData: {},
  gasAggData: {},
  axsAggData: {},
  polAggData: {},
  setAggData(data, symbol) {
    if (symbol == 'btc') {
      set(() => ({
        btcAggData: data
      }));
    } else if (symbol == 'eth') {
      set(() => ({
        ethAggData: data
      }));
    } else if (symbol == 'xrp') {
      set(() => ({
        xrpAggData: data
      }));
    } else if (symbol == 'matic') {
      set(() => ({
        maticAggData: data
      }));
    } else if (symbol == 'doge') {
      set(() => ({
        dogeAggData: data
      }));
    } else if (symbol == 'arb') {
      set(() => ({
        arbAggData: data
      }));
    } else if (symbol == 'sei') {
      set(() => ({
        seiAggData: data
      }));
    } else if (symbol == 'sol') {
      set(() => ({
        solAggData: data
      }));
    } else if (symbol == 'stx') {
      set(() => ({
        stxAggData: data
      }));
    } else if (symbol == 'avax') {
      set(() => ({
        avaxAggData: data
      }));
    } else if (symbol == 'gas') {
      set(() => ({
        gasAggData: data
      }));
    } else if (symbol == 'axs') {
      set(() => ({
        axsAggData: data
      }));
    } else if (symbol == 'pol') {
      set(() => ({
        polAggData: data
      }));
    }
  }
}));

// ticker 소켓데이터 여기다 다 저장
export const tickSocketData = createStore((set) => ({
  btcTickData: {},
  ethTickData: {},
  xrpTickData: {},
  maticTickData: {},
  dogeTickData: {},
  arbTickData: {},
  seiTickData: {},
  solTickData: {},
  stxTickData: {},
  avaxTickData: {},
  gasTickData: {},
  axsTickData: {},
  polTickData: {},
  setTickData(data, symbol) {
    if (symbol == 'btc') {
      set(() => ({
        btcTickData: data
      }));
    } else if (symbol == 'eth') {
      set(() => ({
        ethTickData: data
      }));
    } else if (symbol == 'xrp') {
      set(() => ({
        xrpTickData: data
      }));
    } else if (symbol == 'matic') {
      set(() => ({
        maticTickData: data
      }));
    } else if (symbol == 'doge') {
      set(() => ({
        dogeTickData: data
      }));
    } else if (symbol == 'arb') {
      set(() => ({
        arbTickData: data
      }));
    } else if (symbol == 'sei') {
      set(() => ({
        seiTickData: data
      }));
    } else if (symbol == 'sol') {
      set(() => ({
        solTickData: data
      }));
    } else if (symbol == 'stx') {
      set(() => ({
        stxTickData: data
      }));
    } else if (symbol == 'avax') {
      set(() => ({
        avaxTickData: data
      }));
    } else if (symbol == 'gas') {
      set(() => ({
        gasTickData: data
      }));
    } else if (symbol == 'axs') {
      set(() => ({
        axsTickData: data
      }));
    } else if (symbol == 'pol') {
      set(() => ({
        polTickData: data
      }));
    }
  }
}));

// 호가창 소켓 데이터
export const tradeSocketData = createStore((set) => ({
  btcTradeData: {},
  ethTradeData: {},
  xrpTradeData: {},
  maticTradeData: {},
  dogeTradeData: {},
  arbTradeData: {},
  seiTradeData: {},
  solTradeData: {},
  stxTradeData: {},
  avaxTradeData: {},
  gasTradeData: {},
  axsTradeData: {},
  polTradeData: {},
  setTradeData(data, symbol) {
    if (symbol == 'btc') {
      set(() => ({
        btcTradeData: data
      }));
    } else if (symbol == 'eth') {
      set(() => ({
        ethTradeData: data
      }));
    } else if (symbol == 'xrp') {
      set(() => ({
        xrpTradeData: data
      }));
    } else if (symbol == 'matic') {
      set(() => ({
        maticTradeData: data
      }));
    } else if (symbol == 'doge') {
      set(() => ({
        dogeTradeData: data
      }));
    } else if (symbol == 'arb') {
      set(() => ({
        arbTradeData: data
      }));
    } else if (symbol == 'sei') {
      set(() => ({
        seiTradeData: data
      }));
    } else if (symbol == 'sol') {
      set(() => ({
        solTradeData: data
      }));
    } else if (symbol == 'stx') {
      set(() => ({
        stxTradeData: data
      }));
    } else if (symbol == 'avax') {
      set(() => ({
        avaxTradeData: data
      }));
    } else if (symbol == 'gas') {
      set(() => ({
        gasTradeData: data
      }));
    } else if (symbol == 'axs') {
      set(() => ({
        axsTradeData: data
      }));
    } else if (symbol == 'pol') {
      set(() => ({
        polTradeData: data
      }));
    }
  }
}));

// 시장가 소켓 데이터
export const markSocketData = createStore((set) => ({
  btcMarkData: {},
  ethMarkData: {},
  xrpMarkData: {},
  maticMarkData: {},
  dogeMarkData: {},
  arbMarkData: {},
  seiMarkData: {},
  solMarkData: {},
  stxMarkData: {},
  avaxMarkData: {},
  gasMarkData: {},
  axsMarkData: {},
  polMarkData: {},
  setMarkData(data, symbol) {
    if (symbol == 'btc') {
      set(() => ({
        btcMarkData: data
      }));
    } else if (symbol == 'eth') {
      set(() => ({
        ethMarkData: data
      }));
    } else if (symbol == 'xrp') {
      set(() => ({
        xrpMarkData: data
      }));
    } else if (symbol == 'matic') {
      set(() => ({
        maticMarkData: data
      }));
    } else if (symbol == 'doge') {
      set(() => ({
        dogeMarkData: data
      }));
    } else if (symbol == 'arb') {
      set(() => ({
        arbMarkData: data
      }));
    } else if (symbol == 'sei') {
      set(() => ({
        seiMarkData: data
      }));
    } else if (symbol == 'sol') {
      set(() => ({
        solMarkData: data
      }));
    } else if (symbol == 'stx') {
      set(() => ({
        stxMarkData: data
      }));
    } else if (symbol == 'avax') {
      set(() => ({
        avaxMarkData: data
      }));
    } else if (symbol == 'gas') {
      set(() => ({
        gasMarkData: data
      }));
    } else if (symbol == 'axs') {
      set(() => ({
        axsMarkData: data
      }));
    } else if (symbol == 'pol') {
      set(() => ({
        polMarkData: data
      }));
    }
  }
}));

//청산하기 모달 연동 데이터
export const payoffData = createStore((set) => ({
  payState: {
    pSymbol: '',
    pMarginT: '',
    pDest: '',
    pLeverage: '',
    pAverage: '',
    pQuantity: ''
  },
  setPayOff(obj) {
    set(() => ({
      payState: obj
    }));
  }
}));

//주문 확인서 데이터 저장
export const orderConfirm = createStore((set) => ({
  apiRecall: false,
  orderData: {
    position: '',
    symbol: '',
    type: '',
    dest: '',
    price: '',
    leverage: '',
    margin: '',
    quantity: '',
    trigger: '',
    actionPrice: ''
  },
  setOrderData(data) {
    set(() => ({
      orderData: data
    }));
  },
  setApiCall() {
    set((state) => ({
      apiRecall: !state.apiRecall
    }));
  }
}));

//증거금 수정 모달 데이터
export const marginEdit = createStore((set) => ({
  ableAsset: {},
  setAbleAsset(data) {
    set(() => ({
      ableAsset: data
    }));
  }
}));

// 주문내역 상세 체결 조회
export const orderDetail = createStore((set) => ({
  orderDetailState: { orderSeq: '' },
  setOrderSeq(data) {
    set(
      produce((state) => {
        state.orderDetailState.orderSeq = data;
      })
    );
  }
}));

//목표/손절가 모달 데이터
export const stopLimit = createStore((set) => ({
  stopLimitData: {},
  setStopLimitData(data) {
    set(() => ({
      stopLimitData: data
    }));
  }
}));

//메인화면 시장현황 데이터
export const markListStore = createStore((set) => ({
  markData: {},
  setMarkList(data) {
    set(() => ({
      markData: data
    }));
  }
}));

// 거래하기 하단 리스트 데이터 전달
export const tableData = createStore((set) => ({}));

// 거래하기 차트 리랜더링
export const chartRender = createStore((set) => ({
  chartOpen: false,
  setChartOpen(data) {
    set(() => ({
      chartOpen: data
    }));
  }
}));

// 포지션 리스트
export const positionList = createStore((set) => ({
  positionData: [],
  lineRefresh: false,
  setPosition(data) {
    set(() => ({
      positionData: data
    }));
  },
  setLineRefresh() {
    set((state) => ({
      lineRefresh: !state.lineRefresh
    }));
  }
}));
