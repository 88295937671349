<template lang="">
  <div class="fixed z-10 flex w-screen h-full bg-black bg-opacity-50">
    <div :class="tStyle.inner">
      <!-- 모바일 header -->
      <div
        class="flex items-center w-full px-4 justify-between fixed bg-white py-3 dark:bg-[#111119] sm:hidden"
      >
        <button class="" @click="closeModal">
          <img
            src="@/assets/image/ion_chevron_back_outline.png"
            alt=""
            class="w-[30px] dark:hidden"
          />
          <img
            src="@/assets/image/ion_chevron_back_outline_d.png"
            alt=""
            class="w-[30px] mt-[-30px] dark:mt-0 dark:opacity-100 opacity-0"
          />
        </button>
        <div class="text-[25px] text-center text-[#444] dark:text-[#e5e5e5]">
          {{ $t('common.usersetting') }}
        </div>
        <div class="w-[30px]"></div>
      </div>

      <div class="absolute left-[-1px] top-[-1px] max-sm:hidden fold:hidden">
        <img src="../../assets/image/path1703.png" alt="" class="dark:hidden" />
        <img
          src="../../assets/image/path1703_d.png"
          alt=""
          class="opacity-0 dark:opacity-100"
        />
      </div>
      <h1 :class="tStyle.title">
        {{ $t('common.setting') }}
      </h1>
      <div :class="tStyle.contents">
        <div>
          <div class="sm:pl-[10px]">{{ $t('common.change_nickname') }}</div>
          <input
            type="text"
            ref="nickname"
            v-model="nicknameInput"
            :placeholder="$t('nickname.change_msg')"
            :class="tStyle.change_input"
          />
          <span :class="nValidClass" v-html="nText"> </span>
        </div>
        <div>
          <div class="sm:pl-[10px]">UID</div>
          <input
            type="text"
            v-model="uid"
            :placeholder="$t('common.uuid')"
            :class="tStyle.uuid_input"
            disabled
          />
        </div>

        <div>
          <div class="sm:pl-[10px] mt-[20px] sm:mt-0">
            {{ $t('common.phone') }}
            <button :class="tStyle.revise" @click="handlePhoneModify">
              {{ $t('common.retouch') }}
            </button>
          </div>
        </div>
        <div>
          <div style="position: relative">
            <button
              :class="tStyle.country_input"
              @click="setNationNumModalOpen"
              :disabled="isNationModify ? false : true"
            >
              +{{ selectNationCode }}
            </button>
            <input
              type="number"
              v-model="phone"
              :class="tStyle.number_input"
              :disabled="isPhoneModify ? false : true"
            />
            <button
              v-if="isPhoneModify"
              style="
                position: absolute;
                top: 52%;
                right: 15px;
                transform: translateY(-50%);
              "
              :class="tStyle.revise"
              @click="handleCertificatePhone"
            >
              {{ $t('nickname.auth') }}
            </button>
          </div>
          <span :class="pValidClass" v-html="pText"> </span>
        </div>
        <div>
          <div class="sm:pl-[10px]">
            {{ $t('nickname.email') }}
            <button :class="tStyle.revise" @click="handleEmailModify">
              {{ $t('common.retouch') }}
            </button>
          </div>
          <div style="position: relative">
            <input
              type="text"
              v-model="email"
              :placeholder="
                alertData.newValue != undefined
                  ? alertData.newValue
                  : alertData.uemail
              "
              :class="tStyle.change_input"
              :disabled="isEmailModify ? false : true"
            />
            <button
              v-if="isEmailModify"
              style="
                position: absolute;
                top: 52%;
                right: 15px;
                transform: translateY(-50%);
              "
              :class="tStyle.revise"
              @click="handleCertificateEmail"
            >
              {{ $t('nickname.auth') }}
            </button>
          </div>
          <span :class="validClass" v-html="text" v-if="isEmailModify"> </span>
        </div>
      </div>
      <div
        class="text-[22px] dark:text-[#333333] flex sm:mt-[20px] mt-auto px-4 sm:px-0"
        :class="[!isEmailModify ? 'mt-[45px]' : '']"
      >
        <button :class="tStyle.cancel" @click="closeModal">
          {{ $t('common.cancel') }}
        </button>
        <button :class="tStyle.confirm" @click="setUserSetting">
          {{ $t('common.ok') }}
        </button>
      </div>

      <button :class="tStyle.close_bt" @click="closeModal">
        <img src="../../assets/image/group2322.png" alt="" />
      </button>
    </div>
  </div>
</template>
<script>
import {
  nationNumModal,
  modalControl,
  isLogin,
  apiLanguage,
  refresh
} from '@/store/store';
import Request from '@/utils/Request';
import AesEncrypt from '@/components/Security/AesEncrypt';
import create from 'vue-zustand';

export default {
  name: 'NicknameSetting',
  data() {
    return {
      isPhoneModify: false,
      isEmailModify: false,
      isNationModify: false,
      nicknameInput: '',
      uid: '',
      email: '',
      phone: '',
      validClass:
        'text-[12px] text-[#005cd3] block mt-[-5px] mb-[10px] dark:text-[#fdd26a] sm:pt-[30px] pt-[10px]',
      text: '',
      pValidClass:
        'text-[12px] text-[#005cd3] block mt-[-5px] mb-[10px] dark:text-[#fdd26a] sm:pt-[30px] pt-[10px]',
      pText: '',
      nValidClass:
        'text-[12px] text-[#005cd3] block mt-[-5px] mb-[10px] dark:text-[#fdd26a] sm:pt-[30px] pt-[10px]',
      nText: '',

      tStyle: {
        inner:
          'bg-white w-full h-full sm:w-[550px] sm:h-[700px] m-auto sm:rounded sm:border border-[#C9C9C9] sm:px-[20px] sm:py-[15px] relative sm:dark:bg-[#1c1d22] dark:border-[#606060] dark:text-[#c9c9c9] dark:bg-[#111119] max-sm:flex fold:flex sm:flex-row flex-col pb-4',
        title: 'text-center text-[30px] mt-[40px] max-sm:hidden fold:hidden',
        contents:
          'w-full sm:mt-[32px] sm:text-[20px] text-[14px] pt-[76px] sm:pt-0 px-4 sm:px-0 mt-[20px] max-sm:dark:text-[#e5e5e5] fold:dark:text-[#e5e5e5]',
        uuid_input:
          'w-full sm:h-[55px] py-1 sm:py-0 border pl-[10px] sm:text-[18px] text-[13px] sm:mb-[25px] sm:rounded-[3px] sm:dark:bg-[#3b3b43] dark:border-[#606060] sm:dark:placeholder:text-[#fff] dark:bg-[#1c1d22] dark:placeholder:text-[#9f9f9f]',
        change_input:
          'w-full sm:h-[55px] py-1 sm:py-0 border pl-[10px] sm:text-[18px] text-[13px] sm:rounded-[3px] sm:dark:bg-[#3b3b43] dark:border-[#606060] sm:dark:placeholder:text-[#fff] dark:bg-[#1c1d22] dark:placeholder:text-[#9f9f9f]',
        country_input:
          'w-[19%] sm:h-[55px] py-1 sm:py-0 border pl-[10px] sm:text-[18px] text-[13px] sm:rounded-[3px] mr-[2%] sm:dark:bg-[#25252c] dark:border-[#606060] dark:placeholder:text-[#fff] dark:bg-[#1c1d22] max-sm:bg-[#f7f8fa] max-sm:text-[#82949f] max-sm:dark:text-[#9f9f9f] fold:bg-[#f7f8fa] fold:text-[#82949f] fold:dark:text-[#9f9f9f]',
        number_input:
          'w-[79%] sm:h-[55px] py-1 sm:py-0 border pl-[10px] sm:text-[18px] text-[13px] sm:rounded-[3px] sm:dark:bg-[#3b3b43] dark:border-[#606060] sm:dark:placeholder:text-[#fff] dark:bg-[#1c1d22] dark:placeholder:text-[#9f9f9f]',
        revise:
          'float-right text-[13px] text-[#9f9f9f] underline max-sm:dark:text-[#e5e5e5] fold:dark:text-[#e5e5e5]',

        cancel:
          'w-[249px] sm:h-[60px] max-sm:text-[15px] fold:text-[15px] py-1 sm:py-0 border border-[#cfe4fe] sm:rounded-[3px] sm:dark:bg-[#fff8e8] sm:dark:border-[#fff8e8] dark:border-[#fff] dark:bg-[#fff]',
        confirm:
          'w-[249px] sm:h-[60px] max-sm:text-[15px] fold:text-[15px] py-1 sm:py-0 bg-[#cfe4fe] sm:rounded-[3px] ml-[10px] dark:bg-[#fdd26a]',

        use: 'text-[12px] text-[#005cd3] block mt-[-5px] mb-[10px] dark:text-[#fdd26a]',
        useFail:
          'text-[12px] text-[#e7224b] block mt-[-5px] mb-[10px] dark:text-[#fdd26a]',
        hidden:
          'text-[12px] text-[#005cd3] block mt-[-5px] mb-[10px] dark:text-[#fdd26a] sm:pt-[30px] pt-[10px]',

        close_bt:
          'absolute right-[20px] top-[18px] font-light text-[30px] max-sm:hidden fold:hidden'
      }
    };
  },
  setup() {
    const useNationModal = create(nationNumModal);
    const useModalControl = create(modalControl);
    const useIsLogin = create(isLogin);
    const { setNationNumModalOpen, selectNationCode } = useNationModal();
    const {
      setModalControl,
      alertData,
      setModalName,
      setAlertData,
      setAlertOpen
    } = useModalControl();
    const { setLogout, loginState } = useIsLogin();
    const useApiLanguage = create(apiLanguage);
    const { apiLangCode } = useApiLanguage();
    const useRefresh = create(refresh);
    const { setRefresh } = useRefresh();

    return {
      setNationNumModalOpen,
      selectNationCode,
      setModalControl,
      setModalName,
      alertData,
      setAlertData,
      setLogout,
      loginState,
      setAlertOpen,
      apiLangCode,
      setRefresh
    };
  },
  mounted() {},
  watch: {
    email(a) {
      const emailRegx = /^[A-Za-z0-9_\\.\\-]+@[A-Za-z0-9\\-]+\.[A-Za-z0-9\\-]+/;
      if (a !== this.alertData.uemail) {
        if (!emailRegx.test(a)) {
          this.validClass = this.tStyle.useFail;
          this.text = this.$t('nickname.emailtypefail');
        } else {
          this.validClass = this.tStyle.use;
          this.text = this.$t('nickname.email_msg');
        }
      }
    }
  },
  methods: {
    handlePhoneModify() {
      // 서버에서 휴대폰 인증 처리 되면 아래 두개 주석 해제
      this.isPhoneModify = true;
      this.isNationModify = true;

      // 휴대폰 인증 처리되기 전까지 모달로 막기
      // this.setAlertData({
      //   title: '',
      //   desc: 'We are conducting a review of our SMS system to ensure a secure service. Please refer to our notice for more details.'
      // });
      // this.setAlertOpen();
    },
    handleEmailModify() {
      this.isEmailModify = true;
    },
    async setUserSetting() {
      const nickname = this.nicknameInput;

      if (nickname === '') {
        this.nText = this.$t('nickname.change_msg');
        this.nValidClass = this.tStyle.useFail;
        return;
      }
      if (this.alertData.uname == nickname) {
        this.setModalControl();
        return;
      }

      // if (this.isPhoneModify) {
      //   this.setAlertData({
      //     title: '',
      //     desc: t('nickname.beforeauthphone')
      //   });
      //   this.setAlertOpen();
      //   return;
      // }

      const data = JSON.stringify({
        data: AesEncrypt({
          uname: nickname
        })
      });

      let result = await Request(
        '/api/exs/mypage/v1/send_nickname',
        'post',
        this.apiLangCode[this.$i18n.locale],
        data
      );

      if (!result) {
        this.openAlertModal('', this.$t('nickname.nicknamefail'));
        this.setModalControl();
        return;
      }

      let { status } = result.API_CODE;

      if (status === 'L402') {
        this.setAlertData({
          title: '',
          desc: this.$t('login.msg15')
        });
        this.setAlertOpen();
        localStorage.removeItem('fubit_token');
        localStorage.removeItem('fubit_rtoken');
        this.setLogout();
        this.goMainPage();
      } else if (status === '0000') {
        this.openAlertModal('', this.$t('nickname.nicknamesuccess'));
        this.setModalControl();
        this.setRefresh('nickSetting');
      } else {
        this.openAlertModal('', this.$t('nickname.nicknamefail'));
        this.setModalControl();
      }
    },
    async handleCertificateEmail() {
      //validation
      if (this.email === this.alertData.uemail) {
        this.validClass = this.tStyle.useFail;
        this.text = this.$t('nickname.authemail');
        return;
      } else if (this.email === '') {
        this.validClass = this.tStyle.useFail;
        this.text = this.$t('nickname.emptyemail');
        return;
      }

      //api
      let result = await this.requestAuth(
        'email',
        this.alertData.authType,
        this.email
      );

      if (!result) return;

      //open EmailNumber

      this.setAlertData({ ...result.auth });
      this.setModalName('EmailNumber');
    },
    //휴대폰 인증
    async handleCertificatePhone() {
      //validation
      if (this.phone === this.alertData.uphone) {
        this.pValidClass = this.tStyle.useFail;
        this.pText = this.$t('nickname.authphone');
        return;
      } else if (this.phone === '') {
        this.pValidClass = this.tStyle.useFail;
        this.pText = this.$t('nickname.emptyphone');
        return;
      }
      //api
      let result = await this.requestAuth(
        'phone',
        'phone',
        '+' + this.selectNationCode + String(this.phone)
      );

      if (!result) return;

      //open PhoneNumber
      this.setAlertData({ ...result });
      this.setModalName('PhoneNumber');
    },
    async requestAuth(type, auth_type, new_value) {
      // let new_hash = CryptoJS.SHA512(new_value).toString(CryptoJS.enc.Hex);

      let data = JSON.stringify({
        data: AesEncrypt({
          type: type,
          auth_type: auth_type,
          new_value: new_value
        })
      });

      let result = await Request(
        '/api/exs/mypage/v1/request_auth',
        'post',
        this.apiLangCode[this.$i18n.locale],
        data
      );

      if (!result) {
        this.openAlertModal('', this.$t('nickname.authfail'));
        this.setModalControl();
        return;
      }

      let { API_CODE } = result;
      if (API_CODE.status === 'L402') {
        this.setAlertData({
          title: '',
          desc: this.$t('login.msg15')
        });
        this.setAlertOpen();
        localStorage.removeItem('fubit_token');
        localStorage.removeItem('fubit_rtoken');
        this.setLogout();
        this.goMainPage();
      } else if (API_CODE.status === '0000') {
        return result;
      } else {
        this.openAlertModal(API_CODE.title, API_CODE.message);
        this.setModalControl();
        return false;
      }
      return false;
    },
    openNationalModal() {
      this.setNationNumModalOpen();
    },
    openAlertModal(title, desc) {
      this.setAlertData({ title, desc });
      this.setAlertOpen();
    },
    goMainPage() {
      this.$router.push({
        name: 'MainHome'
      });
    },
    closeModal() {
      this.setAlertData({});
      this.setModalControl();
    },
    async getRequestSetting() {
      let result = await Request(
        '/api/exs/mypage/v1/request_mypage_info',
        'post',
        this.apiLangCode[this.$i18n.locale]
      );

      if (!result) return;
      let { API_CODE } = result;
      if (API_CODE.status === 'L402') {
        this.setAlertData({
          title: '',
          desc: this.$t('login.msg15')
        });
        this.setAlertOpen();
        localStorage.removeItem('fubit_token');
        localStorage.removeItem('fubit_rtoken');
        this.setLogout();
        this.goMainPage();
      } else if (API_CODE.status === '0000') {
        let { login, fee, user, USER, influence } = result;
        this.loginInfo = { ...login, ...user, ...USER, ...fee, ...influence };
        this.nicknameInput = this.loginInfo.uname;
        this.uid = this.loginInfo.duid;

        this.phone =
          this.loginInfo?.uphone == undefined
            ? ''
            : this.loginInfo?.uphone.substring(3);
        if (this.loginInfo.authEmailYn == 'Y') {
          this.email = this.loginInfo.uemail;
        }
      } else {
        this.openAlertModal(API_CODE.title, API_CODE.message);
      }
    }
  },
  created() {
    this.getRequestSetting();
  }
};
</script>
<style lang=""></style>
