<template lang="">
  <div
    class="fixed z-10 w-screen h-full bg-white dark:bg-[#111119]"
    v-if="etcMobile"
  >
    <!-- header -->
    <div
      class="flex z-[10] sm:hidden items-center justify-between w-full px-4 fixed top-0 bg-white py-3 dark:bg-[#111119]"
    >
      <button @click="setModalControl">
        <img
          src="@/assets/image/ion_chevron_back_outline.png"
          alt=""
          class="w-[30px] dark:hidden"
        />
        <img
          src="@/assets/image/ion_chevron_back_outline_d.png"
          alt=""
          class="w-[30px] opacity-0 dark:opacity-100 mt-[-30px] dark:mt-0"
        />
      </button>
      <div class="text-[25px] text-center text-[#444] dark:text-[#e5e5e5]">
        {{ $t('mywallet.walletview.mobilelist2') }}
      </div>
      <div class="w-[30px]"></div>
    </div>
    <!-- 탭메뉴 -->
    <div
      class="pt-[56px] fixed h-[55px] w-full mt-[8px] flex flex-wrap text-center text-[15px] px-4 sm:px-0"
    >
      <div
        :class="[btnType == 'deposit' ? tStyle.btnY : tStyle.btnN]"
        @click="btnSelect('deposit')"
      >
        {{ $t('mywallet.depositwallet.word.depositdetail') }}
      </div>
      <div
        :class="[btnType == 'exchange' ? tStyle.btnY : tStyle.btnN]"
        @click="btnSelect('exchange')"
      >
        {{ $t('mywallet.exchange.word.history') }}
      </div>
      <div
        :class="[btnType == 'withdraw' ? tStyle.btnY : tStyle.btnN]"
        @click="btnSelect('withdraw')"
      >
        {{ $t('mywallet.withdraw.word.withdrawdetail') }}
      </div>
    </div>

    <div
      class="mt-[120px] h-full overflow-y-scroll px-4 dark:text-[#e5e5e5] pb-[150px]"
    >
      <!-- 입금내역 -->
      <div class="pt-10 overflow-y-scroll" v-if="btnType == 'deposit'">
        <!-- 한개영역 -->
        <div
          class="py-4 px-2 border-b border-[#a0a0a0]"
          v-for="(a, i) in depositList"
        >
          <div class="flex items-center text-[15px] justify-between">
            <div class="flex items-center">
              <div class="bg-[#fff8e8] w-[25px] h-[25px] rounded mr-2">
                <img :src="require(`@/assets/image/${a.symbol}.png`)" alt="" />
              </div>
              <div>{{ a.symbol.toUpperCase() }}</div>
            </div>
            <div>
              {{
                a.state == 'P'
                  ? $t('common.subscribe')
                  : a.state == 'I'
                  ? $t('common.progress')
                  : a.state == 'C'
                  ? $t('common.completion')
                  : a.state == 'R'
                  ? $t('common.cancel')
                  : ''
              }}
            </div>
          </div>
          <!-- 날짜 -->
          <div class="text-[13px] text-[#a0a0a0] dark:text-[#9f9f9f]">
            {{ a.regDt }}
          </div>

          <div
            class="bg-[#f7f8fa] flex flex-wrap text-[13px] fold:text-[12px] text-center py-2 leading-[24px] dark:bg-[#1c1d22]"
          >
            <div class="basis-1/2">
              <div class="text-[#a0a0a0]">{{ $t('common.asset') }}</div>
              <div class="font-bold">{{ $t('common.stock') }}</div>
            </div>
            <div class="basis-1/2">
              <div class="text-[#a0a0a0]">
                {{ $t('mywallet.depositwallet.word.amount') }}
              </div>
              <div class="font-bold">
                {{ parseFloat(setDecimal(a.quantity, 6)) }}
                {{ a.symbol.toUpperCase() }}
              </div>
            </div>
            <div class="basis-full mt-[20px] truncate shrink-0">
              <div class="text-[#a0a0a0]">
                {{ $t('mywallet.depositwallet.word.address') }}
              </div>
              <div class="px-2 font-medium truncate">
                {{ a.keyMain }} <br />
                {{ a.keySub }}
              </div>
            </div>
          </div>
        </div>
        <!-- 더보기버튼 -->
        <div class="w-full mt-10 text-center">
          <button
            class="w-[90%] border border-[#cfe4fe] text-[13px] py-1 dark:bg-[#fff] dark:text-[#333] dark:border-[#fff]"
            v-if="inputPaging.more"
          >
            {{ $t('mainhome.word.viewmore') }}
          </button>
        </div>
      </div>
      <!-- 교환내역 -->
      <div class="pt-10" v-else-if="btnType == 'exchange'">
        <!-- 한개영역 -->
        <div
          class="py-4 px-2 border-b border-[#a0a0a0]"
          v-for="(a, i) in changeList"
        >
          <div class="flex items-center text-[15px] justify-between">
            <div class="flex items-center">
              <div class="flex items-center">
                <div class="bg-[#fff8e8] w-[25px] h-[25px] rounded mr-2">
                  <img
                    :src="require(`@/assets/image/${a.fromSymbol}.png`)"
                    alt=""
                  />
                </div>
                <div>{{ a.fromSymbol.toUpperCase() }}</div>
              </div>
              <div class="mx-1">
                <img
                  src="@/assets/image/icon_exc.png"
                  alt=""
                  class="w-[30px]"
                />
              </div>
              <div class="flex items-center">
                <div class="bg-[#fff8e8] w-[25px] h-[25px] rounded mr-2">
                  <img
                    :src="require(`@/assets/image/${a.toSymbol}.png`)"
                    alt=""
                  />
                </div>
                <div>{{ a.toSymbol.toUpperCase() }}</div>
              </div>
            </div>
            <div>
              {{
                a.state == 'P'
                  ? $t('common.subscribe')
                  : a.state == 'I'
                  ? $t('common.progress')
                  : a.state == 'C'
                  ? $t('common.completion')
                  : a.state == 'R'
                  ? $t('common.cancel')
                  : ''
              }}
            </div>
          </div>
          <!-- 날짜 -->
          <div class="text-[13px] text-[#a0a0a0] dark:text-[#9f9f9f]">
            {{ a.regDt }}
          </div>

          <div
            class="bg-[#f7f8fa] flex flex-wrap text-[13px] fold:text-[12px] text-center py-2 leading-[24px] dark:bg-[#1c1d22]"
          >
            <div class="basis-1/2">
              <div class="text-[#a0a0a0]">
                {{ $t('mywallet.exchange.word.exchange_amount') }}
              </div>
              <div class="font-bold">
                {{ parseFloat(setDecimal(a.fromQty, 6)) }}
                {{ a.fromSymbol.toUpperCase() }}
              </div>
            </div>
            <div class="basis-1/2">
              <div class="text-[#a0a0a0]">
                {{ $t('common.price') }}
              </div>
              <div class="font-bold">
                {{ a.rate == 0 ? '0' : setDecimal(a.rate, 6) }}
                {{ a.toSymbol.toUpperCase() }}
              </div>
            </div>
            <div class="basis-1/2 mt-[20px]">
              <div class="text-[#a0a0a0]">
                {{ $t('common.commission') }}
              </div>
              <div class="font-bold">
                {{ a.fee }} {{ a.fromSymbol.toUpperCase() }}
              </div>
            </div>
            <div class="basis-1/2 mt-[20px]">
              <div class="text-[#a0a0a0]">
                {{ $t('mywallet.exchange.word.exchanged') }}
              </div>
              <div class="font-bold">
                {{ a.toQty == 0 ? '0' : parseFloat(setDecimal(a.toQty, 6)) }}
                {{ a.toSymbol.toUpperCase() }}
              </div>
            </div>
          </div>
        </div>
        <!-- 더보기버튼 -->
        <div class="w-full mt-10 text-center">
          <button
            class="w-[90%] border border-[#cfe4fe] text-[13px] py-1 dark:bg-[#fff] dark:text-[#333] dark:border-[#fff]"
            v-if="changePaging.more"
          >
            {{ $t('mainhome.word.viewmore') }}
          </button>
        </div>
      </div>
      <!-- 출금내역 -->
      <div class="pt-10" v-else-if="btnType == 'withdraw'">
        <!-- 한개영역 -->
        <div
          class="py-4 px-2 border-b border-[#a0a0a0]"
          v-for="(a, i) in outputList"
        >
          <div class="flex items-center text-[15px] justify-between">
            <div class="flex items-center">
              <div class="bg-[#fff8e8] w-[25px] h-[25px] rounded mr-2">
                <img :src="require(`@/assets/image/${a.symbol}.png`)" alt="" />
              </div>
              <div>{{ a.symbol.toUpperCase() }}</div>
            </div>
            <div>
              {{
                a.state == 'P'
                  ? $t('common.subscribe')
                  : a.state == 'I'
                  ? $t('common.progress')
                  : a.state == 'C'
                  ? $t('common.completion')
                  : a.state == 'R'
                  ? $t('common.cancel')
                  : ''
              }}
            </div>
          </div>
          <!-- 날짜 -->
          <div class="text-[13px] text-[#a0a0a0] dark:text-[#9f9f9f]">
            {{ a.regDt }}
          </div>

          <div
            class="bg-[#f7f8fa] flex flex-wrap text-[13px] text-center py-2 leading-[24px] dark:bg-[#1c1d22]"
          >
            <div class="basis-1/2">
              <div class="text-[#a0a0a0]">
                {{ $t('mywallet.withdraw.word.amount') }}
              </div>
              <div class="font-bold">
                {{ parseFloat(setDecimal(a.quantity, 6)) }}
                {{ a.symbol.toUpperCase() }}
              </div>
            </div>
            <div class="basis-1/2">
              <div class="text-[#a0a0a0]">
                {{ $t('common.commission') }}
              </div>
              <div class="font-bold">
                {{ a.fee }} {{ a.symbol.toUpperCase() }}
              </div>
            </div>
            <div class="basis-full mt-[20px] shrink-0 truncate">
              <div class="text-[#a0a0a0]">
                {{ $t('mywallet.withdraw.word.withdrawaddress') }}
              </div>
              <div class="font-medium truncate">
                {{ a.keyMain }} <br />
                {{ a.keySub }}
              </div>
            </div>
          </div>
        </div>
        <!-- 더보기버튼 -->
        <div class="w-full mt-10 text-center">
          <button
            class="w-[90%] border border-[#cfe4fe] text-[13px] py-1 dark:bg-[#fff] dark:text-[#333] dark:border-[#fff]"
            v-if="outputPaging.more"
          >
            {{ $t('mainhome.word.viewmore') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  modalControl,
  darkMode,
  apiLanguage,
  refresh,
  checkMobile
} from '@/store/store';
import create from 'vue-zustand';
import Request from '@/utils/Request';
import AesEncrypt from '@/components/Security/AesEncrypt';

export default {
  name: 'AssetList',
  data() {
    return {
      isDark: null,
      btnType: 'deposit',
      inputPaging: {
        count: 20,
        page: 1,
        more: true
      },
      changePaging: {
        count: 20,
        page: 1,
        more: true
      },
      outputPaging: {
        count: 20,
        page: 1,
        more: true
      },
      depositList: [],
      changeList: [],
      outputList: [],
      tStyle: {
        btnY: 'basis-1/3 h-[55px] leading-[53px] leading-[16px] border-t-2 border-[#005cd3] text-[#005cd3] bg-white dark:bg-[#111119] dark:text-[#e5e5e5] dark:border-[#ffb500] font-medium',
        btnN: 'basis-1/3 h-[55px] leading-[53px] leading-[16px] border-t-2 border-[#c9c9c9] bg-[#f2f4f5] dark:bg-[#333] dark:text-[#e5e5e5] dark:border-[#606060]'
      }
    };
  },
  setup() {
    const useModalControl = create(modalControl);
    const { setModalControl, setAlertData, setAlertOpen } = useModalControl();
    const useDarkMode = create(darkMode);
    const { isDarkMode } = useDarkMode();
    const useApiLanguage = create(apiLanguage);
    const { apiLangCode } = useApiLanguage();
    const useRefresh = create(refresh);
    const { loadApi, setRefresh } = useRefresh();
    const useMobile = create(checkMobile);
    const { etcMobile } = useMobile();

    return {
      setModalControl,
      isDarkMode,
      apiLangCode,
      loadApi,
      setRefresh,
      setAlertData,
      setAlertOpen,
      etcMobile
    };
  },
  methods: {
    setDecimal(num, i) {
      const number = parseFloat(num);
      const place = Math.pow(10, i);
      return (Math.floor(number * place) / place).toFixed(i);
    },
    btnSelect(data) {
      this.btnType = data;
    },
    async depositListApi() {
      let data = JSON.stringify({
        data: AesEncrypt({
          symbol: 'all',
          type: 'input',
          pagecount: this.inputPaging.count,
          page: this.inputPaging.page
        })
      });

      let result = await Request(
        '/api/exs/user/v1/request_user_io_list',
        'post',
        this.apiLangCode[this.$i18n.locale],
        data
      );

      if (!result) return;

      let { status, message } = result.API_CODE;

      if (status === '0000') {
        this.depositList = result.data_list;
        if (result.data_list.length == this.inputPaging.count) {
          this.inputPaging.page++;
        } else {
          this.inputPaging.more = false;
        }
      } else {
        this.setAlertData({
          title: '',
          desc: message
        });
        this.setAlertOpen();
      }
    },
    async exchangeListApi() {
      let data = JSON.stringify({
        data: AesEncrypt({
          symbol: 'all',
          pagecount: this.changePaging.count,
          page: this.changePaging.page
        })
      });

      let result = await Request(
        '/api/exs/user/v1/request_user_exchange_list',
        'post',
        this.apiLangCode[this.$i18n.locale],
        data
      );

      if (!result) return;

      let { status, message } = result.API_CODE;

      if (status === '0000') {
        this.changeList = result.data_list;
        if (result.data_list.length == this.changePaging.count) {
          this.changePaging.page++;
        } else {
          this.changePaging.more = false;
        }
      } else {
        this.setAlertData({
          title: '',
          desc: message
        });
        this.setAlertOpen();
      }
    },
    async withdrawListApi() {
      let data = JSON.stringify({
        data: AesEncrypt({
          symbol: 'all',
          type: 'output',
          pagecount: this.outputPaging.count,
          page: this.outputPaging.page
        })
      });

      let result = await Request(
        '/api/exs/user/v1/request_user_io_list',
        'post',
        this.apiLangCode[this.$i18n.locale],
        data
      );

      if (!result) return;

      let { status, message } = result.API_CODE;

      if (status === '0000') {
        this.outputList = result.data_list;
        if (result.data_list.length == this.outputPaging.count) {
          this.outputPaging.page++;
        } else {
          this.outputPaging.more = false;
        }
      } else {
        this.setAlertData({
          title: '',
          desc: message
        });
        this.setAlertOpen();
      }
    }
  },
  mounted() {},
  created() {
    this.depositListApi();
    this.exchangeListApi();
    this.withdrawListApi();
  },
  watch: {
    loadApi(a) {
      if (a == 'withdrawlist') {
        this.withdrawListApi();
        this.setRefresh('');
      }
    }
  }
};
</script>
<style lang=""></style>
