<template lang="">
  <div class="fixed z-10 flex w-screen h-full bg-black bg-opacity-50">
    <div :class="tStyle.inner">
      <div :class="tStyle.point">
        <img src="../../assets/image/path1703.png" alt="" class="dark:hidden">
        <img src="../../assets/image/path1703_d.png" alt="" class="opacity-0 dark:opacity-100">
      </div>
      <div :class="tStyle.title">
        <h2 class="text-[40px] font-bold ">{{ $t('googlecomplete.complete') }}</h2>
        <p class="text-[20px] mt-[40px] font-light">
          {{ $t('googlecomplete.msg') }}<br>{{ $t('googlecomplete.msg2') }}
        </p>
      </div>
      
      
        <button :class="tStyle.confirm">
          {{ $t('common.ok') }}
        </button>      
      

        <button :class="tStyle.close_bt">
        <img src="../../assets/image/group2322.png" alt="">
      </button>
    </div>
  </div>
</template>
<script>
import { nationNumModal } from "@/store/store";
import create from "vue-zustand";

export default {
  name: "GoogleCompleted",
  data() {
    return {
      tStyle: {
        inner: 'bg-white w-[600px] h-[355px] m-auto rounded border border-[#C9C9C9] px-[40px] py-[15px] relative dark:bg-[#1c1d22] dark:text-[#c9c9c9] dark:border-[#606060]',
        title: 'text-center mt-[50px]',
        point: 'absolute left-[-1px] top-[-1px]',
        
  
        confirm: 'w-full h-[60px] bg-[#cfe4fe] rounded-[3px] mt-[50px] dark:bg-[#ffb500] dark:text-[#333]',
        

        
        close_bt: 'absolute right-[20px] top-[18px] font-light text-[30px]',


      },
    };
  },
  setup() {
    const useNationModal = create(nationNumModal);
    const { setModalClose } = useNationModal();

    return{
      setModalClose,
    }
  },
};
</script>
<style lang=""></style>
