<template>
  <div class="">
    <div
      class="fixed flex flex-col items-center justify-center w-screen h-full bg-gray-300/40 dark:bg-gray-600/40"
      v-for="(a, i) in popupData"
      :key="i"
    >
      <div
        class="relative max-sm:w-[80vw] sm:w-[400px] h-[400px] max-sm:h-[80vw] fold:h-[80vw]"
      >
        <div class="w-full h-full cursor-pointer" @click="popupLink(a.link)">
          <img
            class="object-fill w-full h-full border-none"
            :src="a.imageUrl"
          />
        </div>
        <div class="max-sm:hidden fold:hidden">
          <img
            src="../assets/image/group2322.png"
            alt=""
            class="absolute right-[20px] top-[20px] cursor-pointer"
            @click="closePopup(i, a.seq)"
          />
        </div>
        <div
          class="sm:w-[400px] sm:h-[55px] max-sm:py-4 fold:py-4 bg-[#fff] dark:bg-[#1c1d22] px-[20px] flex items-center text-[15px] justify-between"
        >
          <div class="flex items-center justify-center max-sm:hidden fold:hidden">
            <input
              type="checkbox"
              name=""
              id="noPop"
              class="appearance-none w-[14px] h-[14px] border border-[#cfe4fe] checked:bg-[#005cd3] dark:border-[#ffb500] dark:checked:bg-[#ffb500] cursor-pointer"
              v-model="noReplayPop"
            />
            <label for="noPop">
              <p class="ml-[5px] dark:text-[#ffb500] cursor-pointer">
                {{ $t('mainhome.msg.modalmsg') }}
              </p>
            </label>
          </div>
          <div class="max-sm:flex max-sm:w-full fold:flex fold:w-full max-sm:text-[13px] fold:text-[13px] max-sm:leading-[16px] fold:leading-[16px]">
            <button
              class="basis-1/2 max-sm:py-1 border border-[#cfe4fe] dark:bg-[#ffb500] rounded dark:text-[#333] sm:hidden mr-[10px] dark:border-[#ffb500]"
              @click="mobileNorepeat(i, a.seq)"
            >
              {{ $t('mainhome.msg.modalmsg') }}
            </button>
            <button
              class="max-sm:basis-1/2 fold:basis-1/2 max-sm:py-1 fold:py-1 sm:px-[45px] bg-[#cfe4fe] dark:bg-[#ffb500] rounded dark:text-[#333]"
              @click="closePopup(i, a.seq)"
            >
              {{ $t('mainhome.word.close') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import AesDecrypt from './Security/AesDecrypt';
import { apiLanguage } from '@/store/store';
import create from 'vue-zustand';
export default {
  name: 'PopUp',
  components: {},
  data() {
    return {
      popupData: [],
      noReplayPop: false,
      sampleData: ''
    };
  },
  setup() {
    const useApiLanguage = create(apiLanguage);
    const { apiLangCode } = useApiLanguage();
    return {
      apiLangCode
    };
  },
  created() {},
  mounted() {
    this.popupListApi();
  },
  unmounted() {},
  methods: {
    mobileNorepeat(i, a) {
      this.popupData.splice(i);
      const arr = [];

      const local = localStorage.getItem('fubit_pop');
      if (local !== null) {
        const newArray = JSON.parse(local);
        newArray.forEach((i) => arr.push(i));
      }
      arr.push(a);
      localStorage.setItem('fubit_pop', JSON.stringify(arr));
    },
    closePopup(i, a) {
      this.popupData.splice(i);
      if (this.noReplayPop === true) {
        const arr = [];
        const local = localStorage.getItem('fubit_pop');
        if (local !== null) {
          const newArray = JSON.parse(local);
          newArray.forEach((i) => arr.push(i));
        }
        arr.push(a);
        localStorage.setItem('fubit_pop', JSON.stringify(arr));
      }
    },
    popupListApi() {
      const config = {
        method: 'post',
        url: `${process.env.VUE_APP_MAIN_API_URI}/api/exs/main/v1/request_popup`,
        headers: {
          language: this.apiLangCode[this.$i18n.locale],
          apikey: process.env.VUE_APP_MAIN_API_KEY,
          'Content-Type': 'application/json'
        }
      };

      axios(config).then((res) => {
        const decrypted = AesDecrypt(res.data.data.toString());
        const resData = JSON.parse(decrypted);
        if (resData.API_CODE.status == '0000') {
          const resArray = resData.popup_list;
          const getArray = [];
          const local = JSON.parse(localStorage.getItem('fubit_pop'));
          if (local === null) {
            this.popupData = resArray.slice().reverse();
          } else {
            resArray.map((d) => {
              if (!local.includes(d.seq)) {
                getArray.push(d);
              }
            });
            this.popupData = getArray
          }
        }
      });
    },
    popupLink(a) {
      if (a !== undefined) {
        window.open(`https://${a}`, '_blank');
      }
    }
  }
};
</script>
