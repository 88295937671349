<template lang="">
  <div class="fixed z-10 flex w-screen h-full bg-black bg-opacity-50">
    <div :class="tStyle.inner">
      <div class="flex justify-center w-full max-sm:px-[24px] fold:px-[24px]">
        <button class="mr-auto -ml-2 sm:opacity-0">
          <img
            src="@/assets/image/ion_chevron_back_outline.png"
            alt=""
            class="w-[30px]"
          />
        </button>
        <div
          class="sm:mt-[23.5px] flex-1 -ml-4 max-sm:text-[28px] fold:text-[28px] text-center text-[#444] dark:text-[#c9c9c9]"
        >
          {{ $t('login.signup') }}
        </div>
      </div>

      <div class="w-full px-6 mt-20">
        <!-- google email 들어갈 곳 -->
        <input
          type="text"
          class="border w-full rounded bg-[#f7f8fa] mb-[15px] sm:mb-0 py-1 max-sm:text-[14px] fold:text-[14px] px-[10px] sm:px-0 border-[#C9C9C9] dark:bg-[#1c1d22] placeholder:text-[#606060] leading-0.5 outline-none"
          readonly
          :placeholder="mailInputValue"
        />

        <div>
          <div class="text-[15px]">{{ $t('signup.promotion') }}</div>
          <input
            type="text"
            class="border w-full rounded mb-[15px] sm:mb-0 py-1 max-sm:text-[14px] fold:text-[14px] px-[10px] sm:px-0 border-[#C9C9C9] dark:bg-[#1c1d22] placeholder:text-[#c9c9c9] leading-0.5"
            :placeholder="$t('signup.text20')"
          />
        </div>
        <div class="flex w-full mt-[16px] max-sm:text-[15px] fold:text-[15px]">
          <input type="checkbox" class="mr-[5px]" />
          <div class="underline sm:hidden" @click="goTermsModal">
            {{ $t('login.msg26') }}
          </div>
          <div class="text-[#a0a0a0]">{{ $t('login.msg8') }}</div>
        </div>
      </div>

      <div class="px-6 pb-6 mt-auto">
        <button :class="tStyle.confirm">
          {{ $t('common.ok') }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { modalControl } from '@/store/store';
import { mailLoginInput } from '@/store/loginStore';
import create from 'vue-zustand';

export default {
  name: 'GoogleLogin',
  data() {
    return {
      tStyle: {
        inner:
          'flex flex-col bg-white max-sm:w-full max-sm:h-full fold:w-full fold:h-full pt-[26px] sm:pt-0 m-auto dark:bg-[#1c1d22] dark:text-[#c9c9c9] dark:border-[#606060]',
        confirm:
          'w-full sm:h-[60px] py-2 bg-[#cfe4fe] rounded dark:bg-[#ffb500] dark:text-[#333] text-[14px]'
      }
    };
  },
  setup() {
    const useModalControl = create(modalControl);
    const { setModalControl , setModalName } = useModalControl();
    const useMailLoginInput = create(mailLoginInput);
    const { mailInputValue } = useMailLoginInput();

    return {
      setModalControl,
      mailInputValue,
      setModalName
    };
  },
  methods: {
    goTermsModal(){
      this.setModalName('TermPolicy')
    }
  },
};
</script>
<style lang=""></style>
