export default {
  "common": {
    "nation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nation"])},
    "nation_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a country"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "search2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a search term."])},
    "search3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coin"])},
    "asset_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset Management"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today"])},
    "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1W"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["month"])},
    "1month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1M"])},
    "3month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3M"])},
    "mywallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet"])},
    "viewwallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
    "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit"])},
    "exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convert"])},
    "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdraw"])},
    "margin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Margin"])},
    "operate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
    "deposit2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit"])},
    "exchange2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convert"])},
    "withdraw2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdraw"])},
    "trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trade"])},
    "coinsearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search coins available for deposit"])},
    "networkselection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
    "division": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["type"])},
    "condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completed"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
    "commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee"])},
    "invest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["History"])},
    "recentorder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Orders"])},
    "orderhistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset"])},
    "profitloss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PNL"])},
    "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symbol"])},
    "leverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leverage"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
    "invest_limits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit"])},
    "invest_marketprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market"])},
    "invest_conditional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigger"])},
    "orderprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
    "orderquantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "contract_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Executed"])},
    "contract_amount2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fastening quantity"])},
    "contract_Average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average"])},
    "contract_Average2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average closing price"])},
    "target_loss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TP / SL"])},
    "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Side"])},
    "indefinitely": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perpetual"])},
    "crossbuy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross Long"])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submitted"])},
    "part_contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partially Filled"])},
    "contract_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])},
    "contract_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conclusion"])},
    "trigger-clue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trigger condition"])},
    "trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigger condition"])},
    "trigger_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigger Price"])},
    "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event"])},
    "event_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["event details"])},
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral"])},
    "usercenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help Center"])},
    "guidebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beginner's Guide"])},
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Announcement"])},
    "contact us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit a Request"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "writedate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "mypage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account"])},
    "usersetting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "resetting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "authcenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security"])},
    "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
    "phone_auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS Verification"])},
    "email_auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Verification"])},
    "auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["auth"])},
    "google2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTP"])},
    "otp_auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTP Authenticator"])},
    "change_nickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change nickname"])},
    "uuid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter UUID"])},
    "retouch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fix"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "deauthorization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deauthorization"])},
    "certification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["set up"])},
    "removeauthfail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication failed."])},
    "removeauthsuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication has been revoked."])},
    "certificated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["complete"])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["set up"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Futures Balance"])},
    "30deal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30d Trade Volume"])},
    "google_auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTP Verification"])},
    "notice2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notice"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["continue"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log In"])},
    "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
    "whole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all"])},
    "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["symbol"])},
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["english"])},
    "korean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["korean"])},
    "initialization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initialization"])},
    "delet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
    "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My wallet."])},
    "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["failed."])},
    "failfetch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to fetch data."])},
    "user_level_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General member"])},
    "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["application"])},
    "findby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find by"])},
    "googlecert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["google authentication"])},
    "nodata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The data is not available."])},
    "stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["stocks"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["next"])},
    "eventword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["event"])},
    "referer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["referer"])}
  },
  "login": {
    "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up"])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your registration information."])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least one lowercase / uppercase character and one number."])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least one special character."])},
    "msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimun 8 characters."])},
    "re": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["again"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral ID"])},
    "msg5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter invitation code."])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])},
    "msg6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have read and agree to"])},
    "msg7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Service and Privacy Policy"])},
    "msg8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Account"])},
    "msg9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid email account format"])},
    "msg10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid password format"])},
    "msg11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inconsistent password"])},
    "msg12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is an available email."])},
    "msg13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incorrect password"])},
    "msg14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This service is available after logging in."])},
    "msg15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your login session has expired."])},
    "msg16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your registration is complete."])},
    "msg17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please log in to start the transaction."])},
    "msg18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["log in"])},
    "msg19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login Successful"])},
    "msg20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The authentication code is not valid!"])},
    "msg21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication timed out."])},
    "msg22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up is complete."])},
    "msg23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-enter password"])},
    "msg24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are an unregistered member or have a different ID or password."])},
    "loginword1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["save ID"])},
    "msg25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile phone number format is incorrect."])},
    "msg26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms & Policies"])}
  },
  "topmenu": {
    "procenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotion"])},
    "mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Theme"])},
    "sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sound"])},
    "layout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Layout"])}
  },
  "footer": {
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trading in cryptocurrency derivatives involves huge risks."])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions in Digital Assets may be irreversible, and, accordingly, losses due to"])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fraudulent or accidental transactions may not be recoverable."])},
    "introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company introduction"])},
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notice"])},
    "center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investment Protection Center"])},
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequently Asked Questions"])},
    "kakao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KakaoTalk Contact"])},
    "inquiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1:1 Contact Us"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Support"])},
    "word1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서울시 강남구 테헤란로 1길 23, 4층"])},
    "word2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주식회사 푸빗"])},
    "word3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대표 김푸빗"])},
    "word4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사업자 등록번호 123-45-67890"])},
    "word5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가상 사업자 등록번호 123-45-67890"])},
    "word6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can access a variety of services on Fu-bit after logging in."])}
  },
  "mainhome": {
    "word": {
      "startdeal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Trading"])},
      "populardeal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hot"])},
      "bigchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top Volume"])},
      "highprofit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top Gainers"])},
      "marketstatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market"])},
      "newcoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Listing"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price(USDT)"])},
      "ratechange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change(24H)"])},
      "highprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High(24H)"])},
      "lowprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Low(24H)"])},
      "viewmore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])},
      "guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beginner's Guide"])},
      "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit"])},
      "trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Futures"])},
      "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24/7 Support"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
      "inspect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inspect"])}
    },
    "msg": {
      "mainbannermsg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trade with Fubit"])},
      "mainbannermsg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fast and Safety Earn."])},
      "mainbannermsg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join now, Get 30 USDT"])},
      "middlebannermsg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simple and fast coin exchange"])},
      "middlebannermsg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simple and fast digital asset exchange from coin information to order"])},
      "bottomtit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start now! Make your Trading Profit"])},
      "bottom1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "bottom1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easy to understand for everyone"])},
      "bottom2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10 USDT Gift for First Deposit"])},
      "bottom2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "bottom3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100 USDT Prize pool,"])},
      "bottom3_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join and Start"])},
      "bottom4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ask to us Anything"])},
      "bottom4_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It's open for you around the clock."])},
      "modalmsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't watch it again."])},
      "inputtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your E-Mail."])}
    }
  },
  "trade": {
    "modal": {
      "margin_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perpetual Margin Mode"])},
      "cross": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross"])},
      "isolated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isolated"])},
      "cross2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross Margin Mode"])},
      "isolated2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isolated Margin Mode"])},
      "adjust": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjust Margin"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
      "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max addable"])},
      "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size"])},
      "est": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Est.Liq.Price"])},
      "profit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take Profit"])},
      "loss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop Loss"])},
      "entryprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry Price"])},
      "liq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liq.Price"])},
      "size2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size"])},
      "pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimated PNL will be"])},
      "basis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Price Basis"])},
      "tp_sl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TP/SL for entire position"])},
      "markprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark price"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
      "liq2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close Position"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Qty"])}
    },
    "msg": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switching the margin mode will only apply it to the selected contract."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All cross positions under the same margin asset share the same asset cross margin balance."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the event of liquidation, your assets full margin balance along with any remaining open positions under the asset may be forfeited."])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage your risk on individual positions by restricting the amount of margin allocated to each."])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the margin ratio of a position reached 100%, the position will be liquidated. Margin can be added or removed to positions using this mode."])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the position is open, you cannot change the Margin mode"])}
    }
  },
  "mywallet": {
    "walletview": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME > wallet > overview"])},
      "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide"])},
      "totalasset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Balance"])},
      "checkboxtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No transaction support / hiding small assets"])},
      "loss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unrealized PNL"])},
      "possibleasset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
      "inkindasset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spot"])},
      "coin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["total"])},
      "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available"])},
      "freez": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Order"])},
      "bitcoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitcoin"])},
      "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit"])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdraw"])},
      "forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Future assets"])},
      "mobilelist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gift details"])},
      "mobilelist2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stock details"])},
      "futurelist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Futures wallet history"])}
    },
    "depositwallet": {
      "word": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME > wallet > Deposit"])},
        "assetselection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Coin"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit Address"])},
        "precautions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notice"])},
        "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
        "destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destination"])},
        "depositdetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent Deposits"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit quantity"])},
        "copy2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy complete"])},
        "inoutlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit and withdrawal details"])}
      },
      "msg": {
        "precautions_msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Choose the asset and network you want to deposit to, and make sure it's correct"])},
        "precautions_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Take a picture of the deposit address as a QR code, or paste it into your withdrawal wallet and request a withdrawal."])},
        "precautions_msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Please wait until the transmission of the blockchain network is confirmed."])},
        "precautions_msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. Fubit sends assets to the wallet address after the blockchain network confirms the transmission."])},
        "precautions_msg5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["※ You may have assets that require additional data or destination tags besides addresses."])},
        "precautions_msg6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["※ Fu bit is not responsible for any problems caused by incorrectly entering deposit information."])},
        "address_msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "address_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy and enter deposit address"])},
        "alertmsg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["End date cannot be earlier than start date."])}
      }
    },
    "exchange": {
      "word": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME > wallet > Convert"])},
        "before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
        "exchange_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
        "after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
        "possible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available"])},
        "expect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will receive"])},
        "coinprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
        "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter"])},
        "amount_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24H remaining limit"])},
        "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convert History"])},
        "exchanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Executed"])},
        "price_check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview Conversion"])},
        "countLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seconds"])},
        "countlimit2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh after"])}
      },
      "msg": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It's been exchanged."])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exchange failed."])},
        "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter quantity."])},
        "notenough": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insufficient exchangeable assets."])}
      }
    },
    "withdraw": {
      "word": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME > wallet > Withdraw"])},
        "location2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME > wallet > Withdraw > Address manage"])},
        "address_manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address manage"])},
        "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select coin"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address manage"])},
        "address_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["address"])},
        "coinsearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search withdrawable coins"])},
        "address2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Address Book"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
        "withdrawpossible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["withdraw available quantity"])},
        "withdrawpossible2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["available quantity"])},
        "withdrawdetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdraw detail"])},
        "precautions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precautions for Withdrawal"])},
        "minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum withdrawal amount"])},
        "amount_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24-hour withdrawal limit"])},
        "asset2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Coin"])},
        "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal Address"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direct input"])},
        "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network"])},
        "minimum2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum withdrawal amount : 0.001"])},
        "24withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum withdrawable amount for 24 hours"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["address name"])},
        "withdrawaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal address"])},
        "adresslist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["address details"])},
        "adressplus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add address"])},
        "noadress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No registered addresses exist."])},
        "adressmsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter up to 4-5 characters"])},
        "mylevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My withdrawal level"])},
        "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stage"])},
        "levelbtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raising the level"])},
        "destination_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Destination Code"])},
        "addr_err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address error"])},
        "io_check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maintenance"])},
        "over_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abnormal assets"])},
        "inquiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit request"])},
        "notag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag not entered"])},
        "treble": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check treble-rule"])}
      },
      "msg": {
        "withdraw_msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Please make sure that the asset selected for withdrawal and the entered withdrawal address are for the same asset."])},
        "withdraw_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- The selected withdrawal asset can only be received to an address where the asset can be deposited."])},
        "withdraw_msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- After the withdrawal application is completed, it usually takes about an hour to check the details in the wallet where the deposit was received."])},
        "withdraw_msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Withdrawal processing may be delayed depending on circumstances such as the blockchain network."])},
        "withdraw_msg5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- If you are depositing to another exchange wallet, be sure to check the exchange policy."])},
        "withdraw_msg6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- After the withdrawal application is completed, the subsequent procedures are processed in the blockchain network."])},
        "withdraw_msg7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Due to the nature of the blockchain, once the withdrawal begins, it cannot be canceled."])},
        "withdraw_msg8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- As withdrawals are processed in Fubit's system, the withdrawal address may vary depending on the situation."])},
        "withdraw_msg9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- All damages caused by incorrectly entering withdrawal information or not checking the policies of the receiving wallet or exchange are the responsibility of the user, and if fraudulent or abnormal transactions are suspected, Fu bit may restrict withdrawal."])},
        "withdraw_msg10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter withdrawal address."])},
        "withdraw_msg11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insufficient assets available for withdrawal."])}
      }
    }
  },
  "invest": {
    "recentorder": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME > History > Open Orders"])}
    },
    "orderhistory": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME > History > Asset"])}
    },
    "profitloss": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME > History > PNL"])},
      "position_clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position liquidation"])},
      "position_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["position setting"])},
      "average_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry Price"])},
      "liquidation_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
      "loss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realized Profit"])}
    }
  },
  "promotion": {
    "event": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME > Promotion > Event"])},
      "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome Gift"])},
      "bonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membership sign-up bonus payment"])},
      "bonus_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonus payment for deposits of $10,000 or more in August"])},
      "mision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accomplished"])},
      "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongoing"])},
      "acquire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acquisition"])},
      "acquire_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The mission reward has been deposited into the gift wallet."])},
      "completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mission complete"])},
      "participate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mission participation"])},
      "participate_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We'll start the mission now."])},
      "event_compensation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Prize"])},
      "mision_clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mission Complete"])},
      "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms"])},
      "terms1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Service"])},
      "terms2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
      "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link copy"])},
      "noticedetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notice detail"])},
      "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In a list"])},
      "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
      "rewarded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "rewarded2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["has been deposited into your wallet as compensation."])},
      "unlimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unlimited"])},
      "term1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fubit's event is for customers who have registered as members."])},
      "term2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you participate in the event in an abnormal or illegal manner, you may be excluded from the selection of the winner and may be canceled even after winning."])},
      "term3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rewards will be paid at the target promotion center after the event is in progress or ends. Compensation must be received manually."])},
      "term4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The reward you get from your friend's invitation will be automatically paid at 00:00 (UTC) the next day."])},
      "term5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All participants must comply with the terms and conditions of the Fubit. Fubit reserves the right to disqualify participants for fraudulent or violating activities during the event, including enrolment of a batch account to receive additional bonuses and other activities related to illegal, illegal, and harmful purposes."])},
      "term6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rewards can only be used for futures trading and cannot be withdrawn directly."])},
      "term7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rewards can be used as margin and can also be used to pay transaction fees and losses."])},
      "term8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rewards can be used to withdraw all the revenue accumulated as a result of a successful transaction."])},
      "term9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have the right to change the regulations of this event without prior notice of Fubit."])},
      "term10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fubit shall have the final interpretation of this event. If you have any questions, please contact Customer Support."])}
    },
    "invite": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME > Promotion > Referral"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Referral State"])},
      "info_msg_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can invite friends and pay back fees. "])},
      "info_msg_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Based on the fee incurred by the transaction of the friend you invited, my friend and I will receive a payback divided. "])},
      "info_msg_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The friend invitation code sets the percentage of the fee you and your friend will receive payback. "])},
      "info_msg_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For more information, click Manage Invitation Code."])},
      "ranking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friend Invitation Ranking Reward"])},
      "ranking_msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is the ranking of the person who invited the most friends in one month. "])},
      "ranking_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rewards will be automatically deposited into your gift wallet at 00:00 (UTC) on the 1st of the following month for the 1st to 10th place in the Invite a Friend ranking. "])},
      "ranking_msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The rewards paid as ranking rewards can be used as margins and fees required for trading, but cannot be withdrawn externally."])},
      "expect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reward"])},
      "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coin"])},
      "compensation2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Reward"])},
      "person2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral Link"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral Code"])},
      "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share"])},
      "invite_rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leader Board"])},
      "rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rank"])},
      "rank2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["st"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "influencer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["influencer application"])},
      "influencerreview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Influencer screening"])},
      "influencerinquire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Influencer lookup"])},
      "compensation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reward"])},
      "invite_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invited Friends"])},
      "invite_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
      "compensation3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today's compensation."])},
      "invite_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
      "invite_friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User ID"])},
      "reward_join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join Reward"])},
      "reward_deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit Reward"])},
      "reward_deal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trade Reward"])},
      "reward_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coin"])},
      "title_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friend invitation"])},
      "info_msg_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link or code has been copied."])},
      "code_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please set it in Invitation Code Management."])}
    }
  },
  "usercenter": {
    "guidebook": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME > Help Center > Beginner's Guide"])},
      "location2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME > Help Center > Beginner's Guide > Guide details"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guidebook Search"])},
      "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide details"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guide Information"])},
      "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View List"])},
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Image"])},
      "list_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to deposit assets"])},
      "nodata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registered guidebook does not exist."])},
      "linkcopysuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied to clipboard."])}
    },
    "notice": {
      "view": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME > Help Center > Announcement"])}
      },
      "detail": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME > Help Center > Announcement"])}
      },
      "nonotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no registered Notice."])}
    },
    "inquiry": {
      "list": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME > Help Center > Submit a Request"])},
        "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request"])},
        "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Request"])},
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inquiry type"])},
        "situation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State"])},
        "answer_wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting"])},
        "answer_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete"])},
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["account"])},
        "nolist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no inquiry."])},
        "answer_wait_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for your reply."])}
      },
      "reg": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME > Help Center > Submit a Request > My Request"])},
        "usercenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help Center"])},
        "guidebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guidebook"])},
        "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notice"])},
        "inquiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1:1 Contact Us"])},
        "inquirylist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request"])},
        "inquiry_regist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Request"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Type"])},
        "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a type of inquiry."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inquiries"])},
        "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can write 30 characters or less."])},
        "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can enter more than 30 characters and less than 500 characters."])},
        "att_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach File"])},
        "att_file2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["attachment"])},
        "find_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find a file"])},
        "regist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registering"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inquiries have been made."])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed."])},
        "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["select item"])},
        "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answer content"])},
        "msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a title."])},
        "msg5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the contents."])}
      }
    }
  },
  "mypage": {
    "usersetting": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME > Account > profile"])},
      "location2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME > Account > profile > set up"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
      "password2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find your password"])},
      "google_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Authenticator"])},
      "google_not_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlink Google"])},
      "login_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in Verification"])},
      "recent_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last login time"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["registration"])},
      "mycommission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Fees"])},
      "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a discount on transaction fees"])},
      "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["standard fee"])},
      "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount grade"])},
      "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fee discount rate"])},
      "discount_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The standard fee will be applied to the transaction, and the discounted amount will be deposited into the gift wallet at 0:00 (UTC) the day after the transaction date."])},
      "screen_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Layout"])},
      "notification_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification settings"])},
      "sound_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sound setting"])},
      "setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["setting"])},
      "removegooglelink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to turn off Google Interlink Google?"])},
      "removeotp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Google link has been disabled."])},
      "toggle": {
        "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Announcement"])},
        "darkmode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Theme"])},
        "whitemode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["white mode"])},
        "marketstatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market status"])},
        "favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorite"])},
        "recentdeal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trades"])},
        "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])},
        "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
        "e_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Login"])},
        "e_liquidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email forced liquidation"])},
        "e_withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email withdraw"])},
        "e_deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Deposit"])},
        "sms_wd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS In/Out Notification"])},
        "ordercomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Submitted"])},
        "trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigger Order"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Cancelled"])},
        "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Complete"])},
        "conclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Filled"])},
        "liquidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["compulsory liquidation"])}
      }
    },
    "authcenter": {
      "word": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME > Account > Security"])},
        "accountlevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Risk Level : "])},
        "safe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Safety"])},
        "normal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in general"])},
        "danger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High"])},
        "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTP Authenticator"])},
        "anti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anti-phishing code"])},
        "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
        "anti2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anti-phishing code : "])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Within 10 characters"])}
      },
      "msg": {
        "level_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For the safety of account, please set up security authentication."])},
        "level_msg_m1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For a safe account"])},
        "level_msg_m2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please add security authentication."])},
        "email_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link your email address to your account for password recovery and withdrawal confirmation"])},
        "phone_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link your mobile number to your account to receive verification codes via SMS."])},
        "google_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTP is used as a safety verification for when you log in, withdraw or change safety setting."])},
        "usdt_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register your certification and receive 50 USDT as a reward."])},
        "security_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emails sent will contain your anti-phishing code to protect against phishing attaccks. "])},
        "login_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please make sure your web browser shows one of the correct SSL URLs"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The authentication number has been sent."])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to send verification code."])},
        "noemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registered email does not exist."])},
        "nophone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registered mobile phone does not exist."])}
      }
    },
    "activity": {
      "word": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME > Account > Activity"])},
        "record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent Login History"])},
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign out of all devices"])},
        "logout2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])},
        "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection time"])},
        "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["model"])},
        "browser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browser"])},
        "ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ip"])},
        "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
        "model2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["model/browser"])},
        "ip2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location/IP"])},
        "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membership Withdrawal"])},
        "withdrawfail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to withdraw membership."])},
        "withdraw2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal"])},
        "android": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Android"])},
        "korea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korea"])},
        "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot sign up with the same information for 1 month after withdrawing from the membership. \nDo you want to proceed with the withdrawal of membership?"])}
      },
      "msg": {
        "logout_msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is your Fubit login history. "])},
        "logout_msg1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have a history of not logging in yourself,"])},
        "logout_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["please change your account password immediately"])},
        "logout_msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and log out of all devices."])},
        "logout_msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "withdraw_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account information will be deleted immediately and may not be recoverable."])}
      }
    }
  },
  "Influencer": {
    "application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply for an influencer"])},
    "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Influencer screening results"])},
    "referer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["referral manager"])},
    "referer2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["referral"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicant's"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["influencer Recommendation Code"])},
    "entercode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter referral code"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application information"])},
    "enterinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter application information"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To apply"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancellation of application"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reapply"])},
    "noresult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reason for rejection"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["succeeded."])},
    "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["failed."])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])}
  },
  "nickname": {
    "change_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the nickname you want to change."])},
    "nickname_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is an available nickname."])},
    "phone_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available phone numbers."])},
    "email_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This email is available."])},
    "authemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This email has already been subscribed."])},
    "authphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This phone number is already authenticated."])},
    "authfail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication failed."])},
    "nicknamefail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to change nickname."])},
    "nicknamesuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your nickname has been changed."])},
    "emailtypefail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The email format is not valid."])},
    "emptyemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter an email to authenticate."])},
    "emptyphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a phone number to authenticate."])},
    "auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authenticate"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])},
    "beforeauthphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please complete the mobile phone verification."])},
    "beforeauthmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please proceed with the email verification."])}
  },
  "contractdetail": {
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fastening details"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tightening time"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liq.Price"])}
  },
  "withdraworder": {
    "cancel_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to cancel the order?"])}
  },
  "feeinfo": {
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee Information"])},
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Level"])},
    "maker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maker Fee"])},
    "taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taker Fee"])}
  },
  "authgoogle": {
    "auth_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check before proceeding with the certification."])},
    "auth_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is Google OTP APP installed?"])},
    "googleplay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Play"])},
    "appstore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App Store"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Login"])},
    "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTP request failed."])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP Interworking"])}
  },
  "googleqr": {
    "qr_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan the QR code below with Google OTP APP"])},
    "qr_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If scanning is not possible, please enter the code yourself."])},
    "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Authentication"])}
  },
  "googlenumber": {
    "number_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter the 6-digit code from Google Authenticator."])},
    "fail_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!! Incorrect verification code"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTP authentication setup is complete."])}
  },
  "googlecomplete": {
    "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTP Certification completed"])},
    "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTP authentication setup is"])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["complete."])}
  },
  "password": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
    "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old Password"])},
    "now_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the old password"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
    "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "new2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm New Password"])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertification"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend"])},
    "resend2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend"])},
    "authcheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["certified."])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password reset complete."])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please log in to start the transaction."])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password format is not correct."])},
    "msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It doesn't look like a new password."])},
    "msg5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check the current password."])},
    "msg6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check the new password."])},
    "msg7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check the password."])}
  },
  "layout": {
    "setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Layout Settings"])}
  },
  "email": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the 6 verification code received by email."])},
    "auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seconds"])},
    "auth1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available after"])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the 6-digit authentication code sent to your mobile phone."])},
    "authsuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verified."])}
  },
  "phone": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the 6 verification code received by Phone."])},
    "authsuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It's certified."])},
    "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed."])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend"])},
    "resendfail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend failed."])}
  },
  "passwordforgot": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Did you forget your password?"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your registered email."])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The entered membership information could not be found."])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawals, P2P selling, and payment services will be disabled for 24 hours after you make this change to protect your account."])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password"])},
    "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a new password."])},
    "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the New password"])},
    "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the new password again."])},
    "text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Did you forget your password? Please enter your registered mobile phone number."])},
    "text8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send authentication number"])},
    "text9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to send authentication number. Please try again."])},
    "text10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are an unregistered member or have a different ID or password. Please check and try again."])},
    "text11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Did you forget your password? Please enter your registered email."])},
    "text12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your registered mobile phone."])}
  },
  "orderconfirm": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order confirmation"])},
    "deal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction"])},
    "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["order"])},
    "market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["market"])},
    "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit"])},
    "c_market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conditional market price"])},
    "c_designation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditional limit price"])},
    "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target Price"])},
    "lender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loss cut"])},
    "liquidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compulsory liquidation"])},
    "liquidation2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a compulsory liquidator"])},
    "trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigger"])}
  },
  "signup": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up"])},
    "promotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotion or Recommendation Code (Select)"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Service and Privacy Policy"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I confirm and agree to the"])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already have an account?"])},
    "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your email."])},
    "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This email has already been subscribed."])},
    "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid email account format"])},
    "text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your mobile phone number."])},
    "text8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It's a mobile phone that's already registered."])},
    "text9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The information entered does not match the phone number format"])},
    "text10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a password."])},
    "text11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password is different from password verification."])},
    "text12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password entered does not match the format"])},
    "text13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the new password again."])},
    "text14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password is different from password verification."])},
    "text15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The password entered does not match the format"])},
    "text16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code not available"])},
    "text17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Profile photo"])},
    "text18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This nickname is already in use."])},
    "text19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nickname"])},
    "text20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotion or Recommendation Code (Select)"])}
  },
  "withdraw_detail": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details of withdrawal application"])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network"])},
    "commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network Fees"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Budget received amount"])},
    "execution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal execution"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application completed"])},
    "successdesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your withdrawal application has been successfully registered."])},
    "authFail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The authentication numbers do not match."])},
    "getQty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive amount"])}
  },
  "loginconfirm": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This item requires login."])},
    "desc2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to log in?"])}
  },
  "deal": {
    "closeLong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close Short"])},
    "closeShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close Long"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
    "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Index"])},
    "24change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24H Change"])},
    "countdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Countdown"])},
    "funding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funding"])},
    "fundingfee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funding fee"])},
    "valume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume"])},
    "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High"])},
    "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Low"])},
    "marketprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market price"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coin"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "trades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trades"])},
    "scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scale"])},
    "scale2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scale"])},
    "posopen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market"])},
    "trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigger"])},
    "cross": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross"])},
    "isolate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Isolated"])},
    "long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Long"])},
    "short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Short"])},
    "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Executed"])},
    "margin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Margin"])},
    "balange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
    "available2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available"])},
    "pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unrealized PNL"])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit"])},
    "openshort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Short"])},
    "openlong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Long"])},
    "openlong2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buying"])},
    "openshort2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["selling"])},
    "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit"])},
    "convert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convert"])},
    "favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorite"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symbols"])},
    "lastprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Price"])},
    "24h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24h"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size"])},
    "available3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancel"])},
    "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
    "pnl2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PNL"])},
    "openorder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open Orders"])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order History"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset"])},
    "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symbol"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
    "tpsl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TP/SL"])},
    "pnl3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PNL(ROE)"])},
    "liq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liq,Price"])},
    "side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Side"])},
    "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mark Price"])},
    "leverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leverage"])},
    "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Size"])},
    "entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry Price"])},
    "leverage2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["leverage"])},
    "leverage3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["leverage ratio"])},
    "perpetual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perpetual"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "allcancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Cancel"])},
    "shortselling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquidated short selling quantity"])},
    "shortsale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquidated Short Sales Quantity"])},
    "market2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market Price"])},
    "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit"])},
    "averagemarket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average market price"])},
    "fillprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liq. Price"])},
    "trigger2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigger"])},
    "liq2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit"])},
    "immediate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market"])},
    "conditionalmarket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditional Market Price"])},
    "conditionallimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditional Limit"])},
    "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target Price"])},
    "sl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SL"])},
    "liquidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquidation"])},
    "orderprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Price"])},
    "trigger3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigger"])},
    "completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completion"])},
    "spotwallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spot Wallet"])},
    "future": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Future"])},
    "freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeze"])},
    "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move"])},
    "exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exchange"])},
    "deposit2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer-in"])},
    "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer-out"])},
    "ordercondition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Condition"])},
    "trigger4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trigger"])},
    "completion2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail"])},
    "liquidation2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquidation"])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unrealized profit and loss and return on equity are based on the market average."])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When the target price and the loss price are reached, the contract set is executed. You can set the target price by setting the details (target price is based on the market average price)."])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the quantity."])},
    "msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the price."])},
    "msg5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insufficient assets available to order."])},
    "msg6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the invoking price."])},
    "msg7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["orders have been placed."])},
    "msg8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your order has been canceled."])},
    "msg9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter Target Price / Loss cut"])},
    "msg10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the case of the short position, the target price should be higher than the recent signing price."])},
    "msg11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the case of the short position, the loss price should be lower than the recent signing price."])},
    "msg12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the case of long positions, the target price should be lower than the recent closing price."])},
    "msg13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the case of long positions, the loss price should be higher than the recent closing price."])},
    "msg14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We don't have enough liquidation available."])},
    "msg15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total assets = wallet balance + margin + Unrealized PNL"])},
    "msg16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicates the amount used for an indefinite futures contract set by the user."])},
    "msg17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicates the wallet balance currently available for contract execution."])},
    "msg18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unrealized profit and loss and return on equity are based on the market average."])},
    "msg19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orderable quantity is insufficient."])},
    "chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chart"])},
    "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["order"])},
    "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer"])},
    "order2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Status"])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submitted"])},
    "orderFail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order failed"])},
    "msg20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The contract uses USDT as a margin, but is represented by the asset quantity of the stock."])},
    "msg21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicates the margin allocated to the contract."])},
    "msg22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average purchase/sale price at which the contract was executed"])},
    "msg23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the ratio is positive, the long trader will pay a financing fee for the short position. If the ratio is negative, the short trader will pay a financing fee for the long position. Funding fees are calculated every eight hours."])},
    "msg24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the market average price reaches that price, the contract will be liquidated."])},
    "msg25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The contract is terminated immediately at the market average price."])},
    "msg26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum position size for current leverage"])}
  },
  "alert": {
    "orderSuccess": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Success"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order Success"])}
    },
    "positionOrder": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This service requires login."])}
    },
    "favoriteAdd": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registration Successful"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have been registered as a favorite."])}
    },
    "favoriteRemove": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unregister"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your favorites have been turned off."])}
    }
  },
  "coin": {
    "btc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitcoin"])},
    "xrp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripple"])},
    "eth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ethereum"])},
    "sol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solana"])},
    "clay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klaytn"])},
    "matic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polygon"])},
    "avax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avalanche"])},
    "usdt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tether"])},
    "doge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dogecoin"])},
    "arb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbitrum"])},
    "sei": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei"])}
  },
  "level": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Withdrawal level"])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When withdrawing all assets, the withdrawal limit is limited depending on the account authentication level."])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can increase the withdrawal limit by raising the authentication level for your account."])},
    "lev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["level"])},
    "standard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certification criteria"])},
    "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a daily withdrawal limit"])},
    "stage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stage"])},
    "emailor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-mail or mobile phone"])},
    "emailphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail, cell phone."])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID verification"])},
    "idfail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID authentication failed"])},
    "failtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reason for failure"])},
    "levelup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["levelup"])}
  },
  "invitemodal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friend invitation code"])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can set up to five invitation codes."])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The discounted fee will be deposited into each gift wallet at 00:00 (UTC) the next day, and can only be used as a deposit or fee for transactions."])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "myyoupercent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["your/friends ratio"])},
    "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management"])},
    "revise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fix"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["delete"])},
    "plus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])},
    "code_revise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modify invitation code"])},
    "code_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Invitation Code"])},
    "code2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register influencer"])},
    "mypercent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the percentage you receive"])},
    "youpercent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the rate at which a friend receives"])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjustable discount rates are up to 30%."])}
  },
  "influencer_result": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["influencer Review Results"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicant's"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral recommendation code"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application information"])},
    "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reasons for return"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancellation of application"])},
    "re": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reapply"])}
  },
  "idcard": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify ID"])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["with your ID"])},
    "msg1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please upload a photo where you can see your face"])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload ID photo"])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please register the image file."])},
    "regist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["photo registration"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload verification photo"])},
    "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identification verification has been requested."])},
    "msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Take a photo of your ID and apply for approval from your administrator."])},
    "cert_picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentication photo"])},
    "id_picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID photo"])},
    "before_id_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please upload a photo of your ID.."])},
    "before_cert_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please upload a verification photo."])}
  },
  "antiphishing": {
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change request"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phishing protection code has been changed."])},
    "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to change anti-phishing code."])},
    "antiphishing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anti-phishing code"])},
    "lengthover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anti-phishing code must be no more than 10 characters."])},
    "checkinput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter anti-phishing code."])}
  },
  "moneytransfer": {
    "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
    "kind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spot"])},
    "gift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Futures"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
    "get": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
    "property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coin"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
    "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Internal transfers are free."])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The transfer has been completed."])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You cannot transfer assets received as compensation for events and friends invitations."])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you move assets while holding a cross position, the position may be liquidated and you may not be able to move all assets. Please enter the exchangeable asset accurately."])}
  },
  "addressmanage": {
    "addsuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet address has been added."])},
    "addfail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to register wallet address."])},
    "removesuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your wallet address has been deleted."])},
    "removefail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to delete wallet address."])},
    "addname_null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the address name."])},
    "outputkey_null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the withdrawal address."])},
    "destination_null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the destination code."])}
  }
}