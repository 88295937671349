<template lang="">
  <div class="fixed z-10 flex w-screen h-full bg-black bg-opacity-50">
    <div :class="tStyle.inner">
      <h2 class="mt-[10px] text-[25px]">Select Language</h2>
      <input type="text" placeholder="Search" :class="tStyle.input" />
      <table class="w-full mt-[10px] text-[#c9c9c9]">
        <tr :class="tStyle.search_list">
          <td class="w-[50%]"></td>
          <td>{{$t('common.nation')}}</td>
        </tr>
        <tr :class="tStyle.search_list" v-for="(a,i) in nationCode" :key="i" @click="setLanguage(a.name)">
          <td>
            <img
              :src="a.img"
              alt=""
              class="pl-[10px] py-0 h-[70px] "
            />
          </td>
          <td>{{a.name}}</td>
        </tr>
      </table>

      <button :class="tStyle.close_bt">
        <img src="../../assets/image/group2322.png" alt="" />
      </button>
    </div>
  </div>
</template>
<script>
import { modalControl } from '@/store/store';
import create from 'vue-zustand';

export default {
  name: 'SelectLang',
  data() {
    return {
      nationCode: [
        {
          img: require('@/assets/image/Fubit_Gui-54.png'),
          name: 'USA',
          code: 'en'
        },
        {
          img: require('@/assets/image/Fubit_Gui-55.png'),
          name: 'RUSIA',
          code: '7'
        },
        {
          img: require('@/assets/image/Fubit_Gui-56.png'),
          name: 'VIETNAM',
          code: '84'
        },
        {
          img: require('@/assets/image/Fubit_Gui-60.png'),
          name: 'Español',
          code: '34'
        },
        {
          img: require('@/assets/image/Fubit_Gui-59.png'),
          name: 'CHINA',
          code: '86'
        },
        {
          img: require('@/assets/image/Fubit_Gui-58.png'),
          name: 'JAPAN',
          code: '81'
        },
        {
          img: require('@/assets/image/Fubit_Gui-57.png'),
          name: 'KOREA',
          code: '82'
        }
      ],
      tStyle: {
        inner:
          'bg-white w-[532px] text-[18px] m-auto border border-[#C9C9C9] dark:bg-[#1c1d22] dark:text-[#c9c9c9] dark:border-[#606060] px-[40px] pt-[15px] pb-[40px] relative',

        input:
          'w-full h-[55px] mt-[20px] pl-[10px] border rounded-[3px] inline-block dark:bg-[#25252c] dark:border-[#606060]',

        search_list: 'border-b dark:border-[#606060] text-[20px] h-[57px]',

        close_bt: 'absolute right-[20px] top-[18px] font-light text-[30px]'
      }
    };
  },
  setup() {
    const useModalControl = create(modalControl);
    const { setModalControl } = useModalControl();

    return {
      setModalControl
    };
  },
  methods: {
    setLanguage(i){
      this.setSelectLanguage(i)
    }
  },
};
</script>
<style lang=""></style>
