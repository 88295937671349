<template>
  <div class="w-full">
    <!-- 모바일 헤더 -->
    <div
      class="sm:hidden w-full h-[70px] fold:h-[60px] text-[14px] fold:text-[12px] text-center flex items-center justify-center border-t border-[#cfe4fe] dark:border-[#fdd26a] bg-white dark:bg-[#333333] "
    >
      <div v-for="(a, i) in mobileMenu" :key="i" class="w-[25%]">
        <div class="" v-if="i <= 4">
          <div
            :class="[
              i - 1 == thisPage
                ? tStyle.mobilemenuBtnY
                : i + 6 == thisPage
                ? tStyle.mobilemenuBtnY
                : tStyle.mobilemenuBtnN
            ]"
          >
            <div
              :class="[
                i - 1 == thisPage
                  ? tStyle.mobilemenuDeco
                  : i + 6 == thisPage
                  ? tStyle.mobilemenuDeco
                  : tStyle.hidden
              ]"
            ></div>
            <router-link :to="a.path" @click="cateRemove">{{
              a.title
            }}</router-link>
          </div>
        </div>
      </div>
    </div>
    <div :class="tStyle.wrapper">
      <div class="w-[1396px] mx-auto min-w-[1396px]" :class="[($route.path === '/position') ? 'w-[100%] px-2' : 'w-[1396px]']">
        <div class="flex h-full sm:w-full" @mouseleave="cateRemove">
          <div
            @click="goMainPage"
            class="w-[73.8px] h-[26.1px] cursor-pointer self-center flex"
          >
            <img src="@/assets/image/group546.png" alt="" class="dark:hidden" />
            <img
              src="@/assets/image/group546_d.png"
              alt=""
              class="hidden dark:block"
            />
          </div>
          <div :class="tStyle.cateWrapper">
            <div
              class="w-full h-full bg-stone-50/[0.8] dark:bg-neutral-600/[.8]"
            ></div>
          </div>
          <div
            class="flex justify-between w-full h-full text-[15px] ml-[80px] text-[#4d4d4d] dark:text-[#e5e5e5]"
          >
            <div class="flex">
              <div v-for="(a, i) in menu" :key="i">
                <div class="flex flex-col mx-[20px]" v-if="i <= 4">
                  <div
                    :class="[i == thisPage ? tStyle.menuBtnY : tStyle.menuBtnN]"
                    @mouseover="categoryDown"
                  >
                    <div
                      :class="[i == thisPage ? tStyle.menuDeco : tStyle.hidden]"
                    ></div>
                    <router-link :to="a.path" @click="cateRemove">{{
                      a.title
                    }}</router-link>
                    <!-- 프로모션 애니메이션 아이콘 -->
                    <div
                      class="w-[12px] h-[9px] animate-bounce float-right mb-[5px]"
                      v-if="i == 3"
                    >
                      <img
                        src="../assets/image/group13696.png"
                        alt=""
                        class="ml-[2px] dark:hidden"
                      />
                      <img
                        src="../assets/image/group13696_d.png"
                        alt=""
                        class="ml-[2px] relative opacity-0 dark:opacity-100"
                      />
                    </div>
                  </div>
                  <div :class="tStyle.cateList">
                    <div
                      v-for="(b, i) in a.sub"
                      :key="i"
                      class="cursor-pointer"
                      @click="cateRemove"
                    >
                      <div>
                        <router-link :to="b.path">{{ b.title }}</router-link>
                      </div>
                      <!-- <div v-else @click="cateClick">
                        {{ b.title }}
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex text-[13px] leading-[26px]">
              <div
                v-if="isMain && optionData.winNoticeYn == 'Y'"
                class="w-[220px] h-[30px] rounded-2xl bg-[#cfe4ff] dark:bg-[#606060] flex self-center items-center px-[5px] cursor-pointer"
                @click="goNoticePage"
                :class="[!loginState ? 'mr-6' : '']"
              >
                <img
                  src="../assets/image/group157.png"
                  alt=""
                  class="align-sub mr-[5px] dark:hidden"
                />
                <img
                  src="../assets/image/157.png"
                  alt=""
                  class="align-sub mr-[5px] opacity-0 dark:opacity-100"
                />
                <div
                  class="whitespace-nowrap overflow-hidden leading-[28px] dark:text-[#9f9f9f] ml-[-25px] dark:ml-0"
                >
                  {{ notiList[notiSeq] }}
                </div>
              </div>
              <div v-if="loginState == true" class="flex">
                <div v-for="(b, i) in menu" :key="i">
                  <div
                    v-if="i == 5"
                    class="flex flex-col ml-[29px] mr-[10px] cursor-pointer text-center"
                  >
                    <div
                      class="flex leading-[18px] items-center h-20 text-[#fc0d1b] dark:text-[#9f9f9f] dark:bg-[#1c1d22] z-10 bg-[#f8f9fb]"
                      @mouseover="categoryDown"
                      @click="
                        $router.push({
                          name: 'UserSetting'
                        })
                      "
                    >
                      {{ b.title }}
                    </div>
                    <div :class="tStyle.cateList">
                      <div v-for="(c, i) in b.sub" :key="i" @click="cateRemove">
                        <router-link :to="c.path"
                          ><span v-if="i < 3">{{ c.title }}</span
                          ><span v-else @click="onLogout">{{
                            c.title
                          }}</span></router-link
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  class="px-[10px] text-[#868686] dark:text-[#9f9f9f] leading-[18px]"
                  @click="modeToggle"
                >
                  {{ $t('topmenu.mode') }}
                </button>
                <button
                  class="px-[10px] text-[#868686] dark:text-[#9f9f9f] leading-[18px]"
                  @click="setSoundModalOpen"
                >
                  {{ $t('topmenu.sound') }}
                </button>
                <button
                  class="px-[10px] text-[#868686] dark:text-[#9f9f9f] leading-[18px]"
                  @click="setLayoutModalOpen"
                >
                  {{ $t('topmenu.layout') }}
                </button>
              </div>
              <div v-else>
                <button
                  class="text-[#0f5cd4] px-[10px] leading-[80px]"
                  @click="goLoginPage"
                >
                  {{ $t('common.login') }}
                </button>
                <button class="text-[#fc0d1b] px-[10px]" @click="goSignPage">
                  {{ $t('signup.title') }}
                </button>
              </div>
              <div class="relative self-center">
                <div
                  class="w-[40px] h-[21.4px] flex self-center items-center justify-between ml-[9px] cursor-pointer"
                  @click="languageToggle"
                >
                  <div class="w-[29px]">
                    <img :src="countryImg" alt="" />
                  </div>

                  <div class="w-[8px]">
                    <img
                      src="../assets/image/path250.png"
                      alt=""
                      class="ml-[2px]"
                    />
                  </div>
                </div>
                <div
                  class="absolute top-[40px] right-0"
                  v-if="langToggle"
                  @mouseleave="langToggle = false"
                >
                  <ul>
                    <li
                      v-for="(a, i) in languageList"
                      :key="i"
                      class="w-[100px] flex cursor-pointer items-center py-1 justify-end bg-[#f8f9fb] dark:bg-[#1c1d22]"
                      @click="setLang(i)"
                    >
                      <span class="text-[12px]">{{ a.lang }}</span>
                      <img :src="a.img" alt="" class="h-[25px] ml-1" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="flex">
            <ul class="ml-[274px]">
              <li
                class="cursor-pointer"
                @click="goWalletPage"
              >{{ $t('common.viewwallet')}}</li>
              <li
                class="cursor-pointer"
                @click="goDepositPage"
              >{{ $t('common.deposit')}}</li>
              <li
                class="cursor-pointer"
                @click="goExchangePage"
              >{{ $t('common.exchange')}}</li>
              <li
                class="cursor-pointer"
                @click="goWithdrawPage"
              >{{ $t('common.withdraw')}}</li>
            </ul>
            <ul class="ml-[45px]">
              <li
                class="cursor-pointer"
                @click="goRecentPage"
              >{{ $t('common.recentorder')}}</li>
              <li
                class="cursor-pointer"
                @click="goHistoryPage"
              >{{ $t('common.orderhistory')}}</li>
              <li
                class="cursor-pointer"
                @click="goProfitPage"
              >{{ $t('common.profitloss')}}</li>
            </ul>
            <ul class="ml-[45px]">
              <li
                class="cursor-pointer"
                @click="goEventPage"
              >{{ $t('common.event')}}</li>
              <li
                class="cursor-pointer"
                @click="goInvitePage"
              >{{ $t('common.invite')}}</li>
            </ul>
            <ul class="ml-[75px]">
              <li
                class="cursor-pointer"
                @click="goGuidePage"
              >{{ $t('common.guidebook')}}</li>
              <li
                class="cursor-pointer"
                @click="goNoticePage"
              >{{ $t('common.notice')}}</li>
              <li
                class="cursor-pointer"
                @click="goInquiryPage"
              >{{ $t('common.contact us')}}</li>
            </ul>
          </div>
          <div class="flex w-[295px] justify-between">
            <ul
              class=""
              v-if="loginState == true"
            >
              <li
                class="cursor-pointer"
                @click="goMyPage"
              >{{ $t('common.usersetting')}}</li>
              <li
                class="cursor-pointer"
                @click="goAuthPage"
              >{{ $t('common.authcenter')}}</li>
              <li
                class="cursor-pointer"
                @click="goActivityPage"
              >{{ $t('common.activity')}}</li>
            </ul>
            <ul v-else></ul>
            <ul class="">
              <li class="flex items-center">
                <img
                  src="../assets/image/group14210.png"
                  alt=""
                  class="w-[20px] h-[13px] mr-[5px]"
                />
                English
              </li>
              <li class="flex items-center">
                <img
                  src="../assets/image/group14078.png"
                  alt=""
                  class="w-[20px] h-[13px] mr-[5px]"
                />
                Pусский
              </li>
              <li class="flex items-center">
                <img
                  src="../assets/image/group14217.png"
                  alt=""
                  class="w-[20px] h-[13px] mr-[5px]"
                />
                Tiếng Việt
              </li>
              <li class="flex items-center">
                <img
                  src="../assets/image/group14220.png"
                  alt=""
                  class="w-[20px] h-[13px] mr-[5px]"
                />
                Español
              </li>
              <li class="flex items-center">
                <img
                  src="../assets/image/group14249.png"
                  alt=""
                  class="w-[20px] h-[13px] mr-[5px]"
                />
                中文
              </li>
              <li class="flex items-center">
                <img
                  src="../assets/image/group14253.png"
                  alt=""
                  class="w-[20px] h-[13px] mr-[5px]"
                />
                日本語
              </li>
              <li class="flex items-center">
                <img
                  src="../assets/image/group14258.png"
                  alt=""
                  class="w-[20px] h-[13px] mr-[5px]"
                />
                한국어
              </li>
            </ul>
          </div> -->
  </div>
</template>
<script>
import create from 'vue-zustand';
import {
  isLogin,
  nation,
  topNotice,
  darkMode,
  currPage,
  modalControl,
  apiLanguage,
  userSetting
} from '../store/store';
import Request from '@/utils/Request';
import AesEncrypt from '@/components/Security/AesEncrypt';
export default {
  name: 'TopBar',
  data() {
    return {
      notiList: [],
      notiSeq: 0,
      langToggle: false,
      language: 'en',
      languageList: [
        {
          lang: 'ENGLISH',
          name: 'en',
          img: require('@/assets/image/Fubit_Gui-54.png')
        },
        {
          lang: 'Русский',
          name: 'ru',
          img: require('@/assets/image/Fubit_Gui-55.png')
        },
        {
          lang: 'español',
          name: 'es',
          img: require('@/assets/image/Fubit_Gui-60.png')
        },
        {
          lang: '日本語',
          name: 'ja',
          img: require('@/assets/image/Fubit_Gui-58.png')
        },
        {
          lang: 'Tiếng Việt',
          name: 'vi',
          img: require('@/assets/image/Fubit_Gui-56.png')
        },
        {
          lang: '中文',
          name: 'zh',
          img: require('@/assets/image/Fubit_Gui-59.png')
        },
        {
          lang: '한국어',
          name: 'ko',
          img: require('@/assets/image/Fubit_Gui-57.png')
        }
      ],
      countryImg: require('@/assets/image/Fubit_Gui-54.png'),
      // 여기에 tailwindStyle 작성
      tStyle: {
        wrapper:
          'w-full h-20 flex bg-[#f8f9fb] dark:bg-[#1c1d22] z-50 max-sm:hidden fold:hidden',
        cateWrapper:
          'fixed mt-[-335px] w-full h-[255px] left-0 sm:bg-slate/10 backdrop-blur-2xl',
        openCateWrapper:
          'fixed top-[75px] w-full h-[255px] left-0 duration-500 translate-y-1 bg-white/0.5 dark:bg-slate/90 backdrop-blur-lg -z-20',
        closeCateWrapper:
          'fixed mt-[-335px] w-full left-0 bg-slate/10 backdrop-blur-2xl h-[255px]',
        cateList:
          'relative mt-[-335px] text-[13px] dark:text-[#9f9f9f] leading-[32px] pt-[20px] -z-5 ',
        openCateList:
          'relative top-[-5px] text-[13px] dark:text-[#9f9f9f] leading-[32px] pt-[20px] duration-500 translate-y-1 -z-5 ',
        closeCateList:
          'relative mt-[-335px] text-[13px] dark:text-[#9f9f9f] leading-[32px] pt-[20px] -z-5 ',
        menuBtnN:
          'flex items-center h-[80px] hover:text-[#005cd3] dark:hover:text-[#ffb500] hover:font-bold cursor-pointer leading-[18px] dark:bg-[#1c1d22] z-10 bg-[#f8f9fb] ',
        menuBtnY:
          'flex items-center h-[80px] leading-[18px] -ml-[8px] items-center text-[#005cd3] dark:text-[#ffb500] font-bold cursor-pointer dark:bg-[#1c1d22] z-10 bg-[#f8f9fb] ',
        menuDeco:
          'w-[8px] h-[8px] border-l-2 border-t-2 border-[#005cd3] relative top-[-8px] dark:border-[#ffb500] ',
        mobilemenuBtnN:
          'text-[#a0a0a0] dark:text-[#9f9f9f] h-[70px] fold:h-[60px] leading-[70px] fold:leading-[60px] font-medium dark:bg-[#333333] border-r border-[#cfe4fe] dark:border-[#fdd26a]',
        mobilemenuBtnY:
          'text-[#005cd3] h-[70px] fold:h-[60px] leading-[70px] fold:leading-[60px] font-medium dark:text-[#e5e5e5] dark:bg-[#111119] border-r border-[#cfe4fe] dark:border-[#fdd26a] dark:bg-[#111119]',
        mobilemenuDeco:
          'w-[16px] h-[16px] border-l-4 border-t-4 border-[#005cd3] absolute top-0 dark:border-[#ffb500]',
        hidden: 'hidden'
      },
      mobileMenu: [
        { title: 'Home', path: '/' },
        { title: this.$t('mainhome.word.trading'), path: '/position' },
        { title: this.$t('common.mywallet'), path: '/viewwallet' },
        { title: this.$t('common.invest'), path: '/orderlist' }
      ],
      menu: [
        { title: this.$t('mainhome.word.trading'), path: '/position', sub: {} },
        {
          title: this.$t('common.mywallet'),
          path: '/viewwallet',
          sub: [
            {
              title: this.$t('common.viewwallet'),
              path: '/viewwallet',
              login: 'true'
            },
            {
              title: this.$t('common.deposit'),
              path: '/deposit',
              login: 'false'
            },
            { title: this.$t('common.exchange'), path: '/exchange' },
            { title: this.$t('common.withdraw'), path: '/withdraw' }
          ]
        },
        {
          title: this.$t('common.invest'),
          path: '/orderlist',
          sub: [
            {
              title: this.$t('common.recentorder'),
              path: '/orderlist'
            },
            {
              title: this.$t('common.orderhistory'),
              path: '/history'
            },
            {
              title: this.$t('common.profitloss'),
              path: '/loss'
            }
          ]
        },
        {
          title: this.$t('topmenu.procenter'),
          path: '/promotion/event',
          sub: [
            {
              title: this.$t('common.event'),
              path: '/promotion/event'
            },
            {
              title: this.$t('common.invite'),
              path: '/promotion/invite'
            }
          ]
        },
        {
          title: this.$t('common.usercenter'),
          path: '/guide',
          sub: [
            {
              title: this.$t('common.guidebook'),
              path: '/guide'
            },
            {
              title: this.$t('common.notice'),
              path: '/notice'
            },
            {
              title: this.$t('common.contact us'),
              path: '/inquiry'
            }
          ]
        },
        {
          title: this.$t('common.mypage'),
          sub: [
            { title: this.$t('common.usersetting'), path: '/mypage/setting' },
            { title: this.$t('common.authcenter'), path: '/mypage/auth' },
            { title: this.$t('common.activity'), path: '/mypage/active' },
            { title: this.$t('mypage.activity.word.logout2'), path: '' }
          ]
        }
      ],
      mypageMenu: [],
      darkMode: false,
      isCate: false
    };
  },
  methods: {
    goLoginPage() {
      this.$router.push({
        name: 'UserLogin'
      });
    },
    goSignPage() {
      this.$router.push({
        name: 'UserAccount'
      });
    },
    goMyPage() {
      this.$router.push({
        name: 'UserSetting'
      });
      this.cateRemove();
    },
    modeToggle() {
      if (this.isDarkMode == false) {
        this.setDarkMode();
      } else {
        this.resetDarkMode();
      }
    },
    categoryDown() {
      this.tStyle.cateWrapper = this.tStyle.openCateWrapper;
      this.tStyle.cateList = this.tStyle.openCateList;
    },
    cateRemove() {
      this.tStyle.cateWrapper = this.tStyle.closeCateWrapper;
      this.tStyle.cateList = this.tStyle.closeCateList;
    },
    goWalletPage() {
      this.$router.push({
        name: 'ViewWallet'
      });
      this.cateRemove();
    },
    goDepositPage() {
      this.$router.push({
        name: 'DepositWallet'
      });
      this.cateRemove();
    },
    goExchangePage() {
      this.$router.push({
        name: 'AssetExchange'
      });
      this.cateRemove();
    },
    goWithdrawPage() {
      this.$router.push({
        name: 'AssetWithdraw'
      });
      this.cateRemove();
    },
    goRecentPage() {
      this.$router.push({
        name: 'RecentOrder'
      });
      this.cateRemove();
    },
    goHistoryPage() {
      this.$router.push({
        name: 'OrderHistory'
      });
      this.cateRemove();
    },
    goProfitPage() {
      this.$router.push({
        name: 'ProfitLoss'
      });
      this.cateRemove();
    },
    goEventPage() {
      this.$router.push({
        name: 'ProEvent'
      });
      this.cateRemove();
    },
    goInvitePage() {
      this.$router.push({
        name: 'ProInvite'
      });
      this.cateRemove();
    },
    goGuidePage() {
      this.$router.push({
        name: 'GuideBook'
      });
      this.cateRemove();
    },
    goNoticePage() {
      this.$router.push({
        name: 'NoticeView'
      });
      this.cateRemove();
    },
    goInquiryPage() {
      this.$router.push({
        name: 'InquiryList'
      });
      this.cateRemove();
    },
    goAuthPage() {
      this.$router.push({
        name: 'AuthCenter'
      });
      this.cateRemove();
    },
    goActivityPage() {
      this.$router.push({
        name: 'UserActivity'
      });
      this.cateRemove();
    },
    goPositionPage() {
      this.$router.push({
        name: 'PositionOpen'
      });
      this.cateRemove();
    },
    goMainPage() {
      this.$router.push({
        name: 'MainHome'
      });
      this.setCurrPage(null);
    },
    cateClick() {
      this.setModalName('LoginConfirm');
      this.setModalControl();
    },
    setSoundModalOpen() {
      this.setModalName('SoundSetting');
      this.setModalControl();
    },
    setLayoutModalOpen() {
      this.setModalName('LayoutModal');
      this.setModalControl();
    },
    languageToggle() {
      this.langToggle = !this.langToggle;
      // if (this.language === 'ko') {
      //   this.language = 'en';
      //   localStorage.setItem('fubit_lang', 'en');
      //   this.countryImg = require('@/assets/image/Fubit_Gui-54.png');
      //   this.$i18n.locale = 'en';
      // } else {
      //   this.language = 'ko';
      //   this.countryImg = require('@/assets/image/Fubit_Gui-57.png');
      //   localStorage.setItem('fubit_lang', 'ko');
      //   this.$i18n.locale = 'ko';
      // }
      // window.location.reload();
    },
    setLang(i) {
      this.language = this.languageList[i].name;
      this.countryImg = this.languageList[i].img;
      this.langToggle = !this.langToggle;
      window.location.reload();
    },
    onLogout() {
      this.$emit('logout');
      this.$router.push({
        name: 'MainHome'
      });
      localStorage.removeItem('fubit_token');
      localStorage.removeItem('fubit_rtoken');
      this.setLogout();
      // this.$nextTick(() => {
      //   window.location.reload();
      // });
    },
    //공지사항 api 연결
    async noticeListApi() {
      let data = JSON.stringify({
        data: AesEncrypt({
          pagecount: 50,
          page: 1
        })
      });
      let result = await Request(
        '/api/exs/scenter/v1/request_notice_list',
        'post',
        this.apiLangCode[this.$i18n.locale],
        data
      );

      if (!result) return;

      let { status } = result.API_CODE;

      if (status === '0000') {
        let list = result.notice_list.map((d) => {
          return d.title;
        });
        this.notiList = list;
        this.setNotiAuto();
      } else if(status === 'L402'){
        this.onLogout()
      }
    },
    //공지사항 자동 변경
    setNotiAuto() {
      let autoList = setInterval(() => {
        this.notiSeq++;
        if (this.notiList.length < this.notiSeq + 1) {
          this.notiSeq = 0;
        }
      }, 3000);
    }
  },
  setup() {
    const useIsLogin = create(isLogin);
    const { loginState, setLogout } = useIsLogin();
    const useNation = create(nation);
    const useTopNotice = create(topNotice);
    const { isMain } = useTopNotice();
    const {
      languageState,
      nationModalState,
      setNationSelect,
      setNationModalOpen
    } = useNation();
    const useDarkMode = create(darkMode);
    const { isDarkMode, setDarkMode, resetDarkMode } = useDarkMode();
    const useCurrPage = create(currPage);
    const { thisPage, setCurrPage } = useCurrPage();
    const useApiLanguage = create(apiLanguage);
    const { apiLangCode } = useApiLanguage();

    //전체 모달 컨트롤 store
    const useModalControl = create(modalControl);
    const { setModalControl, setModalName, whatModal, isModalOpen } =
      useModalControl();

    const useUserSetting = create(userSetting);
    const { optionData } = useUserSetting();

    return {
      loginState,
      languageState,
      nationModalState,
      setNationSelect,
      setNationModalOpen,
      isMain,
      setDarkMode,
      resetDarkMode,
      isDarkMode,
      thisPage,
      setCurrPage,
      setModalControl,
      setModalName,
      whatModal,
      isModalOpen,
      setLogout,
      apiLangCode,
      optionData
    };
  },
  watch: {
    language(a) {
      // if (a === 'ko') {
      //   localStorage.setItem('fubit_lang', 'ko');
      //   this.$i18n.locale = 'ko';
      // } else if (a === 'en') {
      //   localStorage.setItem('fubit_lang', 'en');
      //   this.$i18n.locale = 'en';
      // } else if (a === 'ru') {
      //   localStorage.setItem('fubit_lang', 'ru');
      //   this.$i18n.locale = 'ru';
      // }
      this.$i18n.locale = a;
      localStorage.setItem('fubit_lang', a);
    }
  },
  created() {
    const local = localStorage.getItem('fubit_lang');
    if (local === 'en') {
      this.language = 'en';
      this.countryImg = this.languageList[0].img;
    } else if (local === 'ru') {
      this.language = 'ru';
      this.countryImg = this.languageList[1].img;
    } else if (local === 'es') {
      this.language = 'es';
      this.countryImg = this.languageList[2].img;
    } else if (local === 'ja') {
      this.language = 'ja';
      this.countryImg = this.languageList[3].img;
    } else if (local === 'vi') {
      this.language = 'vi';
      this.countryImg = this.languageList[4].img;
    } else if (local === 'zh') {
      this.language = 'zh';
      this.countryImg = this.languageList[5].img;
    } else if (local === 'ko') {
      this.language = 'ko';
      this.countryImg = this.languageList[6].img;
    }

    this.noticeListApi();
  }
};
</script>
<style></style>
