<template lang="">
  <div
    class="fixed z-10 w-screen h-full bg-white dark:bg-[#111119]"
    v-if="etcMobile"
  >
    <!-- header -->
    <div
      class="flex z-[10] sm:hidden items-center justify-between w-full px-4 fixed top-0 bg-white py-3 dark:bg-[#111119]"
    >
      <button @click="setModalControl">
        <img
          src="@/assets/image/ion_chevron_back_outline.png"
          alt=""
          class="w-[30px] dark:hidden"
        />
        <img
          src="@/assets/image/ion_chevron_back_outline_d.png"
          alt=""
          class="w-[30px] opacity-0 dark:opacity-100 mt-[-30px] dark:mt-0"
        />
      </button>
      <div class="text-[25px] text-center text-[#444] dark:text-[#e5e5e5]">
        {{ $t('mywallet.walletview.mobilelist') }}
      </div>
      <div class="w-[30px]"></div>
    </div>

    <div
      class="mt-[60px] h-full overflow-y-scroll px-4 dark:text-[#e5e5e5] pb-[150px]"
    >
      <div class="overflow-y-scroll">
        <!-- 한개영역 -->
        <div
          class="py-4 px-2 border-b border-[#a0a0a0]"
          v-for="(a, i) in futureList"
          :key="i"
        >
          <div class="flex items-center text-[15px] justify-between">
            <div class="flex items-center">
              <div class="bg-[#fff8e8] w-[25px] h-[25px] rounded mr-2">
                <img :src="require(`@/assets/image/${a.symbol}.png`)" alt="" />
              </div>
              <div>{{ a.symbol.toUpperCase() }}{{ $t('coin.usdt') }}</div>
            </div>
            <div>
              {{
                a?.state == 'P'
                  ? $t('common.completion')
                  : a?.state == 'W'
                  ? $t('common.wait')
                  : a?.state == 'C'
                  ? $t('common.cancel')
                  : ''
              }}
            </div>
          </div>
          <!-- 날짜 -->
          <div class="text-[13px] text-[#a0a0a0] dark:text-[#9f9f9f]">
            {{ a.regDt }}
          </div>

          <div
            class="bg-[#f7f8fa] flex flex-wrap text-[13px] fold:text-[12px] text-center py-2 leading-[24px] dark:bg-[#1c1d22]"
          >
            <div class="basis-1/2">
              <div class="text-[#a0a0a0]">{{ $t('common.division') }}</div>
              <div class="font-bold">
                {{
                  a.ioCode == 'IE0300' || a.ioCode == 'IR0111'
                    ? $t('common.eventword')
                    : a.ioCode == 'IW0400' || a.ioCode == 'OW0410'
                    ? $t('mywallet.walletview.inkindasset')
                    : $t('common.referer')
                }}
              </div>
            </div>
            <div class="basis-1/2">
              <div class="text-[#a0a0a0]">
                {{ $t('deal.transfer') }}
              </div>
              <div class="font-bold">
                {{ a.io === 'I' ? $t('deal.deposit2') : $t('deal.withdraw') }}
              </div>
            </div>
            <div class="basis-full mt-[20px] truncate shrink-0">
              <div class="text-[#a0a0a0]">
                {{ $t('deal.amount') }}
              </div>
              <div class="px-2 font-medium truncate">
                {{ parseFloat(setDecimal(a.quantity, 6)) }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="futureList.length == 0"
          class="text-center text-[18px] mt-10"
        >
          {{ $t('common.nodata') }}
        </div>
        <!-- 더보기버튼 -->
        <div class="w-full mt-10 text-center">
          <button
            class="w-[90%] border border-[#cfe4fe] dark:border-[#ffb500] text-[13px] py-1 dark:text-[#333] dark:bg-[#ffb500]"
            v-if="this.moreBtn"
            @click="getListMore"
          >
            {{ $t('mainhome.word.viewmore') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  modalControl,
  darkMode,
  apiLanguage,
  refresh,
  checkMobile
} from '@/store/store';
import create from 'vue-zustand';
import Request from '@/utils/Request';
import AesEncrypt from '@/components/Security/AesEncrypt';

export default {
  name: 'GiftList',
  data() {
    return {
      isDark: null,
      btnType: 'deposit',
      paging: {
        count: 20,
        page: 1,
        loading: false
      },
      futureList: [],
      tStyle: {
        btnY: 'basis-1/3 h-[55px] leading-[53px] fold:flex fold:items-center fold:leading-[16px] border-t-2 border-[#005cd3] text-[#005cd3] bg-white dark:bg-[#111119] dark:text-[#e5e5e5] dark:border-[#ffb500] font-medium',
        btnN: 'basis-1/3 h-[55px] leading-[53px] fold:flex fold:items-center fold:leading-[16px] border-t-2 border-[#c9c9c9] bg-[#f2f4f5] dark:bg-[#333] dark:text-[#e5e5e5] dark:border-[#606060]'
      }
    };
  },
  setup() {
    const useModalControl = create(modalControl);
    const { setModalControl, setAlertData, setAlertOpen } = useModalControl();
    const useDarkMode = create(darkMode);
    const { isDarkMode } = useDarkMode();
    const useApiLanguage = create(apiLanguage);
    const { apiLangCode } = useApiLanguage();
    const useRefresh = create(refresh);
    const { loadApi, setRefresh } = useRefresh();
    const useMobile = create(checkMobile);
    const { etcMobile } = useMobile();

    return {
      setModalControl,
      isDarkMode,
      apiLangCode,
      loadApi,
      setRefresh,
      setAlertData,
      setAlertOpen,
      etcMobile
    };
  },
  methods: {
    setDecimal(num, i) {
      const number = parseFloat(num);
      const place = Math.pow(10, i);
      return (Math.floor(number * place) / place).toFixed(i);
    },
    btnSelect(data) {
      this.btnType = data;
    },
    getListMore() {
      this.paging.page++;
      this.futureListApi();
    },
    async futureListApi() {
      let data = JSON.stringify({
        data: AesEncrypt({
          sdate: '',
          edate: '',
          pagecount: this.paging.count,
          page: this.paging.page
        })
      });

      let result = await Request(
        '/api/exs/trade/v1/request_my_assets_history',
        'post',
        this.apiLangCode[this.$i18n.locale],
        data
      );
      if (!result) return;

      let { status, message } = result.API_CODE;
      if (status == 'L402') {
        this.$t('login.msg15');
        localStorage.removeItem('fubit_token');
        localStorage.removeItem('fubit_rtoken');
        this.setLogout();
      } else if (status === '0000') {
        if (result.AUTH !== undefined) {
          localStorage.setItem('fubit_token', result.AUTH.token);
          localStorage.setItem('fubit_rtoken', result.AUTH.rtoken);
        }
        if (this.paging.page == 1) {
          this.futureList = result.my_assets_history;
        } else if (this.paging.page > 1) {
          result.my_assets_history.map((d) => {
            this.futureList.push(d);
          });
        }
      } else {
        console.log(message);
      }
    }
  },
  mounted() {},
  created() {
    this.futureListApi();
  },
  watch: {},
  computed: {
    moreBtn() {
      if (this.futureList.length == this.paging.page * this.paging.count) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
<style lang=""></style>
