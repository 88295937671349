<template lang="">
  <div class="fixed flex w-screen h-full sm:bg-black sm:bg-opacity-50 px-6 sm:px-0 bg-[#f2f4f5] bg-opacity-80">
    <div :class="tStyle.inner">
      <div :class="tStyle.point">
        <img src="../../assets/image/path1709.png" alt="" class="dark:hidden" />
        <img
          src="../../assets/image/path1709_d.png"
          alt=""
          class="opacity-0 dark:opacity-100"
        />
      </div>
      <div :class="tStyle.title">
        <h2 class="sm:text-[30px] text-[22px]">{{ $t('loginconfirm.title') }}</h2>
        <p class="sm:text-[20px] mt-[10px] sm:font-light text-[15px]">
          {{ $t('loginconfirm.desc') }}
        </p>
        <p class="sm:text-[20px] sm:font-light text-[15px]">
          {{ $t('loginconfirm.desc2') }}
        </p>
      </div>
      <div :class="tStyle.bt_box">
        <button :class="tStyle.cancel" @click="setLoginModal">
          {{ $t('common.cancel') }}
        </button>
        <button
          :class="tStyle.confirm"
          @click="goLoginPage"
        >
          {{ $t('common.ok') }}
        </button>
      </div>
      <button :class="tStyle.close_bt" @click="setLoginModal">
        <img src="../../assets/image/group2322.png" alt="" />
      </button>
    </div>
  </div>
</template>
<script>
import { modalControl, isLogin  } from '@/store/store';
import create from 'vue-zustand';

export default {
  name: 'LoginConfirm',
  data() {
    return {
      tStyle: {
        inner:
          'bg-white w-full sm:w-[370px] m-auto rounded sm:border border-[#C9C9C9] px-6 sm:px-[40px] pt-[15px] sm:pb-[40px] relative dark:bg-[#1c1d22] dark:border-[#606060] dark:text-[#c9c9c9] max-sm:flex max-sm:flex-col fold:flex fold:flex-col',
        title: 'text-center sm:mt-[50px] mt-5',
        point: 'absolute left-[-1px] top-[-1px] max-sm:hidden fold:hidden',
        overflow_box:
          'w-full h-[320px] mt-[20px] overflow-y-auto text-[13px] text-center text-[#9f9f9f]',
        order_list:
          'w-[203.2px] font-normal bg-[#f7f8fa] h-[44px] border-b border-b-[#c9c9c9]',
        table_list: 'h-[44px] border-b border-b-[#e5e5e5]',
        bt_box: 'sm:h-[35px] sm:mt-[35px] sm:leading-[35px] mt-auto py-6 sm:py-0 text-center text-[15px] max-sm:flex fold:flex',
        cancel:
          'sm:w-[117px] basis-1/2 py-1 sm:py-0 sm:h-[35px] sm:bg-[#e8eff7] rounded-[3px] dark:text-[#333333] dark:bg-[#fff8e9] bg-white sm:border-none border border-[#cfe4fe] dark:border-[#fff8e9]',
        confirm:
          'sm:w-[117px] basis-1/2 py-1 sm:py-0 sm:h-[35px] bg-[#cfe4fe] rounded-[3px] ml-[10px] dark:text-[#333333] dark:bg-[#ffb500]',

        close_bt: 'absolute right-[20px] top-[18px] font-light text-[30px] max-sm:hidden fold:hidden'
      }
    };
  },
  setup() {
    const useModalControl = create(modalControl);
    const { setModalControl, setModalName } = useModalControl();
    const useIsLogin = create(isLogin);
    const { setLoginModal } = useIsLogin();


    return { setModalControl, setModalName, setLoginModal  };
  },
  methods: {
    goLoginPage() {
      this.setLoginModal();
      this.$router.push({
        name: 'UserLogin'
      });
    }
  },
};
</script>
<style lang=""></style>
