<template lang="">
  <div class="fixed z-10 flex w-screen h-full bg-black bg-opacity-50">
    <div
      class="flex items-center z-[30] w-full px-4 justify-between fixed bg-white py-3 dark:bg-[#111119] sm:hidden"
    >
      <button class="" @click="$router.back()">
        <img
          src="@/assets/image/ion_chevron_back_outline.png"
          alt=""
          class="w-[30px] dark:hidden"
        />
        <img
          src="@/assets/image/ion_chevron_back_outline_d.png"
          alt=""
          class="w-[30px] mt-[-30px] dark:mt-0 dark:opacity-100 opacity-0"
        />
      </button>
      <div class="dark:text-white">
        {{
            authType === 'email'
              ? $t('common.email_auth')
              : authType === 'phone'
              ? $t('common.phone_auth')
              : authType === 'gtp'
              ? $t('common.otp_auth')
              : ''
          }}
      </div>
      <div class="w-[30px]"></div>
      </div>
    <div :class="tStyle.inner">
      <div :class="tStyle.point">
        <img src="../../assets/image/path1703.png" alt="" class="dark:hidden" />
        <img
          src="../../assets/image/path1703_d.png"
          alt=""
          class="opacity-0 dark:opacity-100"
        />
      </div>
      <div :class="tStyle.title">
        <h2 class="text-[30px] font-bold max-sm:hidden fold:hidden">
          {{
            authType === 'email'
              ? $t('common.email_auth')
              : authType === 'phone'
              ? $t('common.phone_auth')
              : authType === 'gtp'
              ? $t('common.otp_auth')
              : ''
          }}
        </h2>
        <h4 class="text-[22px] mt-[60px] max-sm:hidden fold:hidden">
          {{
            authType === 'email'
              ? $t('email.text')
              : authType === 'phone'
              ? $t('phone.text')
              : authType === 'gtp'
              ? $t('googlenumber.number_msg')
              : ''
          }}
        </h4>
        <div class="sm:hidden text-center justify-center mt-[56px] flex">
        <img
          src="@/assets/image/thmb_user.png"
          alt=""
          class="w-[20%] inline mt-[50px] dark:hidden"
        />
        <img
          src="@/assets/image/thmb_user_d.png"
          alt=""
          class="w-[20%] inline -ml-[20%] dark:ml-0 mt-[50px] opacity-0 dark:opacity-100"
        />
      </div>
        <h5
          class="sm:w-[425px] px-6 fold:px-4 sm:px-0 text-[14px] sm:h-[45px] border rounded-full mx-auto mt-[15px] flex items-center justify-center sm:text-[17px] text-[#626060] dark:text-[#c9c9c9] dark:border-[#606060]"
          v-if="authType !== 'gtp'"
        >
          {{ authUid }}
        </h5>
        <h4 class="sm:hidden max-sm:text-[15px] fold:text-[14px] ">
          {{
            authType === 'email'
              ? $t('email.text')
              : authType === 'phone'
              ? $t('phone.text')
              : authType === 'gtp'
              ? $t('googlenumber.number_msg')
              : ''
          }}
        </h4>
      </div>
      <div
        class="w-full sm:h-[90px] relative flex justify-between sm:mt-[88px] mt-[5%] children:last-child:border-[#005CB9]"
      >
        <input
          type="text"
          ref="input"
          maxLength="6"
          v-model="inputNum"
          class="absolute w-full sm:w-[600px] h-[460px] sm:h-[200px] mt-[-410px] bottom-0 cursor-pointer opacity-0 z-[0] focus:outline-none"
        />
        <input
          disabled
          @click="focusInput"
          type="text"
          v-for="(a, i) in 6"
          :key="i"
          v-model="inArr[i]"
          maxLength="1"
          :class="[inputNum.length === i ? tStyle.numInputY : tStyle.numInputN]"
        />
      </div>
      <div :class="tStyle.certFailText">{{ certFailText }}</div>
      <div class="text-right">
        <button class="underline text-[14px] sm:hidden" @click="authResend">
          {{ $t('password.resend2') }}
        </button>
      </div>
      <div v-if="authType !== 'gtp'">
        <button
          class="w-full max-sm:hidden fold:hidden h-[66px] rounded bg-[#CFE4FF] mt-[79px] flex items-center justify-center dark:bg-[#ffb500] dark:text-[#111119]"
          @click="authResend"
          v-if="isResend == true"
        >
          {{ $t('password.resend') }}
        </button>
        <button
          class="w-full max-sm:hidden fold:hidden h-[66px] rounded bg-[#CFE4FF] dark:bg-[#ffb500] mt-[79px] flex items-center justify-center opacity-50"
          v-else
          disabled
        >
          {{ secondCount }} {{ $t('email.auth') }} {{ $t('email.auth1') }}
        </button>
      </div>
      <div :class="[authType == 'gtp' ? 'mb-10' : '']"></div>
      <button :class="tStyle.close_bt" @click="setModalControl">
        <img src="../../assets/image/group2322.png" alt="" />
      </button>
    </div>
  </div>
</template>
<script>
import { modalControl, apiLanguage, refresh } from '@/store/store';
import { withdrawAuthType, withdrawDetail } from '@/store/walletStore';
import Request from '@/utils/Request';
import create from 'vue-zustand';
import AesEncrypt from '../Security/AesEncrypt';

export default {
  name: 'SnsVerification',
  data() {
    return {
      secondCount: 20,
      isResend: true,
      inputNum: '',
      inArr: [],
      inputIndex: 0,
      certFailText: '',
      tStyle: {
        inner:
          'sm:w-[686px] max-sm:h-full fold:h-full bg-white m-auto sm:rounded sm:border border-[#C9C9C9] sm:px-[40px] px-4 sm:py-[30px] relative text-center sm:dark:bg-[#1c1d22] dark:bg-[#111119] dark:border-[#606060] dark:text-[#c9c9c9]',
        title: 'sm:mt-[30px] mt-[56px]',
        point: 'absolute left-[-1px] top-[-1px] max-sm:hidden fold:hidden',
        num_box: 'mx-auto mt-[50px]',
        num: 'w-[80px] h-[80px] border border-b-[2px] border-b-[#626060] inline-block mx-[3px] dark:bg-[#3b3b43] dark:border-[#606060]',
        fail: 'text-center text-[#e7224b] text-[22px]',
        close_bt: 'absolute right-[20px] top-[18px] font-light text-[30px] max-sm:hidden',
        numInput:
          'disabled:bg-white cursor-pointer sm:w-[90px] sm:h-[90px] w-[14.6666%] h-[13.5vw] border border-[#C9C9C9] border-b-4 border-b-[#626060] text-center dark:bg-[#1c1d22] dark:border-[#606060] dark:text-[#c9c9c9]',
        numInputY:
          'disabled:bg-white cursor-pointer sm:w-[90px] sm:h-[90px] w-[14.6666%] h-[13.5vw] border border-[#C9C9C9] border-b-[#005CD3] dark:border-b-[#ffb500] dark:bg-[#1c1d22] dark:border-[#606060] border-b-4 text-center',
        numInputN:
          'disabled:bg-white cursor-pointer sm:w-[90px] sm:h-[90px] w-[14.6666%] h-[13.5vw] border border-[#C9C9C9] border-b-4 border-b-[#626060] text-center dark:bg-[#1c1d22] dark:border-[#606060] dark:text-[#c9c9c9]',
        numInputFail:
          'disabled:bg-white cursor-pointer sm:w-[90px] sm:h-[90px] w-[14.6666%] h-[13.5vw] border border-[#C9C9C9] border-b-4 border-b-[#FC0D1B] text-center dark:bg-[#1c1d22] dark:border-[#FC0D1B] dark:text-[#c9c9c9]',
        certFailText:
          'sm:mt-[8px] max-sm:text-[14px] fold:text-[14px] text-[#FC0D1B] max-sm:text-left fold:text-left'
      }
    };
  },
  setup() {
    const useModalControl = create(modalControl);
    const { setModalControl, setAlertData, setAlertOpen } = useModalControl();
    const useAuthType = create(withdrawAuthType);
    const { authType, authUid, pwChangeData } = useAuthType();
    const useWithdrawDetail = create(withdrawDetail);
    const { withdrawData } = useWithdrawDetail();
    const useApiLanguage = create(apiLanguage);
    const { apiLangCode } = useApiLanguage();
    const useRefresh = create(refresh);
    const { setRefresh } = useRefresh();

    return {
      setModalControl,
      authType,
      authUid,
      withdrawData,
      setAlertData,
      setAlertOpen,
      apiLangCode,
      setRefresh,
      pwChangeData
    };
  },
  methods: {
    focusInput() {
      this.$refs.input.focus();
    },
    // 인증번호 확인 및 출금 신청
    async outputConfirm() {
      const data = {
        symbol: this.withdrawData.symbol,
        net: this.withdrawData.net,
        key_main: this.withdrawData.address,
        key_sub: this.withdrawData.keysub,
        percent_yn: 'N',
        quantity: this.withdrawData.amount,
        code: this.inputNum
      };
      const reqData = JSON.stringify({
        data: AesEncrypt(data)
      });
      let result = await Request(
        '/api/exs/user/v1/request_user_output_confirm',
        'post',
        this.apiLangCode[this.$i18n.locale],
        reqData
      );

      if (!result) {
        return;
      }

      let { API_CODE } = result;

      if (API_CODE.status === 'L402') {
        this.setAlertData({
          title: '',
          desc: this.$t('login.msg15')
        });
        this.setAlertOpen();
        localStorage.removeItem('fubit_token');
        localStorage.removeItem('fubit_rtoken');
        this.goMainPage();
        this.setLogout();
        return;
      } else if (API_CODE.status === '0000') {
        this.setAlertData({
          title: this.$t('withdraw_detail.success'),
          desc: this.$t('withdraw_detail.successdesc')
        });
        this.setAlertOpen();
        this.setModalControl();
        this.setRefresh('withdrawlist');
      } else if (API_CODE.status === '0110') {
        this.certFailText = this.$t('withdraw_detail.authFail');
        this.tStyle.numInputN = this.tStyle.numInputFail;
      } else {
        this.setAlertData({
          title: '',
          desc: API_CODE.message
        });
        this.setAlertOpen();
      }
    },
    async authResend() {
      this.isResend = false;
      const data = {
        symbol: this.withdrawData.symbol,
        net: this.withdrawData.net,
        percent_yn: 'N',
        quantity: this.withdrawData.amount
      };

      const reqData = JSON.stringify({
        data: AesEncrypt(data)
      });
      this.secondCounting();
      let result = await Request(
        '/api/exs/user/v1/request_user_output',
        'post',
        this.apiLangCode[this.$i18n.locale],
        reqData
      );

      if (!result) {
        return;
      }
    },
    secondCounting() {
      var interval = setInterval(() => {
        this.secondCount = this.secondCount - 1;
        if (this.secondCount <= 0) {
          this.timerStop(interval);
        }
      }, 1000);
    },
    timerStop(timer) {
      clearInterval(timer);
      this.isResend = true;
      this.secondCount = 20;
    },
    async confirmAuth() {
      let data = JSON.stringify({
        data: AesEncrypt({
          type: 'pw',
          auth_type: this.authType,
          new_value: this.pwChangeData.new_value,
          uauth: this.inputNum
        })
      });

      let result = await Request(
        '/api/exs/mypage/v1/confirm_auth',
        'post',
        this.apiLangCode[this.$i18n.locale],
        data
      );

      if (!result) {
        this.isFail = true;
        return;
      }

      let { status } = result.API_CODE;

      if (status === 'L402') {
        //session closed
        this.sessionClosed();
      } else if (status === '0000') {
        this.isFail = false;
        this.openAlertModal('', this.$t('password.authcheck'));
        this.setModalControl();
      } else {
        this.isFail = true;
      }
    },
    openAlertModal(title, desc, isReload) {
      this.setAlertData({ title, desc, isReload });
      this.setAlertOpen();
    },
    sessionClosed() {
      this.setAlertData({
        title: '',
        desc: this.$t('login.msg15')
      });
      this.setAlertOpen();
      localStorage.removeItem('fubit_token');
      localStorage.removeItem('fubit_rtoken');
      this.goMainPage();
      this.setLogout();
    }
  },
  watch: {
    inputNum(a, b) {
      var blankCheck = /[\s]/g;
      if (isNaN(a) == true) {
        this.inputNum = b;
      } else if (blankCheck.test(a) == true) {
        this.inputNum = b;
      } else {
        this.inArr = Array.from(this.inputNum);
        if (this.inputNum.length == 6) {
          if (this.pwChangeData == null) {
            this.outputConfirm();
          } else {
            this.confirmAuth();
          }
        } else {
          this.tStyle.numInputN = this.tStyle.numInput;
          this.certFailText = '';
        }
      }
    }
  },
  mounted() {
    window.addEventListener('beforeunload', this.unLoadEvent);
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.unLoadEvent);
  }
};
</script>
<style lang=""></style>
