export default {
  "common": {
    "nation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国家"])},
    "nation_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择国家"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索"])},
    "search2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入搜索词"])},
    "search3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资产"])},
    "asset_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资产管理"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["时间段"])},
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今天"])},
    "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1周"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])},
    "1month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1个月"])},
    "3month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3个月"])},
    "mywallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的钱包"])},
    "viewwallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看钱包"])},
    "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["充值"])},
    "exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["兑换"])},
    "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提现"])},
    "margin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保证金"])},
    "operate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])},
    "deposit2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["充值"])},
    "exchange2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["兑换"])},
    "withdraw2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提现"])},
    "trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易"])},
    "coinsearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可充值的币种搜索"])},
    "networkselection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["网络选择"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["时间"])},
    "division": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分类"])},
    "condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
    "completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已完成"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已取消"])},
    "progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进行中"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["价格"])},
    "commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手续费"])},
    "invest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["投资记录"])},
    "recentorder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未完成订单"])},
    "orderhistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单历史"])},
    "profitloss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收益损失"])},
    "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合约"])},
    "leverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["杠杆"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类型"])},
    "invest_limits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["限价"])},
    "invest_marketprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["市价"])},
    "invest_conditional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条件性"])},
    "orderprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下单价格"])},
    "orderquantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下单数量"])},
    "contract_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成交量"])},
    "contract_amount2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成交数量"])},
    "contract_Average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均成交价"])},
    "contract_Average2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均成交"])},
    "target_loss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目标价/止损价"])},
    "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["方向"])},
    "indefinitely": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无限期"])},
    "crossbuy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交叉多头"])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["等待"])},
    "part_contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部分成交"])},
    "contract_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成交明细"])},
    "contract_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成交完成"])},
    "trigger-clue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["触发条件"])},
    "trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["触发条件"])},
    "trigger_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["触发价格"])},
    "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参加活动"])},
    "event_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动详情"])},
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀请好友"])},
    "usercenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help Center"])},
    "guidebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["指南书"])},
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公告事项"])},
    "contact us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1:1咨询"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标题"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期"])},
    "writedate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["撰写日期"])},
    "mypage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的页面"])},
    "usersetting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人设置"])},
    "setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设置"])},
    "resetting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新设置"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移动电话"])},
    "authcenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["认证中心"])},
    "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帐户活动"])},
    "phone_auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手机认证"])},
    "email_auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件认证"])},
    "auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["认证"])},
    "google2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTP"])},
    "otp_auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTP认证"])},
    "change_nickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改昵称"])},
    "uuid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入UUID"])},
    "retouch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修改"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认"])},
    "deauthorization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消授权"])},
    "certification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["认证"])},
    "removeauthfail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消授权失败"])},
    "removeauthsuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消授权成功"])},
    "certificated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已认证"])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已拒绝"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["礼品钱包余额"])},
    "30deal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近30天交易量"])},
    "google_auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTP 认证"])},
    "notice2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公告"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["继续"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
    "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["谷歌"])},
    "whole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部"])},
    "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["符号"])},
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["英文"])},
    "korean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["韩文"])},
    "initialization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["初始化"])},
    "delet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除"])},
    "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["钱包"])},
    "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["失败"])},
    "failfetch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获取数据失败"])},
    "user_level_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General member"])},
    "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订阅"])},
    "findby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索"])},
    "googlecert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["谷歌认证"])},
    "nodata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有数据"])},
    "stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["现货"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一个"])},
    "eventword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动"])},
    "referer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推荐"])}
  },
  "login": {
    "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册"])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入注册信息。"])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请包含大小写字母和数字。"])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请包含特殊字符。"])},
    "msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入8到30个字符。"])},
    "re": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新输入"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀请码"])},
    "msg5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入邀请码。"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择"])},
    "msg6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "msg7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看并同意服务条款和隐私政策"])},
    "msg8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建账户"])},
    "msg9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件格式不正确。"])},
    "msg10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码格式不正确。"])},
    "msg11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码不一致。"])},
    "msg12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件可用。"])},
    "msg13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请正确输入密码。"])},
    "msg14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["需要登录才能使用的服务。"])},
    "msg15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录会话已过期。"])},
    "msg16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册成功。"])},
    "msg17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请登录以开始交易。"])},
    "msg18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
    "msg19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["祝您愉快的一天。"])},
    "msg20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["验证码无效。"])},
    "msg21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["验证码已过期。"])},
    "msg22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册成功。"])},
    "msg23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新输入密码"])},
    "msg24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户名或密码错误。"])},
    "loginword1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["记住用户名"])},
    "msg25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话号码格式不正确。"])},
    "msg26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条款和政策"])}
  },
  "topmenu": {
    "procenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推广中心"])},
    "mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模式"])},
    "sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["声音"])},
    "layout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["布局"])}
  },
  "footer": {
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["投资具有风险，投资责任由投资者自负。"])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请避免过度投资，考虑由于价值波动而可能导致的损失。"])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司介绍"])},
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公告"])},
    "center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["投资保护中心"])},
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["常见问题"])},
    "kakao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过KakaoTalk咨询"])},
    "inquiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1:1 咨询"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公司"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户支持"])},
    "word1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서울시 강남구 테헤란로 1길 23, 4층"])},
    "word2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주식회사 푸빗"])},
    "word3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대표 김푸빗"])},
    "word4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사업자 등록번호 123-45-67890"])},
    "word5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가상 사업자 등록번호 123-45-67890"])},
    "word6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录后，您可以在 Fu-bit 上访问各种服务。"])}
  },
  "mainhome": {
    "word": {
      "startdeal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开始交易"])},
      "populardeal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["热门交易"])},
      "bigchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大幅波动"])},
      "highprofit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最高收益率"])},
      "newcoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新交易"])},
      "marketstatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["市场现状"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前价格(USDT)"])},
      "ratechange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["变动率(24H)"])},
      "highprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最高价(24H)"])},
      "lowprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最低价(24H)"])},
      "viewmore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看更多"])},
      "guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新手指南"])},
      "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["存款"])},
      "trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易"])},
      "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24小时客户支持"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关闭"])},
      "inspect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["检查"])}
    },
    "msg": {
      "mainbannermsg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利用交易所"])},
      "mainbannermsg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安全、快速地增加您的财富。"])},
      "mainbannermsg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["现在注册即可获得30 USDT。"])},
      "middlebannermsg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["简单快捷的数字货币交易所"])},
      "middlebannermsg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从数字货币信息到订单都非常简单快捷的数字货币交易所"])},
      "bottomtit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与众不同，立即开始！"])},
      "bottom1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果是第一次使用Fu bit，"])},
      "bottom1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们将提供易于理解的说明。"])},
      "bottom2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首次存款将额外奖励"])},
      "bottom2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10 USDT。"])},
      "bottom3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册并开始交易，"])},
      "bottom3_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可获得高达100 USDT的奖励。"])},
      "bottom4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如有任何疑问，请随时咨询。"])},
      "bottom4_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们24小时为您提供服务。"])},
      "modalmsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不再显示"])},
      "inputtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入您的E-Mail地址。"])}
    }
  },
  "trade": {
    "modal": {
      "margin_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["永续合约保证金方式"])},
      "cross": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全仓"])},
      "isolated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["逐仓"])},
      "cross2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全仓模式"])},
      "isolated2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["逐仓模式"])},
      "adjust": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["调整保证金"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["增加"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["减少"])},
      "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最大可用额度"])},
      "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额"])},
      "est": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预估强制平仓价格"])},
      "profit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目标价格"])},
      "loss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["止损价格"])},
      "entryprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均开仓价格"])},
      "liq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["强制平仓价格"])},
      "size2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易数量"])},
      "pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预估收益"])},
      "basis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["触发条件"])},
      "tp_sl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目标价格/止损价格"])},
      "markprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前价格"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下载"])},
      "liq2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平仓"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可平数量"])}
    },
    "msg": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果选择保证金方式，则仅适用于相应的虚拟资产种类。"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将所有合约钱包余额设置为抵押品。"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在清算合约时，您的所有资产可能会被强制没收。"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为每个合约设置单独的抵押品资产。"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当抵押率达到100％时，仅清算该合约。"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果合约正在执行中，则无法更改保证金方式。"])}
    }
  },
  "mywallet": {
    "walletview": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首页 > 我的钱包 > 查看钱包"])},
      "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["隐藏"])},
      "totalasset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总资产"])},
      "checkboxtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不支持交易/小额资产隐藏"])},
      "loss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未实现盈亏"])},
      "possibleasset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可用资产"])},
      "inkindasset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["现货资产"])},
      "coin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["币种"])},
      "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可用"])},
      "freez": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["冻结"])},
      "bitcoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["比特币"])},
      "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["导入"])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["导出"])},
      "forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合约资产"])},
      "mobilelist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期货详情"])},
      "mobilelist2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["股票详细情况"])},
      "futurelist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期货钱包历史记录"])}
    },
    "depositwallet": {
      "word": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首页 > 我的钱包 > 存款"])},
        "assetselection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择资产"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["存款地址"])},
        "precautions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["存款注意事项"])},
        "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["copy"])},
        "destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目的地址"])},
        "depositdetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["存款记录"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["存款数量"])},
        "copy2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已复制"])},
        "inoutlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["充提记录"])}
      },
      "msg": {
        "precautions_msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. 选择您要存入的资产和网络，并确保其正确无误"])},
        "precautions_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. 用二维码扫描存款地址或将其粘贴到提款钱包中并申请提款。"])},
        "precautions_msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. 请等待区块链网络确认转账。"])},
        "precautions_msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. 在区块链网络确认转账后，Fu bit将把资产发送到钱包地址。"])},
        "precautions_msg5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["※ 除地址外，某些资产可能需要其他数据或目标标签。"])},
        "precautions_msg6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["※ 对于由于输入错误的存款信息而导致的任何问题，Fu bit概不负责。"])},
        "address_msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "address_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复制并输入存款地址。"])},
        "alertmsg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["结束日期不能早于开始日期。"])}
      }
    },
    "exchange": {
      "word": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主页 > 我的钱包 > 兑换"])},
        "before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["兑换前"])},
        "exchange_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["兑换数量"])},
        "after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["兑换后"])},
        "possible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可兑换数量"])},
        "expect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预期兑换数量"])},
        "coinprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["币价"])},
        "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每次兑换限额"])},
        "amount_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24小时兑换金额限制"])},
        "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["兑换记录"])},
        "exchanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已兑换数量"])},
        "price_check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["兑换价格确认"])},
        "countLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["秒后刷新"])},
        "countlimit2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      "msg": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["兑换成功."])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["兑换失败."])},
        "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入数量."])},
        "notenough": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可兑换资产不足."])}
      }
    },
    "withdraw": {
      "word": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主页 > 我的钱包 > 提现"])},
        "location2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主页 > 我的钱包 > 提现 > 地址管理"])},
        "address_manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址管理"])},
        "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提现资产"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址管理"])},
        "address_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址"])},
        "coinsearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可提现的加密货币搜索"])},
        "address2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提现地址簿"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提现数量"])},
        "withdrawpossible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可提现数量"])},
        "withdrawpossible2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数量"])},
        "withdrawdetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提现记录"])},
        "precautions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提现注意事项"])},
        "minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最小提现金额"])},
        "amount_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24小时提现限额"])},
        "asset2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提现资产"])},
        "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入提现地址"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手动输入"])},
        "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择网络"])},
        "minimum2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最小提现金额为0.001"])},
        "24withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24小时内最大提现金额"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址名称"])},
        "withdrawaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提现地址"])},
        "adresslist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址列表"])},
        "adressplus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加地址"])},
        "noadress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未添加任何地址"])},
        "adressmsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入4-5个字符"])},
        "mylevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的提现等级"])},
        "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["级别"])},
        "levelbtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提升等级"])},
        "destination_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入目标代码"])},
        "addr_err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址错误"])},
        "io_check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["维护保养"])},
        "over_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["异常资产"])},
        "inquiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户咨询"])},
        "notag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未输入标签"])},
        "treble": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treble-rule确认"])}
      },
      "msg": {
        "withdraw_msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 请务必确认选择的资产和输入的提款地址是针对相同资产的。"])},
        "withdraw_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 所选的提款资产只能接收到该资产的存款地址。"])},
        "withdraw_msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 一般情况下，完成提款申请后，需要约1个小时左右才能在收到的钱包中确认交易。"])},
        "withdraw_msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 提款处理可能会因区块链网络等情况而延迟。"])},
        "withdraw_msg5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 如果要将资金转入其他交易所的钱包，请务必确认该交易所的政策。"])},
        "withdraw_msg6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 提款申请完成后，后续过程将在区块链网络中处理。"])},
        "withdraw_msg7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 由于区块链的特性，一旦提款开始，就无法取消。"])},
        "withdraw_msg8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 由于 Fu bit 系统的原因，提款地址可能会有所不同。"])},
        "withdraw_msg9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 任何因输入错误的提款信息，未确认收款钱包或交易所的政策而导致的损失均由用户承担，如果存在任何不正当或可疑的交易，Fu bit 可能会限制提款。"])},
        "withdraw_msg10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入提款地址。"])},
        "withdraw_msg11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可用提款资产不足。"])}
      }
    }
  },
  "invest": {
    "recentorder": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首页 > 投资记录 > 当前委托"])}
    },
    "orderhistory": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首页 > 投资记录 > 委托记录"])}
    },
    "profitloss": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首页 > 投资记录 > 结算损益"])},
      "position_clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平仓"])},
      "position_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["持仓设置"])},
      "average_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均价格"])},
      "liquidation_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["强制平仓价格"])},
      "loss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["强平损益"])}
    }
  },
  "promotion": {
    "event": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首页 > 促销中心 > 参与活动"])},
      "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["欢迎奖励"])},
      "bonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会员注册奖金发放"])},
      "bonus_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8月存款达到10000美元以上时，发放奖金"])},
      "mision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["执行任务"])},
      "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["进行中"])},
      "acquire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["获得补偿"])},
      "acquire_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任务补偿已汇入礼品包。"])},
      "completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任务完成"])},
      "participate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参与任务"])},
      "participate_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["现在开始任务。"])},
      "event_compensation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动奖励"])},
      "mision_clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任务完成"])},
      "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动条款"])},
      "terms1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务使用协议"])},
      "terms2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人信息处理方针"])},
      "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复制链接"])},
      "noticedetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公告列表"])},
      "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回列表"])},
      "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["浏览次数"])},
      "rewarded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已发放"])},
      "rewarded2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已存入您的钱包。"])},
      "unlimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不限"])},
      "term1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fu bit的活动仅针对已注册会员。"])},
      "term2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["若参与活动的方式非正常或非法，或者妨碍活动运营的行为，则可能被排除在获奖者范围之外，并且在获奖之后也可能被取消。"])},
      "term3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["奖励将在活动进行中或结束后在目标促销中心中发放。奖金必须手动领取。"])},
      "term4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过邀请朋友获得的奖励将于次日00:00（UTC）自动发放。"])},
      "term5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有参与者必须遵守Fu bit使用协议。Fu bit有权取消因批量帐户注册、非法、不良目的及其他与违规行为有关的活动而获得额外奖金的参与者资格。"])},
      "term6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["奖励仅可用于赠品交易，不能直接提现。"])},
      "term7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["奖励可用作保证金，也可用于支付交易手续费和亏损。"])},
      "term8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用奖励进行交易并积累的所有收益都可以提现。"])},
      "term9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fu bit有权在不事先通知的情况下更改本次活动规定。"])},
      "term10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fu bit保留本活动最终解释权。如果您有任何疑问，请联系客户支持。"])}
    },
    "invite": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主页 > 促销中心 > 邀请好友"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的邀请信息"])},
      "info_msg_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀请好友并获得佣金返还。"])},
      "info_msg_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以邀请的朋友交易产生的佣金为基础，我和朋友分摊返还。"])},
      "info_msg_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀请码中设定了您和朋友可获得的佣金返还比例。"])},
      "info_msg_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请点击邀请码管理查看详细信息。"])},
      "ranking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["好友邀请排行榜奖励"])},
      "ranking_msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为期一个月的邀请好友最多人的排行榜。"])},
      "ranking_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["好友邀请排行榜第1到第10名将在下个月1日00:00（UTC）自动存入礼金钱包。"])},
      "ranking_msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["排行榜奖励获得的礼金可用于交易所需的保证金、手续费等，但不可提现至外部。"])},
      "expect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预计奖励金额"])},
      "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["累计人数"])},
      "compensation2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["累计奖励金额"])},
      "person2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推荐链接"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的邀请码"])},
      "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分享"])},
      "invite_rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀请排行榜"])},
      "rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["排名"])},
      "rank2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户ID"])},
      "influencer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申请成为影响者"])},
      "influencerreview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["影响者审核中"])},
      "influencerinquire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查询影响者"])},
      "compensation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["奖励金额"])},
      "invite_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["好友邀请记录"])},
      "invite_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀请时间"])},
      "invite_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册日期"])},
      "compensation3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今日奖励金额"])},
      "invite_friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["被邀请的好友"])},
      "reward_join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册奖励"])},
      "reward_deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["充值奖励"])},
      "reward_deal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易奖励"])},
      "reward_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总奖励"])},
      "title_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀请好友"])},
      "info_msg_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["链接或邀请码已复制。"])},
      "code_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请在邀请码管理中进行设置。"])}
    }
  },
  "usercenter": {
    "guidebook": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主页 > Help center > 指南手册"])},
      "location2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主页 > Help center > 指南手册 > 指南历史"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索指南手册"])},
      "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["指南详情"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["指南信息"])},
      "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看列表"])},
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看图片"])},
      "list_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["存入资产的方法"])},
      "nodata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["暂无注册的指南手册。"])},
      "linkcopysuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已复制到剪贴板。"])}
    },
    "notice": {
      "view": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主页 > Help center > 公告事项"])}
      },
      "detail": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主页 > Help Center > 公告事项 > 公告列表"])}
      },
      "nonotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有注册的公告。"])}
    },
    "inquiry": {
      "list": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主页 > Help Center > 1:1咨询 > 咨询记录"])},
        "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["咨询记录"])},
        "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["咨询登记"])},
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["咨询类型"])},
        "situation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
        "answer_wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["等待回答"])},
        "answer_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回答完成"])},
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户"])},
        "nolist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有咨询记录"])},
        "answer_wait_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["等待回答中"])}
      },
      "reg": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主页 > Help Center > 1:1咨询 > 咨询登记"])},
        "usercenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help Center"])},
        "guidebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["指南"])},
        "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公告"])},
        "inquiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1:1咨询"])},
        "inquirylist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["咨询历史"])},
        "inquiry_regist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["咨询登记"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择类型"])},
        "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请选择咨询类型。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标题"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["咨询内容"])},
        "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可输入30个字符或更少。"])},
        "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可输入30至500个字符。"])},
        "att_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件附件"])},
        "att_file2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["附件"])},
        "find_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查找文件"])},
        "regist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登记"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已咨询。"])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["失败。"])},
        "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择项目"])},
        "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回答内容"])},
        "msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入标题。"])},
        "msg5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入内容。"])}
      }
    }
  },
  "mypage": {
    "usersetting": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主页 > 我的页面 > 个人设置"])},
      "location2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主页 > 我的页面 > 个人设置 > 设置"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改密码"])},
      "password2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找回密码"])},
      "google_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["谷歌关联"])},
      "google_not_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["解除谷歌关联"])},
      "login_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录安全设置"])},
      "recent_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近的登录"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册"])},
      "mycommission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的交易佣金"])},
      "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易佣金折扣"])},
      "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标准佣金"])},
      "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["折扣等级"])},
      "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["佣金折扣率"])},
      "discount_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在交易时，将适用标准佣金，根据佣金折扣率获得的折扣金额将在交易日次日0点（UTC）存入礼物钱包。"])},
      "screen_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易屏幕设置"])},
      "notification_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知设置"])},
      "sound_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["声音设置"])},
      "setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设置"])},
      "removegooglelink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您确定要解除谷歌关联吗？"])},
      "removeotp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["谷歌关联已解除。"])},
      "toggle": {
        "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一行公告"])},
        "darkmode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["黑夜模式"])},
        "whitemode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["白天模式"])},
        "marketstatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["市场状况"])},
        "favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收藏夹窗口"])},
        "recentdeal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近交易窗口"])},
        "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["呼叫窗口"])},
        "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资产窗口"])},
        "e_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件登录通知"])},
        "e_liquidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件强制清算通知"])},
        "e_withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件提款通知"])},
        "e_deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件存款通知"])},
        "sms_wd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS 存款/提款通知"])},
        "ordercomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单提交完成"])},
        "trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["触发器触发"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消订单"])},
        "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单完成"])},
        "conclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单成交"])},
        "liquidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["强制清算"])}
      }
    },
    "authcenter": {
      "word": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首页 > 我的页面 > 认证中心"])},
        "accountlevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户安全等级："])},
        "safe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安全"])},
        "normal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["普通"])},
        "danger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["危险"])},
        "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTP"])},
        "anti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["防钓鱼验证码"])},
        "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改"])},
        "anti2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["防钓鱼验证码："])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不超过10个字符"])}
      },
      "msg": {
        "level_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为了安全起见，请添加额外的安全认证。"])},
        "level_msg_m1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为了安全起见"])},
        "level_msg_m2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请添加额外的安全认证。"])},
        "email_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您将收到用于登录、更改密码和提款的认证码。"])},
        "phone_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您将收到用于登录、更改密码和提款的认证码。"])},
        "google_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您将收到用于登录、更改密码和提款的Google OTP认证码。"])},
        "usdt_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册认证并获得50 USDT奖励。"])},
        "security_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过添加代码到交易所发送的短信和电子邮件来增强安全性。"])},
        "login_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请确认您登录的网站地址。"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["认证码已发送。"])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["认证码发送失败。"])},
        "noemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未找到注册的电子邮件地址。"])},
        "nophone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未找到注册的手机号码。"])}
      }
    },
    "activity": {
      "word": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首页 > 我的页面 > 账户活动"])},
        "record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近登录记录"])},
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在所有设备上注销"])},
        "logout2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注销"])},
        "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录时间"])},
        "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设备"])},
        "browser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["浏览器"])},
        "ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP地址"])},
        "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["位置"])},
        "model2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设备/浏览器"])},
        "ip2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["位置/IP"])},
        "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会员注销"])},
        "withdrawfail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会员注销失败。"])},
        "withdraw2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注销"])},
        "android": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安卓"])},
        "korea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["韩国"])},
        "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会员注销后，1个月内无法使用相同的信息进行会员注册。 \n确定要进行会员注销吗？"])}
      },
      "msg": {
        "logout_msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这是会员的 Fubit 登录历史记录。"])},
        "logout_msg1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果有记录没有被您本人登录，则"])},
        "logout_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请立即"])},
        "logout_msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改帐户密码并在所有设备上注销登录"])},
        "logout_msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["。"])},
        "withdraw_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的帐户信息将立即被删除，并且可能无法恢复。"])}
      }
    }
  },
  "Influencer": {
    "application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申请成为影响者"])},
    "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["影响者审批结果"])},
    "referer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推荐人管理"])},
    "referer2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转诊"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申请人账户"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["影响者推荐码"])},
    "entercode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入推荐码"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申请信息"])},
    "enterinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入申请信息"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交申请"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消申请"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新申请"])},
    "noresult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒绝理由"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申请已成功提交。"])},
    "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申请失败。"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会员ID"])}
  },
  "nickname": {
    "change_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入要更改的昵称"])},
    "nickname_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["昵称可用。"])},
    "phone_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话号码可用。"])},
    "email_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件可用。"])},
    "authemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这是已经验证的电子邮件。"])},
    "authphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这是已经验证的电话号码。"])},
    "authfail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["验证失败。"])},
    "nicknamefail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改昵称失败。"])},
    "nicknamesuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["昵称已更改。"])},
    "emailtypefail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件格式不正确。"])},
    "emptyemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入要验证的电子邮件。"])},
    "emptyphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入要验证的电话号码。"])},
    "auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["验证"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件"])},
    "beforeauthphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请完成手机验证."])},
    "beforeauthmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请进行电子邮件验证."])}
  },
  "contractdetail": {
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成交明细"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成交时间"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成交价"])}
  },
  "withdraworder": {
    "cancel_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您确定要取消订单吗？"])}
  },
  "feeinfo": {
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手续费说明"])},
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["等级"])},
    "maker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maker手续费"])},
    "taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taker手续费"])}
  },
  "authgoogle": {
    "auth_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在进行验证之前，请先确认。"])},
    "auth_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您是否安装了Google OTP应用程序？"])},
    "googleplay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Play"])},
    "appstore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App Store"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google登录"])},
    "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTP请求失败。"])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP绑定"])}
  },
  "googleqr": {
    "qr_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请使用 Google OTP APP 扫描下面的二维码。"])},
    "qr_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果无法扫描，请手动输入代码。"])},
    "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google 验证"])}
  },
  "googlenumber": {
    "number_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入Google OTP身份验证号码。"])},
    "fail_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["验证码无效！"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTP身份验证设置已完成。"])}
  },
  "googlecomplete": {
    "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTP身份验证完成"])},
    "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTP身份验证设置已经"])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完成。"])}
  },
  "password": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入电子邮件"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入电话号码"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改密码"])},
    "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前密码"])},
    "now_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入当前使用的密码"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新密码"])},
    "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入新密码"])},
    "new2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认密码"])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安全验证"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新发送验证码"])},
    "resend2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新发送"])},
    "authcheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已验证"])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码已成功重置。"])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请登录以开始交易。"])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码格式不正确。"])},
    "msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新密码与确认密码不匹配。"])},
    "msg5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请确认您的当前密码。"])},
    "msg6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请确认您的新密码。"])},
    "msg7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请确认您的密码。"])}
  },
  "layout": {
    "setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["布局设置"])}
  },
  "email": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入通过电子邮件发送的6位认证码。"])},
    "auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["秒后可用"])},
    "auth1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入通过电话发送的6位认证码。"])},
    "authsuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["认证成功"])}
  },
  "phone": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入通过手机短信发送的6位认证码。"])},
    "authsuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["认证成功"])},
    "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["失败"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新发送"])},
    "resendfail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新发送失败。"])}
  },
  "passwordforgot": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["忘记密码了吗？"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册时使用的电子邮件地址。"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到输入的会员信息。"])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新设置密码后，将在24小时内限制取款。"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新设置密码"])},
    "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请设置新密码。"])},
    "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新密码（包含字母，数字，特殊字符的组合，长度为8至30个字符）"])},
    "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请再次输入新密码。"])},
    "text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["忘记密码了吗？请输入注册时使用的手机号码。"])},
    "text8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送验证码"])},
    "text9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["验证码发送失败，请重试。"])},
    "text10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未注册用户。"])},
    "text11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["忘记密码了吗？请输入注册时使用的电子邮件地址。"])},
    "text12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入注册时使用的手机号码。"])}
  },
  "orderconfirm": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂單確認書"])},
    "deal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易"])},
    "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂單"])},
    "market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["市價單"])},
    "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["限價單"])},
    "c_market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["條件市價單"])},
    "c_designation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["條件限價單"])},
    "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目標價格"])},
    "lender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["止損價格"])},
    "liquidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["強制清算"])},
    "liquidation2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["強制清算價格"])},
    "trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["觸發價格"])}
  },
  "signup": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册"])},
    "promotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推广或推荐代码（可选）"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["阅读并同意条款和隐私政策"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["。"])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已经有帐号了吗？"])},
    "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入电子邮件。"])},
    "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已注册的电子邮件。"])},
    "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入的信息不符合电子邮件格式。"])},
    "text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入您的电话号码。"])},
    "text8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已注册的移动电话。"])},
    "text9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入的信息不符合电话号码格式。"])},
    "text10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入密码。"])},
    "text11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入的密码与确认密码不同。"])},
    "text12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入的密码格式不正确。"])},
    "text13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请再次输入密码。"])},
    "text14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入的确认密码与密码不同。"])},
    "text15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入的密码格式不正确。"])},
    "text16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代码无效。"])},
    "text17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改个人资料照片"])},
    "text18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["昵称已被使用。"])},
    "text19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["昵称"])},
    "text20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入推广或推荐代码。"])}
  },
  "withdraw_detail": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提款申请详细信息"])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["网络"])},
    "commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["网络手续费"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预计收款金额"])},
    "execution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["执行提款"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓名"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申请完成"])},
    "successdesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提款申请已成功注册。"])},
    "authFail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["验证码不匹配。"])},
    "getQty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接收金额"])}
  },
  "loginconfirm": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["需要登录才能访问此项目。"])},
    "desc2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您要登录吗？"])}
  },
  "deal": {
    "closeLong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平多"])},
    "closeShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平空"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总资产"])},
    "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["指数价格"])},
    "24change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24小时涨跌幅"])},
    "countdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["倒计时"])},
    "funding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资金费率"])},
    "fundingfee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资金费用"])},
    "valume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成交量"])},
    "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最高价"])},
    "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最低价"])},
    "marketprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["市价"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["价格"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["累计数量"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部"])},
    "trades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最新成交"])},
    "scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["放大"])},
    "scale2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["缩小"])},
    "posopen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开仓"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平仓"])},
    "market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["市价委托"])},
    "trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条件委托"])},
    "cross": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全仓"])},
    "isolate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["逐仓"])},
    "long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["做多"])},
    "short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["做空"])},
    "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可用数量"])},
    "margin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保证金"])},
    "balange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总资产"])},
    "available2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可用资产"])},
    "pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未实现盈亏"])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["单位"])},
    "openlong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开多"])},
    "openshort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开空"])},
    "openlong2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["买入"])},
    "openshort2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卖出"])},
    "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["充值"])},
    "convert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转换"])},
    "favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收藏"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索"])},
    "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["币种"])},
    "lastprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最新价"])},
    "24h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24小时涨跌"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数量"])},
    "available3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可用"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["撤单"])},
    "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["持仓"])},
    "pnl2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已实现盈亏"])},
    "openorder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前委托"])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["历史委托"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资产"])},
    "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合约"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])},
    "tpsl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["止盈/止损"])},
    "pnl3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未实现收益率"])},
    "liq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["强平价格"])},
    "side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["方向"])},
    "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前价格"])},
    "leverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["杠杆倍数"])},
    "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成交量"])},
    "entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["持仓均价"])},
    "leverage2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["杠杆倍数设置"])},
    "leverage3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["杠杆倍数"])},
    "perpetual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["永续"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修改"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分享"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["时间"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类型"])},
    "allcancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部撤单"])},
    "shortselling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可平多数量"])},
    "shortsale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可平空数量"])},
    "market2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["市价"])},
    "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["限价"])},
    "averagemarket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["市价平均"])},
    "fillprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最新成交价"])},
    "trigger2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["触发价"])},
    "liq2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清算"])},
    "immediate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即清算"])},
    "conditionalmarket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条件市价"])},
    "conditionallimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条件指定价"])},
    "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目标价"])},
    "sl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["止损价"])},
    "liquidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["强制清算"])},
    "orderprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下单价格"])},
    "trigger3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["触发条件"])},
    "completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成交"])},
    "spotwallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["现货钱包"])},
    "future": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期货钱包"])},
    "freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["冻结"])},
    "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转移"])},
    "exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["兑换"])},
    "deposit2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["存款"])},
    "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取款"])},
    "ordercondition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下单条件"])},
    "trigger4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["触发下单"])},
    "completion2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成交历史"])},
    "liquidation2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["强制清算历史"])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未实现盈亏和自有资本收益率以市场平均价格为基准。"])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当达到目标价或止损价时，将执行设置的合约。可以通过详细设置来设置目标价。（目标价以市场平均价格为基准。）"])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入数量。"])},
    "msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入价格。"])},
    "msg5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可用资产不足。"])},
    "msg6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入触发价格。"])},
    "msg7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下单已注册。"])},
    "msg8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["该订单已成功取消。"])},
    "msg9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入目标价或止损价。"])},
    "msg10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对于多头头寸，目标价格必须高于最新成交价格。"])},
    "msg11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对于多头头寸，止损价格必须低于最新成交价格。"])},
    "msg12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对于空头头寸，目标价格必须低于最新成交价格。"])},
    "msg13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对于空头头寸，止损价格必须高于最新成交价格。"])},
    "msg14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可平数量不足。"])},
    "msg15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总资产=钱包余额+保证金+未实现盈亏"])},
    "msg16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表示用于用户设置的无限期期货合约的金额。"])},
    "msg17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表示当前可用于合约执行的钱包余额。"])},
    "msg18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未实现盈亏和自有资本收益率以市场平均价格为基准。"])},
    "msg19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可下单数量不足。"])},
    "chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["图表"])},
    "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单"])},
    "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转账"])},
    "order2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单状态"])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["等待"])},
    "orderFail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单失败"])},
    "msg20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合约使用USDT作为保证金，但以该资产数量表示。"])},
    "msg21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表示分配给该合约的保证金。"])},
    "msg22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合约执行的平均买入/卖出价格。"])},
    "msg23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果费率为正，则多头交易者支付做空头寸的资金利息。如果费率为负，则空头交易者支付做多头寸的资金"])},
    "msg24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当市场平均价格达到该价格时，合同将被解除。"])},
    "msg25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即以市场平均价格结束合同。"])},
    "msg26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前杠杆最大持仓量"])}
  },
  "alert": {
    "orderSuccess": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单成功"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订单已完成。"])}
    },
    "positionOrder": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["需要登录的服务。"])}
    },
    "favoriteAdd": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册成功"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已添加到收藏夹。"])}
    },
    "favoriteRemove": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消注册"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已取消收藏。"])}
    }
  },
  "coin": {
    "btc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["比特币"])},
    "xrp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["瑞波币"])},
    "eth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以太坊"])},
    "sol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["索尔"])},
    "clay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["克莱顿"])},
    "matic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polygon"])},
    "avax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avalanche"])},
    "usdt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tether"])},
    "doge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["狗狗币"])}
  },
  "level": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提款等级"])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根据账户认证等级，所有资产提款存在限制。"])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提高账户认证等级，可以增加提款限额。"])},
    "lev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["等级"])},
    "standard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["认证标准"])},
    "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每日提款限额"])},
    "stage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["阶段"])},
    "emailor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件或手机号码"])},
    "emailphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件、手机号码"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身份证认证"])},
    "idfail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身份证认证失败"])},
    "failtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["失败原因"])},
    "levelup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提升等级"])}
  },
  "invitemodal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀请码"])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以设置最多5个邀请码。"])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["折扣手续费将在次日00:00（UTC）之前存入您和您的好友的礼品钱包，仅可用于交易保证金和手续费。"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀请码"])},
    "myyoupercent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您 / 好友比例"])},
    "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理"])},
    "revise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修改"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除"])},
    "plus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["copy"])},
    "code_revise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修改邀请码"])},
    "code_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加邀请码"])},
    "code2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邀请码管理"])},
    "mypercent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您获得的比例"])},
    "youpercent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["好友获得的比例"])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可调整的折扣率最高为30%。"])}
  },
  "influencer_result": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["影响者审核结果"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申请人账户"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推荐码"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申请信息"])},
    "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["驳回原因"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消申请"])},
    "re": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重新申请"])}
  },
  "idcard": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身份证认证"])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请上传您的身份证照片"])},
    "msg1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请上传一张您的照片以验证您的身份"])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上传身份证照片"])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请上传图像文件。"])},
    "regist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册图片"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上传认证照片"])},
    "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["认证请求"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身份证认证申请已提交。"])},
    "msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请拍摄身份证照片并进行申请，然后等待管理员批准。"])},
    "cert_picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["认证照片"])},
    "id_picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身份证照片"])},
    "before_id_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请上传您的身份证照片."])},
    "before_cert_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请上传验证照片."])}
  },
  "antiphishing": {
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改请求"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["防钓鱼代码已更改。"])},
    "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改防钓鱼代码失败。"])},
    "antiphishing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["防钓鱼代码"])},
    "lengthover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["防钓鱼代码必须少于10个字符。"])},
    "checkinput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入防钓鱼代码。"])}
  },
  "moneytransfer": {
    "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资金转账"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送钱包"])},
    "kind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["实物钱包"])},
    "gift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["礼物钱包"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["余额"])},
    "get": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接收钱包"])},
    "property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转账资产"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转账数量"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部"])},
    "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资金转账手续费免费。"])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转账已完成。"])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动、邀请好友奖励获得的资产无法转账。"])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果您在持有交叉头寸的情况下转移资产，该头寸可能会被清算，并且您可能无法转移所有资产。请准确输入可兑换资产。"])}
  },
  "addressmanage": {
    "addsuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["钱包地址已添加。"])},
    "addfail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["钱包地址注册失败。"])},
    "removesuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["钱包地址已删除。"])},
    "removefail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["钱包地址删除失败。"])},
    "addname_null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入地址名称."])},
    "outputkey_null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入提款地址."])},
    "destination_null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请输入目的地代码."])}
  }
}