<template lang="">
  <!-- 인플루엔서 신청 -->
  <div class="fixed z-10 flex w-screen h-full sm:bg-black sm:bg-opacity-50 px-6 sm:px-0 bg-[#f2f4f5] bg-opacity-80">
    <div :class="tStyle.inner">
      <div
          class="max-sm:flex max-sm:justify-between max-sm:items-center max-sm:mt-[12%] fold:flex fold:justify-between fold:items-center fold:mt-[12%]"
        >
          <h2
            class="text-[20px] font-regular text-[#444] dark:text-[#e5e5e5] sm:hidden"
          >
            {{ $t('Influencer.application') }}
          </h2>
          <button class="sm:hidden" @click="setModalControl">
          <img
            src="@/assets/image/icon_close.png"
            alt=""
            class="w-[35px] dark:hidden"
          />
          <img
            src="@/assets/image/icon_close_d.png"
            alt=""
            class="w-[35px] opacity-0 dark:opacity-100 mt-[-35px] dark:mt-0"
          />
        </button>
        </div>
      <div class="absolute left-[-1px] top-[-1px] max-sm:hidden fold:hidden">
        <img src="../../assets/image/path1703.png" alt="" class="dark:hidden" />
        <img
          src="../../assets/image/path1703_d.png"
          alt=""
          class="opacity-0 dark:opacity-100"
        />
      </div>
      <h1 :class="tStyle.title">
        {{ $t('Influencer.application') }}
      </h1>
      <div :class="tStyle.contents">
        <div>
          {{ $t('Influencer.account') }}
          <input
            type="text"
            placeholder="Mos**@gmail.com"
            v-bind:value="alertData.email"
            :class="tStyle.account_input"
            readonly
          />
        </div>
        <div>
          {{ $t('Influencer.code') }}
          <input
            type="text"
            ref="entercode"
            :placeholder="$t('Influencer.entercode')"
            :class="tStyle.influencer_input"
          />
        </div>
        <div>
          {{ $t('Influencer.info') }}
          <textarea
            ref="enterinfo"
            :placeholder="$t('Influencer.enterinfo')"
            :class="tStyle.Information_input"
            class="py-4 resize-none max-sm:py-2"
          />
        </div>
      </div>
      <button :class="tStyle.application" @click="sendInfluence">
        {{ $t('Influencer.button') }}
      </button>

      <button :class="tStyle.close_bt" @click="setModalControl">
        <img src="../../assets/image/group2322.png" alt="" />
      </button>
    </div>
  </div>
</template>
<script>
import { modalControl, isLogin, apiLanguage , refresh } from '@/store/store';
import Request from '@/utils/Request';
import AesEncrypt from '@/components/Security/AesEncrypt';
import create from 'vue-zustand';

export default {
  name: 'InfluencerApplication',
  data() {
    return {
      tStyle: {
        inner:
          'bg-white sm:w-[550px] sm:h-[700px] m-auto sm:rounded sm:border border-[#C9C9C9] px-[20px] py-[15px] relative dark:bg-[#1c1d22] dark:border-[#606060] dark:text-[#c9c9c9] max-sm:flex max-sm:flex-col fold:flex fold:flex-col',
        title: 'text-center text-[30px] mt-[40px] max-sm:hidden fold:hidden',
        contents: 'w-full sm:mt-[32px] mt-[10px] sm:text-[20px] text-[15px] mb-4 sm:mb-0',
        account_input:
          'w-full sm:h-[60px] py-1 sm:py-0 border border-[#C9C9C9] sm:pl-[20px] sm:pr-0 px-2 sm:mb-[30px] mb-4 sm:text-[18px] text-[13px] sm:rounded-[3px] dark:bg-[#3b3b43] dark:border-[#606060] dark:placeholder:text-[#9f9f9f] outline-none cursor-default',
        influencer_input:
          'w-full sm:h-[60px] py-1 sm:py-0 border border-[#C9C9C9] sm:pl-[20px] sm:pr-0 px-2 sm:mb-[30px] mb-4 sm:text-[18px] text-[13px] sm:rounded-[3px] dark:bg-[#3b3b43] dark:border-[#606060] dark:placeholder:text-[#9f9f9f]',
        Information_input:
          'w-full sm:h-[200px] h-[100px] border border-[#C9C9C9] sm:pl-[20px] sm:pr-0 px-2 sm:text-[18px] text-[13px] sm:rounded-[3px] dark:bg-[#3b3b43] dark:border-[#606060] dark:placeholder:text-[#9f9f9f]',

        application:
          'w-full sm:h-[60px] bg-[#cfe4ff] sm:rounded-[3px] sm:text-[20px] sm:mt-[20px] sm:dark:text-[#333333] dark:bg-[#ffb500] mt-auto text-[15px] py-1 sm:py-0 dark:text-[#333]',

        close_bt: 'absolute right-[20px] top-[18px] font-light text-[30px] max-sm:hidden fold:hidden'
      }
    };
  },
  setup() {
    const useIsLogin = create(isLogin);
    const useModalControl = create(modalControl);
    const {
      setModalControl,
      setModalName,
      setAlertData,
      alertData,
      setAlertOpen
    } = useModalControl();
    const { setLogout } = useIsLogin();
    const useApiLanguage = create(apiLanguage);
    const { apiLangCode } = useApiLanguage();
    const useRefresh = create(refresh)
    const { setRefresh } = useRefresh()

    return {
      setModalControl,
      setModalName,
      setAlertData,
      alertData,
      setLogout,
      setAlertOpen,
      apiLangCode,
      setRefresh
    };
  },
  methods: {
    async sendInfluence() {
      //validation
      let isValid = this.validation();
      if (!isValid) return;

      let entercode = this.$refs.entercode;
      let enterinfo = this.$refs.enterinfo;

      let { t } = this.$i18n;
      let data = JSON.stringify({
        data: AesEncrypt({
          promocd: entercode.value,
          desc: enterinfo.value
        })
      });

      let result = await Request(
        '/api/exs/mypage/v1/send_influence',
        'post',
        this.apiLangCode[this.$i18n.locale],
        data
      );

      if (!result) {
        this.openAlertModal('', t('common.fail'));
        return;
      }

      let { API_CODE } = result;
      if (API_CODE.status === 'L402') {
        this.setAlertData({
              title: '',
              desc: this.$t('login.msg15')
            });
            this.setAlertOpen();
        localStorage.removeItem('fubit_token');
        localStorage.removeItem('fubit_rtoken');
        this.setLogout();
        this.goMainPage();
      } else if (API_CODE.status === '0000') {
        //성공!!!
        this.setRefresh('influence_request')
        this.openAlertModal(
          t('Influencer.application'),
          t('Influencer.success')
        );
        this.setModalControl()
      } else {
        this.openAlertModal(API_CODE.title, API_CODE.message);
      }
    },
    validation() {
      let entercode = this.$refs.entercode;
      let enterinfo = this.$refs.enterinfo;

      if (!entercode || entercode.value === '') {
        //entercode 입력
        return false;
      }
      if (!enterinfo || enterinfo.value === '') {
        //enterinfo 입력
        return false;
      }

      return true;
    },
    openAlertModal(title, desc) {
      this.setAlertData({ title, desc });
      this.setAlertOpen();
    },
    goMainPage() {
      this.$router.push({
        name: 'MainHome'
      });
    }
  }
};
</script>
<style lang=""></style>
