<template lang="">
  <div class="fixed z-10 flex w-screen h-full bg-black bg-opacity-50">
    <div :class="tStyle.inner">
      <h2 class="text-[30px] mt-[30px]">{{ $t('level.idfail') }}</h2>
      <div class="mt-10">

        <div class="mb-[20px]">
          <div :class="tStyle.box">
            <span>{{ $t('idcard.id_picture') }}</span>
            <a :class="tStyle.download" v-bind:href="this.idResult?.photo1" download>{{ $t('common.ok') }}</a>
          </div>
        </div>

        <div class="mb-[20px]">
          <div :class="tStyle.box">
            <span>{{ $t('idcard.cert_picture') }}</span>
            <a :class="tStyle.download" v-bind:href="this.idResult?.photo2" download>{{ $t('common.ok') }}</a>
          </div>
        </div>
      
        <div class="text-left">{{ $t('level.failtitle') }}</div>
        <div 
          :class="tStyle.comment_box">
          {{ this.idResult?.comment }}
        </div>
      </div>

      <div :class="tStyle.bt_box">
        <button :class="tStyle.confirm" @click="setModalControl">{{ $t('common.ok') }}</button>
      </div>


      <button :class="tStyle.close_bt" @click="setModalControl">
        <img src="../../assets/image/group2322.png" alt="">
      </button>
    </div>
    
  </div>
</template>
<script>
import { nationNumModal, modalControl, isLogin, apiLanguage } from '@/store/store';
import Request from '@/utils/Request';
import create from 'vue-zustand';

export default {
  name: 'IdFail',
  data() {
    return {
      idResult:{},
      tStyle: {
        inner:
          'bg-white w-[440px] flex flex-col m-auto rounded border border-[#C9C9C9] px-[30px] relative dark:bg-[#1c1d22] dark:border-[#606060] dark:text-[#c9c9c9] text-center ',
        close_bt: 'absolute right-[20px] top-[18px] font-light text-[30px]',
        bt_box: 'w-full flex text-center text-[15px] mt-auto py-6',
        download: 'text-[18px] bg-[#cfe4fe] rounded-[3px] dark:bg-[#fdd26a] dark:text-[#333] px-[10px] cursor-pointer',
        box: 'w-full text-left flex items-center justify-between relative mt-2 sm:h-[55px] py-1 sm:py-0 border px-[10px] sm:text-[18px] text-[14px] sm:mb-[25px] sm:rounded-[3px] dark:bg-[#3b3b43] dark:border-[#606060] dark:placeholder:text-[#fff]',
        comment_box: 'text-left border border-[#e5e5e5] h-32 overflow-y-scroll dark:border-[#606060] mt-2 p-2 rounded text-[18px] w-full dark:bg-[#3b3b43] leading-none',
        cancel: 'basis-1/2 h-[60px] text-[22px] border border-[#cfe4fe] rounded-[3px] dark:bg-[#fff8e8] dark:text-[#333]',
        confirm: 'basis-full h-[60px] text-[22px] bg-[#cfe4fe] rounded-[3px] dark:bg-[#fdd26a] dark:text-[#333]',
        
      }
    };
  },
  setup() {
    const useNationModal = create(nationNumModal);
    const useModalControl = create(modalControl);
    const useIsLogin = create(isLogin);
    const useApiLanguage = create(apiLanguage);
    const { apiLangCode } = useApiLanguage();
    const { setModalClose } = useNationModal();
    const { setModalControl, setAlertData, setAlertOpen } = useModalControl();
    const { setLogout } = useIsLogin();

    return {
      setModalClose,
      setModalControl,
      setLogout,
      apiLangCode,
      setAlertData,
      setAlertOpen
    };
  },
  mounted(){
    this.getIdCard();
  },
  methods: {
    //신분증 인증 요청 결과
    async getIdCard(){
      let result = await Request("/api/mypage/v1/request_idcard", "post", this.apiLangCode[this.$i18n.locale]);

      if(!result) {
        this.setModalControl();
        return;
      }

      let {status} = result.API_CODE;

      if(status === "L402"){
        this.setAlertData({
              title: '',
              desc: this.$t('login.msg15')
            });
            this.setAlertOpen();
        localStorage.removeItem('fubit_token');
        localStorage.removeItem('fubit_rtoken');
        this.goMainPage();
        this.setLogout();
      }
      else if(status === "0000"){
        if(result.idCard.state !== "R") {
          this.setModalControl();
          return;
        }
        this.idResult = result.idCard;
      }
      else{
        this.setModalControl();
        return;
      }
    },
    getMainPage(){
      this.$router.push({
        name: 'MainHome'
      })
    }
  }
};
</script>
<style lang=""></style>
