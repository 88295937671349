<template lang="">
  <!-- 인플루엔서 심사결과 -->
  <div class="fixed z-10 flex w-screen h-full bg-black bg-opacity-50">
    <div :class="tStyle.inner">
      <div class="absolute left-[-1px] top-[-1px]">
        <img src="../../assets/image/path1703.png" alt="" class="dark:hidden" />
        <img
          src="../../assets/image/path1703_d.png"
          alt=""
          class="opacity-0 dark:opacity-100"
        />
      </div>
      <h1 :class="tStyle.title">
        {{ $t('Influencer.result') }}
      </h1>
      <div :class="tStyle.contents">
        <div>
          <div class="pl-[10px] mb-[5px]">
            {{ $t('Influencer.account') }}
          </div>
          <input
            type="text"
            placeholder="Mos**@gmail.com"
            v-bind:value="alertInfo?.uid"
            :class="tStyle.account_input"
          />
        </div>
        <div>
          <div class="pl-[10px] mb-[5px]">
            {{ $t('Influencer.code') }}
          </div>
          <input
            type="text"
            v-bind:value="alertData?.promocd"
            :placeholder="$t('Influencer.entercode')"
            :class="tStyle.influencer_input"
          />
        </div>
        <div>
          <div class="pl-[10px] mb-[5px]">
            {{ $t('Influencer.info') }}
          </div>
          <input
            type="text"
            v-bind:value="alertData?.desc"
            :placeholder="$t('Influencer.enterinfo')"
            :class="tStyle.Information_input"
          />
        </div>
        <div>
          <div class="pl-[10px] mb-[5px]">
            {{ $t('Influencer.noresult') }}
          </div>
          <input
            type="text"
            v-bind:value="alertData?.answer"
            :placeholder="$t('Influencer.enterinfo')"
            :class="tStyle.Information_input"
          />
        </div>
      </div>
      <div :class="tStyle.bt_box">
        <button :class="tStyle.cancel" @click="closeModal">
          {{ $t('Influencer.cancel') }}
        </button>
        <button :class="tStyle.confirm" @click="requestInfluence">
          {{ $t('Influencer.resend') }}
        </button>
      </div>

      <button :class="tStyle.close_bt" @click="closeModal">
        <img src="../../assets/image/group2322.png" alt="" />
      </button>
    </div>
  </div>
</template>
<script>
import {
  nationNumModal,
  modalControl,
  isLogin,
  apiLanguage
} from '@/store/store';
import create from 'vue-zustand';
import Request from '@/utils/Request';
import AesEncrypt from '@/components/Security/AesEncrypt';

export default {
  name: 'InfluencerApplication_2',
  data() {
    return {
      influenceInfo: {},
      tStyle: {
        inner:
          'bg-white w-[550px] m-auto rounded border border-[#C9C9C9] px-[40px] py-[40px] relative dark:bg-[#1c1d22] dark:border-[#606060] dark:text-[#c9c9c9]',
        title: 'text-center text-[30px] mt-[40px]',
        contents: 'w-full mt-[32px] text-[22px]',
        account_input:
          'w-full h-[60px] border pl-[10px] mb-[15px] text-[18px] rounded-[3px] dark:border-[#606060] dark:text-[#9f9f9f] dark:bg-[#3b3b43]',
        influencer_input:
          'w-full h-[60px] border pl-[10px] mb-[15px] text-[18px] rounded-[3px] dark:border-[#606060] dark:text-[#9f9f9f] dark:bg-[#3b3b43]',
        Information_input:
          'w-full h-[100px] border pl-[10px] mb-[15px] text-[18px] rounded-[3px] dark:border-[#606060] dark:text-[#9f9f9f] dark:bg-[#3b3b43]',

        bt_box: ' h-[35px] mt-[35px] text-center text-[15px] mb-[30px] flex',
        confirm:
          'w-full h-[60px] bg-[#cfe4fe] text-[22px] rounded-[3px] dark:text-[#333] dark:bg-[#ffb500]',
        cancel:
          'w-full h-[60px] bg-[#e8eff7] text-[22px] rounded-[3px] dark:bg-[#fff8e8] mr-[15px]',
        close_bt: 'absolute right-[20px] top-[18px] font-light text-[30px]'
      }
    };
  },
  setup() {
    const useNationModal = create(nationNumModal);
    const useModalControl = create(modalControl);
    const useIsLogin = create(isLogin);
    const { setModalClose } = useNationModal();
    const {
      setModalControl,
      setModalName,
      setAlertData,
      setAlertOpen,
      alertData
    } = useModalControl();
    const { setLogout } = useIsLogin();

    const useApiLanguage = create(apiLanguage);
    const { apiLangCode } = useApiLanguage();

    return {
      setModalClose,
      setModalControl,
      setModalName,
      setAlertData,
      setLogout,
      setAlertOpen,
      alertData,
      apiLangCode
    };
  },
  mounted() {},
  methods: {
    async requestInfluence() {
      let { t } = this.$i18n;
      let data = JSON.stringify({
        data: AesEncrypt({
          promocd: this.alertData?.promocd,
          desc: this.alertData?.desc
        })
      });

      let result = await Request(
        '/api/exs/mypage/v1/send_influence',
        'post',
        this.apiLangCode[this.$i18n.locale],
        data
      );

      if (!result) {
        this.openAlertModal('', t('Influencer.fail'));
        return;
      }

      let { API_CODE } = result;

      if (API_CODE.status === 'L402') {
        this.setAlertData({
              title: '',
              desc: this.$t('login.msg15')
            });
            this.setAlertOpen();
        localStorage.removeItem('fubit_token');
        localStorage.removeItem('fubit_rtoken');
        this.setLogout();
        this.goMainPage();
      } else if (API_CODE.status === '0000') {
        this.openAlertModal('', t('Influencer.success'));
      } else {
        this.openAlertModal('', t('Influencer.fail'));
      }
    },
    closeModal() {
      this.setAlertData({});
      this.setModalControl();
    },
    openAlertModal(title, desc, isReload) {
      this.setAlertData({ title, desc, isReload });
      this.setAlertOpen();
    },
    goMainPage() {
      this.$router.push({
        name: 'MainHome'
      });
    }
  }
};
</script>
<style lang=""></style>
