<template lang="">
  <div class="flex fixed z-10 sm:bg-black sm:bg-opacity-50 w-screen h-full bg-[#f2f4f5] bg-opacity-80 px-6 sm:px-0">
    <div :class="tStyle.inner">
      <div :class="tStyle.point">
        <img src="../../assets/image/path1703.png" alt="" class="dark:hidden">
        <img src="../../assets/image/path1703_d.png" alt="" class="opacity-0 dark:opacity-100">
      </div>
      <div :class="tStyle.title">
        <h2 class="sm:text-[40px] text-[20px] sm:font-bold max-sm:text-[#000] max-sm:dark:text-[#e5e5e5] fold:text-[#000] fold:dark:text-[#e5e5e5]">{{ $t('common.google_auth') }}</h2>
        <p class="sm:text-[20px] mt-[30px] text-[15px] max-sm:leading-[22px] max-sm:dark:text-[#e5e5e5] fold:leading-[22px] fold:dark:text-[#e5e5e5]">
          {{ $t('googleqr.qr_msg') }}<span class="max-sm:hidden fold:hidden"><br /></span> {{ $t('googleqr.qr_msg2') }}
        </p>
      </div>
      <div :class="tStyle.qr_box">
        <div :class="tStyle.qr">
          <img :src="this.alertData.qrUrl" alt="qr" class="max-sm:inline fold:inline"/>
        </div>
        <div :class="tStyle.qr_text">
          <div :class="tStyle.qr_text1">{{ $t('googleqr.google') }}</div>
          <div :class="tStyle.qr_text2">{{this.alertData.code}}</div>
        </div>
      </div>

      <button :class="tStyle.confirm" @click="openOtpNumber">{{ $t('common.next') }}</button>

      <button :class="tStyle.close_bt" @click="closeModal">
        <img src="../../assets/image/group2322.png" alt="" />
      </button>
    </div>
  </div>
</template>
<script>
import { nationNumModal, modalControl } from '@/store/store';
import create from 'vue-zustand';

export default {
  name: 'GoogleQr',
  data() {
    return {
      tStyle: {
        inner: 'bg-white sm:w-[600px] sm:h-[540px] m-auto sm:rounded sm:border border-[#C9C9C9] sm:px-[40px] sm:py-[15px] relative dark:bg-[#1c1d22] dark:border-[#606060] dark:text-[#c9c9c9] px-6 fold:px-4',
        title: 'text-center mt-[50px]',
        point: 'absolute left-[-1px] top-[-1px] max-sm:hidden fold:hidden',
        qr_box: 'flex sm:w-[450px] sm:h-[160px] sm:border border-[#c9c9c9] mx-auto sm:mt-[50px] dark:border-[#606060] sm:dark:bg-[#25252c] sm:py-0 py-10 flex-col sm:flex-row dark:bg-[#1c1d22]',
        qr: 'w-[80px] h-[80px] max-sm:mx-auto fold:mx-auto sm:w-[158px] sm:h-[158px] max-sm:text-center fold:text-center sm:border-r border-[#c9c9c9] sm:float-left dark:border-[#606060]',
        qr_text: 'sm:w-[290px] w-full sm:border-none border border-[#c9c9c9] sm:h-[158px] sm:float-right text-center sm:text-[20px] text-[15px] sm:mt-0 mt-5 dark:border-[#606060]',
        qr_text1: 'w-full sm:h-[70px] sm:py-0 py-1.5 border-b border-[#c9c9c9] sm:leading-[70px] dark:border-[#606060] max-sm:dark:text-[#e5e5e5] fold:dark:text-[#e5e5e5]',
        qr_text2: 'w-full text-[14px] fold:text-[10px] sm:h-[90px] sm:py-0 py-1.5 sm:leading-[90px] text-[#005cd3] dark:text-[#ffb500]',

        confirm: 'w-full sm:h-[60px] max-sm:text-[15px] fold:text-[15px] bg-[#cfe4fe] sm:rounded-[3px] sm:mt-[40px] dark:bg-[#ffb500] dark:text-[#333] mb-6 sm:mb-0 py-1 sm:py-0 max-sm:dark:bg-[#fdd26a]',

        close_bt: 'absolute right-[20px] top-[18px] font-light text-[30px] max-sm:hidden fold:hidden'
      }
    };
  },
  setup() {
    const useNationModal = create(nationNumModal);
    const useModalControl = create(modalControl);
    const { setModalClose } = useNationModal();
    const { alertData, setModalName, setModalControl, setAlertData } = useModalControl();

    return {
      setModalClose,
      alertData,
      setModalName,
      setModalControl,
      setAlertData
    };
  },
  methods: {
    openOtpNumber(){
      this.setAlertData({key: this.alertData.code , qrUrl:this.alertData.qrUrl});
      this.setModalName("GoogleNumber2");
    },
    closeModal(){
      this.setAlertData({});
      this.setModalControl();
    }
  },
  mounted() {
  },
};
</script>
<style lang=""></style>
