<template lang="">
  <div class="fixed z-10 flex w-screen h-full bg-black bg-opacity-50">
    <div :class="tStyle.inner">
      <div :class="tStyle.title">
        <h2 class="text-[40px] text-center">
          {{ $t('login.signup') }}
        </h2>
        <div class="flex mt-[52px]">
          <div class="w-[8px] h-[8px] rounded-full bg-[#c9c9c9] mr-[6px]"></div>
          <div class="w-[8px] h-[8px] rounded-full bg-[#c9c9c9] mr-[6px]"></div>
          <div class="w-[8px] h-[8px] rounded-full bg-[#0f5cd4]"></div>
        </div>
        <h1 class="text-[40px] mt-[20px]">{{ $t('login.msg16') }}</h1>
        <p class="text-[20px] mt-[40px]">
          {{ $t('login.msg17') }}
        </p>
      </div>

      <button class="w-full h-[60px] bg-[#cfe4fe] rounded-[3px] mt-[50px] dark:bg-[#ffb500] dark:text-[#333]">
        {{ $t('login.msg18') }}
      </button>
    </div>
  </div>
</template>
<script>
import { nationNumModal } from '@/store/store';
import create from 'vue-zustand';

export default {
  name: 'SignupCompleted',
  data() {
    return {
      tStyle: {
        inner:
          'bg-white w-[600px] h-[444px] m-auto rounded border border-[#C9C9C9] px-[40px] py-[15px] relative dark:bg-[#1c1d22] dark:text-[#c9c9c9] dark:border-[#606060]',
        title: 'mt-[60px]'
      }
    };
  },
  setup() {
    const useNationModal = create(nationNumModal);
    const { setModalClose } = useNationModal();

    return {
      setModalClose
    };
  }
};
</script>
<style lang=""></style>
