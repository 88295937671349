<template lang="">
  <div class="fixed z-10 flex w-screen h-full sm:bg-black sm:bg-opacity-50 bg-[#f2f4f5] bg-opacity-80 px-6 fold:px-4 sm:px-0">
    <div
      class="bg-white sm:w-[660px] w-full m-auto sm:rounded sm:border border-[#C9C9C9] px-6 fold:px-4 sm:px-[20px] sm:pt-[60px] relative dark:bg-[#1c1d22] dark:border-[#606060] dark:text-[#c9c9c9] max-sm:flex max-sm:flex-col fold:flex fold:flex-col fold:pb-4 pb-6 sm:pb-0"
    >
    <!-- 모바일 header -->
      <div
          class="sm:hidden flex justify-between items-center mt-[12%]"
        >
          <h2
            class="text-[20px] fold:text-[18px] fold:leading-[20px] font-regular text-[#444] dark:text-[#e5e5e5]"
          >
          {{
            alertData === 'revise'
              ? $t('invitemodal.code_revise')
              : $t('invitemodal.code_add')
          }}
          </h2>
          <button class="sm:hidden" @click="setModalControl">
          <img
            src="@/assets/image/icon_close.png"
            alt=""
            class="w-[35px] dark:hidden"
          />
          <img
            src="@/assets/image/icon_close_d.png"
            alt=""
            class="w-[35px] opacity-0 dark:opacity-100 mt-[-35px] dark:mt-0"
          />
        </button>
      </div>
      <!-- point image -->
      <div class="absolute left-[-1px] top-[-1px] max-sm:hidden fold:hidden">
        <img src="../../assets/image/path1703.png" alt="" class="dark:hidden" />
        <img
          src="../../assets/image/path1703_d.png"
          alt=""
          class="opacity-0 dark:opacity-100"
        />
      </div>
      <div class="max-sm:hidden fold:hidden">
        <h2 class="text-[38px]">
          {{
            alertData === 'revise'
              ? $t('invitemodal.code_revise')
              : $t('invitemodal.code_add')
          }}
        </h2>
      </div>
      <div class="sm:flex w-full sm:mt-20 mt-[20px] sm:text-[20px] text-[15px] max-sm:dark:text-[#e5e5e5] fold:dark:text-[#e5e5e5]">
        <div class="sm:mr-4 basis-1/2">
          <p>{{ $t('invitemodal.mypercent') }}</p>
          <input
            class="w-full border bg-[#f7f8fa] sm:rounded sm:p-4 py-1 px-2 font-semibold sm:mt-2 mt-1 text-[#005cd3] sm:dark:bg-[#3b3b43] dark:border-[#606060] dark:text-[#24ae64] dark:bg-[#3b3b43]"
            v-model="meValue"
            disabled
          />
        </div>
        <div class="basis-1/2 sm:mt-0 mt-2 max-sm:dark:text-[#e5e5e5] fold:dark:text-[#e5e5e5]">
          <p>{{ $t('invitemodal.youpercent') }}</p>
          <input
            class="w-full border bg-[#f7f8fa] sm:rounded sm:p-4 py-1 px-2 font-semibold sm:mt-2 mt-1 dark:bg-[#3b3b43] dark:border-[#606060] dark:text-[#fff]"
            v-model="friendValue"
            disabled
          />
        </div>
      </div>
      <!-- slider -->

      <div class="sm:mt-[50px] mt-[40px] sm:px-4 px-2 sm:mb-0 mb-3">
        <VueSlider
          :processStyle="processStyle"
          v-model="sendPercent"
          :dotSize="15"
          :min="0"
          :max="100"
          tooltip="always"
          :tooltip-formatter="'{value}%'"
          :tooltipStyle="tooltipStyle"
          :dotOptions="dotOptions"
          :stepActiveStyle="stepActiveStyle"
          :stepStyle="stepStyle"
          :marks="marks"
        />
      </div>
      <!-- <div class="text-center text-[16px] mt-20 max-sm:mt-4 mb-10 max-sm:text-[15px] max-sm:dark:text-[#e5e5e5]">
        {{ $t('invitemodal.msg3') }}
      </div> -->
      <!-- button -->
      <div class="flex sm:my-10 dark:text-[#333] mt-auto">
        <button
          class="basis-1/2 border border-[#cfe4ff] mr-2 sm:rounded sm:py-3 py-1 max-sm:text-[15px] fold:text-[15px] sm:dark:bg-[#fff8e8] sm:dark:border-[#fff8e8] dark:bg-[#fff] dark:border-[#fff]"
          @click="setModalName('InviteCode')"
        >
          {{ $t('common.cancel') }}
        </button>
        <button
          class="basis-1/2 bg-[#cfe4ff] sm:rounded sm:py-3 py-1 max-sm:text-[15px] fold:text-[15px] sm:dark:bg-[#ffb500] dark:bg-[#fdd26a]"
          @click="confirmInviteCode"
        >
          {{ $t('common.ok') }}
        </button>
      </div>

      <!-- close button -->
      <button
        class="absolute right-[20px] top-[18px] font-light text-[30px] max-sm:hidden fold:hidden"
        @click="setModalName('InviteCode')"
      >
        <img src="../../assets/image/group2322.png" alt="" />
      </button>
    </div>
  </div>
</template>
<script>
import {
  nationNumModal,
  modalControl,
  isLogin,
  apiLanguage,
  inviteCodeStore
} from '@/store/store';
import create from 'vue-zustand';
import Request from '@/utils/Request';
import AesEncrypt from '../Security/AesEncrypt';
import VueSlider from 'vue-3-slider-component';

export default {
  name: 'InvitecodeRevise',
  data() {
    return {
      sendPercent: 0,
      meValue: 0,
      friendValue: 100,
      marks: {
        0: '',
        25: '',
        50: '',
        75: '',
        100: ''
      },
      stepStyle: {
        display: 'block',
        width: '12px',
        borderRadius: '50%',
        height: '12px',
        backgroundColor: '#cccccc',
        marginTop: '-4px',
        marginLeft: '-6px'
      },
      stepActiveStyle: {
        display: 'block',
        width: '14px',
        borderRadius: '50%',
        height: '14px',
        backgroundColor: '#fc0d1b',
        marginTop: '-5px',
        marginLeft: '-5px'
      },
      processStyle: {
        backgroundColor: '#fc0d1b'
      },
      dotOptions: {
        style: { backgroundColor: '#fc0d1b' }
      },
      tooltipStyle: {
        color: '#ffffff',
        backgroundColor: '#fc0d1b',
        height: '18px',
        lineHeight: '15px',
        borderColor: '#fc0d1b'
      },
      tStyle: {}
    };
  },
  setup() {
    const useModalControl = create(modalControl);
    const useNationModal = create(nationNumModal);
    const useIsLogin = create(isLogin);
    const { setModalClose } = useNationModal();
    const {
      setModalName,
      setAlertData,
      alertData,
      setModalControl,
      setAlertOpen
    } = useModalControl();
    const { setLogout } = useIsLogin();
    const useApiLanguage = create(apiLanguage);
    const { apiLangCode } = useApiLanguage();
    const useInviteCodeStore = create(inviteCodeStore)
    const { codeState } = useInviteCodeStore()

    return {
      setModalClose,
      setModalName,
      setAlertData,
      alertData,
      setLogout,
      setModalControl,
      setAlertOpen,
      apiLangCode,
      codeState
    };
  },
  components: {
    VueSlider
  },
  watch: {
    sendPercent(a) {
      const b = Math.round(a)
      this.sendPercent = b
      this.meValue = b;
      this.friendValue = 100 - b;
    },
    meValue(a){
      this.sendPercent = a
    }
  },
  methods: {
    async confirmInviteCode() {
      if (this.codeState.type === 'add') {
        this.addInviteCode(this.meValue, this.friendValue);
      } 
      else {
        this.updateInviteCode(
          this.codeState.seq,
          this.meValue,
          this.friendValue,
          this.codeState.default
        );
      }
    },
    //추가
    async addInviteCode(my_percent, down_percent) {
      let { t } = this.$i18n;

      let data = JSON.stringify({
        data: AesEncrypt({
          slot: this.codeState.slot,
          my_percent: my_percent,
          down_percent: down_percent
        })
      });
      let result = await Request(
        '/api/exs/promo/v1/add_invite_code',
        'post',
        this.apiLangCode[this.$i18n.locale],
        data
      );
      
      if (!result) {
        this.openAlertModal('', t('common.fail'));
        return;
      }
      let { status } = result.API_CODE;

      if (status === 'L402') {
        this.setAlertData({
              title: '',
              desc: this.$t('login.msg15')
            });
            this.setAlertOpen();
        localStorage.removeItem('fubit_token');
        localStorage.removeItem('fubit_rtoken');
        this.setLogout();
        this.goMainPage();
      } else if (status === '0000') {
        //success
        this.setModalName("InviteCode");
      } else {
        this.openAlertModal('', t('common.fail'));
      }
    },
    //수정
    async updateInviteCode(seq, my_percent, down_percent, default_yn) {
      let { t } = this.$i18n;

      let data = JSON.stringify({
        data: AesEncrypt({
          seq: seq,
          my_percent: my_percent,
          down_percent: down_percent,
          default_yn: default_yn
        })
      });
      let result = await Request(
        '/api/exs/promo/v1/update_invite_code',
        'post',
        this.apiLangCode[this.$i18n.locale],
        data
      );

      if (!result) {
        this.openAlertModal('', t('common.fail'));
        return;
      }

      let { status } = result.API_CODE;
      if (status === 'L402') {
        this.setAlertData({
              title: '',
              desc: this.$t('login.msg15')
            });
            this.setAlertOpen();
        localStorage.removeItem('fubit_token');
        localStorage.removeItem('fubit_rtoken');
        this.setLogout();
        this.goMainPage();
      } else if (status === '0000') {
        //success
        this.setModalName("InviteCode")
      } else {
        this.openAlertModal('', t('common.fail'));
      }
    },
    openAlertModal(title, desc, isReload) {
      this.setAlertData({ title, desc, isReload });
      this.setAlertOpen();
    },
    //modal 닫기
    closeModal(data) {
      this.setAlertData(data);
      this.setModalName(this.alertData.popupName);
    },
    goMainPage() {
      this.$router.push({
        name: 'MainHome'
      });
    }
  },
  created() {
    if(this.codeState.type == 'edit'){
      this.meValue = parseInt(this.codeState.me)
      this.friendValue = parseInt(this.codeState.you)
    }
    
  },
};
</script>
<style lang=""></style>
