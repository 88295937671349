<template lang="">
  <div
    class="fixed z-10 flex w-screen h-full sm:bg-black sm:bg-opacity-50 bg-[#f2f4f5] bg-opacity-80 px-6 fold:px-4 sm:px-0"
  >
    <div :class="tStyle.inner">
      <h2
        class="sm:text-[30px] sm:mt-[30px] mt-[10px] text-[20px] fold:text-[18px]"
      >
        {{ $t('mypage.usersetting.discount') }}
      </h2>
      <div
        class="sm:text-[18px] text-[15px] fold:text-[13px] mt-[20px] leading-[26px]"
      >
        <div class="flex justify-between">
          <div>
            {{ $t('mypage.usersetting.fee')
            }}<span class="max-sm:hidden fold:hidden">:</span>
          </div>
          <div>
            Maker {{ parseFloat(setDecimal(alertData.feeMaker, 3)) }}% Taker
            {{ parseFloat(setDecimal(alertData.feeTaker, 3)) }}%
          </div>
        </div>
        <div class="flex justify-between">
          <div>
            {{ $t('mypage.usersetting.rating')
            }}<span class="max-sm:hidden fold:hidden">:</span>
          </div>
          <div>{{ alertData.userLevel }}</div>
        </div>
        <!-- <div class="flex justify-between">
          <div>{{ $t('mypage.usersetting.percent') }}<span class="max-sm:hidden">:</span></div>
          <div>0%</div>
        </div> -->
      </div>
      <p
        class="text-left sm:text-[18px] text-[15px] fold:text-[13px] leading-[22px] mt-[20px]"
      >
        {{ $t('mypage.usersetting.discount_msg') }}
      </p>
      <div :class="tStyle.bt_box">
        <button :class="tStyle.confirm" @click="setModalControl">
          {{ $t('common.ok') }}
        </button>
      </div>

      <button :class="tStyle.close_bt" @click="setModalControl">
        <img src="../../assets/image/group2322.png" alt="" />
      </button>
    </div>
  </div>
</template>
<script>
import { nationNumModal, modalControl, isLogin } from '@/store/store';
import create from 'vue-zustand';

export default {
  name: 'DisscountFee',
  data() {
    return {
      tStyle: {
        inner:
          'bg-white w-full sm:w-[440px] m-auto sm:rounded sm:border border-[#C9C9C9] sm:p-[30px] px-6 fold:px-4 pb-6 fold:pb-4 relative dark:bg-[#1c1d22] dark:border-[#606060] dark:text-[#c9c9c9] text-center',
        close_bt:
          'absolute right-[20px] top-[18px] font-light text-[30px] max-sm:hidden fold:hidden',
        bt_box: 'w-full flex mt-[35px] text-center text-[15px]',
        confirm:
          'basis-full sm:h-[60px] text-[13px] py-1 sm:py-0 sm:text-[22px] bg-[#cfe4fe] rounded-[3px] dark:bg-[#fdd26a] dark:text-[#333]'
      }
    };
  },
  setup() {
    const useNationModal = create(nationNumModal);
    const useModalControl = create(modalControl);
    const useIsLogin = create(isLogin);
    const { setModalClose } = useNationModal();
    const {
      setModalControl,
      setModalName,
      setAlertData,
      setAlertOpen,
      alertData
    } = useModalControl();
    const { setLogout } = useIsLogin();

    return {
      setModalClose,
      setModalControl,
      setLogout,
      setModalName,
      setAlertData,
      setAlertOpen,
      alertData
    };
  },
  methods: {
    setDecimal(num, i) {
      const number = parseFloat(num);
      const place = Math.pow(10, i);
      return (Math.floor(number * place) / place).toFixed(i);
    }
  },
  mounted() {}
};
</script>
<style lang=""></style>
