<template lang="">
  <div class="fixed z-10 flex w-screen h-full bg-black bg-opacity-50">
    <div
      class="fixed top-0 z-30 flex items-center justify-between w-full px-4 py-3 bg-white sm:hidden"
    >
      <button @click="setModalControl">
        <img
          src="@/assets/image/ion_chevron_back_outline.png"
          alt=""
          class="w-[30px]"
        />
      </button>
      <div class="text-[25px] text-center dark:text-[#c9c9c9] text-[#444]">
        {{ $t('password.change') }}
      </div>
      <div class="w-[30px]"></div>
    </div>
    <div :class="tStyle.inner">
      <div class="absolute left-[-1px] top-[-1px] max-sm:hidden fold:hidden">
        <img src="../../assets/image/path1703.png" alt="" class="dark:hidden" />
        <img
          src="../../assets/image/path1703_d.png"
          alt=""
          class="opacity-0 dark:opacity-100"
        />
      </div>
      <h1 :class="tStyle.title">{{ $t('password.change') }}</h1>
      <div :class="tStyle.contents">
        <div class="mb-[15px]">
          <div class="sm:pl-[10px] mb-[5px]">{{ $t('password.now') }}</div>
          <input
            type="password"
            ref="now_pwd"
            :placeholder="$t('password.now_password')"
            :class="tStyle.account_input"
          />
          <span v-if="oldFail" class="ml-[5px] text-[#e7224b] text-[13px]">
            {{ $t('password.msg5') }}
          </span>
        </div>
        <div class="mb-[15px]">
          <div class="sm:pl-[10px] mb-[5px]">{{ $t('password.new') }}</div>
          <input
            type="password"
            v-model="newPwd"
            :placeholder="$t('passwordforgot.text5')"
            :class="tStyle.account_input"
          />
          <span
            v-if="pwdFail"
            v-html="pwdText"
            class="ml-[5px] text-[#e7224b] text-[13px]"
          >
          </span>
        </div>
        <div class="mb-[15px]">
          <div class="sm:pl-[10px] mb-[5px]">{{ $t('password.new2') }}</div>
          <input
            type="password"
            v-model="confirmPwd"
            :placeholder="$t('password.new_password')"
            :class="tStyle.account_input"
          />
          <span
            v-if="confirmFail"
            v-html="confirmText"
            class="ml-[5px] text-[#e7224b] text-[13px]"
          >
          </span>
        </div>

        <!-- <div class="">
          <h4 class="sm:pl-[10px] mb-[10px]">{{ $t('password.security') }}</h4>
          <div class="flex flex-wrap" style="grid-gap: 14px" v-if="!selectedAuthType">
            <button
              v-for="(item, i) in authType"
              :class="tStyle[`check_${item}`]"
              v-bind:key="i"
              @click="requestAuthType(item)"
            >
              {{ $t(`common.${item}_auth`) }}
            </button>
          </div>
          <div
            v-else-if="selectedAuthType"
            :class="tStyle.num_box"
            style="position: relative"
          >
            <span
              v-if="selectedAuthType === 'email'"
              class="absolute cursor-pointer text-[13px]"
              style="text-decoration: underline; right: 10px; top: -38px"
              @click="requestAuth('email')"
            >
              {{ $t('password.resend2') }}
            </span>

            <input
              type="text"
              ref="input"
              maxLength="6"
              v-model="inputNum"
              class="test absolute text-white w-full h-[80px] cursor-pointer opacity-0 z-[0] focus:outline-none"
            />

            <input
              disabled
              @click="focusInput"
              type="text"
              v-for="(a, i) in 6"
              :key="i"
              v-model="inArr[i]"
              maxLength="1"
              :class="[
                inputNum.length === i
                  ? tStyle.numY
                  : !isFail
                  ? tStyle.num
                  : tStyle.numInputFail
              ]"
            />
          </div>
        </div> -->
      </div>
      <div :class="tStyle.bt_box">
        <button :class="tStyle.cancel" v-on:click="setModalControl">
          {{ $t('common.cancel') }}
        </button>
        <button :class="tStyle.confirm" @click="requestAuthType">
          {{ $t('common.ok') }}
        </button>
      </div>

      <button :class="tStyle.close_bt" v-on:click="setModalControl">
        <img src="../../assets/image/group2322.png" alt="" />
      </button>
    </div>
  </div>
</template>
<script>
import {
  nationNumModal,
  modalControl,
  isLogin,
  apiLanguage
} from '@/store/store';
import { withdrawAuthType } from '@/store/walletStore';
import Request from '@/utils/Request';
import AesEncrypt from '@/components/Security/AesEncrypt';
import create from 'vue-zustand';
import CryptoJS from 'crypto-js';

export default {
  name: 'ChangePassword',
  data() {
    return {
      authType: ['phone', 'email', 'otp'],
      newPwd: '',
      confirmPwd: '',
      selectedAuthType: null,
      inputNum: '',
      inArr: [],
      pwdFail: false,
      confirmFail: false,
      oldFail: false,
      isFail: false,
      pwdText: '',
      confirmText: '',

      tStyle: {
        inner:
          'bg-white w-full pt-[56px] px-6 h-full sm:w-[550px] sm:h-[600px] m-auto sm:rounded sm:border border-[#C9C9C9] sm:px-[20px] sm:py-[15px] relative dark:bg-[#1c1d22] dark:text-[#c9c9c9] dark:border-[#606060] flex flex-col',
        title: 'text-center text-[30px] mt-[40px] max-sm:hidden fold:hidden',
        contents: 'w-full mt-[32px] sm:text-[22px] text-[15px]',
        account_input:
          'w-full sm:h-[60px] border pl-[10px] mb-[0px] text-[13px] py-1 sm:py-0 sm:text-[18px] sm:rounded-[3px] dark:bg-[#3b3b43] dark:border-[#606060] dark:placeholder-[#fff]',
        check_otp:
          'sm:px-2 flex justify-center items-center sm:h-[60px] leading-[24px] inline-block bg-[#cfe4ff] rounded-[3px] text-center text-[#7f7f7f] text-[20px] dark:bg-[#fdd26a] dark:text-[#333]',
        check_email:
          'px-2 sm:h-[60px] py-1 sm:py-0 text-[15px] inline-block bg-[#cfe4ff] rounded-[3px] text-center text-[#7f7f7f] sm:text-[20px] sm:leading-[60px] dark:bg-[#fdd26a] dark:text-[#333]',
        check_phone:
          'px-2 sm:h-[60px] py-1 sm:py-0 max-sm:text-[15px] inline-block border rounded-[3px] text-center text-[#7f7f7f] text-[20px] sm:leading-[60px] dark:text-[#333] dark:bg-[#fff]',
        bt_box: 'text-center text-[15px] mt-auto pb-6 flex',
        cancel:
          'sm:h-[60px] text-[15px] basis-1/2 py-1 sm:py-0 sm:text-[22px] border border-[#cfe4fe] rounded-[3px] dark:bg-[#fff8e8] dark:text-[#333]',
        confirm:
          'sm:h-[60px] text-[15px] basis-1/2 py-1 sm:py-0 sm:text-[22px] bg-[#cfe4fe] rounded-[3px] ml-[10px] dark:bg-[#fdd26a] dark:text-[#333]',

        close_bt: 'absolute right-[20px] top-[18px] font-light text-[30px]',
        num_box:
          'w-full h-[80px] flex justify-between mt-[10px] children:last-child:border-[#005CB9]',
        numInput:
          'disabled:bg-white cursor-pointer w-[80px] h-[80px] border border-[#C9C9C9] border-b-[2px] border-b-[#626060] text-center dark:bg-[#1c1d22] dark:border-[#606060]',
        num: 'w-[80px] h-[80px] border border-b-[2px] border-b-[#626060] inline-block mx-[3px] dark:bg-[#3b3b43] dark:border-[#606060] text-center',
        numY: 'disabled:bg-white cursor-pointer w-[80px] h-[80px] border border-[#C9C9C9] border-b-[#005CD3] dark:border-b-[#ffb500] dark:bg-[#1c1d22] dark:border-[#606060] border-b-[2px] text-center',
        numInputFail:
          'disabled:bg-white cursor-pointer w-[80px] h-[80px] border border-[#C9C9C9] border-b-[2px] border-b-[#FC0D1B] text-center',
        fail: 'text-center text-[#e7224b] text-[22px]'
      }
    };
  },
  setup() {
    const useNationModal = create(nationNumModal);
    const useModalControl = create(modalControl);
    const useIsLogin = create(isLogin);
    const { setModalClose } = useNationModal();
    const {
      setModalControl,
      alertData,
      setAlertData,
      setModalName,
      setAlertOpen
    } = useModalControl();
    const { setLogout } = useIsLogin();
    const useApiLanguage = create(apiLanguage);
    const { apiLangCode } = useApiLanguage();
    const useAuthType = create(withdrawAuthType);
    const { pwChangeDataSet, setAuthType, setAuthUid } = useAuthType();
    return {
      setModalClose,
      setModalControl,
      alertData,
      setAlertData,
      setModalName,
      setAlertOpen,
      setLogout,
      apiLangCode,
      setAuthType,
      setAuthUid,
      pwChangeDataSet
    };
  },
  beforeMount() {
    this.requestMyInfo();
  },
  watch: {
    newPwd(a) {
      const pwdRegx =
        /^(?=.*[a-zA-z])(?=.*[0-9])(?=.*[.,$`~!@$!%*#^?&\\(\\)\-_=+]).{8,30}$/;
      if (!pwdRegx.test(a)) {
        this.pwdFail = true;
        this.pwdText = this.$t('password.msg3');
      } else {
        this.pwdFail = false;
        this.pwdText = '';
      }
    },
    confirmPwd(a) {
      if (this.newPwd !== a) {
        this.confirmFail = true;
        this.confirmText = this.$t('password.msg4');
      } else {
        this.confirmFail = false;
        this.confirmText = '';
      }
    },
    inputNum(a, b) {
      var blankCheck = /[\s]/g;
      if (isNaN(a) == true) {
        this.inputNum = b;
      } else if (blankCheck.test(a) == true) {
        this.inputNum = b;
      } else {
        this.inArr = Array.from(this.inputNum);

        if (this.inputNum.length === 6) {
          this.confirmAuth(this.inputNum);
        } else if (this.inputNum.length < 6) {
          this.tStyle.numInput =
            'disabled:bg-white cursor-pointer w-[90px] h-[90px] border border-[#C9C9C9] border-b-4 border-b-[#626060] text-center';
        }
      }
    }
  },
  methods: {
    //사용자 보안인증 확인
    requestMyInfo() {
      if (!this.alertData.duid) return;
      this.authType = [];
      let { authEmailYn, authPhoneYn, authGtpYn } = this.alertData;

      if (authGtpYn === 'Y') {
        this.authType.push('otp');
        return;
      }

      if (authPhoneYn === 'Y') {
        this.authType.push('phone');
      }
      if (authEmailYn === 'Y') {
        this.authType.push('email');
      }
    },
    requestAuthType() {
      let isValid = this.valiation();
      if (!isValid) return;

      this.requestAuth();
    },
    async requestAuth() {
      //password validation

      let oldHash = CryptoJS.SHA512(this.$refs.now_pwd.value).toString(
        CryptoJS.enc.Hex
      );
      let newHash = CryptoJS.SHA512(this.newPwd).toString(CryptoJS.enc.Hex);

      let userData = {
        type: 'pw',
        auth_type: this.alertData.authType,
        new_value: newHash,
        old_value: oldHash
      };

      let data = JSON.stringify({
        data: AesEncrypt(userData)
      });

      let result = await Request(
        '/api/exs/mypage/v1/request_auth',
        'post',
        this.apiLangCode[this.$i18n.locale],
        data
      );
      if (!result) {
        this.openAlertModal('', this.$t('common.fail'));
        this.setModalControl();
        return;
      }

      let { API_CODE } = result;

      if (API_CODE.status === 'L402') {
        this.sessionClosed();
      } else if (API_CODE.status === '0000') {
        //success
        this.pwChangeDataSet(userData);
        this.setAuthType(result.auth.authType);
        this.setAuthUid(result.auth.authVal);
        this.setModalName('SnsVerification');
      } else {
        this.openAlertModal(API_CODE.title, API_CODE.message);
        this.setModalControl();
      }
    },
    valiation() {
      const { t } = this.$i18n;
      let old_pwd = this.$refs.now_pwd?.value;

      //입력란 체크
      if (!old_pwd || old_pwd.value === '') {
        this.oldFail = true;
        return false;
      } else {
        this.oldFail = false;
      }
      if (!this.newPwd || this.newPwd === '') {
        this.pwdFail = true;
        this.pwdText = t('password.msg6');
        return false;
      }
      if (!this.confirmPwd || this.confirmPwd === '') {
        this.confirmFail = true;
        this.confirmText = t('password.msg7');
        return false;
      }

      return true;
    },
    sessionClosed() {
      this.setAlertData({
        title: '',
        desc: this.$t('login.msg15')
      });
      this.setAlertOpen();
      localStorage.removeItem('fubit_token');
      localStorage.removeItem('fubit_rtoken');
      this.goMainPage();
      this.setLogout();
    },
    openAlertModal(title, desc, isReload) {
      this.setAlertData({ title, desc, isReload });
      this.setAlertOpen();
    },
    goMainPage() {
      this.$router.push({
        name: 'MainHome'
      });
    }
  },
  created() {}
};
</script>
<style lang=""></style>
