<template lang="">
  <div
    class="fixed z-10 flex w-screen h-full sm:bg-black sm:bg-opacity-50 bg-[#f2f4f5] bg-opacity-80"
  >
    <div :class="tStyle.inner">
      <div :class="tStyle.point">
        <img src="../../assets/image/path1703.png" alt="" class="dark:hidden" />
        <img
          src="../../assets/image/path1703_d.png"
          alt=""
          class="opacity-0 dark:opacity-100"
        />
      </div>
      <div :class="tStyle.title">
        <h2
          class="sm:text-[30px] sm:font-bold text-[22px] font-regular max-sm:text-[#444] fold:text-[#444]"
        >
          {{
            orderData.direct === true
              ? $t('deal.immediate')
              : $t('orderconfirm.title')
          }}
        </h2>
      </div>
      <div
        class="text-left sm:text-[20px] mt-[40px] px-6 sm:px-0 text-[15px]"
      >
        <div class="flex mb-[4%] sm:mb-0">
          <div class="basis-1/3">{{ $t('orderconfirm.deal') }}</div>
          <div
            class="basis-2/3 text-right text-[16px] sm:font-light font-semibold"
          >
            {{ orderData.symbol.toUpperCase() }} USDT {{ $t('deal.perpetual') }}
          </div>
        </div>
        <div class="flex mb-[4%] sm:mb-0">
          <div class="basis-1/3">{{ $t('orderconfirm.order') }}</div>
          <div
            class="basis-2/3 text-right text-[16px] sm:font-light font-semibold"
          >
            {{
              orderData.type === 'taker'
                ? $t('orderconfirm.market')
                : orderData.type === 'maker'
                ? $t('orderconfirm.market')
                : orderData.type === 'cluet'
                ? $t('orderconfirm.limit')
                : orderData.type === 'cluem'
                ? $t('orderconfirm.c_market')
                : orderData.type === 'stop'
                ? $t('orderconfirm.target')
                : orderData.type === 'limit'
                ? $t('orderconfirm.lender')
                : orderData.type === 'force'
                ? $t('orderconfirm.liquidation')
                : ''
            }}
          </div>
        </div>
        <div class="flex mb-[4%] sm:mb-0">
          <div class="basis-1/3">{{ $t('deal.side') }}</div>
          <div
            class="basis-2/3 text-right text-[16px] sm:font-light font-semibold"
          >
            {{
              orderData.dest === 'long'
                ? $t('deal.openlong')
                : $t('deal.openshort')
            }}
          </div>
        </div>
        <div class="flex mb-[4%] sm:mb-0">
          <div class="basis-1/3">{{ $t('deal.leverage') }}</div>
          <div
            class="basis-2/3 text-right text-[16px] sm:font-light font-semibold"
          >
            {{
              orderData.margin === 'cross'
                ? $t('deal.cross')
                : $t('deal.isolate')
            }}
            {{ orderData.leverage }}X
          </div>
        </div>
        <div class="flex mb-[4%] sm:mb-0" v-if="orderData.type !== 'taker'">
          <div class="basis-1/3">{{ $t('deal.price') }}</div>
          <div
            class="basis-2/3 text-right text-[16px] sm:font-light font-semibold"
          >
            {{ orderData.price }} USDT
          </div>
        </div>
        <div class="flex mb-[4%] sm:mb-0">
          <div class="basis-1/3">{{ $t('trade.modal.size2') }}</div>
          <div
            class="basis-2/3 text-right text-[16px] sm:font-light font-semibold"
            v-if="orderData.gauge === true"
          >
            {{ orderData.quantity }} %
          </div>
          <div
            class="basis-2/3 text-right text-[16px] sm:font-light font-semibold"
            v-else
          >
            {{ orderData.quantity }} {{ orderData.symbol.toUpperCase() }}
          </div>
        </div>
        <div class="flex mb-[4%] sm:mb-0">
          <div class="basis-1/3">{{ $t('orderconfirm.trigger') }}</div>
          <div
            class="basis-2/3 text-right text-[16px] sm:font-light font-semibold"
          >
            {{
              orderData.trigger === 'M'
                ? $t('deal.averagemarket')
                : orderData.trigger === 'P'
                ? $t('deal.fillprice')
                : null
            }}
          </div>
        </div>
        <div class="flex mb-[4%] sm:mb-0">
          <div class="basis-1/3">{{ $t('deal.trigger2') }}</div>
          <div
            class="basis-2/3 text-right text-[16px] sm:font-light font-semibold"
          >
            {{ orderData.actionPrice }}
          </div>
        </div>
      </div>

      <div :class="tStyle.bt_box">
        <button :class="tStyle.cancel" @click="setModalControl">
          {{ $t('deal.cancel') }}
        </button>
        <button :class="tStyle.confirm" @click="orderSubmit">
          {{
            orderData.direct === true
              ? $t('deal.immediate')
              : $t('orderconfirm.order')
          }}
        </button>
      </div>

      <button :class="tStyle.close_bt" @click="setModalControl">
        <img src="../../assets/image/group2322.png" alt="" />
      </button>
    </div>
  </div>
</template>
<script>
import { modalControl, apiLanguage } from '@/store/store';
import { orderConfirm } from '@/store/positionStore';
import create from 'vue-zustand';
import axios from 'axios';
import AesEncrypt from '@/components/Security/AesEncrypt';
import AesDecrypt from '@/components/Security/AesDecrypt';

export default {
  name: 'OrderConfirm',
  data() {
    return {
      tStyle: {
        inner:
          'bg-white w-[90%] max-sm:flex fold:flex flex-col bg-white sm:w-[450px] m-auto sm:rounded sm:border border-[#C9C9C9] sm:px-[40px] sm:py-[15px] relative text-center dark:bg-[#1c1d22] dark:border-[#606060] dark:text-[#c9c9c9]',
        title: 'mt-[50px]',
        point: 'absolute left-[-1px] top-[-1px] max-sm:hidden fold:hidden',
        fail: 'text-center text-[#e7224b] text-[22px]',

        bt_box:
          'w-full mt-auto flex sm:mt-[48px] sm:mb-[20px] pb-6 sm:pb-0 text-center text-[22px] dark:text-[#333] px-6 sm:px-0',
        cancel:
          'basis-1/2 py-1 sm:py-0 max-sm:text-[15px] fold:text-[15px] sm:h-[60px] border border-[#cfe4fe] rounded-[3px] dark:bg-[#fff8e8] dark:border-[#fff8e8]',
        confirm:
          'basis-1/2 py-1 sm:py-0 max-sm:text-[15px] fold:text-[15px] sm:h-[60px] bg-[#cfe4fe] rounded-[3px] ml-[20px] dark:bg-[#ffb500]',

        close_bt:
          'absolute right-[20px] top-[18px] font-light text-[30px] max-sm:hidden fold:hidden'
      }
    };
  },
  setup() {
    const useModalControl = create(modalControl);
    const {
      setModalControl,
      setModalName,
      setAlertModal,
      whatModal,
      setAlertData,
      isAlertModal,
      setAlertOpen
    } = useModalControl();
    const useOrderConfirm = create(orderConfirm);
    const { orderData, setApiCall } = useOrderConfirm();
    const useApiLanguage = create(apiLanguage);
    const { apiLangCode } = useApiLanguage();

    return {
      setModalControl,
      orderData,
      setModalName,
      setAlertModal,
      whatModal,
      setAlertData,
      isAlertModal,
      setApiCall,
      setAlertOpen,
      apiLangCode
    };
  },
  methods: {
    orderSubmit() {
      const percent = () => {
        if (this.orderData.gauge === true) {
          return 'Y';
        } else {
          return 'N';
        }
      };
      const localToken = 'Bearer ' + localStorage.getItem('fubit_token');
      const localRtoken = 'Bearer ' + localStorage.getItem('fubit_rtoken');
      const data = {
        type: this.orderData.position,
        symbol: this.orderData.symbol,
        order_type: this.orderData.type,
        dest_ls: this.orderData.dest,
        margin_ci: this.orderData.margin,
        price: this.orderData.price,
        quantity: this.orderData.quantity,
        percent_yn: percent(),
        leverage: this.orderData.leverage,
        reserve: this.orderData.trigger,
        reserve_price: this.orderData.actionPrice
      };
      const encrypted = AesEncrypt(data);
      const reqData = JSON.stringify({
        data: encrypted
      });
      const config = {
        method: 'post',
        url: `${process.env.VUE_APP_MAIN_API_URI}/api/exs/trade/v1/send_order`,
        headers: {
          language: this.apiLangCode[this.$i18n.locale],
          apikey: process.env.VUE_APP_MAIN_API_KEY,
          'Content-Type': 'application/json',
          Authorization: localToken,
          Validation: localRtoken
        },
        data: reqData
      };
      axios(config).then((res) => {
        const decrypted = AesDecrypt(res.data.data.toString());
        const resData = JSON.parse(decrypted);
        if (resData.API_CODE.status == 'L402') {
          this.setAlertData({
              title: '',
              desc: this.$t('login.msg15')
            });
            this.setAlertOpen();
          localStorage.removeItem('fubit_token');
          localStorage.removeItem('fubit_rtoken');
          this.setLogout();
        } else if (resData.API_CODE.status == '0000') {
          if (resData.AUTH !== undefined) {
            localStorage.setItem('fubit_token', resData.AUTH.token);
            localStorage.setItem('fubit_rtoken', resData.AUTH.rtoken);
          }
          this.setAlertData({
            title: this.$t('alert.orderSuccess.title'),
            desc: this.$t('alert.orderSuccess.desc'),
            type: 'order'
          });
          this.setAlertOpen();
        }
      });
    }
  }
};
</script>
<style lang=""></style>
