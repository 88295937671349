<template lang="">
  <div class="fixed z-10 flex w-screen h-full bg-black bg-opacity-50">
    <div :class="tStyle.inner">
      <!-- <input
        type="text"
        :placeholder="$t('common.search3')"
        :class="tStyle.input"
      />
      <button :class="tStyle.search">{{ $t('common.search') }}</button> -->
      <table class="w-full mb-[20px]">
        <tr :class="tStyle.search_list">
          <td class="w-[30%] pl-[10px]">{{ $t('common.symbol') }}</td>
          <td>{{ $t('common.english') }}</td>
          <td>{{ $t('common.korean') }}</td>
        </tr>
        <tr
          :class="tStyle.search_list"
          v-for="(a, i) in coin"
          :key="i"
          @click="symbolChange(a.symbol)"
        >
          <td class="text-center">
            <img :src="a.img" alt="" class="w-[28px]" />
          </td>
          <td>{{ a.symbol }}</td>
          <td>{{ a.name }}</td>
        </tr>
        <!-- <tr :class="tStyle.search_list">
          <td class="text-center">
            <div class="flex">
              <img src="" alt="" class="dark:hidden">
              <img src="../../assets/image/group13996_d.png" alt="" class="opacity-0 dark:opacity-100">
            </div>
            
          </td>
          <td>ETH</td>
          <td>이더리움</td>
        </tr> -->
      </table>

      <button :class="tStyle.close_bt" @click="setModalControl">
        <img src="../../assets/image/group2322.png" alt="" />
      </button>
    </div>
  </div>
</template>
<script>
import { modalControl } from '@/store/store';
import { depositSymbol } from '@/store/walletStore';
import create from 'vue-zustand';

export default {
  name: 'CoinSearch',
  data() {
    return {
      coin: [
        {
          symbol: 'BTC',
          name: this.$t('coin.btc'),
          img: require('../../assets/image/square1447.png')
        },
        {
          symbol: 'ETH',
          name: this.$t('coin.eth'),
          img: require('../../assets/image/group13996.png')
        },
        {
          symbol: 'USDT',
          name: 'USDT',
          img: require('../../assets/image/doge.png')
        },
        {
          symbol: 'XRP',
          name: this.$t('coin.xrp'),
          img: require('../../assets/image/square1444.png')
        },
        // {
        //   symbol: 'MATIC',
        //   name: this.$t('coin.matic'),
        //   img: require('../../assets/image/matic.png')
        // }
      ],
      tStyle: {
        inner:
          'bg-white w-[340px] text-[13px] m-auto rounded border border-[#C9C9C9] px-[20px] pt-[30px] relative dark:bg-[#1c1d22] dark:border-[#606060] dark:text-[#c9c9c9]',
        input:
          'w-[79%] pl-[10px] border border-[#c9c9c9] rounded-[3px] inline-block dark:bg-[#111119] dark:border-[#606060] dark:placeholder:text-[#c9c9c9]',
        search:
          'w-[19%] border border-[#cfe4ff] bg-[#cfe4ff] rounded-[3px] text-center ml-[2%] inline-block dark:text-[#333333] dark:bg-[#ffb500] dark:border-[#ffb500]',
        search_list:
          'border-b border-[#c9c9c9] dark:border-[#606060] text-[#9f9f9f] cursor-pointer',
        close_bt: 'absolute right-[20px] top-[18px] font-light text-[30px]'
      }
    };
  },
  setup() {
    const useModalControl = create(modalControl);
    const { setModalControl } = useModalControl();
    const useDepositSymbol = create(depositSymbol);
    const { setDepositSymbol } = useDepositSymbol();

    return {
      setModalControl,
      setDepositSymbol
    };
  },
  methods: {
    symbolChange(data) {
      this.setDepositSymbol(data)
      this.setModalControl();
    }
  }
};
</script>
<style lang=""></style>
