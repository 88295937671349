<template>
  <div
    class="flex flex-col items-center justify-center h-screen pb-10 sm:w-full max-sm:px-6 fold:px-4 text-white bg-[#111119]"
  >
    <img
      src="@/assets/image/group12561_d.png"
      alt=""
      class="w-[100px]"
      @click="count"
    />
    <h2 class="mt-4 font-bold text-[30px] text-[#ffb500]">System Update</h2>
    <p class="text-[16px] mt-2">2024-10-04 07:00-09:00 (UTC) <br />- Stabilizing the trading system</p>
    <div class="sm:w-[1278px] w-full h-[1px] bg-[#606060] my-6"></div>
    <p class="text-center text-[16px] leading-[20px]">
      Proceed with the update to stabilize
      the system. <br />The position is maintained during the corresponding time,<br /> and
      the price change during the inspection time Fubit is not responsible for
      the loss.
    </p>
    <!-- <p class="text-center text-[16px] leading-[20px]">
      Please use "fu-bit.io".<br />
      "www.fu-bit.com" will be open after inspection.
      <br />
      <br />
      Fubit will come to you as soon as possible with better shape.
      <br />
      <br />
      Click Here >>> <a href="https://www.fu-bit.io" class="ml-2 underline">go to "fu-bit.io"</a>
    </p> -->
    <input type="text" v-model="testnum" v-if="pwOpen" class="text-[black]" />
  </div>
</template>
<script>
import create from 'vue-zustand';
import { system } from '@/store/store';
export default {
  name: 'SystemUpdate',
  data() {
    return {
      counting: 0,
      testnum: '',
      pwOpen: false
    };
  },
  setup() {
    const useSystem = create(system);
    const { setServiceCheck } = useSystem();
    return {
      setServiceCheck
    };
  },
  methods: {
    count() {
      this.counting++;
    }
  },
  watch: {
    counting(a) {
      if (a == 4) {
        this.counting = 0;
        this.pwOpen = true;
      }
    },
    testnum(a) {
      if (a == '1111') {
        localStorage.setItem('fubit_system', 'false');
        this.setServiceCheck();
      }
    }
  }
};
</script>
<style lang=""></style>
