import axios from 'axios';
import AesDecrypt from '@/components/Security/AesDecrypt';

// axios.defaults.headers.common[] = '*';

const Request = async (uri, method, language, encryptedData, headers) => {
  try {
    let response = await axios({
      method: method,
      url: `${process.env.VUE_APP_MAIN_API_URI}${uri}`,
      // uri: `https://www.fu-bit.com:9080${uri}`,
      headers: {
        // 'accept': 'applcation/json',
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Origin': '*',
        language: language || 'en',
        apikey: process.env.VUE_APP_MAIN_API_KEY,
        Authorization: `Bearer ${localStorage.getItem('fubit_token')}`,
        Validation: `Bearer ${localStorage.getItem('fubit_rtoken')}`,
        ...headers
      },
      data: encryptedData
    });
    if (response.status !== 200) return false;
    let decrtypedResult = JSON.parse(AesDecrypt(response.data.data.toString()));

    return decrtypedResult;
  } catch (e) {
    console.log('catch =>');
    console.log(e);
    return false;
  }
};

export default Request;
