<template lang="">
  <div class="fixed z-10 flex w-screen h-full bg-white dark:bg-[#111119] text-[15px] dark:text-[#e5e5e5] pb-4">
    <!-- herder -->
    <div
      class="fixed top-0 flex items-center justify-between w-full px-4 py-3 max-sm:bg-white max-sm:dark:bg-[#111119] fold:bg-white fold:dark:bg-[#111119]"
    >
      <button
        @click="setModalControl"
      >
        <img
          src="@/assets/image/ion_chevron_back_outline.png"
          alt=""
          class="w-[30px] dark:hidden"
        />
        <img
          src="@/assets/image/ion_chevron_back_outline_d.png"
          alt=""
          class="w-[30px] opacity-0 dark:opacity-100 mt-[-30px] dark:mt-0"
        />
      </button>
      <div class="text-[25px] text-center text-[#444] dark:text-[#e5e5e5]">
        {{ $t('common.usercenter') }}
      </div>
      <div class="w-[30px]"></div>
    </div>
    <!-- content -->
    <div class="pt-[80px] sm:pt-0 w-full px-4 h-full overflow-y-scroll">
      <div>
        <p>{{ $t('usercenter.inquiry.reg.select') }}</p>
        <div class="border mt-2 bg-[#f7f8fa] p-2 dark:bg-[#1c1d22] dark:border-[#606060] text-[13px]">
          {{mobileData.type}}
        </div>
      </div>

      <div class="mt-4">
        <p>{{ $t('usercenter.inquiry.reg.title') }}</p>
        <div class="border bg-[#f7f8fa] py-2 px-4 dark:bg-[#1c1d22] dark:border-[#606060] text-[13px] truncate">
          {{mobileData.title}}
        </div>
      </div>

      <div class="mt-4">
        <p>{{ $t('usercenter.inquiry.reg.content') }}</p>
        <div class="border py-3 px-4  mt-2 bg-[#f7f8fa] dark:bg-[#1c1d22] dark:border-[#606060] text-[13px] leading-[22px]">
          {{mobileData.body}}
        </div>
      </div>
      
      <div class="mt-4">
        <p>{{ $t('usercenter.inquiry.reg.att_file') }}</p>
        <div class="border mt-2 bg-[#f7f8fa] text-[#a0a0a0] text-[13px] underline dark:bg-[#1c1d22] dark:border-[#606060] h-[50px] leading-[50px] px-4">
          {{mobileData.photoName}}
        </div>
      </div>

      <div class="mt-4">
        <p>{{ $t('usercenter.inquiry.reg.answer') }}</p>
        <div class="border mt-2 bg-[#f7f8fa] p-2 dark:bg-[#1c1d22] dark:border-[#606060] text-[13px] min-h-[50px]">
          {{mobileData.state === 'W' ? $t('usercenter.inquiry.list.answer_wait_msg') : mobileData.answer}}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  modalControl,
  noticeStore,
  apiLanguage
} from '@/store/store';
import create from 'vue-zustand';
import AesEncrypt from '@/components/Security/AesEncrypt'
import Request from '@/utils/Request'
export default {
  name: 'InquiryMobile',
  data() {
    return {
      mobileData : {},
    };
  },
  setup() {
    const useModalControl = create(modalControl);
    const { setModalControl } = useModalControl();
    const useNoticeDetail = create(noticeStore);
    const { noticeSeq } = useNoticeDetail();
    const useApiLanguage = create(apiLanguage);
    const { apiLangCode } = useApiLanguage();
    return {
      setModalControl,
      noticeSeq,
      apiLangCode
      
    }
  },
  methods: {
    async getDetailData() {
      let data = JSON.stringify({
          data: AesEncrypt({
            seq: this.noticeSeq
          })
        });

        let result = await Request(
          '/api/exs/scenter/v1/request_inquire_info',
          'post',
          this.apiLangCode[this.$i18n.locale],
          data
        );

        let { status } = result.API_CODE;

        if (status === 'L402') {
          //session closed
        } else if (status === '0000') {
          this.mobileData = result.inquire;
        }
    }
  },
  created() {

    this.getDetailData()
  },
};
</script>
