<template lang="">
  <div class="fixed z-10 flex w-screen h-full sm:bg-black sm:bg-opacity-50 bg-[#f2f4f5] bg-opacity-80 px-6 fold:px-4 sm:px-0">
    <div class="w-full bg-white sm:w-[900px] m-auto sm:rounded sm:border border-[#C9C9C9] sm:px-[40px] px-6 sm:pt-[60px] relative dark:bg-[#1c1d22] dark:border-[#606060] dark:text-[#c9c9c9]">
      <!-- point image -->
      <div class="absolute left-[-1px] top-[-1px] max-sm:hidden fold:hidden">
        <img src="../../assets/image/path1703.png" alt="" class="dark:hidden">
        <img src="../../assets/image/path1703_d.png" alt="" class="opacity-0 dark:opacity-100">
      </div>
      <div class="max-sm:mt-10 fold:mt-10">
        <h2 class="sm:text-[38px] text-[20px] sm:text-left text-center max-sm:text-[#444] max-sm:dark:text-[#e5e5e5] fold:text-[#444] fold:dark:text-[#e5e5e5]">{{ $t('level.title') }}</h2>
        <p class="sm:text-[18px] text-[15px] fold:text-[13px] max-sm:leading-[22px] fold:leading-[18px] sm:text-[#9f9f9f] max-sm:dark:text-[#e5e5e5] fold:dark:text-[#e5e5e5] mt-4">{{ $t('level.msg1') }}</p>
        <p class="sm:text-[18px] text-[15px] fold:text-[13px] max-sm:leading-[22px] fold:leading-[18px] sm:text-[#9f9f9f] max-sm:dark:text-[#e5e5e5] fold:dark:text-[#e5e5e5] sm:-mt-1">{{ $t('level.msg2') }}</p>
      </div>
      <table
        class="w-full text-center max-sm:leading-[18px] fold:leading-[18px] break-normal sm:text-[16px] text-[12px] fold:text-[10px] text-[#9f9f9f] my-10 max-sm:mt-4 fold:mt-4"
      >
        <tr
          class="w-full bg-[#f7f8fa] font-regular border-b border-[#c9c9c9] dark:bg-[#111119] dark:border-[#606060]"
        >
          <td class="w-1/6 sm:py-2">{{ $t('level.lev') }}</td>
          <td class="sm:w-1/3 max-sm:w-1/3 sm:py-2">
            {{ $t('level.standard') }}
          </td>
          <td class="w-1/2 max-sm:3/6 sm:py-2">{{ $t('level.withdraw') }}</td>
        </tr>
        <tr
          class="border-b border-[#e5e5e5] dark:border-[#606060] dark:bg-[#25252c] max-sm:dark:bg-[#1c1d22]"
        >
          <td class="sm:py-2 max-sm:text-[13px]">1{{ $t('level.stage') }}</td>
          <td class="sm:py-2">{{ $t('level.emailor') }}</td>
          <td
            class="flex justify-between px-2 text-right max-sm:flex-wrap max-sm:justify-center max-sm:px-4 sm:py-2"
          >
            <span class="mr-1 sm:basis-1/4">1,000 USDT</span>
            <span class="sm:basis-1/4">0.05 BTC</span>
            <span class="mr-1 sm:basis-1/4">0.7 ETH</span>
            <span class="sm:basis-1/4">3,000 XRP</span>
          </td>
        </tr>
        <tr
          class="border-b border-[#e5e5e5] dark:border-[#606060] dark:bg-[#25252c] max-sm:dark:bg-[#1c1d22]"
        >
          <td class="sm:py-2 max-sm:text-[13px]">2{{ $t('level.stage') }}</td>
          <td class="sm:py-2">{{ $t('level.emailphone') }}</td>
          <td
            class="flex justify-between px-2 text-right sm:py-2 max-sm:flex-wrap max-sm:justify-center max-sm:px-4"
          >
            <span class="mr-1 sm:basis-1/4">20,000 USDT</span>
            <span class="sm:basis-1/4">1 BTC</span>
            <span class="mr-1 sm:basis-1/4">15 ETH</span>
            <span class="sm:basis-1/4">55,000 XRP</span>
          </td>
        </tr>
        <tr
          class="border-b border-[#e5e5e5] dark:border-[#606060] dark:bg-[#25252c] max-sm:dark:bg-[#1c1d22]"
        >
          <td class="sm:py-2 max-sm:text-[13px]">3{{ $t('level.stage') }}</td>
          <td class="sm:py-2">{{ $t('level.id') }}</td>
          <td
            class="flex justify-between px-2 text-right sm:py-2 max-sm:flex-wrap max-sm:justify-center max-sm:px-4"
          >
            <span class="mr-1 sm:basis-1/4">100,000 USDT</span>
            <span class="sm:basis-1/4">5 BTC</span>
            <span class="mr-1 sm:basis-1/4">70 ETH</span>
            <span class="sm:basis-1/4">270,000 XRP</span>
          </td>
        </tr>
      </table>
      <div class="flex mb-10 max-sm:mb-6 dark:text-[#333] max-sm:text-[14px] fold:text-[14px]">
        <button
          class="basis-1/2 border border-[#cfe4ff] mr-2 sm:rounded py-1 sm:py-3 dark:border-[#fff8e9] sm:dark:bg-[#fff8e9] dark:bg-[#fff]"
          @click="setModalControl"
        >
          {{ $t('common.cancel') }}
        </button>
        <button
          class="basis-1/2 bg-[#cfe4ff] sm:rounded py-1 sm:py-3 sm:dark:bg-[#ffb500] dark:bg-[#fdd26a]"
          @click="
            () => {
              $router.push({
                name: 'AuthCenter'
              });
              setModalControl();
            }
          "
        >
          {{ $t('level.levelup') }}
        </button>
      </div>

      <!-- close button -->
      <button class="absolute right-[20px] top-[18px] font-light text-[30px] max-sm:hidden fold:hidden" @click="setModalControl" >
        <img src="../../assets/image/group2322.png" alt="" />
      </button>
    </div>
  </div>
</template>
<script>
import { modalControl } from '@/store/store';
import create from 'vue-zustand';

export default {
  name: 'WithdrawLevel',
  data() {
    return {
      tStyle: {}
    };
  },
  setup() {
    const useModalControl = create(modalControl);
    const { setModalControl } = useModalControl();

    return {
      setModalControl
    };
  }
};
</script>
<style lang=""></style>
