<template lang="">
  <div class="flex fixed z-10 bg-black bg-opacity-50 w-screen h-full">
    <div :class="tStyle.inner">
      <div :class="tStyle.point">
        <img src="../../assets/image/path1703.png" alt="" class="dark:hidden">
        <img src="../../assets/image/path1703_d.png" alt="" class="opacity-0 dark:opacity-100">
      </div>
      <div :class="tStyle.title">
        <h2 class="text-[30px] font-bold ">{{ $t('common.google_auth') }}</h2>
        <p class="text-[20px] mt-[30px] font-light">
          {{ $t('googlenumber.number_msg') }}
        </p>

        <div :class="tStyle.num_box">
          <div :class="tStyle.num"></div>
          <div :class="tStyle.num"></div>
          <div :class="tStyle.num"></div>
          <div :class="tStyle.num"></div>
          <div :class="tStyle.num"></div>
          <div :class="tStyle.num"></div>
        </div>
      </div>
      
      
        <button :class="tStyle.confirm">
          {{ $t('common.ok') }}
          </button>      
      

        <button :class="tStyle.close_bt" v-on:click="setModalControl">
        <img src="../../assets/image/group2322.png" alt="">
      </button>
    </div>
  </div>
</template>
<script>
import { nationNumModal, modalControl } from "@/store/store";
import create from "vue-zustand";

export default {
  name: "GoogleNumber",
  data() {
    return {
      tStyle: {
        inner: 'bg-white w-[600px] h-[540px] m-auto rounded border border-[#C9C9C9] px-[40px] py-[15px] relative dark:bg-[#1c1d22] dark:border-[#606060] dark:text-[#c9c9c9]',
        title: 'text-center mt-[50px]',
        point: 'absolute left-[-1px] top-[-1px]',
        num_box: 'mx-auto mt-[100px]',
        num: 'w-[80px] h-[80px] border border-b-[2px] border-b-[#626060] inline-block mx-[3px] dark:bg-[#3b3b43] dark:border-[#606060]',
        

  
        confirm: 'w-full h-[60px] bg-[#cfe4fe] rounded-[3px] mt-[80px] dark:bg-[#ffb500] dark:text-[#333]',
        

        
        close_bt: 'absolute right-[20px] top-[18px] font-light text-[30px]',


      },
    };
  },
  setup() {
    const useNationModal = create(nationNumModal);
    const useModalControl = create(modalControl);
    const { setModalClose } = useNationModal();
    const { setModalControl } = useModalControl();

    return{
      setModalClose,
      setModalControl
    }
  },
};
</script>
<style lang=""></style>
