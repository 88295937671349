<template lang="">
  <div
    class="fixed z-10 flex w-screen h-full bg-white sm:bg-black sm:bg-opacity-50"
  >
    <div
      class="fixed top-0 z-30 flex items-center justify-between w-full px-4 py-3 sm:hidden bg-white dark:bg-[#111119]"
    >
      <button @click="closeModal">
        <img
          src="@/assets/image/ion_chevron_back_outline.png"
          alt=""
          class="w-[30px] dark:hidden"
        />
        <img
          src="@/assets/image/ion_chevron_back_outline_d.png"
          alt=""
          class="w-[30px] opacity-0 dark:opacity-100 mt-[-30px] dark:mt-0"
        />
      </button>
      <div class="text-[25px] text-center text-[#444] dark:text-[#e5e5e5]">
        {{ $t('common.email_auth') }}
      </div>
      <div class="w-[30px]"></div>
    </div>
    <div :class="tStyle.inner">
      <div :class="tStyle.point">
        <img src="../../assets/image/path1703.png" alt="" class="dark:hidden" />
        <img
          src="../../assets/image/path1703_d.png"
          alt=""
          class="opacity-0 dark:opacity-100"
        />
      </div>
      <div :class="tStyle.title">
        <h2 class="text-[40px] max-sm:hidden fold:hidden">{{ $t('common.email_auth') }}</h2>
        <p class="text-[20px] mt-[40px] max-sm:hidden fold:hidden">
          {{ $t('email.text') }}
        </p>
        <div class="flex justify-center sm:hidden">
          <img
            src="@/assets/image/thmb_user.png"
            alt=""
            class="w-[20%] inline dark:hidden"
          />
          <img
            src="@/assets/image/thmb_user_d.png"
            alt=""
            class="w-[20%] -ml-[20%] dark:ml-0 inline opacity-0 dark:opacity-100"
          />
        </div>

        <p
          class="sm:text-[20px] text-[14px] mt-[10px] inline-block px-6 sm:px-[100px] sm:py-1 rounded-full border border-[#c9c9c9] dark:border-[#606060] max-sm:dark:text-[#e5e5e5] fold:dark:text-[#e5e5e5]"
        >
          {{ this.alertData?.newValue }}
        </p>
        <p class="text-[15px] mt-[10px] sm:hidden dark:text-[#e5e5e5] max-sm:leading-[18px] fold:leading-[18px]">
          {{ $t('email.text') }}
        </p>

        <div :class="tStyle.num_box">
          <input
            type="text"
            ref="input"
            maxLength="6"
            v-model="inputNum"
            class="test absolute text-white w-full sm:w-[600px] h-[100px] sm:h-[600px] mt-[-50px] sm:mt-[-410px] cursor-pointer opacity-0 z-[0] focus:outline-none"
          />

          <input
            disabled
            @click="focusInput"
            type="text"
            v-for="(a, i) in 6"
            :key="i"
            v-model="inArr[i]"
            maxLength="1"
            :class="[
              inputNum.length === i
                ? tStyle.numY
                : !isFail
                ? tStyle.num
                : tStyle.numInputFail
            ]"
          />
        </div>
        <p v-if="isFail" :class="tStyle.fail" class="max-sm:hidden fold:hidden">
          {{ $t('googlenumber.fail_msg') }}
        </p>
      </div>
      <!--
        <div
          class="sm:mt-[23.5px] max-sm:text-[25px] text-center text-[40px] sm:leading-[58px] text-[#606060] dark:text-[#c9c9c9] max-sm:text-[#444]"
        >
          {{ $t('login.signup') }}
        </div>
        <div class="w-[30px] sm:hidden"></div>
      </div>

      <div class="sm:hidden text-center max-sm:mt-[56px]">
        <img src="@/assets/image/thmb_user.png" alt="" class="w-[20%] inline max-sm:mt-[50px]">
      </div>
      <p class="text-[20px] mt-[40px]">
        {{ $t('email.text') }}
      </p>
      <div
        class="sm:w-[425px] max-sm:px-6  max-sm:text-[14px] sm:h-[45px] border rounded-full mx-auto mt-[78px] flex items-center justify-center text-[17px] text-[#626060] dark:text-[#c9c9c9] dark:border-[#606060] max-sm:mt-[30px]"
      >
        
        {{ pwChangeMailInput }}
      </div>
      <div :class="tStyle.num_box">
        <input
          type="text"
          ref="input"
          maxLength="6"
          v-model="inputNum"
          class="test absolute text-white w-[600px] h-[500px] mt-[-410px] cursor-pointer opacity-0 z-[0] focus:outline-none"
        />

        <input
          disabled
          @click="focusInput"
          type="text"
          v-for="(a, i) in 6"
          :key="i"
          v-model="inArr[i]"
          maxLength="1"
          :class="[inputNum.length === i ? tStyle.numY : tStyle.num]"
        />
        <p v-if="isFail" :class="tStyle.fail">{{ $t('googlenumber.fail_msg') }}</p>
      </div>-->
      <div class="max-sm:flex fold:flex justify-between mt-[10px] sm:mt-0">
        <p v-if="isFail" :class="tStyle.fail" class="sm:hidden">
          {{ $t('googlenumber.fail_msg') }}
        </p>
        <button :class="tStyle.confirm" @click="requestAuth">
          <span class="max-sm:hidden fold:hidden">{{ $t('password.resend') }}</span>
          <span class="sm:hidden">{{ $t('password.resend2') }}</span>
        </button>
      </div>

      <!--
      <p class="mt-[38px] text-center">{{ $t('passwordforgot.text3') }}</p>
      -->

      <button :class="tStyle.close_bt" v-on:click="closeModal">
        <img src="../../assets/image/group2322.png" alt="" />
      </button>
    </div>
  </div>
</template>
<script>
import {
  nationNumModal,
  modalControl,
  isLogin,
  apiLanguage,
  refresh
} from '@/store/store';
import CryptoJS from 'crypto-js';
import Request from '@/utils/Request';
import AesEncrypt from '@/components/Security/AesEncrypt';
import create from 'vue-zustand';

export default {
  name: 'EmailNumber',
  data() {
    return {
      inputNum: '',
      inArr: [],
      isFail: false,
      tStyle: {
        inner:
          'bg-white w-full pt-[56px] px-6 h-full sm:w-[600px] sm:h-[610px] m-auto sm:rounded sm:border border-[#C9C9C9] sm:px-[40px] sm:py-[15px] relative sm:dark:bg-[#1c1d22] dark:bg-[#111119] dark:border-[#606060] dark:text-[#c9c9c9]',
        title: 'text-center sm:mt-[50px] mt-[80px]',
        point: 'absolute left-[-1px] top-[-1px] max-sm:hidden fold:hidden',
        num_box:
          'w-full sm:h-[80px] flex justify-between mt-[10px] sm:mt-[88px] children:last-child:border-[#005CB9]',
        numInput:
          'disabled:bg-white cursor-pointer sm:w-[90px] sm:h-[90px] w-[14.6666%] h-[13.5vw] border border-[#C9C9C9] border-b-[4px] border-b-[#626060] text-center dark:bg-[#1c1d22] dark:border-[#606060]',
        num: 'sm:w-[90px] sm:h-[90px] w-[14.6666%] h-[13.5vw] border border-b-[4px] sm:border-b-[#626060] inline-block sm:dark:bg-[#3b3b43] dark:border-[#606060] max-sm:bg-[#fff] max-sm:border-[#c9c9c9] fold:bg-[#fff] fold:border-[#c9c9c9] border-b-[#606060] text-center dark:bg-[#1c1d22] disabled:bg-white',
        numY: 'disabled:bg-white cursor-pointer sm:w-[90px] sm:h-[90px] w-[14.6666%] h-[13.5vw] border border-[#C9C9C9] border-b-[#005CD3] dark:border-b-[#ffb500] dark:bg-[#1c1d22] dark:border-[#606060] border-b-[4px] text-center',
        numInputFail:
          'disabled:bg-white cursor-pointer sm:w-[90px] sm:h-[90px] w-[14.6666%] h-[13.5vw] border border-[#C9C9C9] border-b-[4px] border-b-[#606060] text-center dark:bg-[#1c1d22] dark:border-[#606060]',
        fail: 'text-center text-[#e7224b] sm:text-[22px] text-[13px]',

        confirm:
          'sm:w-full sm:h-[60px] max-sm:text-[15px] fold:text-[15px] sm:no-underline underline sm:bg-[#cfe4fe] rounded-[3px] sm:mt-[80px] sm:dark:bg-[#ffb500] sm:dark:text-[#333] max-sm:ml-auto fold:ml-auto dark:text-[#e5e5e5]',

        close_bt:
          'absolute right-[20px] top-[18px] font-light text-[30px] max-sm:hidden fold:hidden'
      }
    };
  },
  setup() {
    const useNationModal = create(nationNumModal);
    const useModalControl = create(modalControl);
    const useIsLogin = create(isLogin);
    const { setModalClose } = useNationModal();
    const {
      setModalControl,
      alertData,
      setAlertData,
      setModalName,
      setAlertOpen
    } = useModalControl();
    const { setLogout, loginState } = useIsLogin();
    const useApiLanguage = create(apiLanguage);
    const { apiLangCode } = useApiLanguage();
    const useRefresh = create(refresh)
    const { setRefresh } = useRefresh()

    return {
      setModalClose,
      setModalControl,
      alertData,
      setAlertData,
      setLogout,
      loginState,
      setModalName,
      setAlertOpen,
      apiLangCode,
      setRefresh
    };
  },
  mounted() {
    this.focusInput();
  },
  watch: {
    inputNum(a, b) {
      var blankCheck = /[\s]/g;
      if (isNaN(a) == true) {
        this.inputNum = b;
      } else if (blankCheck.test(a) == true) {
        this.inputNum = b;
      } else {
        this.inArr = Array.from(this.inputNum);

        if (this.inputNum.length === 6) {
          this.confirmAuth(this.inputNum);
        } else if (this.inputNum.length < 6) {
          this.tStyle.numInput =
            'disabled:bg-white cursor-pointer w-[90px] h-[90px] border border-[#C9C9C9] border-b-4 border-b-[#626060] text-center';
        }
      }
    }
  },
  methods: {
    //인증번호 확인
    async confirmAuth(uauth) {
      let { t } = this.$i18n;
      let { newValue, authType } = this.alertData;
      // let hash = CryptoJS.SHA512(newValue).toString(CryptoJS.enc.Hex);

      
      let data = JSON.stringify({
        data: AesEncrypt({
          type: 'email',
          auth_type: authType,
          new_value: newValue,
          uauth: uauth
        })
      });

      let result = await Request(
        '/api/exs/mypage/v1/confirm_auth',
        'post',
        this.apiLangCode[this.$i18n.locale],
        data
      );

      if (!result) {
        this.isFail = true;
        return;
      }
      let { API_CODE } = result;

      if (API_CODE.status === 'L402') {
        this.setAlertData({
          title: '',
          desc: this.$t('login.msg15')
        });
        this.setAlertOpen();
        localStorage.removeItem('fubit_token');
        localStorage.removeItem('fubit_rtoken');
        this.setLogout();
        this.goMainPage();
      } else if (API_CODE.status === '0000') {
        this.openAlertModal('', t('email.authsuccess'));
      }
      //인증코드 일치 않음
      else if (API_CODE.status === '0110') {
        this.isFail = true;
      }
      //인증코드 유효하지 않음
      else if (API_CODE.status === '0111') {
        this.isFail = true;
      } else {
        this.isFail = true;
      }
    },
    //재전송
    async requestAuth() {
      this.inputNum = '';
      let { t } = this.$i18n;
      let { newValue, authType } = this.alertData;

      let new_hash = CryptoJS.SHA512(newValue).toString(CryptoJS.enc.Hex);

      let data = JSON.stringify({
        data: AesEncrypt({
          type: 'email',
          auth_type: authType,
          new_value: new_hash
        })
      });
      let result = await Request(
        '/api/exs/mypage/v1/request_auth',
        'post',
        this.apiLangCode[this.$i18n.locale],
        data
      );

      if (!result) {
        this.openAlertModal('', t('phone.resendfail'));
        return;
      }

      let { API_CODE } = result;

      if (API_CODE.status === 'L402') {
        this.setAlertData({
          title: '',
          desc: this.$t('login.msg15')
        });
        this.setAlertOpen();
        localStorage.removeItem('fubit_token');
        localStorage.removeItem('fubit_rtoken');
        this.setLogout();
        this.goMainPage();
      } else if (API_CODE.status === '0000') {
        this.isFail = false;
        this.inputNum = '';
      } else {
        this.openAlertModal('', t('phone.resendfail'));
        return;
      }
      return false;
    },
    openAlertModal(title, desc) {
      this.setAlertData({ title, desc });
      this.setAlertOpen();
      this.setModalControl()
      this.setRefresh('nickSetting')
    },
    closeModal() {
      this.setAlertData({});
      this.setModalControl();
    },
    focusInput() {
      this.$refs.input.focus();
    },
    goMainPage() {
      this.$router.push({
        name: 'MainHome'
      });
    }
  }
};
</script>
<style lang=""></style>
