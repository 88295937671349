<template lang="">
  <div class="fixed z-10 flex w-screen h-full sm:bg-black sm:bg-opacity-50 bg-[#f2f4f5] bg-opacity-80 px-6 sm:px-0">
    <div :class="tStyle.inner">
      <div :class="tStyle.point">
        <img src="../../assets/image/path1703.png" alt="" class="dark:hidden" />
        <img
          src="../../assets/image/path1703_d.png"
          alt=""
          class="opacity-0 dark:opacity-100"
        />
      </div>
      <div :class="tStyle.title">
        <div
          class="max-sm:flex max-sm:justify-between max-sm:items-center max-sm:mt-[12%] fold:flex fold:justify-between fold:items-center fold:mt-[12%]"
        >
          <h2
            class="sm:text-[30px] sm:font-bold text-[20px] font-regular max-sm:text-[#444] max-sm:dark:text-[#e5e5e5] fold:text-[#444] fold:dark:text-[#e5e5e5]"
          >
            {{ $t("trade.modal.adjust") }}
          </h2>
          <button class="sm:hidden" @click="setModalControl">
            <img
              src="../../assets/image/icon_close.png"
              alt=""
              class="w-[35px] dark:hidden"
            />
            <img
              src="../../assets/image/icon_close_d.png"
              alt=""
              class="w-[35px] mt-[-35px] dark:mt-0 opacity-0 dark:opacity-100"
            />
          </button>
        </div>
        <div class="flex mt-[30px] sm:text-[20px] text-[15px]">
          <button
            class="py-2 border sm:mr-6 sm:rounded basis-1/2"
            :class="[
              payType === true
              ? 'sm:bg-[#005cd3] dark:bg-[#fdd26a] sm:border-t-0 border-t-2 dark:border-[#fdd26a] sm:text-[#fff] dark:text-[#333333] max-sm:border-[#005cd3] max-sm:border-x-0 max-sm:border-b-0 fold:border-[#005cd3] fold:border-x-0 fold:border-b-0 text-[#005cd3]'
: 'sm:bg-[#fff] sm:dark:bg-[#fff8e8] sm:dark:text-[#333] border-[#c9c9c9] bg-[#f2f4f5] max-sm:border-x-0 max-sm:border-b-0 max-sm:border-t-2 fold:border-x-0 fold:border-b-0 fold:border-t-2 dark:bg-[#fff] dark:text-[#9f9f9f]'
            ]"
            @click="
              () => {
                payType = true;
              }
            "
          >
            {{ $t("trade.modal.add") }}
          </button>
          <!-- <button class="basis-1/2 bg-[#e8eff7] rounded py-2 mr-6 dark:text-[#333] dark:bg-[#fff8e8]">{{ $t('trade.modal.add') }}</button> -->
          <button
            class="py-2 border sm:rounded basis-1/2"
            :class="[
              payType === false
              ? 'sm:bg-[#005cd3] dark:bg-[#fdd26a] sm:border-t-0 border-t-2 dark:border-[#fdd26a] sm:text-[#fff] dark:text-[#333333] max-sm:border-[#005cd3] max-sm:border-x-0 max-sm:border-b-0 fold:border-[#005cd3] fold:border-x-0 fold:border-b-0 text-[#005cd3]'
: 'sm:bg-[#fff] sm:dark:bg-[#fff8e8] sm:dark:text-[#333] border-[#c9c9c9] bg-[#f2f4f5] max-sm:border-x-0 max-sm:border-b-0 max-sm:border-t-2 fold:border-x-0 fold:border-b-0 fold:border-t-2 dark:bg-[#fff] dark:text-[#9f9f9f]'
            ]"
            @click="
              () => {
                payType = false;
              }
            "
          >
            {{ $t("trade.modal.remove") }}
          </button>
          <!-- <button class="basis-1/2 bg-[#cfe4fe] rounded py-2 dark:text-[#333] dark:bg-[#fdd26a] ">{{ $t('trade.modal.remove') }}</button> -->
        </div>
        <div class="flex text-left sm:text-[20px] mt-[10px] text-[15px]">
          <p>{{ $t("trade.modal.max") }} <span class="max-sm:hidden fold:hidden">:</span></p>
          <p class="ml-auto max-sm:font-semibold fold:font-semibold">
            36.181.24 <span class="max-sm:text-[#c9c9c9] max-sm:dark:text-[#606060] fold:text-[#c9c9c9] fold:dark:text-[#606060]">USDT</span>
          </p>
        </div>
        <div
          class="flex border border-[#c9c9c9] dark:border-[#606060] sm:justify-between sm:leading-[50px] mt-[10px] max-sm:text-[14px] fold:text-[14px] px-[10px] py-1 sm:p-0 max-sm:dark:bg-[#3b3b43] fold:dark:bg-[#3b3b43]"
        >
          <div class="basis-1/6 max-sm:text-[#a0a0a0] fold:text-[#a0a0a0]">
            {{ $t("trade.modal.size") }}
          </div>
          <div class="max-sm:basis-4/6 fold:basis-4/6">
            <input
              type="text"
              class="sm:basis-4/6 w-full outline-none text-right sm:dark:bg-[#1c1d22] dark:text-[#fff] dark:bg-[#3b3b43]"
            />
          </div>

          <div
            class="basis-1/6 sm:text-[#c9c9c9] sm:text-[18px] text-[14px] sm:font-regular font-bold text-[#a0a0a0]"
          >
            USDT
          </div>
        </div>
        <div class="mt-[20px]">
          <div class="flex items-center justify-between">
            <div class="w-[10px] h-[10px] rounded-full bg-[#c9c9c9]"></div>
            <div class="w-[10px] h-[10px] rounded-full bg-[#c9c9c9]"></div>
            <div class="w-[10px] h-[10px] rounded-full bg-[#c9c9c9]"></div>
            <div class="w-[10px] h-[10px] rounded-full bg-[#c9c9c9]"></div>
            <div
              class="w-[12px] h-[12px] rounded-full bg-[#c9c9c9] flex justify-center items-center"
            >
              <div class="w-[6px] h-[6px] rounded-full bg-white"></div>
            </div>
          </div>
          <div class="w-full h-[2px] bg-[#c9c9c9] mt-[-7px]"></div>
        </div>
        <div class="sm:hidden bg-[#c9c9c9] w-full h-[1px] mt-[30px] dark:bg-[#606060]"></div>
        <div
          class="flex text-left sm:text-[#333333] text-[#606060] mt-[20px] sm:text-[20px] text-[15px] dark:text-[#c9c9c9]"
        >
          <p>{{ $t("trade.modal.est") }}<span class="max-sm:hidden fold:hidden">:</span></p>
          <p class="ml-auto sm:font-regular font-bold">
            23.221.26 <span class="max-sm:text-[#c9c9c9] max-sm:dark:text-[#606060] fold:text-[#c9c9c9] fold:dark:text-[#606060]">USDT</span>
          </p>
        </div>
      </div>
      <div></div>
      <div :class="tStyle.confirm">
        <button
          class="basis-1/2 sm:rounded-[3px] dark:bg-[#1c1d22] dark:text-[#e5e5e5] py-1 sm:py-0
bg-[#e8eff7] sm:dark:bg-[#fff8e9] sm:dark:text-[#333]"
          @click="setModalControl"
        >
          {{ $t("common.cancel") }}
        </button>
        <button
          class="basis-1/2 sm:rounded-[3px] dark:bg-[#fdd26a] py-1 sm:py-0
bg-[#cfe4fe] sm:dark:bg-[#ffb500]
dark:text-[#333] ml-[10px]"
        >
          {{ $t("common.ok") }}
        </button>
      </div>

      <button :class="tStyle.close_bt" @click="setModalControl">
        <img src="../../assets/image/group2322.png" alt="" />
      </button>
    </div>
  </div>
</template>
<script>
import { modalControl } from "@/store/store";
import create from "vue-zustand";

export default {
  name: "MarginCorrection",
  data() {
    return {
      payType: true,
      tStyle: {
        inner:
          "bg-white sm:w-[500px] sm:h-[500px] m-auto sm:rounded sm:border border-[#C9C9C9] sm:px-[40px] sm:py-[15px] relative dark:bg-[#1c1d22] dark:border-[#606060] dark:text-[#c9c9c9] px-6",
        title: "text-center mt-[50px] max-sm:text-[30px] fold:text-[30px]",
        point: "absolute left-[-1px] top-[-1px] max-sm:hidden fold:hidden",
        confirm:
          "w-full sm:h-[60px] flex mt-[40px] max-sm:text-[14px] fold:text-[14px] pb-6 sm:pb-0",

        close_bt:
          "absolute right-[20px] top-[18px] font-light text-[30px] max-sm:hidden fold:hidden"
      }
    };
  },
  setup() {
    const useModalControl = create(modalControl);
    const { setModalControl } = useModalControl();

    return {
      setModalControl
    };
  }
};
</script>
<style lang=""></style>