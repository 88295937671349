<template lang="">
  <div
    class="fixed z-10 flex w-screen h-full sm:bg-black sm:bg-opacity-50 bg-[#f2f4f5] bg-opacity-80 px-6 fold:px-4 sm:px-0"
  >
    <div :class="tStyle.inner">
      <div :class="tStyle.point">
        <img src="../../assets/image/path1703.png" alt="" class="dark:hidden" />
        <img
          src="../../assets/image/path1703_d.png"
          alt=""
          class="opacity-0 dark:opacity-100"
        />
      </div>
      <div :class="tStyle.title">
        <div
          class="max-sm:flex max-sm:justify-between max-sm:items-center fold:flex fold:justify-between fold:items-center mt-[12%] sm:mt-0"
        >
          <h2
            class="sm:text-[30px] sm:font-bold text-[20px] fold:text-[18px] font-regular max-sm:dark:text-[#e5e5e5] max-sm:text-[#444] fold:dark:text-[#e5e5e5] fold:text-[#444]"
          >
            {{ $t('trade.modal.liq2') }}
          </h2>
          <button class="sm:hidden" @click="setModalControl">
            <img
              src="../../assets/image/icon_close.png"
              alt=""
              class="w-[35px] dark:hidden"
            />
            <img
              src="../../assets/image/icon_close_d.png"
              alt=""
              class="w-[35px] mt-[-35px] dark:mt-0 opacity-0 dark:opacity-100"
            />
          </button>
        </div>
        <div
          class="sm:text-[18px] sm:leading-[22px] leading-[30px] mt-[30px] text-[15px] fold:text-[13px] max-sm:dark:text-[#e5e5e5] fold:dark:text-[#e5e5e5]"
        >
          <div class="flex">
            <div>
              {{ $t('common.contract')
              }}<span class="max-sm:hidden fold:hidden">:</span>
            </div>
            <div class="ml-auto">
              {{ payState.pSymbol.toUpperCase() }} USDT
              {{ $t('deal.perpetual') }}
              {{
                payState.pDest === 'long'
                  ? $t('deal.openlong')
                  : $t('deal.openshort')
              }}
              {{ payState.pLeverage }}x
            </div>
          </div>
          <div class="flex">
            <div>
              {{ $t('trade.modal.entryprice')
              }}<span class="max-sm:hidden fold:hidden">:</span>
            </div>
            <div class="ml-auto">
              {{ payState.pAverage }}
              <span class="max-sm:dark:text-[#9f9f9f] fold:dark:text-[#9f9f9f]"
                >USDT</span
              >
            </div>
          </div>
          <div class="flex">
            <div>
              {{ $t('trade.modal.amount')
              }}<span class="max-sm:hidden fold:hidden">:</span>
            </div>
            <div class="ml-auto">
              {{ changeQty }}
              <span
                class="max-sm:dark:text-[#9f9f9f] fold:dark:text-[#9f9f9f]"
                >{{ payState.pSymbol.toUpperCase() }}</span
              >
            </div>
          </div>
        </div>
        <div class="flex mt-[30px] sm:text-[20px] text-[15px]">
          <button
            class="py-2 border sm:mr-6 sm:rounded basis-1/2"
            :class="[
              payType === true
                ? 'sm:bg-[#005cd3] dark:bg-[#fdd26a] sm:border-t border-t-2 dark:border-[#fdd26a] sm:text-[#fff] dark:text-[#333333] max-sm:border-[#005cd3] max-sm:border-x-0 max-sm:border-b-0 fold:border-[#005cd3] fold:border-x-0 fold:border-b-0 text-[#005cd3]'
                : 'sm:bg-[#fff] sm:dark:bg-[#fff8e8] sm:border-t sm:dark:text-[#333] border-[#c9c9c9] bg-[#f2f4f5] max-sm:border-x-0 max-sm:border-b-0 max-sm:border-t-2 fold:border-x-0 fold:border-b-0 fold:border-t-2 dark:bg-[#fff] dark:text-[#9f9f9f]'
            ]"
            @click="
              () => {
                payType = true;
              }
            "
          >
            {{ $t('common.invest_limits') }}
          </button>
          <button
            class="py-2 border sm:rounded basis-1/2"
            :class="[
              payType === false
                ? 'sm:bg-[#005cd3] dark:bg-[#fdd26a] sm:border-t border-t-2 dark:border-[#fdd26a] sm:text-[#fff] dark:text-[#333333] max-sm:border-[#005cd3] max-sm:border-x-0 max-sm:border-b-0 fold:border-[#005cd3] fold:border-x-0 fold:border-b-0 text-[#005cd3]'
                : 'sm:bg-[#fff] sm:dark:bg-[#fff8e8] sm:border-t sm:dark:text-[#333] border-[#c9c9c9] bg-[#f2f4f5] max-sm:border-x-0 max-sm:border-b-0 max-sm:border-t-2 fold:border-x-0 fold:border-b-0 fold:border-t-2 dark:bg-[#fff] dark:text-[#9f9f9f]'
            ]"
            @click="
              () => {
                payType = false;
              }
            "
          >
            {{ $t('common.invest_marketprice') }}
          </button>
        </div>
        <div
          class="flex border border-[#c9c9c9] justify-between py-1 sm:py-0 sm:leading-[50px] sm:mt-[10px] mt-[20px] px-[10px] dark:border-[#606060] max-sm:text-[14px] max-sm:dark:bg-[#3b3b43] fold:text-[14px] fold:dark:bg-[#3b3b43]"
          v-if="payType === true"
        >
          <div
            class="shrink-0 max-sm:text-[#a0a0a0] max-sm:dark:text-[#9f9f9f] fold:text-[#a0a0a0] fold:dark:text-[#9f9f9f]"
          >
            {{ $t('common.orderprice') }}
          </div>
          <input
            type="number"
            class="sm:w-[55%] max-sm:w-[50%] fold:w-[40%] outline-none text-right sm:dark:bg-[#1c1d22] dark:text-[#fff] dark:bg-[#3b3b43]"
            v-model="orderPrice"
          />
          <div
            class="text-[#c9c9c9] sm:text-[18px] text-[14px] sm:font-regular font-bold max-sm:dark:text-[#9f9f9f] fold:dark:text-[#9f9f9f]"
          >
            USDT
          </div>
        </div>
        <div
          class="flex border border-[#c9c9c9] justify-between py-1 sm:py-0 sm:leading-[50px] sm:mt-[10px] mt-[20px] px-[10px] dark:border-[#606060] max-sm:text-[14px] max-sm:dark:bg-[#3b3b43] fold:text-[14px] fold:dark:bg-[#3b3b43]"
          v-if="isGaugeOpen === false"
        >
          <div
            class="max-sm:text-[#a0a0a0] max-sm:dark:text-[#9f9f9f] fold:text-[#a0a0a0] fold:dark:text-[#9f9f9f]"
          >
            {{ $t('deal.size') }}
          </div>
          <input
            type="number"
            class="sm:w-[55%] max-sm:w-[50%] fold:w-[40%] outline-none text-right sm:dark:bg-[#1c1d22] dark:text-[#fff] dark:bg-[#3b3b43]"
            v-model="orderQuantity"
            ref="qtyinput"
          />
          <div
            class="text-[#c9c9c9] sm:text-[18px] text-[14px] sm:font-regular font-bold max-sm:dark:text-[#9f9f9f] fold:dark:text-[#9f9f9f]"
          >
            {{ payState.pSymbol.toUpperCase() }}
          </div>
        </div>
        <div
          class="flex border border-[#c9c9c9] justify-between max-sm:text-[14px] fold:text-[14px] py-1 sm:py-0 sm:leading-[50px] sm:mt-[10px] mt-[20px] dark:border-[#606060] max-sm:dark:bg-[#3b3b43] fold:dark:bg-[#3b3b43]"
          v-else
        >
          <div
            class="pl-[10px] text-left basis-2/6 max-sm:dark:text-[#9f9f9f] max-sm:text-[#a0a0a0] fold:dark:text-[#9f9f9f] fold:text-[#a0a0a0]"
          >
            {{ $t('deal.available') }}
          </div>
          <div class="flex basis-4/6">
            <input
              type="text"
              class="w-full pr-2 text-right outline-none text-[#333333] sm:dark:bg-[#1c1d22] dark:text-[#fff] dark:bg-[#3b3b43]"
              v-model="gaugeQuantity"
              @focus="focusOn"
            />
            <div
              class="text-[#c9c9c9] sm:text-[18px] text-[14px] sm:font-regular font-bold max-sm:dark:text-[#9f9f9f] fold:dark:text-[#9f9f9f]"
            >
              {{ payState.pSymbol.toUpperCase() }}
            </div>
          </div>
          <div
            class="pr-2 text-right underline cursor-pointer basis-1/6"
            @click="
              () => {
                quantityGauge = '0';
              }
            "
          >
            {{ $t('deal.cancel') }}
          </div>
        </div>

        <div class="mt-[20px]">
          <VueSlider
            class="cursor-pointer"
            :processStyle="processStyle"
            tooltip="always"
            :tooltip-formatter="'{value}%'"
            :tooltipStyle="tooltipStyle"
            :dotOptions="dotOptions"
            :stepActiveStyle="stepActiveStyle"
            :stepStyle="stepStyle"
            :marks="marks"
            tooltipPlacement="bottom"
            heigth="2"
            :railStyle="railStyle"
            v-model="quantityGauge"
          />
        </div>
      </div>
      <div :class="tStyle.confirm">
        <button
          class="basis-1/2 sm:rounded-[3px] max-sm:py-1 bg-[#e8eff7] sm:dark:bg-[#fff8e9] dark:text-[#333] dark:bg-[#fff]"
          @click="setModalControl"
        >
          {{ $t('common.cancel') }}
        </button>
        <button
          class="basis-1/2 sm:rounded-[3px] max-sm:py-1 bg-[#cfe4fe] sm:dark:bg-[#ffb500] dark:text-[#333] ml-[10px] dark:bg-[#fdd26a]"
          @click="endPositionSubmit"
        >
          {{ $t('common.ok') }}
        </button>
      </div>

      <button :class="tStyle.close_bt" @click="setModalControl">
        <img src="../../assets/image/group2322.png" alt="" />
      </button>
    </div>
  </div>
</template>
<script>
import { modalControl, apiLanguage } from '@/store/store';
import { payoffData, orderConfirm } from '@/store/positionStore';
import create from 'vue-zustand';
import axios from 'axios';
import AesEncrypt from '../Security/AesEncrypt';
import AesDecrypt from '../Security/AesDecrypt';
import VueSlider from 'vue-3-slider-component';

export default {
  name: 'PayOff',
  data() {
    return {
      buttonAction: false,
      isGaugeOpen: false,
      payType: true,
      orderPrice: '0',
      orderQuantity: '0',
      gaugeQuantity: '0',
      quantityGauge: '0',
      marks: {
        1: '',
        25: '',
        50: '',
        75: '',
        100: ''
      },
      stepStyle: {
        display: 'block',
        width: '8px',
        borderRadius: '50%',
        height: '8px',
        backgroundColor: '#cccccc',
        marginTop: '-3px',
        marginLeft: '-5px'
      },
      stepActiveStyle: {
        display: 'block',
        width: '8px',
        borderRadius: '50%',
        height: '8px',
        backgroundColor: '#fc0d1b',
        marginTop: '-3px',
        marginLeft: '-5px'
      },
      railStyle: {
        height: '2px'
      },
      processStyle: {
        backgroundColor: '#fc0d1b'
      },
      dotOptions: {
        style: { backgroundColor: '#fc0d1b', dotSize: '10' }
      },
      tooltipStyle: {
        color: '#ffffff',
        backgroundColor: '#fc0d1b',
        height: '18px',
        lineHeight: '18px',
        borderColor: '#fc0d1b'
      },
      tStyle: {
        inner:
          'bg-white sm:w-[500px] sm:h-[580px] m-auto sm:rounded sm:border border-[#C9C9C9] sm:px-[40px] px-6 fold:px-4 sm:py-[15px] relative dark:bg-[#1c1d22] dark:border-[#606060] dark:text-[#c9c9c9]',
        title: 'text-center sm:mt-[50px]',
        point: 'absolute left-[-1px] top-[-1px] max-sm:hidden fold:hidden',
        confirm:
          'w-full sm:px-[40px] sm:h-[60px] flex sm:absolute left-0 right-0 bottom-[40px] pt-[20%] sm:py-0 mt-auto sm:mt-0 pb-6 max-sm:text-[14px] fold:text-[14px]',

        close_bt:
          'absolute right-[20px] top-[18px] font-light text-[30px] max-sm:hidden fold:hidden'
      }
    };
  },
  setup() {
    const useModalControl = create(modalControl);
    const { setModalControl, setModalName, setAlertData, setAlertOpen } =
      useModalControl();
    const usePayOff = create(payoffData);
    const { payState } = usePayOff();
    const useApiLanguage = create(apiLanguage);
    const { apiLangCode } = useApiLanguage();
    const useOrderConfirm = create(orderConfirm);
    const { setApiCall } = useOrderConfirm();

    return {
      setModalControl,
      payState,
      setModalName,
      setAlertData,
      setAlertOpen,
      apiLangCode,
      setApiCall
    };
  },
  methods: {
    endPositionSubmit() {
      if (!this.buttonAction) {
        this.buttonAction = true;
        const localToken = 'Bearer ' + localStorage.getItem('fubit_token');
        const localRtoken = 'Bearer ' + localStorage.getItem('fubit_rtoken');
        // 청산 지정가
        if (this.payType === true) {
          if (this.orderPrice == '0' || this.orderPrice === '') {
            this.setAlertData({
              title: '',
              desc: this.$t('deal.msg4')
            });
            this.setAlertOpen();
          } else {
            if (this.isGaugeOpen === true) {
              const sendData = {
                percent_yn: 'Y',
                type: 'close',
                symbol: this.payState.pSymbol,
                order_type: 'maker',
                dest_ls: this.payState.pDest,
                margin_ci: this.payState.pMarginT,
                price: this.orderPrice,
                quantity: this.quantityGauge,
                leverage: this.payState.pLeverage
              };

              const encrypted = AesEncrypt(sendData);
              var data = JSON.stringify({
                data: encrypted
              });
              var config = {
                method: 'post',
                url: `${process.env.VUE_APP_MAIN_API_URI}/api/exs/trade/v1/send_order`,
                headers: {
                  language: this.apiLangCode[this.$i18n.locale],
                  apikey: process.env.VUE_APP_MAIN_API_KEY,
                  'Content-Type': 'application/json',
                  Authorization: localToken,
                  Validation: localRtoken
                },
                data: data
              };
              axios(config).then((res) => {
                const decrypted = AesDecrypt(res.data.data.toString());
                const resData = JSON.parse(decrypted);
                if (resData.API_CODE.status == 'L402') {
                  this.setAlertData({
                    title: '',
                    desc: this.$t('login.msg15')
                  });
                  this.setAlertOpen();
                  localStorage.removeItem('fubit_token');
                  localStorage.removeItem('fubit_rtoken');
                  this.setLogout();
                } else if (resData.API_CODE.status == '0000') {
                  if (resData.AUTH !== undefined) {
                    localStorage.setItem('fubit_token', resData.AUTH.token);
                    localStorage.setItem('fubit_rtoken', resData.AUTH.rtoken);
                  }
                  // this.setAlertData({
                  //   title: '',
                  //   desc: this.$t('deal.msg7'),
                  //   type: 'order'
                  // });
                  // this.setAlertOpen();
                  this.setModalControl();
                  this.setApiCall();
                }
              });
            } else {
              if (this.orderQuantity == '0' || this.orderQuantity == '') {
                this.setAlertData({
                  title: '',
                  desc: this.$t('deal.msg3')
                });
                this.setAlertOpen();
              } else if (this.orderQuantity > this.payState.pQuantity) {
                this.setAlertData({
                  title: '',
                  desc: this.$t('deal.msg14')
                });
                this.setAlertOpen();
              } else {
                const sendData = {
                  percent_yn: 'N',
                  type: 'close',
                  symbol: this.payState.pSymbol,
                  order_type: 'maker',
                  dest_ls: this.payState.pDest,
                  margin_ci: this.payState.pMarginT,
                  price: this.orderPrice,
                  quantity: this.orderQuantity,
                  leverage: this.payState.pLeverage
                };
                const encrypted = AesEncrypt(sendData);
                var data = JSON.stringify({
                  data: encrypted
                });
                var config = {
                  method: 'post',
                  url: `${process.env.VUE_APP_MAIN_API_URI}/api/exs/trade/v1/send_order`,
                  headers: {
                    language: this.apiLangCode[this.$i18n.locale],
                    apikey: process.env.VUE_APP_MAIN_API_KEY,
                    'Content-Type': 'application/json',
                    Authorization: localToken,
                    Validation: localRtoken
                  },
                  data: data
                };
                axios(config).then((res) => {
                  const decrypted = AesDecrypt(res.data.data.toString());
                  const resData = JSON.parse(decrypted);
                  if (resData.API_CODE.status == 'L402') {
                    this.setAlertData({
                      title: '',
                      desc: this.$t('login.msg15')
                    });
                    this.setAlertOpen();
                    localStorage.removeItem('fubit_token');
                    localStorage.removeItem('fubit_rtoken');
                    this.setLogout();
                  } else if (resData.API_CODE.status == '0000') {
                    if (resData.AUTH !== undefined) {
                      localStorage.setItem('fubit_token', resData.AUTH.token);
                      localStorage.setItem('fubit_rtoken', resData.AUTH.rtoken);
                    }
                    // this.setAlertData({
                    //   title: '',
                    //   desc: this.$t('deal.msg7'),
                    //   type: 'order'
                    // });
                    // this.setAlertOpen();
                    this.setApiCall();
                    this.setModalControl();
                  }
                });
              }
            }
          }
        }
        // 청산 시장가
        else {
          if (this.orderPrice == '0' || this.orderPrice === '') {
            this.setAlertData({
              title: '',
              desc: this.$t('deal.msg4')
            });
            this.setAlertOpen();
          } else {
            if (this.isGaugeOpen === true) {
              const sendData = {
                percent_yn: 'Y',
                type: 'close',
                symbol: this.payState.pSymbol,
                order_type: 'taker',
                dest_ls: this.payState.pDest,
                margin_ci: this.payState.pMarginT,
                price: this.payState.pRecent,
                quantity: this.quantityGauge,
                leverage: this.payState.pLeverage
              };
              const encrypted = AesEncrypt(sendData);
              var data = JSON.stringify({
                data: encrypted
              });
              var config = {
                method: 'post',
                url: `${process.env.VUE_APP_MAIN_API_URI}/api/exs/trade/v1/send_order`,
                headers: {
                  language: this.apiLangCode[this.$i18n.locale],
                  apikey: process.env.VUE_APP_MAIN_API_KEY,
                  'Content-Type': 'application/json',
                  Authorization: localToken,
                  Validation: localRtoken
                },
                data: data
              };
              axios(config).then((res) => {
                const decrypted = AesDecrypt(res.data.data.toString());
                const resData = JSON.parse(decrypted);
                if (resData.API_CODE.status == 'L402') {
                  this.setAlertData({
                    title: '',
                    desc: this.$t('login.msg15')
                  });
                  this.setAlertOpen();
                  localStorage.removeItem('fubit_token');
                  localStorage.removeItem('fubit_rtoken');
                  this.setLogout();
                } else if (resData.API_CODE.status == '0000') {
                  if (resData.AUTH !== undefined) {
                    localStorage.setItem('fubit_token', resData.AUTH.token);
                    localStorage.setItem('fubit_rtoken', resData.AUTH.rtoken);
                  }
                  // this.setAlertData({
                  //   title: '',
                  //   desc: this.$t('deal.msg7'),
                  //   type: 'order'
                  // });
                  // this.setAlertOpen();
                  this.setModalControl();
                  this.setApiCall();
                }
              });
            } else {
              if (this.orderQuantity == '0' || this.orderQuantity == '') {
                this.setAlertData({
                  title: '',
                  desc: this.$t('deal.msg3')
                });
                this.setAlertOpen();
              } else if (this.orderQuantity > this.payState.pQuantity) {
                this.setAlertData({
                  title: '',
                  desc: this.$t('deal.msg14')
                });
                this.setAlertOpen();
              } else {
                const sendData = {
                  percent_yn: 'N',
                  type: 'close',
                  symbol: this.payState.pSymbol,
                  order_type: 'taker',
                  dest_ls: this.payState.pDest,
                  margin_ci: this.payState.pMarginT,
                  price: this.payState.pRecent,
                  quantity: this.orderQuantity,
                  leverage: this.payState.pLeverage
                };
                const encrypted = AesEncrypt(sendData);
                var data = JSON.stringify({
                  data: encrypted
                });
                var config = {
                  method: 'post',
                  url: `${process.env.VUE_APP_MAIN_API_URI}/api/exs/trade/v1/send_order`,
                  headers: {
                    language: this.apiLangCode[this.$i18n.locale],
                    apikey: process.env.VUE_APP_MAIN_API_KEY,
                    'Content-Type': 'application/json',
                    Authorization: localToken,
                    Validation: localRtoken
                  },
                  data: data
                };
                axios(config).then((res) => {
                  const decrypted = AesDecrypt(res.data.data.toString());
                  const resData = JSON.parse(decrypted);
                  if (resData.API_CODE.status == 'L402') {
                    this.setAlertData({
                      title: '',
                      desc: this.$t('login.msg15')
                    });
                    this.setAlertOpen();
                    localStorage.removeItem('fubit_token');
                    localStorage.removeItem('fubit_rtoken');
                    this.setLogout();
                  } else if (resData.API_CODE.status == '0000') {
                    if (resData.AUTH !== undefined) {
                      localStorage.setItem('fubit_token', resData.AUTH.token);
                      localStorage.setItem('fubit_rtoken', resData.AUTH.rtoken);
                    }
                    // this.setAlertData({
                    //   title: '',
                    //   desc: this.$t('deal.msg7'),
                    //   type: 'order'
                    // });
                    // this.setAlertOpen();
                    this.setModalControl();
                    this.setApiCall();
                  }
                });
              }
            }
          }
        }
      }
    },
    focusOn() {
      this.isGaugeOpen = false;
      this.quantityGauge = '0';
      setTimeout(() => {
        this.$refs.qtyinput.focus();
      }, 300);
    },
    setDecimal(num, i) {
      const number = parseFloat(num);
      const place = Math.pow(10, i);
      return (Math.floor(number * place) / place).toFixed(i);
    }
  },
  components: {
    VueSlider
  },
  watch: {
    quantityGauge(a) {
      if (a > 0) {
        this.isGaugeOpen = true;
        this.gaugeQuantity = (
          (parseFloat(this.payState.pQuantity) / 100) *
          a
        ).toFixed(6);
      } else {
        this.isGaugeOpen = false;
        this.orderQuantity = 0;
      }
    },
    orderQuantity(a) {
      if (a > this.payState.pQuantity) {
        this.orderQuantity = this.payState.pQuantity;
      }
    }
  },
  mounted() {
    this.orderPrice = this.payState.pRecent;
    console.log('?')
  },
  computed: {
    changeQty() {
      const a = this.setDecimal(this.payState.pQuantity, 6);
      return a;
    }
  }
};
</script>
<style>
input::-webkit-inner-spin-button {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}
</style>
