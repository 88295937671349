<template lang="">
  <div class="fixed z-10 flex w-screen h-full bg-black bg-opacity-50">
    <div :class="tStyle.inner">
      <div :class="tStyle.point">
        <img src="../../assets/image/path1709.png" alt="" class="dark:hidden">
        <img src="../../assets/image/path1709_d.png" alt="" class="opacity-0 dark:opacity-100">
      </div>
      <div :class="tStyle.title">
        <h2 class="text-[30px]">{{ $t('mypage.usersetting.toggle.cancel')}}</h2>
        <p class="text-[20px] mt-[10px] font-light">{{ $t('withdraworder.cancel_msg')}}</p>
      </div>
      <div :class="tStyle.bt_box">
        <button :class="tStyle.cancel">{{ $t('common.cancel')}}</button>
        <button :class="tStyle.confirm">{{ $t('common.ok')}}</button>
      </div>
      
      

      <button :class="tStyle.close_bt">
        <img src="../../assets/image/group2322.png" alt="" />
      </button>
    </div>
  </div>
</template>
<script>
import { nationNumModal } from "@/store/store";
import create from "vue-zustand";

export default {
  name: "WithdrawOrder",
  data() {
    return {
      tStyle: {
        inner: 'bg-white w-[370px] h-[250px] m-auto rounded border border-[#C9C9C9] px-[40px] py-[15px] relative dark:bg-[#1c1d22] dark:border-[#606060] dark:text-[#c9c9c9]',
        title: 'text-center mt-[50px]',
        point: 'absolute left-[-1px] top-[-1px]',
        overflow_box: 'w-full h-[320px] mt-[20px] overflow-y-auto text-[13px] text-center text-[#9f9f9f]',
        order_list: 'w-[203.2px] font-normal bg-[#f7f8fa] h-[44px] border-b border-b-[#c9c9c9]',
        table_list: 'h-[44px] border-b border-b-[#e5e5e5]',
        bt_box: ' h-[35px] mt-[35px] text-center text-[15px]',
        cancel: 'w-[117px] h-[35px] bg-[#e8eff7] rounded-[3px] dark:text-[#333333] dark:bg-[#fff8e9]',
        confirm: 'w-[117px] h-[35px] bg-[#cfe4fe] rounded-[3px] ml-[10px] dark:text-[#333333] dark:bg-[#ffb500]',
        

        
        close_bt: 'absolute right-[20px] top-[18px] font-light text-[30px]',


      },
    };
  },
  setup() {
    const useNationModal = create(nationNumModal);
    const { setModalClose } = useNationModal();

    return{
      setModalClose,
    }
  },
};
</script>
<style lang=""></style>
