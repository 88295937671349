<template lang="">
  <div class="fixed z-10 flex w-screen h-full bg-black bg-opacity-50">
    <div :class="tStyle.inner">
      <div :class="tStyle.point">
        <img src="../../assets/image/path1707.png" alt="" class="dark:hidden" />
        <img
          src="../../assets/image/path1707_d.png"
          alt=""
          class="opacity-0 dark:opacity-100"
        />
      </div>
      <div :class="tStyle.title">{{ $t('layout.setting') }}</div>
      <div :class="tStyle.contents">
        <div :class="tStyle.contents_left">
          <h3 :class="tStyle.left_tit">
            {{ $t('mypage.usersetting.screen_setting') }}
          </h3>
          <div :class="tStyle.screen">
            <button @click="selectedLayout = 1">
              <div
                :class="
                  this.selectedLayout === 1 ? tStyle.selected1 : tStyle.screen1
                "
              >
                <div
                  :class="
                    this.selectedLayout === 1
                      ? tStyle.selected1_1
                      : tStyle.screen1_1
                  "
                ></div>
              </div>
            </button>
            <button @click="selectedLayout = 2">
              <div
                :class="
                  this.selectedLayout === 2 ? tStyle.selected2 : tStyle.screen2
                "
              >
                <div
                  :class="
                    this.selectedLayout === 2
                      ? tStyle.selected2_1
                      : tStyle.screen2_1
                  "
                ></div>
              </div>
            </button>
            <button @click="selectedLayout = 3">
              <div
                :class="
                  this.selectedLayout === 3 ? tStyle.selected3 : tStyle.screen3
                "
              >
                <div
                  :class="
                    this.selectedLayout === 3
                      ? tStyle.selected3_1
                      : tStyle.screen3_1
                  "
                ></div>
              </div>
            </button>
            <button @click="selectedLayout = 4">
              <div
                :class="
                  this.selectedLayout === 4 ? tStyle.selected4 : tStyle.screen4
                "
              >
                <div
                  :class="
                    this.selectedLayout === 4
                      ? tStyle.selected4_1
                      : tStyle.screen4_1
                  "
                ></div>
              </div>
            </button>
          </div>
        </div>
        <div :class="tStyle.contents_right">
          <div :class="tStyle.slide_bt_box">
            <div>{{ $t('mypage.usersetting.toggle.darkmode') }}</div>
            <div
              :class="tStyle.slide_bt_bg"
              v-if="darkMode"
              @click="darkMode = !darkMode"
            >
              <div :class="tStyle.circle"></div>
            </div>
            <div :class="tStyle.slide_bt_bg2" v-else @click="darkMode = !darkMode">
              <div :class="tStyle.circle2"></div>
            </div>
          </div>
          <div :class="tStyle.slide_bt_box">
            <div>{{ $t('mypage.usersetting.toggle.marketstatus') }}</div>
            <div
              :class="tStyle.slide_bt_bg"
              v-if="marketStatus"
              @click="marketStatus = !marketStatus"
            >
              <div :class="tStyle.circle"></div>
            </div>
            <div :class="tStyle.slide_bt_bg2" v-else @click="marketStatus = !marketStatus">
              <div :class="tStyle.circle2"></div>
            </div>
          </div>
          <div :class="tStyle.slide_bt_box">
            <div>{{ $t('mypage.usersetting.toggle.favorite') }}</div>
            <div
              :class="tStyle.slide_bt_bg"
              v-if="favorite"
              @click="favorite = !favorite"
            >
              <div :class="tStyle.circle"></div>
            </div>
            <div :class="tStyle.slide_bt_bg2" v-else @click="favorite = !favorite">
              <div :class="tStyle.circle2"></div>
            </div>
          </div>
          <div :class="tStyle.slide_bt_box">
            <div>{{ $t('mypage.usersetting.toggle.recentdeal') }}</div>
            <div
              :class="tStyle.slide_bt_bg"
              v-if="recent"
              @click="recent = false"
            >
              <div :class="tStyle.circle"></div>
            </div>
            <div :class="tStyle.slide_bt_bg2" v-else @click="recent = true">
              <div :class="tStyle.circle2"></div>
            </div>
          </div>
          <div :class="tStyle.slide_bt_box">
            <div>{{ $t('mypage.usersetting.toggle.call') }}</div>
            <div :class="tStyle.slide_bt_bg" v-if="call" @click="call = false">
              <div :class="tStyle.circle"></div>
            </div>
            <div :class="tStyle.slide_bt_bg2" v-else @click="call = true">
              <div :class="tStyle.circle2"></div>
            </div>
          </div>
          <div :class="tStyle.slide_bt_box">
            <div>{{ $t('mypage.usersetting.toggle.asset') }}</div>
            <div :class="tStyle.slide_bt_bg" v-if="asset" @click="asset = false">
              <div :class="tStyle.circle"></div>
            </div>
            <div :class="tStyle.slide_bt_bg2" v-else @click="asset = true">
              <div :class="tStyle.circle2"></div>
            </div>
          </div>
        </div>
      </div>
      <div :class="tStyle.bt_box">
        <button :class="tStyle.confirm" @click="setLayout">
          {{ $t('common.ok') }}
        </button>
      </div>

      <button :class="tStyle.close_bt">
        <img
          src="../../assets/image/group2322.png"
          alt=""
          @click="setModalControl"
        />
      </button>
    </div>
  </div>
</template>
<script>
import { modalControl, darkMode, isLogin, apiLanguage , userSetting } from '@/store/store';
import create from 'vue-zustand';
import AesEncrypt from '../Security/AesEncrypt';
import Request from '@/utils/Request';

export default {
  name: 'LayoutModal',
  data() {
    return {
      darkMode: localStorage.getItem('fubit_theme') === 'light' ? false : true,
      marketStatus: false,
      favorite: false,
      recent: false,
      call: false,
      asset: false,
      selectedLayout: 1,

      tStyle: {
        inner:
          'bg-white w-[650px] h-[425px] m-auto rounded border border-[#C9C9C9] px-[20px] py-[15px] relative dark:bg-[#1c1d22] dark:border-[#606060] dark:text-[#c9c9c9]',
        point: 'absolute left-[-1px] top-[-1px]',
        title: 'text-center text-[20px] font-regular',
        contents: 'w-full h-[252px]',
        contents_left:
          'w-[345px] mt-[32px] float-left border-r dark:border-[#e5e5e5]',
        left_tit: 'text-[15px]',
        screen: 'w-full h-full',
        selectedScreen: '',

        screen1:
          'w-[150px] h-[100px] border border-[#c9c9c9] rounded-[3px] float-left flex items-center mr-[20px] dark:bg-[#333333] dark:border-[#606060]',
        screen1_1: 'w-[30px] h-[52px] bg-[#c9c9c9] ml-[100px] rounded-[3px]',
        screen2:
          'w-[150px] h-[100px] border border-[#c9c9c9] rounded-[3px] float-left flex items-center dark:bg-[#333333] dark:border-[#606060]',
        screen2_1: 'w-[30px] h-[52px] bg-[#c9c9c9] ml-[20px] rounded-[3px]',
        screen3:
          'w-[150px] h-[100px] border border-[#c9c9c9] rounded-[3px] float-left mr-[20px] mt-[20px] relative dark:bg-[#333333] dark:border-[#606060]',
        screen3_1:
          'w-[52px] h-[30px] bg-[#c9c9c9] rounded-[3px] absolute right-[20px] bottom-[20px]',
        screen4:
          'w-[150px] h-[100px] border border-[#c9c9c9] rounded-[3px] float-left mt-[20px] relative dark:bg-[#333333] dark:border-[#606060]',
        screen4_1:
          'w-[52px] h-[30px] bg-[#c9c9c9] rounded-[3px] absolute left-[20px] bottom-[20px]',

        selected1:
          'w-[150px] h-[100px] border border-[#0e60d0] bg-[#cfe4ff] rounded-[3px] flex items-center float-left mr-[20px] dark:bg-[#fff8e8] dark:border-[#ffb500]',
        selected1_1:
          'w-[30px] h-[52px] bg-[#005cd3] ml-[100px] rounded-[3px] dark:bg-[#ffb500]',
        selected2:
          'w-[150px] h-[100px] border border-[#0e60d0] bg-[#cfe4ff] rounded-[3px] float-left flex items-center dark:bg-[#fff8e8] dark:border-[#ffb500]',
        selected2_1:
          'w-[30px] h-[52px] bg-[#005cd3] ml-[20px] rounded-[3px] dark:bg-[#ffb500]',
        selected3:
          'w-[150px] h-[100px] border border-[#0e60d0] bg-[#cfe4ff] rounded-[3px] float-left mr-[20px] mt-[20px] relative dark:bg-[#fff8e8] dark:border-[#ffb500]',
        selected3_1:
          'w-[52px] h-[30px] bg-[#005cd3] rounded-[3px] absolute right-[20px] bottom-[20px] dark:bg-[#ffb500]',
        selected4:
          'w-[150px] h-[100px] border border-[#0e60d0] bg-[#cfe4ff] rounded-[3px] float-left mt-[20px] relative dark:bg-[#fff8e8] dark:border-[#ffb500]',
        selected4_1:
          'w-[52px] h-[30px] bg-[#005cd3] rounded-[3px] absolute left-[20px] bottom-[20px] dark:bg-[#ffb500]',

        contents_right:
          'float-right w-[260px] mt-[32px] pl-[20px] text-[15px] leading-[42px]',
        slide_bt_box: 'flex items-center justify-between',
        slide_bt_bg:
          'w-[35px] h-[14px] bg-[#cfe4ff] rounded-full flex items-center px-[2px] dark:bg-[#fff8e8] cursor-pointer',
        slide_bt_bg2:
          'w-[35px] h-[14px] bg-[#c9c9c9] rounded-full flex items-center px-[2px] dark:bg-[#111119] cursor-pointer',
        circle:
          'w-[10px] h-[10px] rounded-full bg-[#005cd3] dark:bg-[#ffb500] ml-auto',
        circle2: 'w-[10px] h-[10px] rounded-full bg-white dark:bg-[#c9c9c9]',
        bt_box: 'float-right h-[35px] mt-[35px] text-center text-[15px]',
        cancel:
          'w-[117px] h-[35px] bg-[#e8eff7] rounded-[3px] dark:text-[#333333] dark:bg-[#fff8e8]',
        confirm:
          'w-[117px] h-[35px] bg-[#cfe4fe] rounded-[3px] ml-[10px] dark:text-[#333333] dark:bg-[#ffb500]',

        close_bt: 'absolute right-[20px] top-[18px] font-light text-[30px]'
      }
    };
  },
  setup() {
    const useModalControl = create(modalControl);
    const useDarkMode = create(darkMode);
    const useIsLogin = create(isLogin);
    const { setModalControl, setAlertData, setAlertOpen } = useModalControl();
    const { isDarkMode, setDarkMode, resetDarkMode } = useDarkMode();
    const { setLogout } = useIsLogin();
    const useApiLanguage = create(apiLanguage);
    const { apiLangCode } = useApiLanguage();
    const useUserSetting = create(userSetting)
    const { setOption , updating , optionData } = useUserSetting()

    return {
      setModalControl,
      isDarkMode,
      setDarkMode,
      resetDarkMode,
      setLogout,
      apiLangCode,
      setOption,
      updating,
      optionData,
      setAlertData,
      setAlertOpen
    };
  },
  created() {
    this.getOptionInfo();
  },
  methods: {
    async getOptionInfo() {
      let result = await Request(
        '/api/exs/mypage/v1/request_option',
        'post',
        this.apiLangCode[this.$i18n.locale]
      );
      
      if (!result) return;
      console.log(result)
      let { API_CODE } = result;

      if (API_CODE.status === 'L402') {
        this.sessionClosed();
      } else if (API_CODE.status === '0000') {
        let options = result.option;
        this.setOption(options)
        //레이아웃 설정
        this.selectedLayout = parseInt(options.layoutType.split('type')[1]);
        this.onelineNotice = options.winNoticeYn === 'Y' ? true : false;
        this.marketStatus = options.winMarketYn === 'Y' ? true : false;
        this.call = options.winTradeYn === 'Y' ? true : false;
        this.recent = options.winTradeRecentYn === 'Y' ? true : false;
        this.asset = options.winAssetsYn === 'Y' ? true : false;
        this.favorite = options.winFavoriteYn === 'Y' ? true : false;
      }
    },
    async setOptionInfo() {
      let optList = [
        {
          option: 'layoutType',
          layout_type: `type${this.selectedLayout}`
        },
        {
          option: 'winNoticeYn',
          onoff_yn: this.onlineNotice ? 'Y' : 'N'
        },
        {
          option: 'winMarketYn',
          onoff_yn: this.marketStatus ? 'Y' : 'N'
        },
        {
          option: 'winTradeYn',
          onoff_yn: this.call ? 'Y' : 'N'
        },
        {
          option: 'winTradeRecentYn',
          onoff_yn: this.recent ? 'Y' : 'N'
        },
        {
          option: 'winAssetsYn',
          onoff_yn: this.asset ? 'Y' : 'N'
        },
        {
          option: 'winFavoriteYn',
          onoff_yn: this.favorite ? 'Y' : 'N'
        },
      ]
      let data = JSON.stringify({
        data: AesEncrypt({
          list: optList
        })
      });
      
      let result = await Request(
        '/api/exs/mypage/v1/update_option',
        'post',
        this.apiLangCode[this.$i18n.locale],
        data
      );
      console.log(result)
      if (!result) return;

      let { status , message } = result.API_CODE;
      if (status === 'L402') {
        this.sessionClosed();
      } else if (status === '0000') {
        window.location.reload();
        return
      } else {
        this.setAlertData({
          title: '',
          desc: message
        });
        this.setAlertOpen();
      }
    },
    sessionClosed() {
      let { t } = this.$i18n;
      this.setAlertData({
              title: '',
              desc: this.$t('login.msg15')
            });
            this.setAlertOpen();
      localStorage.removeItem('fubit_token');
      localStorage.removeItem('fubit_rtoken');
      this.setLogout();
    },
    setLayout() {
      this.setOptionInfo()
      if (this.darkMode) this.setDarkMode();
      else this.resetDarkMode();
      this.setModalControl(); 

    },
    goMainPage() {
      this.$router.push({
        name: 'MainHome'
      });
    },
  }
};
</script>
<style lang=""></style>
