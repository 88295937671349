<template lang="">
  <div class="w-screen h-full bg-white dark:bg-[#111119]">
    <div class="w-40 max-sm:w-28 fold:w-24 mx-auto sm:mt-[20%] mt-[70%] text-center">
      <img src="@/assets/image/group12561.png" alt="" class="dark:hidden" />
      <img src="@/assets/image/group12561_d.png" alt="" class="opacity-0 dark:opacity-100">
      <img
        :src="require(`@/assets/image/loading_${num}.png`)"
        alt=""
        class="w-[50%] max-sm:w-[50%] mx-auto dark:hidden"
      />
      <img 
        :src="require(`@/assets/image/loading_d${num}.png`)" 
        alt=""
        class="dark:mt-10 w-[50%] mx-auto opacity-0 dark:opacity-100"
        />
    </div>
  </div>
</template>
<script>
export default {
  name: 'SplashView',
  data() {
    return {
      num: 1,
      darkNum: 1,
      tStyle: {}
    };
  },
  setup() {
    return {};
  },
  methods: {},
  created() {
    setInterval(() => {
      this.num++;
      if (this.num > 3) {
        clearInterval();
        this.num = 1;
      }
    }, 300);
  }
};
</script>
<style lang=""></style>
