<template lang="">
  <div class="fixed z-10 flex w-screen h-full bg-black bg-opacity-50" >
    <div
      class="bg-white w-full sm:w-[550px] h-full sm:h-[700px] m-auto sm:rounded sm:border border-[#C9C9C9] sm:px-[20px] sm:py-[15px] relative sm:dark:bg-[#1c1d22] dark:text-[#c9c9c9] dark:border-[#606060] max-sm:leading-[25px] fold:leading-[25px] dark:bg-[#111119]"
    >
      <div
        class="flex sm:justify-center items-center justify-between w-full px-4 max-sm:fixed fold:fixed max-sm:bg-white max-sm:dark:bg-[#111119] fold:bg-white fold:dark:bg-[#111119] py-[13px] sm:py-0"
      >
        <button class="sm:hidden shrink-0" @click="setModalControl">
          <img
            src="@/assets/image/ion_chevron_back_outline.png"
            alt=""
            class="w-[30px] dark:hidden"
          />
          <img
            src="@/assets/image/ion_chevron_back_outline_d.png"
            alt=""
            class="w-[30px] opacity-0 dark:opacity-100 mt-[-30px] dark:mt-0"
          />
        </button>
        <div
          class="sm:mt-[23.5px] text-[25px] text-center sm:text-[40px] sm:leading-[58px] sm:text-[#606060] sm:dark:text-[#fff] text-[#444] dark:text-[#e5e5e5]"
        >
          {{
            type === 'service'
              ? $t('promotion.event.terms1')
              : $t('promotion.event.terms2')
          }}
        </div>
        <div class="w-[30px] sm:hidden"></div>
      </div>
      <div class="flex flex-wrap justify-center w-full px-6 mt-20 text-center sm:mt-6">
        <span
          class="sm:text-[18px] text-[15px] cursor-pointer"
          :class="[
            type == 'service'
              ? 'font-bold dark:text-[#ffb500] text-[#005cb9]'
              : ''
          ]"
          @click="type = 'service'"
          >[{{ $t('promotion.event.terms1') }}]</span
        >
        <span
          class="sm:text-[18px] text-[15px] cursor-pointer ml-4"
          :class="[
            type !== 'service'
              ? 'font-bold dark:text-[#ffb500] text-[#005cb9]'
              : ''
          ]"
          @click="type = 'policy'"
          >[{{ $t('promotion.event.terms2') }}]</span
        >
      </div>
      <div
        class="sm:w-full sm:h-[75%] h-[80%] mt-[20px] sm:text-[15px] text-[13px] sm:mt-4 px-6"
      >
        <div
          class="w-full h-full overflow-y-scroll scrollbar-thin dark:scrollbar-thumb-[#333333] scrollbar-thumb-[#9f9f9f] scrollbar-thumb-rounded-full p-4 resize-none disabled:bg-[#fff] dark:disabled:bg-[#3b3b43]"
          disabled
          v-if="type === 'service'"
          v-html="service"
        ></div>
        <!-- <textarea
          class="w-full h-full overflow-y-scroll scrollbar-thin dark:scrollbar-thumb-[#333333] scrollbar-thumb-[#9f9f9f] scrollbar-thumb-rounded-full p-4 resize-none disabled:bg-[#fff] dark:disabled:bg-[#3b3b43]"
          disabled
          v-if="type === 'service'"
          v-html="service"
        ></textarea> -->
        <div
          class="w-full h-full overflow-y-scroll scrollbar-thin dark:scrollbar-thumb-[#333333] scrollbar-thumb-[#9f9f9f] scrollbar-thumb-rounded-full p-4 resize-none disabled:bg-[#fff] dark:disabled:bg-[#3b3b43]"
          disabled
          v-else
          v-html="policy"
        ></div>
      </div>

      <button :class="tStyle.close_bt" @click="setModalControl">
        <img src="../../assets/image/group2322.png" alt="" />
      </button>
    </div>
  </div>
</template>
<script>
import { nationNumModal, modalControl, apiLanguage } from '@/store/store';
import create from 'vue-zustand';
import Request from '../../utils/Request';

export default {
  name: 'TermPolicy',
  data() {
    return {
      type: 'service',
      service: '',
      policy: '',
      tStyle: {
        close_bt:
          'absolute right-[20px] top-[18px] font-light text-[30px] max-sm:hidden fold:hidden'
      }
    };
  },
  setup() {
    const useNationModal = create(nationNumModal);
    const useModalControl = create(modalControl);
    const { setModalClose } = useNationModal();
    const { setModalControl, alertData, setAlertData, setModalName } =
      useModalControl();
    const useApiLanguage = create(apiLanguage);
    const { apiLangCode } = useApiLanguage();

    return {
      setModalClose,
      setModalControl,
      alertData,
      setAlertData,
      setModalName,
      apiLangCode
    };
  },
  methods: {
    async termsApi() {
      const lang = this.apiLangCode[this.$i18n.locale];

      let result = await Request(
        '/api/exs/join/v1/request_current_terms',
        'post',
        lang
      );
      const { API_CODE, terms_list } = result;
      if (!result) {
        return;
      }

      if (API_CODE.status === '0000') {
        this.service = terms_list[0].body;
        this.policy = terms_list[1].body;
      }
    }
  },
  components: {
    Request
  },
  mounted() {
    this.termsApi();
  }
};
</script>
<style lang=""></style>
