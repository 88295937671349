<template lang="">
  <div
    class="fixed z-30 flex w-screen h-full sm:bg-black sm:bg-opacity-50 bg-white max-sm:dark:bg-[#111119] fold:dark:bg-[#111119] sm:overflow-y-visible overflow-y-scroll"
  >
    <div
      class="relative bg-white w-[100%] max-sm:h-[100%] fold:h-[100%] sm:w-[600px] m-auto sm:rounded sm:border border-[#C9C9C9] sm:px-[40px] sm:py-[15px] sm:dark:bg-[#1c1d22] dark:bg-[#111119] dark:border-[#606060] dark:text-[#c9c9c9] max-sm:flex max-sm:flex-col fold:flex fold:flex-col overflow-y-scroll sm:overflow-y-visible pb-40"
    >
      <div class="absolute left-[-1px] top-[-1px] max-sm:hidden fold:hidden">
        <img src="../../assets/image/path1703.png" alt="" class="dark:hidden" />
        <img
          src="../../assets/image/path1703_d.png"
          alt=""
          class="opacity-0 dark:opacity-100"
        />
      </div>
      <!-- 모바일타이틀 -->
      <div
        class="flex items-center justify-between w-full px-6 fixed bg-white py-3 sm:hidden dark:bg-[#111119]"
      >
        <button @click="setModalControl">
          <img
            src="@/assets/image/ion_chevron_back_outline.png"
            alt=""
            class="w-[30px] dark:hidden"
          />
          <img
            src="@/assets/image/ion_chevron_back_outline_d.png"
            alt=""
            class="w-[30px] opacity-0 dark:opacity-100 mt-[-30px] dark:mt-0"
          />
        </button>
        <div class="text-[25px] text-center text-[#444] dark:text-[#e5e5e5]">
          {{ $t('moneytransfer.transfer') }}
        </div>
        <div class="w-[30px] sm:hidden"></div>
      </div>

      <h1 class="text-center text-[30px] mt-[40px] max-sm:hidden fold:hidden">
        {{ $t('moneytransfer.transfer') }}
      </h1>
      <div
        class="flex sm:flex-row flex-col sm:mt-[60px] mt-[56px] px-4 sm:px-0 max-sm:dark:text-[#e5e5e5] fold:dark:text-[#e5e5e5]"
      >
        <div class="sm:basis-2/5 sm:mt-0 mt-[10px]">
          <p class="max-sm:text-[15px] fold:text-[15px]">
            {{ $t('moneytransfer.send') }}
          </p>
          <select
            name=""
            id=""
            class="w-full sm:mt-[10px] mt-1.5 border border-[#e5e5e5] sm:rounded sm:text-[20px] px-2 sm:py-1 text-[#9f9f9f] dark:bg-[#1c1d22] dark:border-[#606060] text-[13px] py-2 max-sm:dark:text-[#e5e5e5] fold:dark:text-[#e5e5e5]"
            v-model="walletType"
          >
            <option value="actual">{{ $t('moneytransfer.kind') }}</option>
            <option value="futures">{{ $t('moneytransfer.gift') }}</option>
          </select>
          <div class="flex justify-center my-4 sm:hidden">
            <img src="@/assets/image/group14147.png" alt="" />
          </div>
          <div
            class="flex justify-between text-[15px] text-[#9f9f9f] max-sm:hidden fold:hidden"
          >
            <p>{{ $t('moneytransfer.balance') }} :</p>
            <p v-if="walletType == 'actual'">
              {{ setDecimal(assetList.wallet_list[0].available, 6) }} USDT
            </p>
            <p v-else>
              {{ setDecimal(assetList.myassets.remain_price, 6) }} USDT
            </p>
          </div>
        </div>
        <div
          class="flex items-center justify-center basis-1/5 max-sm:hidden fold:hidden"
        >
          <img src="@/assets/image/group14147_2.png" alt="" class="w-[25px]" />
        </div>
        <div class="sm:basis-2/5">
          <p class="max-sm:text-[15px] fold:text-[15px]">
            {{ $t('moneytransfer.get') }}
          </p>
          <select
            name=""
            id=""
            class="w-full sm:mt-[10px] mt-1.5 border border-[#e5e5e5] sm:rounded sm:text-[20px] px-2 sm:py-1 text-[#9f9f9f] dark:bg-[#1c1d22] dark:border-[#606060] text-[13px] py-2 max-sm:dark:text-[#e5e5e5] fold:dark:text-[#e5e5e5]"
            v-model="walletType2"
          >
            <option value="futures">{{ $t('moneytransfer.gift') }}</option>
            <option value="actual">{{ $t('moneytransfer.kind') }}</option>
          </select>
          <div
            class="flex justify-between text-[15px] text-[#9f9f9f] max-sm:hidden fold:hidden"
          >
            <p>{{ $t('moneytransfer.balance') }} :</p>
            <p v-if="walletType == 'futures'">
              {{ setDecimal(assetList.wallet_list[0].available, 6) }} USDT
            </p>
            <p v-else>
              {{ setDecimal(assetList.myassets.remain_price, 6) }} USDT
            </p>
          </div>
        </div>
      </div>
      <div
        class="sm:mt-[20px] mt-10 px-4 sm:px-0 max-sm:dark:text-[#e5e5e5] fold:dark:text-[#e5e5e5]"
      >
        <p class="max-sm:text-[15px] fold:text-[15px]">
          {{ $t('moneytransfer.property') }}
        </p>
        <select
          name=""
          id=""
          class="w-full sm:mt-[10px] mt-1.5 border border-[#e5e5e5] sm:rounded sm:text-[20px] px-2 sm:py-1 text-[#9f9f9f] dark:bg-[#1c1d22] dark:border-[#606060] text-[13px] py-2 max-sm:dark:text-[#e5e5e5] fold:dark:text-[#e5e5e5]"
          v-model="selectSymbol"
        >
          <option value="usdt">USDT</option>
        </select>
      </div>
      <div
        class="sm:mt-[20px] mt-10 px-4 sm:px-0 max-sm:dark:text-[#e5e5e5] fold:dark:text-[#e5e5e5]"
      >
        <p class="max-sm:text-[15px] fold:text-[15px]">
          {{ $t('moneytransfer.quantity') }}
        </p>
        <div
          class="w-full sm:mt-[10px] mt-1.5 border border-[#e5e5e5] sm:rounded sm:text-[20px] px-2 py-1 text-[#9f9f9f] flex justify-end dark:border-[#606060] text-[13px] max-sm:dark:bg-[#1c1d22] fold:dark:bg-[#1c1d22]"
        >
          <input
            type="number"
            class="outline-none text-right dark:bg-[#1c1d22] max-sm:w-[40%] max-sm:dark:text-[#e5e5e5] fold:w-[40%] fold:dark:text-[#e5e5e5]"
            v-model="sendValue"
          />
          <p
            class="sm:text-[15px] text-[13px] text-[#9f9f9f] ml-5 max-sm:dark:text-[#e5e5e5] fold:dark:text-[#e5e5e5]"
          >
            USDT
          </p>
          <div
            class="sm:ml-10 ml-2 sm:text-[15px] text-[13px] flex items-center"
          >
            <button
              class="px-4 sm:h-[30px] bg-[#cfe4fe] text-[#606060] leading-[30px] rounded dark:bg-[#ffb500] dark:text-[#333]"
              @click="fullValue"
            >
              {{ $t('moneytransfer.all') }}
            </button>
          </div>
        </div>
        <p
          class="text-[13px] sm:text-[#9f9f9f] mt-2 sm:mt-0 text-[#005cd3] max-sm:dark:text-[#ffb500] fold:dark:text-[#ffb500] leading-[20px]"
        >
          ※ {{ $t('moneytransfer.msg') }}<br />
          ※ {{ $t('moneytransfer.msg2') }}
        </p>
      </div>

      <div
        class="max-sm:w-full fold:w-full sm:text-[22px] text-[14px] sm:mb-4 dark:text-[#333333] max-sm:fixed fold:fixed sm:z-0 z-[10] bottom-0 flex sm:mt-[20px] mt-auto p-4 sm:p-0 max-sm:bg-white max-sm:dark:bg-[#111119] fold:bg-white fold:dark:bg-[#111119]"
      >
        <button
          class="basis-1/2 sm:h-[60px] py-2 sm:py-0 border border-[#cfe4fe] rounded-[3px] sm:dark:bg-[#fff8e8] dark:border-[#fff8e8] dark:bg-[#fff]"
          @click="setModalControl"
        >
          {{ $t('common.cancel') }}
        </button>
        <button
          class="basis-1/2 sm:h-[60px] py-2 sm:py-0 bg-[#cfe4fe] rounded-[3px] sm:ml-[20px] ml-2 dark:bg-[#fdd26a]"
          @click="submitTransfer"
        >
          {{ $t('common.ok') }}
        </button>
      </div>

      <button
        class="absolute right-[20px] top-[18px] font-light text-[30px] max-sm:hidden fold:hidden"
        @click="setModalControl"
      >
        <img src="../../assets/image/group2322.png" alt="" />
      </button>
    </div>
  </div>
</template>
<script>
import {
  modalControl,
  moneyTransfer,
  refresh,
  apiLanguage
} from '@/store/store';
import { assetList } from '@/store/walletStore';
import create from 'vue-zustand';
import AesEncrypt from '@/components/Security/AesEncrypt';
import Request from '@/utils/Request';
import VueSlider from 'vue-3-slider-component';

export default {
  name: 'MoneyTransfer',
  data() {
    return {
      buttonAction: false,
      assetList2: {
        myassets: {
          remain_price: '55'
        }
      },
      walletData: {},
      assetList: {},
      sendValue: '',
      selectSymbol: 'usdt',
      sendPercent: 0,
      walletType: 'actual',
      walletType2: 'futures',
      marks: {
        1: '',
        25: '',
        50: '',
        75: '',
        100: ''
      },
      stepStyle: {
        display: 'block',
        width: '12px',
        borderRadius: '50%',
        height: '12px',
        backgroundColor: '#cccccc',
        marginTop: '-4px',
        marginLeft: '-6px'
      },
      stepActiveStyle: {
        display: 'block',
        width: '14px',
        borderRadius: '50%',
        height: '14px',
        backgroundColor: '#fc0d1b',
        marginTop: '-5px',
        marginLeft: '-5px'
      },
      processStyle: {
        backgroundColor: '#fc0d1b'
      },
      dotOptions: {
        style: { backgroundColor: '#fc0d1b' }
      },
      tooltipStyle: {
        color: '#ffffff',
        backgroundColor: '#fc0d1b',
        height: '18px',
        lineHeight: '15px',
        borderColor: '#fc0d1b'
      },
      tStyle: {}
    };
  },
  setup() {
    const useModalControl = create(modalControl);
    const useMoneyTransfer = create(moneyTransfer);
    const useAssetList = create(assetList);
    const { isFuture } = useMoneyTransfer();
    const { setModalControl, setModalName, setAlertData, setAlertOpen } =
      useModalControl();
    const { assets } = useAssetList();
    const useRefresh = create(refresh);
    const { setRefresh, setWalletReload } = useRefresh();
    const useApiLanguage = create(apiLanguage);
    const { apiLangCode } = useApiLanguage();

    return {
      setModalControl,
      isFuture,
      assets,
      setModalName,
      setAlertData,
      setAlertOpen,
      setRefresh,
      apiLangCode,
      setWalletReload
    };
  },
  watch: {
    walletType(a) {
      if (a == 'actual') {
        this.walletType2 = 'futures';
      } else if (a == 'futures') {
        this.walletType2 = 'actual';
      }
      this.sendPercent = 0;
      this.sendValue = '0.00';
    },
    walletType2(a) {
      if (a == 'actual') {
        this.walletType = 'futures';
      } else if (a == 'futures') {
        this.walletType = 'actual';
      }
      this.sendPercent = 0;
      this.sendValue = '0.00';
    },
    sendPercent(a) {
      if (this.walletType == 'actual') {
        this.sendValue = this.setDecimal(
          (parseFloat(this.assetList.wallet_list[0].available) / 100) *
            this.sendPercent,
          6
        );
      } else {
        this.sendValue = this.setDecimal(
          (parseFloat(this.assetList.myassets.remain_price) / 100) *
            this.sendPercent,
          6
        );
      }
    },
    sendValue(a, b) {
      const str = String(a);

      let pattern = /^\d*.?\d{0,6}$/;

      if (!pattern.test(a)) {
        this.sendValue = b;
      }
      if (this.walletType === 'actual') {
        if (a > parseFloat(this.assetList.wallet_list[0].available)) {
          this.sendValue = parseFloat(this.assetList.wallet_list[0].available);
        } else if (a < 0) {
          this.sendValue = 0;
        }
      } else {
        if (a > parseFloat(this.assetList.myassets.remain_price)) {
          this.sendValue = parseFloat(this.assetList.myassets.remain_price);
        } else if (a < 0) {
          this.sendValue = 0;
        }
      }
    }
  },
  created() {
    this.assetList = this.assets;

    if (this.isFuture === true) {
      this.walletType = 'futures';
      this.walletType2 = 'actual';
    }
  },
  methods: {
    valueChange() {
      if (this.walletType === 'actual') {
        if (
          this.sendValue > parseFloat(this.assetList.wallet_list[0].available)
        ) {
          this.sendValue = parseFloat(this.assetList.wallet_list[0].available);
        } else if (this.sendValue < 0) {
          this.sendValue = 0;
        }
        this.sendPercent =
          (this.sendValue /
            parseFloat(this.assetList.wallet_list[0].available)) *
          100;
      } else {
        if (this.sendValue > parseFloat(this.assetList.myassets.remain_price)) {
          this.sendValue = parseFloat(this.assetList.myassets.remain_price);
        } else if (this.sendValue < 0) {
          this.sendValue = 0;
        }
        this.sendPercent = (
          (this.sendValue / parseFloat(this.assetList.myassets.remain_price)) *
          100
        ).toFixed(2);
      }
    },
    async submitTransfer() {
      if (!this.buttonAction) {
        this.buttonAction = true;
        if (parseFloat(this.sendValue) === 0) {
          this.openAlertModal('', this.$t('deal.msg3'), 'MoneyTransfer');
          this.buttonAction = false;
          return;
        }
        if (this.wallettype !== 'futures') {
          if (this.assetList.wallet_list[0].available === this.sendValue) {
            this.openAlertModal('', this.$t('moneytransfer.msg3'), 'MoneyTransfer');
            //this.openAlertModal('123', 'abc', 'MoneyTransfer');
            //this.buttonAction = false;
          }
        }
        let { t } = this.$i18n;
        let data = JSON.stringify({
          data: AesEncrypt({
            type: this.walletType === 'actual' ? 'get' : 'put',
            symbol: this.selectSymbol,
            value: this.sendValue
          })
        });

        let result = await Request(
          '/api/exs/user/v1/request_user_assets_move',
          'post',
          this.apiLangCode[this.$i18n.locale],
          data
        );


        if (!result) {
          this.openAlertModal('', t('common.fail'));
        }

        let { status } = result.API_CODE;
        if (status === 'L402') {
          this.openAlertModal('', this.$t('login.msg15'));
          localStorage.removeItem('fubit_token');
          localStorage.removeItem('fubit_rtoken');
          this.setLogout();
          this.goMainPage();
        } else if (status === '0000') {
          if (result.AUTH !== undefined) {
            localStorage.setItem('fubit_token', result.AUTH.token);
            localStorage.setItem('fubit_rtoken', result.AUTH.rtoken);
          }
          this.openAlertModal('', this.$t('moneytransfer.msg1'));
          this.setModalControl();
          this.setWalletReload();
        } else {
          console.log(result.API_CODE.message);
          this.openAlertModal('', result.API_CODE.message);
          this.buttonAction = false;
        }
      }
    },
    openAlertModal(title, desc, popupName) {
      this.setAlertData({ title, desc, popupName });
      this.setAlertOpen();
    },
    fullValue() {
      this.sendPercent = 100;
    },
    setDecimal(num, i) {
      const number = parseFloat(num);
      const place = Math.pow(10, i);
      return (Math.floor(number * place) / place).toFixed(i);
    },
    goMainPage() {
      this.$router.push({
        name: 'MainHome'
      });
    }
  },
  components: {
    VueSlider
  },
  mounted() {}
};
</script>
<style>
input::-webkit-inner-spin-button {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}
</style>
