<template lang="">
  <div
    class="fixed z-10 flex sm:flex-row flex-col w-screen h-full sm:bg-black sm:bg-opacity-50 bg-[#f2f4f5] bg-opacity-80"
    style="z-index: 100"
  >
    <div
      class="bg-white mt-auto dark:bg-[#1c1d22] w-full sm:w-[532px] sm:h-[664px] sm:m-auto sm:rounded sm:border border-[#C9C9C9] p-4 sm:p-[40px] dark:border-[#606060]"
    >
      <div class="flex flex-col w-full h-full">
        <div
          class="flex justify-between text-[25px] leading-[25px] items-center max-sm:hidden fold:hidden"
        >
          <div class="dark:text-[#fff]">Select Area Code</div>
          <div @click="setNationNumModalClose" class="cursor-pointer">
            <img src="../../assets/image/group2322.png" alt="" />
          </div>
        </div>
        <div class="sm:hidden text-[18px] dark:text-[#e5e5e5]">
          {{ $t('common.nation_select') }}
        </div>
        <div
          class="mt-[25px] text-[22px] flex justify-end items-center max-sm:hidden fold:hidden"
        >
          <input
            type="text"
            class="w-full rounded h-[66px] border border-[#C9C9C9] px-[17px] dark:bg-[#1c1d22] dark:border-[#606060] dark:text-[#fff]"
            placeholder="Search"
            v-model="searchInput"
          />
          <div class="absolute w-[20px] h-[20px] mr-[15px]">
            <img src="../../assets/image/path440.png" alt="" />
          </div>
        </div>
        <div
          class="w-full flex justify-between pl-[122px] pt-[27px] pr-[25px] text-[20px] leading-[20px] pb-[10px] border-b text-[#C9C9C9] border-[#C9C9C9] dark:border-b-[#606060] max-sm:hidden fold:hidden"
        >
          <span>{{ $t('common.nation') }}</span>
          <span>Code</span>
        </div>
        <div class="w-full group max-sm:h-full fold:h-full">
          <div
            class="w-full sm:h-[406px] h-full overflow-y-auto sm:text-[#C9C9C9] sm:text-[20px] text-[16px] scrollbar-thin scrollbar-thumb-rounded-full scrollbar-thumb-transparent group-hover:scrollbar-thumb-[#9f9f9f] dark:group-hover:scrollbar-thumb-[#333333] relative"
          >
            <div
              :class="tStyle.nationListWrap"
              v-for="(a, i) in filterList"
              :key="i"
            >
              <div :class="tStyle.nationWrap">
                <div class="w-[20px]" v-if="etcMobile">
                  <div v-if="radioCheck == i">
                    <img
                      src="@/assets/image/radio_check.png"
                      alt=""
                      class="dark:hidden"
                    />
                    <img
                      src="@/assets/image/radio_check_d.png"
                      alt=""
                      class="hidden dark:block"
                    />
                  </div>

                  <img
                    src="@/assets/image/radio_off.png"
                    alt=""
                    class=""
                    v-else
                  />
                </div>
                <img :class="tStyle.nationImg" :src="a.img" />
                <div :class="tStyle.nationName">{{ a.name }}</div>
              </div>
              <div :class="tStyle.nationNum">+{{ a.code }}</div>
              <div
                class="absolute sm:w-[450px] sm:h-[60px] h-[50px] w-full"
                @click="choiceNation"
                :index="i"
              ></div>
            </div>
          </div>
        </div>
        <div class="sm:hidden flex text-[15px] pt-8">
          <button
            class="basis-1/2 border border-[#cfe4fe] dark:bg-white dark:border-white py-1"
            @click="setNationNumModalClose"
          >
            {{ $t('common.cancel') }}
          </button>
          <button
            class="basis-1/2 bg-[#cfe4fe] dark:bg-[#fdd26a] py-1 ml-2.5"
            @click="mobileOk"
          >
            {{ $t('common.ok') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { nationNumModal, checkMobile } from '@/store/store';
import create from 'vue-zustand';

export default {
  name: 'NationNum',
  data() {
    return {
      radioCheck: '',
      searchInput: '',
      filterList: [],
      nationCode: [
        {
          img: require('@/assets/image/Fubit_Gui-54.png'),
          name: 'USA',
          code: '1'
        },
        {
          img: require('@/assets/image/Fubit_Gui-55.png'),
          name: 'RUSIA',
          code: '7'
        },
        {
          img: require('@/assets/image/Fubit_Gui-56.png'),
          name: 'VIETNAM',
          code: '84'
        },
        {
          img: require('@/assets/image/Fubit_Gui-60.png'),
          name: 'Español',
          code: '34'
        },
        {
          img: require('@/assets/image/Fubit_Gui-59.png'),
          name: 'CHINA',
          code: '86'
        },
        {
          img: require('@/assets/image/Fubit_Gui-58.png'),
          name: 'JAPAN',
          code: '81'
        },
        {
          img: require('@/assets/image/Fubit_Gui-57.png'),
          name: 'KOREA',
          code: '82'
        }
      ],
      tStyle: {
        nationListWrap:
          'w-full sm:h-[60px] h-[50px] sm:border-b border-[#E2E2E2] dark:border-[#606060] relative items-center flex justify-between cursor-pointer',
        nationWrap: 'flex items-center',
        nationImg: 'ml-[21px] sm:w-[65px] sm:h-[60px] w-[50px] h-[45px]',
        nationName: 'sm:ml-[35px] ml-2 dark:text-[#e5e5e5]',
        nationNum: 'mr-[25px] max-sm:hidden fold:hidden'
      }
    };
  },
  setup() {
    const useNationNumModal = create(nationNumModal);
    const {
      nationNumState,
      setNationNumModalClose,
      setNationCode,
      selectNationCode
    } = useNationNumModal();
    const useMobile = create(checkMobile);
    const { etcMobile } = useMobile();

    return {
      nationNumState,
      setNationNumModalClose,
      setNationCode,
      selectNationCode,
      etcMobile
    };
  },
  methods: {
    choiceNation(e) {
      if (!this.etcMobile) {
        const i = e.target.getAttribute('index');
        this.setNationCode(this.nationCode[i].code);
        this.setNationNumModalClose();
      } else {
        const i = e.target.getAttribute('index');
        this.radioCheck = i;
      }
    },
    mobileOk() {
      this.setNationCode(this.nationCode[this.radioCheck].code);
      this.setNationNumModalClose();
    }
  },
  watch: {
    searchInput(a) {
      const setArr = [];
      for (let i = 0; i < this.nationCode.length; i++) {
        if (this.nationCode[i].name.includes(a) === true) {
          setArr.push(this.nationCode[i]);
        }
      }
      this.filterList = setArr;
    }
  },
  created() {
    this.filterList = this.nationCode;
    const arr = this.nationCode.map((d) => {
      return d.code;
    });
    this.radioCheck = arr.indexOf(this.selectNationCode);
  }
};
</script>
<style lang=""></style>
