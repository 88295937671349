<template lang="">
  <div class="fixed z-10 flex w-screen h-full bg-black bg-opacity-50">
    <div class="bg-white w-[600px] m-auto rounded border border-[#C9C9C9] px-[40px] pt-[60px] relative dark:bg-[#1c1d22] dark:border-[#606060] dark:text-[#c9c9c9]">
      <!-- point image -->
      <div class="absolute left-[-1px] top-[-1px]">
        <img src="../../assets/image/path1703.png" alt="" class="dark:hidden">
        <img src="../../assets/image/path1703_d.png" alt="" class="opacity-0 dark:opacity-100">
      </div>
      <div>
        <h2 class="text-[38px]">{{ $t('influencer_result.title') }}</h2>
      </div>
      <div class="mt-6">
        <p class="text-[18px] ml-4">{{ $t('influencer_result.account') }}</p>
        <div class="border bg-[#f7f8fa] rounded text-[16px] py-2 px-4 text-[#9f9f9f] dark:bg-[#3b3b43] dark:border-[#606060]">arare2341@gamil.com</div>
      </div>
      <div class="mt-6">
        <p class="text-[18px] ml-4">{{ $t('influencer_result.code') }} <span class="text-[#fc2a36]">*</span></p>
        <div class="border bg-[#f7f8fa] rounded text-[16px] py-2 px-4 text-[#9f9f9f] dark:bg-[#3b3b43] dark:border-[#606060]">arare9041</div>
      </div>
      <div class="mt-6">
        <p class="text-[18px] ml-4">{{ $t('influencer_result.info') }} <span class="text-[#fc2a36]">*</span></p>
        <div class="border bg-[#f7f8fa] rounded text-[16px] p-4 text-[#9f9f9f] dark:bg-[#3b3b43] dark:border-[#606060]">
          <div>{{ $t('influencer_result.platform') }} : 유튜브</div>
          <div>{{ $t('influencer_result.channel') }} : 코인유니버스</div>
          <div>{{ $t('influencer_result.member') }} : 100{{ $t('influencer_result.people') }}</div>
          <div>{{ $t('influencer.id') }}{{ $t('influencer_result.msg1') }}</div>
        </div>
      </div>
      <div class="mt-6 mb-10">
        <p class="text-[18px] ml-4">{{ $t('influencer_result.return') }}</p>
        <div class="border h-32 bg-[#f7f8fa] rounded text-[16px] py-2 px-4 text-[#9f9f9f] dark:bg-[#3b3b43] dark:border-[#606060]">{{ $t('influencer_result.msg2') }}</div>
      </div>
      
      
        <!-- button -->
        <div class="flex mb-10 dark:text-[#333]">
          <button class="basis-1/2 border border-[#cfe4ff] mr-2 rounded py-3 dark:border-[#fff8e8] dark:bg-[#fff8e8]">{{ $t('influencer_result.cancel') }}</button>
          <button class="basis-1/2 bg-[#cfe4ff] rounded py-3 dark:bg-[#ffb500]">{{ $t('influencer_result.re') }}</button>
          
        </div>

      <!-- close button -->
      <button class="absolute right-[20px] top-[18px] font-light text-[30px]">
        <img src="../../assets/image/group2322.png" alt="" />
      </button>
    </div>
  </div>
</template>
<script>
import { nationNumModal } from '@/store/store';
import create from 'vue-zustand';

export default {
  name: 'InfluencerResult',
  data() {
    return {
      tStyle: {
      }
    };
  },
  setup() {
    const useNationModal = create(nationNumModal);
    const { setModalClose } = useNationModal();

    return {
      setModalClose
    };
  }
};
</script>
<style lang="">
  
</style>
