<template lang="">
  <div
    class="fixed z-10 flex w-screen h-full bg-black bg-opacity-50 max-sm:bg-white"
  >
    <!-- 모바일header -->
    <div :class="tStyle.inner">
      <div
        class="flex items-center w-full px-4 justify-between fixed bg-white py-3 dark:bg-[#111119] sm:hidden"
      >
        <button class="" @click="setModalControl">
          <img
            src="@/assets/image/ion_chevron_back_outline.png"
            alt=""
            class="w-[30px] dark:hidden"
          />
          <img
            src="@/assets/image/ion_chevron_back_outline_d.png"
            alt=""
            class="w-[30px] mt-[-30px] dark:mt-0 dark:opacity-100 opacity-0"
          />
        </button>
        <div
          class="text-[25px] text-center text-[#444] first-line:dark:text-[#e5e5e5]"
        >
          {{ $t('common.contract_detail') }}
        </div>
        <div class="w-[30px]"></div>
      </div>
      <!-- 모바일 content -->
      <div
        v-for="data in myMatchList"
        :key="data.key"
        class="sm:hidden pt-[56px] px-4 mt-6"
      >
        <div class="w-full border-b px-4 py-6 border-[#a0a0a0] dark:border-[#9f9f9f] dark:text-[#e5e5e5]">
          <div class="flex items-center text-[15px]">
            <div class="w-[25px] h-[25px] bg-[#fff8e8] dark:bg-[#111119] rounded mr-2">
              <img src="@/assets/image/square1447.png" alt="" />
            </div>
            <div>
              {{ data.symbol.toUpperCase() }} USDT {{ $t('deal.perpetual') }}
            </div>
            <div class="ml-auto">
              <span
                >{{
                  data.destLs == 'long'
                    ? $t('deal.openlong')
                    : $t('deal.openshort')
                }}
                {{ data.type == 'open' ? '' : $t('deal.liq2') }}</span
              >
            </div>
          </div>
          <div class="text-[#a0a0a0] text-[13px] dark:text-[#9f9f9f]">{{ data.regDt }}</div>
          <div
            class="flex bg-[#f7f8fa] dark:bg-[#1c1d22] text-[13px] text-center mt-2 py-2 leading-[24px]"
          >
            <div class="basis-1/3">
              <div class="text-[#a0a0a0] dark:text-[#9f9f9f]">
                {{ $t('deal.size') }}({{ data.symbol.toUpperCase() }})
              </div>
              <div class="font-bold">{{ setDecimal(data.quantity , 6) }}</div>
            </div>
            <div class="basis-1/3">
              <div class="text-[#a0a0a0] dark:text-[#9f9f9f]">
                {{ $t('contractdetail.price') }}(USDT)
              </div>
              <div class="font-bold">{{ data.price }}</div>
            </div>
            <div class="basis-1/3">
              <div class="text-[#a0a0a0] dark:text-[#9f9f9f]">
                {{ $t('common.commission') }}(USDT)
              </div>
              <div class="font-bold">{{ data.fee == 0 ? '0' :setDecimal(data.fee , 6) }}</div>
            </div>
          </div>
        </div>
      </div>
      <div :class="tStyle.point">
        <img src="../../assets/image/path1709.png" alt="" class="dark:hidden" />
        <img
          src="../../assets/image/path1709_d.png"
          alt=""
          class="opacity-0 dark:opacity-100"
        />
      </div>
      <h1 :class="tStyle.title">{{ $t('contractdetail.list') }}</h1>
      <div :class="tStyle.overflow_box">
        <table :class="tStyle.contract_table">
          <tr :class="tStyle.table_list">
            <th :class="tStyle.order_list">{{ $t('common.contract') }}</th>
            <th :class="tStyle.order_list">{{ $t('contractdetail.time') }}</th>
            <th :class="tStyle.order_list">
              {{ $t('common.contract_amount') }}
            </th>
            <th :class="tStyle.order_list">{{ $t('contractdetail.price') }}</th>
            <th :class="tStyle.order_list">
              {{ $t('common.commission') }}(USDT)
            </th>
          </tr>
          <tr
            :class="tStyle.table_list"
            v-for="data in myMatchList"
            :key="data.key"
          >
            <td>
              {{ data.symbol.toUpperCase() }} USDT {{ $t('deal.perpetual') }}
            </td>
            <td>{{ data.regDt }}</td>
            <td>{{ setDecimal(data.quantity,6) }} {{ data.symbol.toUpperCase() }}</td>
            <td>{{ data.price }} USDT</td>
            <td>{{ data.fee == 0 ? '0' :setDecimal(data.fee , 6) }}</td>
          </tr>
        </table>
      </div>

      <button :class="tStyle.close_bt" @click="setModalControl">
        <img src="../../assets/image/group2322.png" alt="" />
      </button>
    </div>
  </div>
</template>
<script>
import { modalControl, apiLanguage } from '@/store/store';
import { orderDetail } from '@/store/positionStore';
import create from 'vue-zustand';
import axios from 'axios';
import AesEncrypt from '@/components/Security/AesEncrypt';
import AesDecrypt from '@/components/Security/AesDecrypt';

export default {
  name: 'ContractDetails',
  data() {
    return {
      myMatchList: [],
      tStyle: {
        inner:
          'bg-white sm:w-[1098px] sm:h-[430px] w-full h-full m-auto sm:rounded sm:border border-[#C9C9C9] sm:px-[40px] sm:py-[15px] relative sm:dark:bg-[#1c1d22] dark:bg-[#111119] dark:border-[#606060] dark:text-[#c9c9c9]',
        point: 'absolute left-[-1px] top-[-1px] max-sm:hidden fold:hidden',
        title: 'text-[30px] mt-[10px] max-sm:hidden fold:hidden',
        overflow_box:
          'w-full h-[320px] mt-[20px] overflow-y-auto text-[13px] text-center text-[#9f9f9f] scrollbar-thin dark:scrollbar-thumb-[#333333] scrollbar-thumb-[#9f9f9f] scrollbar-thumb-rounded-full max-sm:hidden fold:hidden',
        order_list:
          'sm:w-[203.2px] font-normal bg-[#f7f8fa] h-[44px] border-b border-b-[#c9c9c9] dark:bg-[#111119] dark:text-[#9f9f9f] dark:border-[#606060]',
        table_list:
          'h-[44px] border-b border-b-[#e5e5e5] dark:text-[#c9c9c9] dark:border-[#606060] dark:bg-[#25252c]',

        close_bt:
          'absolute right-[20px] top-[18px] font-light text-[30px] max-sm:hidden fold:hidden'
      }
    };
  },
  setup() {
    const useModalControl = create(modalControl);
    const { setModalControl, setAlertData, setAlertOpen } = useModalControl();
    const useOrderDetail = create(orderDetail);
    const { orderDetailState } = useOrderDetail();
    const useApiLanguage = create(apiLanguage);
    const { apiLangCode } = useApiLanguage();

    return {
      setModalControl,
      orderDetailState,
      apiLangCode,
      setAlertData,
      setAlertOpen
    };
  },
  mounted() {
    const localToken = 'Bearer ' + localStorage.getItem('fubit_token');
    const localRtoken = 'Bearer ' + localStorage.getItem('fubit_rtoken');

    const data = {
      order_seq: this.orderDetailState.orderSeq
    };
    const encrypted = AesEncrypt(data);
    const reqData = JSON.stringify({
      data: encrypted
    });
    var config = {
      method: 'post',
      url: `${process.env.VUE_APP_MAIN_API_URI}/api/exs/trade/v1/request_history_match`,
      headers: {
        language: this.apiLangCode[this.$i18n.locale],
        apikey: process.env.VUE_APP_MAIN_API_KEY,
        'Content-Type': 'application/json',
        Authorization: localToken,
        Validation: localRtoken
      },
      data: reqData
    };
    axios(config).then((res) => {
      const decrypted = AesDecrypt(res.data.data.toString());
      const resData = JSON.parse(decrypted);
      if (resData.API_CODE.status == 'L402') {
        this.setAlertData({
              title: '',
              desc: this.$t('login.msg15')
            });
            this.setAlertOpen();
        localStorage.removeItem('fubit_token');
        localStorage.removeItem('fubit_rtoken');
        this.setLogout();
      } else if (resData.API_CODE.status == '0000') {
        if (resData.AUTH !== undefined) {
          localStorage.setItem('fubit_token', resData.AUTH.token);
          localStorage.setItem('fubit_rtoken', resData.AUTH.rtoken);
        }
        this.myMatchList = resData.mymatch_list;
      }
    });
  },
  methods: {
    setDecimal(num, i) {
      const number = parseFloat(num);
      const place = Math.pow(10, i);
      return (Math.floor(number * place) / place).toFixed(i);
    }
  },
};
</script>
<style lang=""></style>
