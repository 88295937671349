export default {
  "common": {
    "nation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国名"])},
    "nation_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国を選択する"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索"])},
    "search2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索語を入力してください."])},
    "search3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coin"])},
    "asset_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["資産管理"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日付"])},
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今日"])},
    "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["七日"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])},
    "1month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1月"])},
    "3month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3月"])},
    "mywallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["財布"])},
    "viewwallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["概要"])},
    "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["供託金"])},
    "exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["変換"])},
    "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退会"])},
    "margin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["しょうこきん"])},
    "operate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["処置"])},
    "deposit2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["供託金"])},
    "exchange2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["変換"])},
    "withdraw2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退会"])},
    "trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["貿易"])},
    "coinsearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索コインを入金可能"])},
    "networkselection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ネットワーク"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["時間"])},
    "division": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイプ"])},
    "condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ステータス"])},
    "completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完了しました"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["除去"])},
    "progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保留中"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["価格"])},
    "commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["料金"])},
    "invest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["投資内訳"])},
    "recentorder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未決済注文"])},
    "orderhistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["資産 "])},
    "profitloss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["損益 "])},
    "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["シンボル"])},
    "leverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ればれっじ"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィルター "])},
    "invest_limits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["指値"])},
    "invest_marketprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マーケットオーダー "])},
    "invest_conditional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条件付き"])},
    "orderprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文価格"])},
    "orderquantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文数量"])},
    "contract_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["約定量"])},
    "contract_amount2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["約定数量"])},
    "contract_Average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均約定価格"])},
    "contract_Average2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均約定"])},
    "target_loss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ターゲットプライス / ストップロス"])},
    "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["方向"])},
    "indefinitely": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無期限"])},
    "crossbuy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クロス取引"])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["待機"])},
    "part_contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一部約定"])},
    "contract_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["約定履歴"])},
    "contract_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["約定完了"])},
    "trigger-clue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トリガー条件"])},
    "trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["発動条件"])},
    "trigger_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["発動価格"])},
    "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベント参加"])},
    "event_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベント詳細"])},
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["友達紹介"])},
    "usercenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help Center"])},
    "guidebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ガイドブック"])},
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お知らせ"])},
    "contact us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お問い合わせ"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイトル"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日付"])},
    "writedate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作成日"])},
    "mypage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マイページ"])},
    "usersetting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人設定"])},
    "setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定"])},
    "resetting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再設定"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メール"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["携帯電話"])},
    "authcenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証センター"])},
    "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウント活動"])},
    "phone_auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["携帯電話認証"])},
    "email_auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メール認証"])},
    "auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証"])},
    "google2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTP"])},
    "otp_auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTP 認証"])},
    "change_nickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ニックネーム変更"])},
    "uuid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UUID 入力"])},
    "retouch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修正"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認"])},
    "deauthorization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証解除"])},
    "certification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証する"])},
    "removeauthfail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証解除に失敗しました."])},
    "removeauthsuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証解除されました."])},
    "certificated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証完了"])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["却下"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ギフトウォレット残高"])},
    "30deal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["過去30日間の取引量)"])},
    "google_auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTP 認証"])},
    "notice2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お知らせ"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["継続する"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン"])},
    "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
    "whole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部"])},
    "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["シンボル"])},
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["英文"])},
    "korean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["韓国語)"])},
    "initialization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["初期化"])},
    "delet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除"])},
    "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ウォレット"])},
    "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["失敗しました."])},
    "failfetch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["データを取得できませんでした."])},
    "user_level_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General member"])},
    "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申請"])},
    "findby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索"])},
    "googlecert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google 認証"])},
    "nodata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["データがありません."])},
    "stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["株式"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次へ"])},
    "eventword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベント"])},
    "referer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リファラル"])}
  },
  "login": {
    "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新規登録"])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録情報を入力してください."])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大文字/小文字+数字を入力してください."])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["記号を入力してください ."])},
    "msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8文字以上、30文字以下で入力してください."])},
    "re": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再入力"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待コード"])},
    "msg5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待コードを入力してください."])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択"])},
    "msg6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "msg7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 利用規約及びプライバシーポリシ"])},
    "msg8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ーに同意します."])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新規登録"])},
    "msg9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレスの形式が正しくありません。"])},
    "msg10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入力されたパスワードは形式に合っていません。"])},
    "msg11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードが一致しません。"])},
    "msg12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用可能なメールアドレスです。"])},
    "msg13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを正確に入力してください。"])},
    "msg14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン後に利用可能なサービスです。"])},
    "msg15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログインセッションが期限切れになりました。"])},
    "msg16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録が完了しました。"])},
    "msg17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引開始のためにログインしてください。"])},
    "msg18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログインする"])},
    "msg19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フビットと一緒に素晴らしい1日をお過ごしください。"])},
    "msg20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証コードが正しくありません！"])},
    "msg21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証時間が過ぎました。"])},
    "msg22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会員登録が完了しました。"])},
    "msg23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード再入力"])},
    "msg24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDまたはパスワードが一致しません。"])},
    "loginword1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID 保存"])},
    "msg25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["携帯電話番号の形式が正しくありません。"])},
    "msg26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["規約及びポリシー"])}
  },
  "topmenu": {
    "procenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロモーションセンター"])},
    "mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["モード"])},
    "sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サウンド"])},
    "layout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レイアウト"])}
  },
  "footer": {
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["投資はリスクを伴います。投資に関する責任は自己責任です。"])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["価値変動による損失発生可能性などを考慮して、無理な投資は避けてください。"])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社紹介"])},
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お知らせ"])},
    "center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["投資保護センター"])},
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["よくある質問"])},
    "kakao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カカオトークで問い合わせる"])},
    "inquiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1:1で問い合わせる"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会社"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お客様サポート"])},
    "word1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서울시 강남구 테헤란로 1길 23, 4층"])},
    "word2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주식회사 푸빗"])},
    "word3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대표 김푸빗"])},
    "word4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사업자 등록번호 123-45-67890"])},
    "word5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가상 사업자 등록번호 123-45-67890"])},
    "word6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン後、Fu-bitのさまざまなサービスを利用できます。"])}
  },
  "mainhome": {
    "word": {
      "startdeal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引を始める"])},
      "populardeal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["人気取引"])},
      "bigchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大幅変動"])},
      "highprofit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最高収益率"])},
      "newcoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新規取引"])},
      "marketstatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["市場状況"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在価格（USDT）"])},
      "ratechange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["変動率（24H）"])},
      "highprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最高価格（24H）"])},
      "lowprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最低価格（24H）"])},
      "viewmore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["もっと見る"])},
      "guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["初心者ガイド"])},
      "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入金する"])},
      "trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引する"])},
      "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24時間カスタマーサポート"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["閉じる"])},
      "inspect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["点検"])}
    },
    "msg": {
      "mainbannermsg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引所を活用して"])},
      "mainbannermsg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安全かつ迅速に資産を増やしましょう。"])},
      "mainbannermsg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今すぐ登録すれば、30 USDTをプレゼントします。"])},
      "middlebannermsg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["簡単でスピーディなコイン取引所"])},
      "middlebannermsg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コイン情報から注文まで簡単でスピーディなデジタル資産取引所"])},
      "bottomtit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["他の人とは違う今すぐ始めてください！"])},
      "bottom1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fu bitが初めての場合、"])},
      "bottom1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["誰でも分かりやすく説明いたします。"])},
      "bottom2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["初めて入金すると、"])},
      "bottom2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10 USDTを追加でプレゼントします。"])},
      "bottom3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録して取引を開始すると、"])},
      "bottom3_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最大100 USDTの報酬をプレゼントします。"])},
      "bottom4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["気になることはいつでも聞いてください。"])},
      "bottom4_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24時間、会員の方のために開放しています。"])},
      "modalmsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再度表示しない"])},
      "inputtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mailを入力してください。"])}
    }
  },
  "trade": {
    "modal": {
      "margin_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["永久証拠金モード"])},
      "cross": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クロス"])},
      "isolated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分離"])},
      "cross2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クロス"])},
      "isolated2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分離"])},
      "adjust": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マージン変更"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["増加"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["減少"])},
      "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最大可能金額"])},
      "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金額"])},
      "est": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["想定強制清算価格"])},
      "profit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目標価格"])},
      "loss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["損切価格"])},
      "entryprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均開始価格"])},
      "liq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["強制清算価格"])},
      "size2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引数量"])},
      "pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["想定利益"])},
      "basis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["発動条件"])},
      "tp_sl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目標価格/損切価格"])},
      "markprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在価格"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ダウンロード"])},
      "liq2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清算する"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清算可能数量"])}
    },
    "msg": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マージン方式を選択すると、該当する仮想通貨にのみ適用されます。"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["先物ウォレットにある残高をすべて担保に設定します。"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["契約清算時、お客様のすべての資産が強制的に没収される可能性があります。"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["各契約に分離された担保資産を設定します。"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["担保率が100％に達した場合、該当する契約のみが清算されます。"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["契約が実行中の場合、マージン方式を変更することはできません。"])}
    }
  },
  "mywallet": {
    "walletview": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホーム > マイウォレット > ウォレットビュー"])},
      "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非表示"])},
      "totalasset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["総資産"])},
      "checkboxtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引未対応 / 小口資産を非表示にする"])},
      "loss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未実現損益"])},
      "possibleasset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引可能資産"])},
      "inkindasset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現物資産"])},
      "coin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coin"])},
      "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利用可能"])},
      "freez": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["凍結"])},
      "bitcoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ビットコイン"])},
      "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["インポート"])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エクスポート"])},
      "forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["先物資産"])},
      "mobilelist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["贈り物の詳細"])},
      "mobilelist2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["株式の内訳"])},
      "futurelist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["先物ウォレット履歴"])}
    },
    "depositwallet": {
      "word": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホーム > マイウォレット > 入金"])},
        "assetselection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["資産選択"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入金アドレス"])},
        "precautions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入金注意事項"])},
        "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コピー"])},
        "destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デスティネーション"])},
        "depositdetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入金履歴"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入金額"])},
        "copy2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コピー完了"])},
        "inoutlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入出金明細"])}
      },
      "msg": {
        "precautions_msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. 入金する資産とネットワークを選択し、正確か確認してください。"])},
        "precautions_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. 入金アドレスをQRコードで読み取るか、出金ウォレットに貼り付けて出金を申請してください。"])},
        "precautions_msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. ブロックチェーンネットワークが送信を確認するまで、お待ちください。"])},
        "precautions_msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. Fu bitはブロックチェーンネットワークが送信を確認した後、資産をウォレットアドレスに送信します。"])},
        "precautions_msg5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["※ アドレス以外に追加データやデスティネーションタグが必要な資産がある場合があります。"])},
        "precautions_msg6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["※ 入金情報を誤って入力して発生するすべての問題に対して、Fu bitは責任を負いません。"])},
        "address_msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "address_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入金アドレスをコピーして入力してください。"])},
        "alertmsg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["終了日は開始日よりも早くすることはできません。"])}
      }
    },
    "exchange": {
      "word": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホーム > マイウォレット > 交換する"])},
        "before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交換前"])},
        "exchange_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交換量"])},
        "after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交換後"])},
        "possible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交換可能量"])},
        "expect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交換予想量"])},
        "coinprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コイン相場"])},
        "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1回の交換限度額"])},
        "amount_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24時間交換額制限"])},
        "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交換履歴"])},
        "exchanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交換された量"])},
        "price_check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交換相場確認"])},
        "countLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["秒後更新"])},
        "countlimit2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      "msg": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交換が完了しました。"])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交換に失敗しました。"])},
        "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数量を入力してください。"])},
        "notenough": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交換可能な資産が不足しています。"])}
      }
    },
    "withdraw": {
      "word": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホーム > マイウォレット > 引き出し"])},
        "location2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホーム > マイウォレット > 引き出し > アドレス管理"])},
        "address_manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アドレス管理"])},
        "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["引き出し資産"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アドレス"])},
        "address_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アドレス"])},
        "coinsearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["引き出し可能なコインを検索"])},
        "address2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["引き出しアドレス帳"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["引き出し数量"])},
        "withdrawpossible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["引き出し可能数量"])},
        "withdrawpossible2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可能数量"])},
        "withdrawdetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["引き出し履歴"])},
        "precautions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["引き出し注意事項"])},
        "minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最小出金金額"])},
        "amount_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24時間出金限度額"])},
        "asset2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["引き出し資産"])},
        "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["引き出しアドレスを直接入力"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["直接入力"])},
        "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ネットワークを選択"])},
        "minimum2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最小引き出し額: 0.001"])},
        "24withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24時間以内の最大引き出し可能金額"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アドレス名"])},
        "withdrawaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["引き出しアドレス"])},
        "adresslist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アドレス一覧"])},
        "adressplus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アドレス追加"])},
        "noadress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録されたアドレスがありません。"])},
        "adressmsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4~5文字まで入力可能"])},
        "mylevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私の引き出しレベル"])},
        "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レベル"])},
        "levelbtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レベルアップ"])},
        "destination_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デスティネーションコードを入力してください"])},
        "addr_err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アドレスエラー"])},
        "io_check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メンテナンス"])},
        "over_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異常資産"])},
        "inquiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顧客相談"])},
        "notag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タグ未入力"])},
        "treble": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treble-rule確認"])}
      },
      "msg": {
        "withdraw_msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 出金するために選択した資産と入力された出金先アドレスが同じ資産を対象としているか必ず確認してください。"])},
        "withdraw_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 選択した出金資産は、その資産の入金が可能なアドレスでしか受け取ることができません。"])},
        "withdraw_msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 出金申請が完了した後、入金されたウォレットで履歴を確認するまで一般的に1時間程度かかります。"])},
        "withdraw_msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 出金処理はブロックチェーンネットワークなどの状況によって遅れることがあります。"])},
        "withdraw_msg5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 他の取引所ウォレットに入金する場合には、該当取引所のポリシーを必ず確認してください。"])},
        "withdraw_msg6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 出金申請が完了した後の手順は、ブロックチェーンネットワークで処理されます。"])},
        "withdraw_msg7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- ブロックチェーンの特性上、一度出金が始まると取り消すことはできません。"])},
        "withdraw_msg8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Fu bitのシステムで出金が進行されるため、出金されたアドレスは状況によって異なる場合があります。"])},
        "withdraw_msg9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 出金情報を誤って入力した場合、入金するウォレットまたは取引所のポリシーを確認しなかった場合に発生するすべての損害は、ユーザーにあり、不正または異常取引が疑われる場合は、Fu bitで出金を制限することがあります。"])},
        "withdraw_msg10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出金先アドレスを入力してください。"])},
        "withdraw_msg11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出金可能資産が不足しています。"])}
      }
    }
  },
  "invest": {
    "recentorder": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホーム > 投資履歴 > 現在の注文"])}
    },
    "orderhistory": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホーム > 投資履歴 > 注文履歴"])}
    },
    "profitloss": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホーム > 投資履歴 > 決済損益"])},
      "position_clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポジションクローズ"])},
      "position_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポジション設定"])},
      "average_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均価格"])},
      "liquidation_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清算価格"])},
      "loss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清算損益"])}
    }
  },
  "promotion": {
    "event": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホーム >プロモーションセンター > イベント参加"])},
      "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ウェルカム報酬"])},
      "bonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新規登録ボーナス"])},
      "bonus_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8月に10,000ドル以上の入金がある場合、ボーナスが支払われます。"])},
      "mision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任務遂行"])},
      "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["進行中"])},
      "acquire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["補償獲得"])},
      "acquire_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ミッションの報酬がギフトウォレットに入金されました。"])},
      "completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完了"])},
      "participate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ミッション参加"])},
      "participate_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今からミッションを始めます。"])},
      "event_compensation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベント報酬"])},
      "mision_clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ミッション完了"])},
      "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベント利用規約"])},
      "terms1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サービス利用規約"])},
      "terms2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人情報取扱方針"])},
      "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リンクをコピー"])},
      "noticedetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お知らせ詳細"])},
      "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リストに戻る"])},
      "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["閲覧数"])},
      "rewarded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["報酬金で"])},
      "rewarded2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたのウォレットに振り込まれました。"])},
      "unlimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無制限"])},
      "term1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fubitのイベントは、会員として登録したお客様を対象としています。"])},
      "term2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["異常または違法な方法でイベントに参加した場合、受賞者の選定から除外され、受賞後にもキャンセルされる場合があります。"])},
      "term3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["報酬は、イベントが進行中または終了した後、対象のプロモーションセンターで支払われます。報酬は手動で受け取る必要があります。"])},
      "term4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["友達の招待から得た報酬は、翌日00:00（UTC）に自動的に支払われます。"])},
      "term5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全ての参加者は、Fubitの利用規約に従う必要があります。Fubitは、不正な活動や、追加のボーナスを受け取るためにバッチアカウントを登録するなど、イベント中の不正行為や違法行為、有害な目的に関連するその他の活動のために参加者を失格にする権利を有します。"])},
      "term6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["報酬は先物取引にのみ使用でき、直接引き出すことはできません。"])},
      "term7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["報酬は証拠金として使用でき、取引手数料や損失の支払いにも使用できます。"])},
      "term8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["報酬は、成功した取引の結果として蓄積されたすべての収益を引き出すために使用できます。"])},
      "term9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fubitは事前通知なしにこのイベントの規則を変更する権利を有します。"])},
      "term10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fubitはこのイベントの最終的な解釈権を有します。ご質問がある場合は、カスタマーサポートにお問い合わせください。"])}
    },
    "invite": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホーム > プロモーションセンター > 友達招待"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私の招待情報"])},
      "info_msg_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["友達を招待して手数料をペイバックすることができます。"])},
      "info_msg_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待した友達の取引で発生した手数料を基準に、私と友達が分け合ってペイバックを受けます。"])},
      "info_msg_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["友達招待コードには、あなたと友達が受け取る手数料の比率が設定されています。"])},
      "info_msg_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳細は招待コード管理を押して確認してください。"])},
      "ranking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["友達招待ランキング報酬"])},
      "ranking_msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1か月間で友達を一番多く招待した人のランキングです。"])},
      "ranking_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["友達招待ランキング1位から10位までは、翌月1日0時(UTC)に報酬金がギフトウォレットに自動入金されます。"])},
      "ranking_msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ランキング報酬で支払われた報酬金は、取引に必要なマージン、手数料などに使用できますが、外部への出金はできません。"])},
      "expect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予想報酬金"])},
      "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["累計人数"])},
      "compensation2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["累計報酬金"])},
      "person2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推奨リンク"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私の招待コード"])},
      "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共有する"])},
      "invite_rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待ランキングボード"])},
      "rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["順位"])},
      "rank2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["位"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アイディー"])},
      "influencer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["インフルエンサー申請"])},
      "influencerreview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["インフルエンサー審査中"])},
      "influencerinquire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["インフルエンサー照会"])},
      "compensation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["報酬金"])},
      "invite_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["友達招待履歴"])},
      "invite_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待時間"])},
      "invite_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録日"])},
      "compensation3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今日の報酬金"])},
      "invite_friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待した友達"])},
      "reward_join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録報酬"])},
      "reward_deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入金報酬"])},
      "reward_deal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引報酬"])},
      "reward_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合計報酬"])},
      "title_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["友達招待"])},
      "info_msg_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リンクまたはコードがコピーされました。"])},
      "code_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待コード管理で設定してください。"])}
    }
  },
  "usercenter": {
    "guidebook": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホーム > Help center > ガイドブック"])},
      "location2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホーム > Help center > ガイドブック > ガイド内訳"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ガイドブックの検索"])},
      "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ガイド詳細"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ガイド情報"])},
      "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リスト表示"])},
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["画像表示"])},
      "list_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アセットを入金する方法"])},
      "nodata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録されたガイドブックはありません。"])},
      "linkcopysuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クリップボードにコピーされました。"])}
    },
    "notice": {
      "view": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホーム > Help center > お知らせ"])}
      },
      "detail": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホーム > Help center > お知らせ > お知らせ詳細"])}
      },
      "nonotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録されたお知らせがありません。"])}
    },
    "inquiry": {
      "list": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホーム > Help center > お問い合わせ > 履歴"])},
        "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["履歴"])},
        "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録"])},
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["種類"])},
        "situation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状況"])},
        "answer_wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回答待ち"])},
        "answer_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回答済み"])},
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウント"])},
        "nolist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["問い合わせ内容がありません。"])},
        "answer_wait_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回答待ちです。"])}
      },
      "reg": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホーム > Help center > お問い合わせ > 登録"])},
        "usercenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help Center"])},
        "guidebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ガイドブック"])},
        "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お知らせ"])},
        "inquiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1:1お問い合わせ"])},
        "inquirylist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お問い合わせ履歴"])},
        "inquiry_regist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["種類選択"])},
        "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お問い合わせの種類を選択してください。"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイトル"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お問い合わせ内容"])},
        "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30字以内で入力してください。"])},
        "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30字以上、500字以内で入力可能です。"])},
        "att_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイル添付"])},
        "att_file2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添付ファイル"])},
        "find_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファイル検索"])},
        "regist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録する"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お問い合わせが完了しました。"])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["失敗しました。"])},
        "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選択してください"])},
        "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回答内容"])},
        "msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["タイトルを入力してください。"])},
        "msg5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お問い合わせ内容を入力してください。"])}
      }
    }
  },
  "mypage": {
    "usersetting": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホーム > マイページ > 個人設定"])},
      "location2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホーム > マイページ > 個人設定 > 設定する"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード変更"])},
      "password2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードをお忘れの場合"])},
      "google_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google連携"])},
      "google_not_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google連携解除"])},
      "login_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログインセキュリティ設定"])},
      "recent_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近のログイン"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録"])},
      "mycommission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手数料"])},
      "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手数料割引"])},
      "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基準手数料"])},
      "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割引等級"])},
      "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手数料割引率"])},
      "discount_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引手数料は、基準手数料が適用され、手数料割引率に基づいて、取引日の翌日0時（UTC）にプレゼントウォレットに入金されます。"])},
      "screen_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引画面設定"])},
      "notification_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知設定"])},
      "sound_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サウンド設定"])},
      "setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定する"])},
      "removegooglelink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google連携を解除しますか？"])},
      "removeotp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google連携が解除されました。"])},
      "toggle": {
        "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1行ニュース"])},
        "darkmode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ダークモード"])},
        "whitemode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホワイトモード"])},
        "marketstatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["市場ステータス"])},
        "favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お気に入り"])},
        "recentdeal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近の取引"])},
        "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文帳簿"])},
        "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["資産"])},
        "e_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールログイン通知"])},
        "e_liquidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メール強制清算通知"])},
        "e_withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メール出金通知"])},
        "e_deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メール入金通知"])},
        "sms_wd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS入出金通知"])},
        "ordercomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文完了"])},
        "trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トリガー発動"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文キャンセル"])},
        "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文完了"])},
        "conclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文約定"])},
        "liquidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["強制清算"])}
      }
    },
    "authcenter": {
      "word": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホーム > マイページ > 認証センター"])},
        "accountlevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントセキュリティレベル : "])},
        "safe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安全"])},
        "normal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["普通"])},
        "danger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["危険"])},
        "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTP"])},
        "anti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィッシング防止コード"])},
        "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["変更する"])},
        "anti2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィッシング防止コード : "])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10文字以内"])}
      },
      "msg": {
        "level_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安全なアカウントのために、セキュリティ認証を追加してください。"])},
        "level_msg_m1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安全なアカウントのために"])},
        "level_msg_m2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セキュリティ認証を追加してください。"])},
        "email_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン、パスワード変更、出金時に使用される認証番号をメールで受信します。"])},
        "phone_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン、パスワード変更、出金時に使用される認証番号を携帯電話番号で受信します。"])},
        "google_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン、パスワード変更、出金時に使用される認証番号を Google OTP で受信します。"])},
        "usdt_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証を登録し、50 USDT を報酬として受け取りましょう。"])},
        "security_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引所から送信されるメッセージ、メールにコードを追加してセキュリティを強化します。"])},
        "login_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アクセスしているサイトのアドレスを確認してください。"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証番号が送信されました。"])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証番号の送信に失敗しました。"])},
        "noemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録されたメールアドレスが存在しません。"])},
        "nophone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録された携帯電話番号が存在しません。"])}
      }
    },
    "activity": {
      "word": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ホーム > マイページ > アカウント活動"])},
        "record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近のログイン記録"])},
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべてのデバイスからログアウト"])},
        "logout2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログアウト"])},
        "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接続時間"])},
        "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["機種"])},
        "browser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ブラウザ"])},
        "ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP"])},
        "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["位置"])},
        "model2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["機種/ブラウザ"])},
        "ip2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["位置/IP"])},
        "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会員退会"])},
        "withdrawfail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会員退会に失敗しました。"])},
        "withdraw2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退会する"])},
        "android": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Android"])},
        "korea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["韓国"])},
        "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会員退会後1ヶ月間同じ情報での再登録はできません。会員退会を続行しますか？"])}
      },
      "msg": {
        "logout_msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会員様のフュービットログイン履歴です。 "])},
        "logout_msg1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["直接ログインしていない記録がある場合は、"])},
        "logout_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すぐに"])},
        "logout_msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントパスワードの変更とすべてのデバイスからログアウト"])},
        "logout_msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["してください。"])},
        "withdraw_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウント情報がすぐに削除され、復元が不可能になる場合があります。"])}
      }
    }
  },
  "Influencer": {
    "application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["インフルエンサー申請"])},
    "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["インフルエンサー審査結果"])},
    "referer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リファラル管理者"])},
    "referer2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リファラル"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申請者アカウント"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["インフルエンサー紹介コード"])},
    "entercode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["紹介コードを入力"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申請情報"])},
    "enterinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申請情報を入力"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申請する"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申請をキャンセルする"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再申請"])},
    "noresult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["否決理由"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申請が完了しました。"])},
    "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申請に失敗しました。"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])}
  },
  "nickname": {
    "change_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["変更するニックネームを入力してください"])},
    "nickname_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用可能なニックネームです。"])},
    "phone_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用可能な電話番号です。"])},
    "email_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用可能なメールアドレスです。"])},
    "authemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すでに認証されたメールアドレスです。"])},
    "authphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すでに認証された電話番号です。"])},
    "authfail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証に失敗しました。"])},
    "nicknamefail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ニックネームの変更に失敗しました。"])},
    "nicknamesuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ニックネームが変更されました。"])},
    "emailtypefail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレスの形式が正しくありません。"])},
    "emptyemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証するメールアドレスを入力してください。"])},
    "emptyphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証する電話番号を入力してください。"])},
    "auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証する"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレス"])},
    "beforeauthphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["携帯電話の認証を完了してください."])},
    "beforeauthmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールの確認を進めてください."])}
  },
  "contractdetail": {
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["約定詳細履歴"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["約定時間"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["約定価格"])}
  },
  "withdraworder": {
    "cancel_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文をキャンセルしますか?"])}
  },
  "feeinfo": {
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手数料案内"])},
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レベル"])},
    "maker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メーカー手数料"])},
    "taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テイカー手数料"])}
  },
  "authgoogle": {
    "auth_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証を行う前に、まず確認してください。"])},
    "auth_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTPアプリがインストールされていますか？"])},
    "googleplay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Play"])},
    "appstore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App Store"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Googleログイン"])},
    "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTPリクエストに失敗しました。"])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP連動"])}
  },
  "googleqr": {
    "qr_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以下のQRコードをGoogle OTPアプリでスキャンしてください。"])},
    "qr_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["スキャンできない場合は、コードを直接入力してください。"])},
    "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google 認証"])}
  },
  "googlenumber": {
    "number_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTPの認証コードを入力してください。"])},
    "fail_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証コードが正しくありません！"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTP認証の設定が完了しました。"])}
  },
  "googlecomplete": {
    "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTP認証完了"])},
    "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTP認証の設定が"])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完了しました。"])}
  },
  "password": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレスを入力"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話番号を入力"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード変更"])},
    "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在のパスワード"])},
    "now_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用中のパスワードを入力してください"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいパスワード"])},
    "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを入力"])},
    "new2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード確認"])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["セキュリティ認証"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証コード再送信"])},
    "resend2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再送信"])},
    "authcheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証が完了しました。"])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードの再設定が完了しました。"])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引開始のためにログインしてください。"])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードの形式が正しくありません。"])},
    "msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいパスワードが一致しません。"])},
    "msg5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在のパスワードを確認してください。"])},
    "msg6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいパスワードを確認してください。"])},
    "msg7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを確認してください。"])}
  },
  "layout": {
    "setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レイアウト設定"])}
  },
  "email": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eメールで送信された6桁の認証コードを入力してください。"])},
    "auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["秒"])},
    "auth1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["後に利用可能"])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話番号に送信された6桁の認証コードを入力してください。"])},
    "authsuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証が完了しました。"])}
  },
  "phone": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["携帯電話に送信された6桁の認証コードを入力してください。"])},
    "authsuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証が完了しました。"])},
    "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["失敗しました。"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再送する"])},
    "resendfail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再送に失敗しました。"])}
  },
  "passwordforgot": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードをお忘れですか？"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録したメールアドレスを入力してください。"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入力した会員情報が見つかりません。"])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを再設定すると、24時間出金が制限されます。"])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワード再設定"])},
    "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいパスワードを入力してください。"])},
    "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいパスワード（文字、数字、特殊文字の組み合わせ8〜30文字）"])},
    "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新しいパスワードをもう一度入力してください。"])},
    "text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードをお忘れですか？登録した携帯電話番号を入力してください。"])},
    "text8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証番号送信"])},
    "text9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証番号の送信に失敗しました。もう一度お試しください。"])},
    "text10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録されていないユーザーです。"])},
    "text11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードをお忘れですか？登録したメールアドレスを入力してください。"])},
    "text12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録した携帯電話番号を入力してください。"])}
  },
  "orderconfirm": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文確認書"])},
    "deal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引"])},
    "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文"])},
    "market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成行注文"])},
    "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["指値注文"])},
    "c_market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条件付成行注文"])},
    "c_designation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条件付指値注文"])},
    "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目標価格"])},
    "lender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ストップロス価格"])},
    "liquidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["強制清算"])},
    "liquidation2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["強制清算価格"])},
    "trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トリガー"])}
  },
  "signup": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["会員登録"])},
    "promotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロモーションまたは推薦コード(任意)"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利用規約および個人情報保護方針"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["に同意します。"])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["既にアカウントをお持ちですか？"])},
    "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレスを入力してください。"])},
    "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["既に登録されたメールアドレスです。"])},
    "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入力した情報がメールアドレスの形式に合っていません。"])},
    "text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電話番号を入力してください。"])},
    "text8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["既に登録された携帯電話番号です。"])},
    "text9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入力した情報が電話番号の形式に合っていません。"])},
    "text10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを入力してください。"])},
    "text11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入力されたパスワードがパスワード確認と一致しません。"])},
    "text12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入力したパスワードが形式に合っていません。"])},
    "text13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["パスワードを再度入力してください。"])},
    "text14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入力したパスワード確認がパスワードと一致しません。"])},
    "text15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入力されたパスワードが形式に合っていません。"])},
    "text16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用できないコードです。"])},
    "text17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロフィール写真の変更"])},
    "text18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["既に使用されているニックネームです。"])},
    "text19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ニックネーム"])},
    "text20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["プロモーションまたは推薦コードを入力してください。"])}
  },
  "withdraw_detail": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出金申請詳細"])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ネットワーク"])},
    "commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ネットワーク手数料"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["予定受取金額"])},
    "execution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出金実行"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名前"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申請完了"])},
    "successdesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出金申請が正常に登録されました。"])},
    "authFail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証番号が一致しません。"])}
  },
  "loginconfirm": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログインが必要な項目です。"])},
    "desc2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログインしますか？"])}
  },
  "deal": {
    "closeLong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ロングを決済"])},
    "closeShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ショートを決済"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["総資産"])},
    "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["しすう"])},
    "24change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24時間変動率"])},
    "countdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["カウントダウン"])},
    "funding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["しきん"])},
    "fundingfee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ファンディング料金"])},
    "volume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取引量"])},
    "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最高価格"])},
    "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最低価格"])},
    "marketprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["マーケット"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["価格"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["累積数量"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["すべて"])},
    "trades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近の取引"])},
    "scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拡大"])},
    "scale2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["縮小"])},
    "posopen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["オープン"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クローズ"])},
    "market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成行注文"])},
    "trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条件付き注文"])},
    "cross": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クロス"])},
    "isolate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分離"])},
    "long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["long"])},
    "short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["short"])},
    "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文数量"])},
    "margin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["証拠金"])},
    "available2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["残りの資産"])},
    "pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未実現損益"])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["単位"])},
    "openlong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["買い注文"])},
    "openshort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["売り注文"])},
    "openlong2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["買い"])},
    "openshort2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["売り"])},
    "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入金する"])},
    "convert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交換する"])},
    "favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お気に入り"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["検索"])},
    "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コイン名"])},
    "lastprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在価格"])},
    "24h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["変動率"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数量"])},
    "available3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用可能"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["除去"])},
    "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポジション"])},
    "pnl2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["決済損益"])},
    "openorder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未約定注文"])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文履歴"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["資産"])},
    "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["契約"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])},
    "tpsl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["利確/損切"])},
    "pnl3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未実現収益率"])},
    "liq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["強制決済価格"])},
    "side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["方向"])},
    "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在価格"])},
    "leverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レバレッジ"])},
    "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["約定数量"])},
    "entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均約定価格"])},
    "leverage2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レバレッジ設定"])},
    "leverage3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レバレッジ率"])},
    "perpetual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無期限"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修正"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["共有"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["時間"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["種類"])},
    "allcancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全除去"])},
    "shortselling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["売り注文可能数量"])},
    "shortsale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["買い注文可能数量"])},
    "market2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成行注文"])},
    "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["指値注文"])},
    "averagemarket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平均市場価格"])},
    "fillprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["直近約定価格"])},
    "trigger2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["発動価格"])},
    "liq2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清算"])},
    "immediate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["即時清算"])},
    "conditionalmarket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条件付市場価格"])},
    "conditionallimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["条件付き指値"])},
    "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目標価格"])},
    "sl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["損切り価格"])},
    "liquidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["強制清算"])},
    "orderprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文価格"])},
    "trigger3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["発動条件"])},
    "completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["約定"])},
    "spotwallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現物ウォレット"])},
    "future": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["先物ウォレット"])},
    "freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["凍結"])},
    "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移動"])},
    "exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交換"])},
    "deposit2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入金"])},
    "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出金"])},
    "ordercondition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文条件"])},
    "trigger4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トリガー注文"])},
    "completion2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["約定履歴"])},
    "liquidation2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["強制清算履歴"])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未実現損益および自己資本利益率は市場平均価格を基準にします。"])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目標および損切り価格に達すると、設定した契約が実行されます。 設定により目標価格を設定することができます。（目標価格は市場平均価格基準）"])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数量を入力してください。"])},
    "msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["価格を入力してください。"])},
    "msg5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文可能な資産が不足しています。"])},
    "msg6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["発動価格を入力してください。"])},
    "msg7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文が登録されました。"])},
    "msg8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["該当注文が正常にキャンセルされました。"])},
    "msg9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目標価格または損切り価格を入力してください。"])},
    "msg10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["売りポジションの場合、目標価格は最近の約定価格より高くなければなりません。"])},
    "msg11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["売りポジションの場合、損切り価格は最近の約定価格より低くなければなりません。"])},
    "msg12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["買いポジションの場合、目標価格は最近の約定価格より低くなければなりません。"])},
    "msg13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["買いポジションの場合、損切り価格は最近の約定価格より高くなければなりません。"])},
    "msg14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["清算可能数量が不足しています。"])},
    "msg15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["総資産 = ウォレット残高 + マージン + 未実現損益"])},
    "msg16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ユーザーが設定した永続契約に使用された金額を示します。"])},
    "msg17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在の契約実行に使用可能なウォレット残高を示します。"])},
    "msg18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未実現損益および自己資本利益率は市場平均価格を基準にします。"])},
    "msg19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文可能な数量が不足しています。"])},
    "chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["チャート"])},
    "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文"])},
    "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送金"])},
    "order2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文状況"])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["待機中"])},
    "orderFail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文失敗"])},
    "msg20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["契約はUSDTをマージンに使用しますが、該当銘柄の資産数量で表示されます。"])},
    "msg21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["該当契約に割り当てられたマージンを示します。"])},
    "msg22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["契約が実行された平均的な買い/売り価格"])},
    "msg23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レートがプラスの場合、ロングトレーダーはショートポジションに対する資金調達手数料を支払います。レートがマイナスの場合、ショートトレーダーはロングポジションに対する資金調達手数料を支払います。ファンディング手数料は8時間ごとに清算されます。"])},
    "msg24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["市場平均価格がその価格に達した場合、契約は清算されます。"])},
    "msg25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["市場平均価格で今すぐ契約を終了します。"])},
    "msg26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在のレバレッジに対する最大ポジションサイズ"])}
  },
  "alert": {
    "orderSuccess": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文完了"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注文が完了しました。"])}
    },
    "positionOrder": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログインが必要なサービスです。"])}
    },
    "favoriteAdd": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録完了"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お気に入りに登録されました。"])}
    },
    "favoriteRemove": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録解除"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["お気に入りから解除されました。"])}
    }
  },
  "coin": {
    "btc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ビットコイン"])},
    "xrp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["リップル"])},
    "eth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イーサリアム"])},
    "sol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ソリナ"])},
    "clay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["クレイトン"])},
    "matic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ポリゴン"])},
    "avax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アバランチ"])},
    "usdt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["テザー"])},
    "doge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ドージコイン"])}
  },
  "level": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出金レベル"])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全ての資産の出金は、アカウントの認証レベルによって制限があります。"])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["アカウントの認証段階を上げると、出金制限を増やすことができます。"])},
    "lev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レベル"])},
    "standard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証基準"])},
    "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1日の出金制限"])},
    "stage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["段階"])},
    "emailor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールまたは携帯電話"])},
    "emailphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メール、携帯電話の両方"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本人確認"])},
    "idfail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本人確認失敗"])},
    "failtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["失敗理由"])},
    "levelup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["レベルアップ"])}
  },
  "invitemodal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["友達招待コード"])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待コードは5つまで設定できます。"])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["割引手数料は、翌日の午前0時（UTC）に各自のプレゼントウォレットに入金され、取引時にまーじん、手数料のみ使用できます。"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コード"])},
    "myyoupercent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなた/友達"])},
    "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理"])},
    "revise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["修正"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["削除"])},
    "plus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["追加"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["copy"])},
    "code_revise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待コード修正"])},
    "code_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待コード追加"])},
    "code2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["招待コード管理"])},
    "mypercent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたが受け取る割合"])},
    "youpercent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["友達が受け取る割合"])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["調整できる割引率は最大30％です。"])}
  },
  "influencer_result": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["インフルエンサー 審査結果"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申請者のアカウント"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["紹介コード"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申請情報"])},
    "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["却下理由"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申請をキャンセル"])},
    "re": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再申請"])}
  },
  "idcard": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本人確認をする"])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本人確認のために、"])},
    "msg1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたの顔が写った写真をアップロードしてください。"])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身分証明書の写真をアップロードしてください。"])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["画像ファイルを登録してください。"])},
    "regist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["写真登録"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証用の写真をアップロードする"])},
    "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証申請"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本人確認申請が完了しました。"])},
    "msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身分証明書の写真を撮影して申請し、管理者の承認を受けてください。"])},
    "cert_picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証用の写真"])},
    "id_picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身分証明書の写真"])},
    "before_id_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["身分証明書の写真をアップロードしてください."])},
    "before_cert_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認証用の写真をアップロードしてください."])}
  },
  "antiphishing": {
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["変更要求"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィッシング防止コードが変更されました。"])},
    "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィッシング防止コードの変更に失敗しました。"])},
    "antiphishing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィッシング防止コード"])},
    "lengthover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィッシング防止コードは10文字以内である必要があります。"])},
    "checkinput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["フィッシング防止コードを入力してください。"])}
  },
  "moneytransfer": {
    "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["資金移動"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["送金するウォレット"])},
    "kind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現物ウォレット"])},
    "gift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["贈り物ウォレット"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["残高"])},
    "get": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受け取るウォレット"])},
    "property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移動資産"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移動数量"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全体"])},
    "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["資金移動手数料は無料です。イベントや友達紹介の報酬で受け取った資産は移動できません。"])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["資金移動が完了しました。"])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["イベントや友達紹介の報酬として受け取った資産は移動できません。"])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交差ポジション保有状態で資産移動時にポジションが清算されることがあり、全体資産を移転できない場合があります。交換可能な資産を正確に入力してください"])}
  },
  "addressmanage": {
    "addsuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ウォレットアドレスが追加されました。"])},
    "addfail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ウォレットアドレスの登録に失敗しました。"])},
    "removesuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ウォレットアドレスが削除されました。"])},
    "removefail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ウォレットアドレスの削除に失敗しました。"])},
    "addname_null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["住所名を入力してください."])},
    "outputkey_null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["引き出し先アドレスを入力してください."])},
    "destination_null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["デスティネーションコードを入力してください."])}
  }
}