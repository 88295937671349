import { createStore } from 'zustand/vanilla';

//로그인 유저 정보 저장
export const mailLoginInput = createStore((set) => ({
  mailInputValue: '',
  mailPwInputValue: '',
  authType:'',
  // 구글 회원가입 상태인지
  isGoogleSign: false,
  setMailInput(data) {
    set(() => ({ mailInputValue: data }));
  },
  setMailPwInput(data) {
    set(() => ({ mailPwInputValue: data }));
  },
  setGoogleSign(){
    set(()=>({
      isGoogleSign: true
    }))
  },
  setAuthType(data){
    set(()=>({
      authType: data
    }))
  }
}));

//로그인 토큰 저장
export const loginToken = createStore((set) => ({
  loginTokenValue: '',
  setLoginToken(data) {
    set(() => ({
      loginTokenValue: data
    }));
  }
}));

//패스워드 찾기 인증 메일 저장
export const pwChangeEmail = createStore((set) => ({
  pwChangeMailInput: '',
  setPwChangeEmail(data) {
    set(() => ({
      pwChangeMailInput: data
    }));
  }
}));

//인증번호 전송 상태 저장
export const certNumSend = createStore((set) => ({
  isCertNumSend: false,
  authToken: '',
  setCertNumSend() {
    set(() => ({
      isCertNumSend: true
    }));
  },
  setAuthToken(data) {
    set(() => ({
      authToken: data
    }));
  }
}));

// 메일 인증 시 회원가입 내용 저장
export const authSignData = createStore((set) => ({
  signData: {
    type: '',
    id: '',
    pw: '',
    code: '',
    terms: ''
  },

  setAuthSignData(data) {
    set(() => ({
      signData: data
    }));
  }
}));

//메인화면 이메일 저장
export const mainEmailStore = createStore((set) => ({
  mainEmail: '',
  setMainEmail(data) {
    set(() => ({
      mainEmail: data
    }));
  }
}));
