export default {
  "common": {
    "nation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["국가"])},
    "nation_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["국가선택"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검색"])},
    "search2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검색어를 입력해 주세요."])},
    "search3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검색하기"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자산"])},
    "asset_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자산관리"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기간"])},
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오늘"])},
    "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1주"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["월"])},
    "1month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1개월"])},
    "3month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3개월"])},
    "mywallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내 지갑"])},
    "viewwallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지갑보기"])},
    "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입금하기"])},
    "exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["교환하기"])},
    "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["출금하기"])},
    "margin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["증거금"])},
    "operate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["운영"])},
    "deposit2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입금"])},
    "exchange2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["교환"])},
    "withdraw2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["출금"])},
    "trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래하기"])},
    "coinsearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입금 가능 코인 검색"])},
    "networkselection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["네트워크 선택"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시간"])},
    "division": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구분"])},
    "condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상태"])},
    "completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["완료"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소"])},
    "progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["진행중"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시세"])},
    "commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수수료"])},
    "invest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["투자내역"])},
    "recentorder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미체결 주문"])},
    "orderhistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문내역"])},
    "profitloss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마감손익"])},
    "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계약"])},
    "leverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["레버리지"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["종류"])},
    "invest_limits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지정가"])},
    "invest_marketprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시장가"])},
    "invest_conditional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조건부"])},
    "orderprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문가격"])},
    "orderquantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문수량"])},
    "contract_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["체결량"])},
    "contract_amount2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["체결수량"])},
    "contract_Average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평균체결가"])},
    "contract_Average2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평균체결"])},
    "target_loss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목표가 / 손절가"])},
    "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["방향"])},
    "indefinitely": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["무기한"])},
    "crossbuy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["교차 공매수"])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대기"])},
    "part_contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일부체결"])},
    "contract_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["체결내역"])},
    "contract_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["체결완료"])},
    "trigger-clue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발동조건"])},
    "trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발동조건"])},
    "trigger_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발동가격"])},
    "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이벤트 참여"])},
    "event_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이벤트 상세"])},
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["친구초대"])},
    "usercenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객센터"])},
    "guidebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가이드북"])},
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공지사항"])},
    "contact us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1:1문의하기"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제목"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["날짜"])},
    "writedate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["작성일"])},
    "mypage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마이페이지"])},
    "usersetting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인설정"])},
    "setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설정"])},
    "resetting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["재설정"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["휴대전화"])},
    "authcenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증센터"])},
    "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정활동"])},
    "phone_auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["휴대전화 인증"])},
    "email_auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일 인증"])},
    "auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증"])},
    "google2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구글 OTP"])},
    "otp_auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구글 OTP 인증"])},
    "change_nickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["닉네임 변경"])},
    "uuid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UUID 입력"])},
    "retouch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수정"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["확인"])},
    "deauthorization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증해제"])},
    "certification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증하기"])},
    "removeauthfail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증해제에 실패하였습니다."])},
    "removeauthsuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증해제되었습니다."])},
    "certificated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증완료"])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["반려"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선물지갑 잔액"])},
    "30deal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지난30일동안 거래량"])},
    "google_auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTP 인증"])},
    "notice2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공지사항"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계속"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인"])},
    "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
    "whole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전체"])},
    "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["심볼"])},
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["영문"])},
    "korean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["한글"])},
    "initialization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초기화"])},
    "delet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["삭제"])},
    "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지갑"])},
    "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["실패하였습니다."])},
    "failfetch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["데이터를 가져오지 못하였습니다."])},
    "user_level_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["일반회원"])},
    "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신청"])},
    "findby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로 찾기"])},
    "googlecert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구글 인증"])},
    "nodata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["데이터가 없습니다."])},
    "stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현물"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주소"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다음"])},
    "eventword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이벤트"])},
    "referer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["레퍼럴"])}
  },
  "login": {
    "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원가입"])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가입정보를 입력해 주세요."])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대/소문자 + 숫자를 입력해주세요."])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["특수문자를 입력해주세요."])},
    "msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8자리 이상, 30자리 이하로 입력해주세요."])},
    "re": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["재입력"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초대코드"])},
    "msg5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초대코드를 입력해 주세요."])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선택"])},
    "msg6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "msg7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이용약관 및 개인정보정책"])},
    "msg8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["을 확인하고, 동의합니다."])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원가입"])},
    "msg9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일 형식이 올바르지 않습니다."])},
    "msg10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입력한 비밀번호가 형식에 맞지 않습니다."])},
    "msg11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호가 일치하지 않습니다."])},
    "msg12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용가능한 이메일입니다."])},
    "msg13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호를 정확히 입력해 주세요."])},
    "msg14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인 후 이용 가능한 서비스입니다."])},
    "msg15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인 세션이 만료되었습니다."])},
    "msg16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가입이 완료되었습니다."])},
    "msg17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래시작을 위해 로그인 해주세요."])},
    "msg18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인하기"])},
    "msg19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["퓨빗과 함께 좋은 하루되세요."])},
    "msg20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증코드가 올바르지 않습니다!"])},
    "msg21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증시간이 초과하였습니다."])},
    "msg22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원가입이 완료되었습니다."])},
    "msg23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 재입력"])},
    "msg24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이디 또는 비밀번호가 일치하지 않습니다."])},
    "loginword1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이디 저장"])},
    "msg25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["휴대폰번호 형식이 올바르지 않습니다."])},
    "msg26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["약관 및 정책"])}
  },
  "topmenu": {
    "procenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["프로모션 센터"])},
    "mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모드"])},
    "sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["소리"])},
    "layout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["레이아웃"])}
  },
  "footer": {
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["투자는 위험을 수반하며, 투자에 대한 책임은 본인에게 있습니다."])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가치변동으로 인한 손실발생 가능성 등을 고려하여 무리한 투자는 지양하세요."])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회사소개"])},
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공지사항"])},
    "center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["투자보호센터"])},
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자주하는 질문"])},
    "kakao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카카오톡 문의하기"])},
    "inquiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1:1 문의하기"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회사"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객지원"])},
    "word1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서울시 강남구 테헤란로 1길 23, 4층"])},
    "word2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주식회사 푸빗"])},
    "word3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대표 김푸빗"])},
    "word4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사업자 등록번호 123-45-67890"])},
    "word5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가상 사업자 등록번호 123-45-67890"])},
    "word6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인 후 Fu-bit의 다양한 서비스를 이용하실 수 있습니다."])}
  },
  "mainhome": {
    "word": {
      "startdeal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래시작"])},
      "populardeal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인기 거래"])},
      "bigchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["큰 변화폭"])},
      "highprofit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최고 수익률"])},
      "newcoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신규 거래"])},
      "marketstatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시장현황"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재가격(USDT)"])},
      "ratechange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변동률(24H)"])},
      "highprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최고가(24H)"])},
      "lowprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최저가(24H)"])},
      "viewmore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["더보기"])},
      "guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초보자 가이드"])},
      "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입금하기"])},
      "trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래하기"])},
      "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24시간고객지원"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["닫기"])},
      "inspect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["점검"])}
    },
    "msg": {
      "mainbannermsg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래소를 활용해서"])},
      "mainbannermsg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["안전하고,빠르게자산을늘리세요."])},
      "mainbannermsg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지금 가입하시면 30 USDT를 드립니다."])},
      "middlebannermsg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["간편하고 빠른 코인거래소"])},
      "middlebannermsg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코인 정보부터 주문까지 간편하고 빠른 디지털 자산거래소"])},
      "bottomtit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["남들과는 다르게 지금바로시작하세요!"])},
      "bottom1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fu bit이 처음이라면,"])},
      "bottom1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["누구나 알기쉽게 설명해드립니다."])},
      "bottom2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["처음 입금하면"])},
      "bottom2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10 USDT를 추가로 드립니다."])},
      "bottom3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가입하고 거래를 시작하면"])},
      "bottom3_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최대 100 USDT의 보상을 드립니다."])},
      "bottom4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["궁금한 점은 언제든 물어보세요."])},
      "bottom4_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24시간 회원님을 위해 열려있습니다."])},
      "modalmsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다시보지않기"])},
      "inputtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail을 입력하세요."])}
    }
  },
  "trade": {
    "modal": {
      "margin_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["무기한 선물계약 증거금 방식"])},
      "cross": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["교차"])},
      "isolated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["격리"])},
      "cross2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["교차방식"])},
      "isolated2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["격리방식"])},
      "adjust": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["증거금 수정"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["증가"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["감소"])},
      "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최대 가능금액"])},
      "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["금액"])},
      "est": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예상 강제청산가"])},
      "profit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목표가"])},
      "loss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["손절가"])},
      "entryprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평균시작가"])},
      "liq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["강제청산가격"])},
      "size2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래수량"])},
      "pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예상수익"])},
      "basis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발동조건"])},
      "tp_sl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목표가/손절가"])},
      "markprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재가격"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다운로드"])},
      "liq2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["청산하기"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["청산가능수량"])}
    },
    "msg": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["증거금방식을 선택하면 해당 가상자산 종목에만 적용됩니다."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선물 지갑에 있는 잔액을 전부 담보로 설정합니다."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계약 청산 시 귀하의 모든 자산이 강제 몰수 될 수 있습니다."])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["각 계약마다 분리된 담보자산을 설정합니다."])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["담보율이 100%에 도달하면 해당계약만 청산 됩니다."])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계약이 실행되어 있는 경우, 증거금방식을 변경할 수 없습니다."])}
    }
  },
  "mywallet": {
    "walletview": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME > 내 지갑 > 지갑보기"])},
      "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숨기기"])},
      "totalasset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["총 자산"])},
      "checkboxtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래 미지원 / 소액 자산 숨기기"])},
      "loss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미실현 손익"])},
      "possibleasset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래가능자산"])},
      "inkindasset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현물자산"])},
      "coin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코인"])},
      "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용가능"])},
      "freez": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["동결"])},
      "bitcoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비트코인"])},
      "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가져오기"])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내보내기"])},
      "forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선물자산"])},
      "mobilelist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선물내역"])},
      "mobilelist2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현물내역"])},
      "futurelist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선물지갑내역"])}
    },
    "depositwallet": {
      "word": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME > 내 지갑 > 입금하기"])},
        "assetselection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자산선택"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입금주소"])},
        "precautions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입금 주의사항"])},
        "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["복사"])},
        "destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["데스티네이션"])},
        "depositdetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입금내역"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입금수량"])},
        "copy2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["복사완료"])},
        "inoutlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입출금내역"])}
      },
      "msg": {
        "precautions_msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. 입금 할 자산과 네트워크를 선택하고, 정확한지 확인해주세요."])},
        "precautions_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. 입금주소를 QR코드로 촬영하거나, 출금지갑에 붙여넣고, 출금을 신청하세요."])},
        "precautions_msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. 블록체인 네트워크가 전송이 확인될 때 까지 기다려주세요."])},
        "precautions_msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. Fu bit은 블록체인 네트워크가 전송을 확인한 후 자산을 지갑 주소로 전송합니다."])},
        "precautions_msg5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["※ 주소 외에 추가 데이터 또는 데스티네이션 태그가 필요한 자산이 있을 수 있습니다."])},
        "precautions_msg6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["※ 입금 정보를 잘못 입력하여 발생하는 모든 문제에 대해서 Fu bit은 책임지지 않습니다."])},
        "address_msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "address_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입금주소를 복사해 입력하세요."])},
        "alertmsg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["종료일은 시작일보다 빠를 수 없습니다."])}
      }
    },
    "exchange": {
      "word": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME > 내 지갑 > 교환하기"])},
        "before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["교환 전"])},
        "exchange_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["교환수량"])},
        "after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["교환 후"])},
        "possible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["교환가능수량"])},
        "expect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["교환 예상 수량"])},
        "coinprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코인 시세"])},
        "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1회 교환한도"])},
        "amount_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24시간금액제한"])},
        "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["교환내역"])},
        "exchanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["교환된 수량"])},
        "price_check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["교환 시세 확인"])},
        "countLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초 후 갱신"])},
        "countlimit2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      },
      "msg": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["교환되었습니다."])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["교환에 실패하였습니다."])},
        "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수량을 입력하세요."])},
        "notenough": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["교환 가능 자산이 부족합니다."])}
      }
    },
    "withdraw": {
      "word": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME > 내 지갑 > 출금하기"])},
        "location2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME > 내 지갑 > 출금하기 > 주소관리"])},
        "address_manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주소관리"])},
        "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["출금 자산"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주소관리"])},
        "address_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주소"])},
        "coinsearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["출금 가능 코인 검색"])},
        "address2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["출금주소록"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["출금수량"])},
        "withdrawpossible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["출금가능수량"])},
        "withdrawpossible2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가능수량"])},
        "withdrawdetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["출금내역"])},
        "precautions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["출금 주의사항"])},
        "minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최소 출금 금액"])},
        "amount_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24시간출금한도"])},
        "asset2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["출금자산"])},
        "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["출금주소 직접입력"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["직접입력"])},
        "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["네트워크 선택"])},
        "minimum2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최소 출금액 : 0.001"])},
        "24withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24시간 동안 최대 출금 가능 금액"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주소 이름"])},
        "withdrawaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["출금주소"])},
        "adresslist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주소내역"])},
        "adressplus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주소추가"])},
        "noadress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록된 주소가 없습니다."])},
        "adressmsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4~5자까지 입력"])},
        "mylevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내 출금레벨"])},
        "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["단계"])},
        "levelbtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["레벨올리기"])},
        "destination_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["데스티네이션 코드 입력"])},
        "addr_err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주소오류"])},
        "io_check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입출금점검"])},
        "over_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이상자산감지"])},
        "inquiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객문의"])},
        "notag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["태그미입력"])},
        "treble": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["트레블룰확인"])}
      },
      "msg": {
        "withdraw_msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 출금을 위해 선택한 자산과 입력된 출금주소가 동일한 자산을 대상으로 하는지 반드시 확인해주세요."])},
        "withdraw_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 선택한 출금 자산은 해당 자산의 입금이 가능한 주소로만 받을 수 있습니다."])},
        "withdraw_msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 출금 신청이 완료된 후 입금 받은 지갑에서 내역을 확인하기까지는 일반적으로 1시간 내외가 소요됩니다."])},
        "withdraw_msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 출금 처리는 블록체인 네트워크 등의 상황에 따라 지연이 발생할 수 있습니다."])},
        "withdraw_msg5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 다른 거래소 지갑으로 입금하는 경우에는 해당 거래소 정책을 반드시 확인해주세요."])},
        "withdraw_msg6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 출금신청이 완료되면 이후 절차는 블록체인 네트워크에서 처리됩니다."])},
        "withdraw_msg7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 블록체인의 특성 상 한번 출금이 시작되면 취소할 수 없습니다."])},
        "withdraw_msg8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Fu bit의 시스템에서 출금이 진행되기 때문에 출금된 주소는 상황따라 다를 수 있습니다."])},
        "withdraw_msg9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- 출금 정보를 잘못 입력하거나, 입금 받는 지갑 또는 거래소의 정책을 확인하지 않아 발생하는 모든 손해는 사용자에게 있고, 부정 및 이상거래가 의심되는 경우는 Fu bit에서 출금을 제한 할 수 있습니다."])},
        "withdraw_msg10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["출금주소를 입력하세요."])},
        "withdraw_msg11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["출금 가능 자산이 부족합니다."])}
      }
    }
  },
  "invest": {
    "recentorder": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME > 투자내역 > 현재주문"])}
    },
    "orderhistory": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME > 투자내역 > 주문내역"])}
    },
    "profitloss": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME > 투자내역 > 마감손익"])},
      "position_clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포지션 청산"])},
      "position_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포지션 설정"])},
      "average_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평균가격"])},
      "liquidation_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["청산가격"])},
      "loss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["청산손익"])}
    }
  },
  "promotion": {
    "event": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME > 프로모션 센터 > 이벤트 참여"])},
      "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["웰컴보상"])},
      "bonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원가입 보너스 지급"])},
      "bonus_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8월 10,000 달러이상 입금시 보너스 지급"])},
      "mision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미션수행"])},
      "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["진행중"])},
      "acquire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보상획득"])},
      "acquire_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미션 보상이 선물지갑으로 입금되었습니다."])},
      "completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미션완료"])},
      "participate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미션참여"])},
      "participate_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지금부터 미션을 시작합니다."])},
      "event_compensation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이벤트보상"])},
      "mision_clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미션 완료"])},
      "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이벤트 이용약관"])},
      "terms1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서비스 이용약관"])},
      "terms2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["개인정보 처리방침"])},
      "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["링크복사"])},
      "noticedetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공지내역"])},
      "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목록으로"])},
      "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조회수"])},
      "rewarded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보상금으로"])},
      "rewarded2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가 회원님의 지갑으로 입금되었습니다."])},
      "unlimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["무제한"])},
      "term1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fu bit의 이벤트는 회원가입한 고객을 대상으로 합니다."])},
      "term2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비정상적이거나 불법적인 방법으로 이벤트에 참여한 경우, 이벤트 운영에 방해되는 행위를 한 경우에는 당첨자 선정 대상에서 제외될 수 있으며, 당첨 후에도 취소될 수 있습니다."])},
      "term3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보상은 이벤트가 진행 중이거나 종료 후 대상 프로모션 센터에서 지급됩니다. 보상금은 수동으로 수령해야 합니다."])},
      "term4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["친구초대로 얻게 되는 보상은 다음날 00시(UTC)에 자동으로 지급됩니다."])},
      "term5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 참가자는 Fu bit이용약관을 준수해야 합니다. Fu bit은 추가 보너스를 받기 위한 일괄 계정 등록 및 불법, 불법, 유해한 목적과 관련된 기타 활동을 포함하여 행사 중에 부정 또는 위반 활동을 수행한 참가자를 실격할 권리 있습니다."])},
      "term6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보상은 선물 거래에만 사용할 수 있으며 직접 출금할 수 없습니다."])},
      "term7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보상은 증거금으로 사용할 수 있으며 거래 수수료 및 손실을 지불하는 데 사용할 수도 있습니다."])},
      "term8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보상을 사용해 성공적인 거래 결과로 누적된 모든 수익을 출금할 수 있습니다."])},
      "term9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fu bit 사전 통지 없이 본 행사의 규정을 변경할 권리가 있습니다."])},
      "term10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fu bit은 본 이벤트의 최종 해석권을 가집니다. 궁금한 점이 있으시면 고객 지원에 문의하십시오."])}
    },
    "invite": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME > 프로모션 센터 > 친구초대"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나의 초대 정보"])},
      "info_msg_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["친구를 초대하고, 수수료를 페이백 받을 수 있습니다. "])},
      "info_msg_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초대한 친구의 거래로 발생한 수수료를 기준으로 나와 친구가 나눠서 페이백 받습니다. "])},
      "info_msg_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["친구초대 코드에는 당신과 친구가 페이백 받을 수수료 비율이 설정되어 있습니다. "])},
      "info_msg_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자세한 내용은 초대코드 관리를 눌러 확인하세요. "])},
      "ranking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["친구초대 랭킹 보상"])},
      "ranking_msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1개월 간 친구를 가장 많이 초대한 사람의 랭킹입니다. "])},
      "ranking_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["친구초대 랭킹 1위~10위까지는 다음달 1일 00시(UTC)에 보상금이 선물지갑으로 자동 입금됩니다. "])},
      "ranking_msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["랭킹보상으로 지급된 보상금은 거래에 필요한 증거금, 수수료 등으로 사용할 수 있지만 외부로 출금할 수는 없습니다."])},
      "expect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예상보상금"])},
      "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["누적인원"])},
      "compensation2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["누적보상금"])},
      "person2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["명"])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추천링크"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내 초대코드"])},
      "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공유하기"])},
      "invite_rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초대 랭킹보드"])},
      "rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["순위"])},
      "rank2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["위"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이디"])},
      "influencer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인플루언서 신청"])},
      "influencerreview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인플루언서 심사중"])},
      "influencerinquire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인플루언서 조회"])},
      "compensation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보상금"])},
      "invite_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["친구 초대 내역"])},
      "invite_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초대한 시간"])},
      "invite_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가입일"])},
      "compensation3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["오늘 보상금"])},
      "invite_friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초대한 친구"])},
      "reward_join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가입보상"])},
      "reward_deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입금보상"])},
      "reward_deal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래보상"])},
      "reward_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["합계보상"])},
      "title_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["친구 초대"])},
      "info_msg_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["링크 또는 코드가 복사되었습니다."])},
      "code_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초대코드 관리에서 설정해 주세요."])}
    }
  },
  "usercenter": {
    "guidebook": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME > 고객센터 > 가이드북"])},
      "location2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME > 고객센터 > 가이드북 > 가이드내역"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가이드북 검색하기"])},
      "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가이드 상세"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가이드 정보"])},
      "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리스트 보기"])},
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미지 보기"])},
      "list_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자산 입금하는 방법"])},
      "nodata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록된 가이드북이 존재하지 않습니다."])},
      "linkcopysuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["클립보드로 복사되었습니다."])}
    },
    "notice": {
      "view": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME > 고객센터 > 공지사항"])}
      },
      "detail": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME > 고객센터 > 공지사항 > 공지내역"])}
      },
      "nonotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록된 공지사항이 없습니다."])}
    },
    "inquiry": {
      "list": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME > 고객센터 > 1:1문의하기 > 문의내역"])},
        "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문의내역"])},
        "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문의등록"])},
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문의유형"])},
        "situation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["상태"])},
        "answer_wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["답변대기"])},
        "answer_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["답변완료"])},
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정"])},
        "nolist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문의 내용이 없습니다."])},
        "answer_wait_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["답변 대기중 입니다."])}
      },
      "reg": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME > 고객센터 > 1:1문의하기 > 문의등록"])},
        "usercenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["고객센터"])},
        "guidebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가이드북"])},
        "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공지사항"])},
        "inquiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1:1문의하기"])},
        "inquirylist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문의내역"])},
        "inquiry_regist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문의등록"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["유형선택"])},
        "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문의유형을 선택해 주세요."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제목"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문의내용"])},
        "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30자 이하 작성 가능합니다."])},
        "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30자 이상, 500자 이하로 입력 가능합니다."])},
        "att_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일첨부"])},
        "att_file2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["첨부파일"])},
        "find_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["파일찾기"])},
        "regist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록하기"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["문의되었습니다."])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["실패하였습니다."])},
        "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["항목선택"])},
        "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["답변내용"])},
        "msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["제목을 입력해 주세요."])},
        "msg5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["내용을 입력해 주세요."])}
      }
    }
  },
  "mypage": {
    "usersetting": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME > 마이페이지 > 개인설정"])},
      "location2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME > 마이페이지 > 개인설정 > 설정하기"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 변경"])},
      "password2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호찾기"])},
      "google_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구글연동"])},
      "google_not_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구글연동해제"])},
      "login_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인 보안 설정"])},
      "recent_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최근 로그인"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록"])},
      "mycommission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["나의 거래 수수료"])},
      "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래 수수료 할인"])},
      "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기준 수수료"])},
      "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원등급"])},
      "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수수료 할인율"])},
      "discount_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래 시에는 기준 수수료가 적용되고, 수수료 할인율에 따라 할인 받은 금액은 거래일 다음날 0시(UTC)에 선물지갑으로 입금됩니다."])},
      "screen_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래화면 설정"])},
      "notification_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["알림 설정"])},
      "sound_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사운드 설정"])},
      "setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["설정하기"])},
      "removegooglelink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구글 연동을 해제하시겠습니까?"])},
      "removeotp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구글 연동이 해제되었습니다."])},
      "toggle": {
        "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["한줄공지"])},
        "darkmode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다크모드"])},
        "whitemode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["화이트모드"])},
        "marketstatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시장현황"])},
        "favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["즐겨찾기창"])},
        "recentdeal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최근거래창"])},
        "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["호가창"])},
        "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자산창"])},
        "e_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일 로그인 알림"])},
        "e_liquidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일 강제청산 알림"])},
        "e_withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일 출금 알림"])},
        "e_deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일 입금 알림"])},
        "sms_wd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS 입/출금 알림"])},
        "ordercomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문 제출 완료"])},
        "trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["트리거 발동"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문취소"])},
        "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문완료"])},
        "conclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문체결"])},
        "liquidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["강제청산"])}
      }
    },
    "authcenter": {
      "word": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME > 마이페이지 > 인증센터"])},
        "accountlevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정보안 등급 : "])},
        "safe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["안전"])},
        "normal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보통"])},
        "danger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["위험"])},
        "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GoogleOTP 인증"])},
        "anti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["피싱 방지코드"])},
        "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변경하기"])},
        "anti2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["피싱방지코드 : "])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10자 이내"])}
      },
      "msg": {
        "level_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["안전한 계정을 위해서 보안인증을 추가로 해주세요."])},
        "level_msg_m1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["안전한 계정을 위해서"])},
        "level_msg_m2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보안인증을 추가로 해주세요."])},
        "email_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인, 비밀변호 변경, 출금시 사용되는 인증번호를 이메일로 수신합니다."])},
        "phone_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인, 비밀변호 변경, 출금시 사용되는 인증번호를 전화번호로 수신합니다."])},
        "google_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인, 비밀변호 변경, 출금시 사용되는 인증번호를 GoogleOTP로 수신합니다."])},
        "usdt_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증을 등록하고 50 USDT를 보상으로 받으세요."])},
        "security_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래소에서 발송하는 문자, 이메일에 코드를 추가하여 보안을 강화합니다."])},
        "login_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["접속하신 사이트의 주소가 아래와 같은지 확인하세요."])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증번호가 전송되었습니다."])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증번호 전송에 실패하였습니다."])},
        "noemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록된 이메일이 존재하지 않습니다."])},
        "nophone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록된 휴대전화가 존재하지 않습니다."])}
      }
    },
    "activity": {
      "word": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME > 마이페이지 > 계정활동"])},
        "record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최근 로그인 기록"])},
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든 디바이스에서 로그아웃"])},
        "logout2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그아웃"])},
        "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["접속시간"])},
        "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기종"])},
        "browser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["브라우저"])},
        "ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아이피"])},
        "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["위치"])},
        "model2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["기종/브라우저"])},
        "ip2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["위치/IP"])},
        "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원탈퇴"])},
        "withdrawfail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원탈퇴에 실패하였습니다."])},
        "withdraw2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["탈퇴하기"])},
        "android": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["안드로이드"])},
        "korea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대한민국"])},
        "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원탈퇴 후 1개월 간 동일한 정보로 회원가입 할 수 없습니다. \n회원탈퇴를 진행하시겠습니까?"])}
      },
      "msg": {
        "logout_msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원님의 퓨빗 로그인 이력입니다. "])},
        "logout_msg1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["직접 로그인하지 않은 기록이 있다면,"])},
        "logout_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["즉시"])},
        "logout_msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정 비밀번호 변경 및 모든 디바이스에서 로그아웃"])},
        "logout_msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해주세요."])},
        "withdraw_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정정보가 즉시삭제되며, 복구가 불가능 할수있습니다."])}
      }
    }
  },
  "Influencer": {
    "application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인플루언서 신청"])},
    "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인플루언서 심사결과"])},
    "referer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["레퍼럴 관리자"])},
    "referer2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["레퍼럴"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신청자 계정"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인플루언서 추천코드"])},
    "entercode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추천코드 입력"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신청 정보"])},
    "enterinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신청정보 입력"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신청하기"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신청취소"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["재신청"])},
    "noresult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["반려사유"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신청이 완료되었습니다."])},
    "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신청 실패하였습니다."])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원아이디"])}
  },
  "nickname": {
    "change_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변경하실 닉네임을 입력해 주세요"])},
    "nickname_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용 가능한 닉네임 입니다."])},
    "phone_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용 가능한 전화번호 입니다."])},
    "email_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용 가능한 이메일 입니다."])},
    "authemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 인증된 이메일입니다."])},
    "authphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 인증된 전화번호입니다."])},
    "authfail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증 실패하였습니다."])},
    "nicknamefail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["닉네임 변경 실패하였습니다."])},
    "nicknamesuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["닉네임이 변경되었습니다."])},
    "emailtypefail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일 형식이 올바르지 않습니다."])},
    "emptyemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증할 이메일을 입력해주세요."])},
    "emptyphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증할 전화번호를 입력해주세요."])},
    "auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증하기"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일"])},
    "beforeauthphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["휴대폰 인증을 진행해 주세요."])},
    "beforeauthmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일 인증을 진행해 주세요."])}
  },
  "contractdetail": {
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["체결 세부내역"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["체결시간"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["체결가"])}
  },
  "withdraworder": {
    "cancel_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문을 취소하시겠습니까?"])}
  },
  "feeinfo": {
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수수료 안내"])},
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["레벨"])},
    "maker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["메이커 수수료"])},
    "taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["테이커 수수료"])}
  },
  "authgoogle": {
    "auth_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증 진행 전에 먼저 확인해주세요."])},
    "auth_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구글OTP APP이 설치되어 있으신가요?"])},
    "googleplay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Play"])},
    "appstore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App Store"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google 로그인"])},
    "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["구글OTP 요청에 실패하였습니다."])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OTP연동하기"])}
  },
  "googleqr": {
    "qr_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTP APP으로 아래의 QR코드를 스캔하세요."])},
    "qr_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["스캔이 불가한경우 코드를 직접 입력해주세요."])},
    "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google 인증"])}
  },
  "googlenumber": {
    "number_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTP 인증번호를 입력해 주세요."])},
    "fail_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증코드가 올바르지 않습니다!"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTP인증 설정이 완료되었습니다."])}
  },
  "googlecomplete": {
    "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTP 인증완료"])},
    "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTP 인증 설정이"])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["완료되었습니다."])}
  },
  "password": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일 입력"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전화번호 입력"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 변경"])},
    "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 비밀번호"])},
    "now_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용중인 비밀번호를 입력하세요"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 비밀번호"])},
    "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 입력"])},
    "new2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 확인"])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보안인증"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증번호 재전송"])},
    "resend2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["다시보내기"])},
    "authcheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증되었습니다."])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 재설정이 완료되었습니다."])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래 시작을 위해 로그인 해주세요."])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["패스워드 형식이 올바르지 않습니다."])},
    "msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 비밀번호와 같지 않습니다."])},
    "msg5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 비밀번호를 확인해주세요."])},
    "msg6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 비밀번호를 확인해주세요."])},
    "msg7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 확인을 해주세요."])}
  },
  "layout": {
    "setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["레이아웃 설정"])}
  },
  "email": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일로 전송된 6자리 인증코드를 입력해주세요."])},
    "auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초"])},
    "auth1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["후 사용가능"])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전화번호로 전송된 6자리 인증코드를 입력해주세요."])},
    "authsuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증되었습니다."])}
  },
  "phone": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["휴대전화로 전송된 6자리 인증코드를 입력해주세요."])},
    "authsuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증되었습니다."])},
    "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["실패하였습니다."])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["재전송"])},
    "resendfail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["재전송 실패하였습니다."])}
  },
  "passwordforgot": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호를 잊으셨나요?"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가입하신 이메일을 입력해주세요."])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입력한 회원정보를 찾을 수 없습니다."])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호를 재설정하면 24시간 동안 출금이 제한됩니다."])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호 재설정"])},
    "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새로운 비밀번호를 입력해주세요."])},
    "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 비밀번호 (문자, 숫자, 특수문자 조합 8자리~30자리)"])},
    "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["새 비밀번호를 다시 한번 입력해주세요."])},
    "text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호를 잊으셨나요? 가입하신 휴대전화를 입력해 주세요."])},
    "text8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증번호 전송"])},
    "text9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증번호 발송에 실패하였습니다. 다시 시도해 주세요."])},
    "text10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가입되지 않은 사용자입니다."])},
    "text11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호를 잊으셨나요? 가입하신 이메일을 입력해 주세요."])},
    "text12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가입하신 휴대전화를 입력해주세요."])}
  },
  "orderconfirm": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문확인서"])},
    "deal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래"])},
    "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문"])},
    "market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시장가"])},
    "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지정가"])},
    "c_market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조건부시장가"])},
    "c_designation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조건부지정가"])},
    "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목표가"])},
    "lender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["손절가"])},
    "liquidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["강제청산"])},
    "liquidation2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["강제청산가"])},
    "trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["트리거"])}
  },
  "signup": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["회원가입"])},
    "promotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["프로모션 또는 추천코드(선택)"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이용약관 및 개인정보정책"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["을 확인하고, 동의합니다."])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 계정이 있으신가요?"])},
    "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일을 입력해주세요."])},
    "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 가입된 이메일입니다."])},
    "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입력한 정보가 이메일 형식에 맞지 않습니다."])},
    "text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전화번호를 입력해주세요."])},
    "text8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 가입된 휴대전화입니다."])},
    "text9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입력한 정보가 전화번호 형식에 맞지 않습니다."])},
    "text10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호를 입력해주세요."])},
    "text11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입력한 비밀번호가 비밀번호 확인과 다릅니다."])},
    "text12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입력한 비밀번호가 형식에 맞지 않습니다."])},
    "text13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비밀번호를 다시 입력해주세요."])},
    "text14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입력한 비밀번호 확인이 비밀번호와 다릅니다."])},
    "text15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입력한 비밀번호가 형식에 맞지 않습니다."])},
    "text16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용할 수 없는 코드입니다."])},
    "text17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["프로필사진 변경"])},
    "text18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미 사용 중인 닉네임입니다."])},
    "text19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["닉네임"])},
    "text20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["프로모션 또는 추천코드를 입력해주세요."])}
  },
  "withdraw_detail": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["출금 신청 상세정보"])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["네트워크"])},
    "commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["네트워크 수수료"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["예상 수령금액"])},
    "execution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["출금실행"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이름"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신청완료"])},
    "successdesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["출금 신청이 정상적으로 등록되었습니다."])},
    "authFail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증번호가 일치하지 않습니다."])},
    "getQty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["받는수량"])}
  },
  "loginconfirm": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인이 필요한 항목입니다."])},
    "desc2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인 하시겠습니까?"])}
  },
  "deal": {
    "closeLong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공매수 청산"])},
    "closeShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공매도 청산"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["총 자산"])},
    "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인덱스가격"])},
    "24change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24H변동률"])},
    "countdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["카운트다운"])},
    "funding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["펀딩비율"])},
    "fundingfee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["펀딩피"])},
    "valume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["거래량"])},
    "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최고가"])},
    "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최저가"])},
    "marketprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시장평균가"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가격"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["누적수량"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전체"])},
    "trades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최근거래"])},
    "scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["확대"])},
    "scale2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["축소"])},
    "posopen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포지션 오픈"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포지션 청산"])},
    "market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시장가 주문"])},
    "trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조건부 주문"])},
    "cross": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["교차"])},
    "isolate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["격리"])},
    "long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["롱"])},
    "short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["숏"])},
    "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문수량"])},
    "margin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["증거금"])},
    "balange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["총자산"])},
    "available2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["남은자산"])},
    "pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미실현 손익"])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["단위"])},
    "openlong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공매수"])},
    "openshort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공매도"])},
    "openlong2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매수"])},
    "openshort2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["매도"])},
    "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입금하기"])},
    "convert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["교환하기"])},
    "favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["즐겨찾기"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["검색"])},
    "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코인명"])},
    "lastprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재가"])},
    "24h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등락률"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수량"])},
    "available3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용가능"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["취소"])},
    "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["포지션"])},
    "pnl2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["마감손익"])},
    "openorder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미체결주문"])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문내역"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자산"])},
    "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계약"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["운영"])},
    "tpsl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목표가/손절가"])},
    "pnl3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미실현 수익율"])},
    "liq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["강제청산가"])},
    "side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["방향"])},
    "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재가격"])},
    "leverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["레버리지"])},
    "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["체결량"])},
    "entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["평균체결가"])},
    "leverage2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["레버리지 설정"])},
    "leverage3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["레버리지 비율"])},
    "perpetual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["무기한"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수정"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공유"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시간"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["종류"])},
    "allcancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전체취소"])},
    "shortselling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["청산 가능 공매수 수량"])},
    "shortsale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["청산 가능 공매도 수량"])},
    "market2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시장가"])},
    "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지정가"])},
    "averagemarket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시장평균가"])},
    "fillprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["최근체결가"])},
    "trigger2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발동가"])},
    "liq2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["청산"])},
    "immediate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["즉시청산"])},
    "conditionalmarket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조건부시장가"])},
    "conditionallimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조건부지정가"])},
    "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목표가"])},
    "sl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["손절가"])},
    "liquidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["강제청산"])},
    "orderprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문가격"])},
    "trigger3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발동조건"])},
    "completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["체결"])},
    "spotwallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현물지갑"])},
    "future": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선물지갑"])},
    "freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["동결"])},
    "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이동"])},
    "exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["교환"])},
    "deposit2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["입금"])},
    "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["출금"])},
    "ordercondition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문조건"])},
    "trigger4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["트리거주문"])},
    "completion2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["체결내역"])},
    "liquidation2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["강제청산내역"])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미실현 손익 및 자기자본이익률은 시장평균가를 기준으로 합니다."])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목표가격과 손절가격에 도달하면 설정한 계약이 실행 됩니다. 상세설정을 통해 목표가격을 설정할 수 있습니다.(목표가격은 시장 평균가를 기준으로 합니다.)"])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수량을 입력해 주세요."])},
    "msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가격을 입력해 주세요."])},
    "msg5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문가능한 자산이 부족합니다."])},
    "msg6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["발동가격을 입력해 주세요."])},
    "msg7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문이 등록되었습니다."])},
    "msg8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 주문이 정상적으로 취소되었습니다."])},
    "msg9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["목표가 또는 손절가를 입력해 주세요."])},
    "msg10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공매수 포지션의 경우 목표가는 최근체결가 보다 높아야합니다."])},
    "msg11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공매수 포지션의 경우 손절가는 최근체결가 보다 낮아야합니다."])},
    "msg12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공매도 포지션의 경우 목표가는 최근체결가 보다 낮아야합니다."])},
    "msg13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["공매도 포지션의 경우 손절가는 최근체결가 보다 높아야합니다."])},
    "msg14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["청산 가능 수량이 부족합니다."])},
    "msg15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["총 자산 = 지갑잔액 + 증거금 + 미실현손익"])},
    "msg16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사용자가 설정한 무기한선물계약에 사용 된 금액을 나타냅니다."])},
    "msg17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 계약실행에 사용 가능한 지갑잔액을 나타냅니다."])},
    "msg18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["미실현 손익 및 자기자본이익률은 시장평균가를 기준으로 합니다."])},
    "msg19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문가능한 수량이 부족합니다."])},
    "chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["차트"])},
    "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문"])},
    "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이체"])},
    "order2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문상태"])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대기"])},
    "orderFail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문실패"])},
    "msg20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계약은 USDT를 증거금으로 사용하지만, 해당 종목의 자산수량으로 표시됩니다."])},
    "msg21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["해당 계약에 할당 된 증거금을 나타냅니다."])},
    "msg22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계약이 실행된 평균 매입/매도 가격"])},
    "msg23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비율이 플러스면 롱 트레이더는 숏 포지션에 대한 자금 조달 수수료를 지불합니다. 비율이 마이너스면 숏 트레이더는 롱 포지션에 대한 자금 조달 수수료를 지불합니다. 펀딩 수수료는 8시간마다 정산됩니다."])},
    "msg24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시장평균가가 해당 가격에 도달할 경우 계약이 청산 됩니다."])},
    "msg25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["시장평균가로 지금 즉시 계약을 종료합니다."])},
    "msg26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현재 레버리지에서 포지션 최대금액"])}
  },
  "alert": {
    "orderSuccess": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문 성공"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주문이 완료되었습니다."])}
    },
    "positionOrder": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["로그인이 필요한 서비스입니다."])}
    },
    "favoriteAdd": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록 성공"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["즐겨찾기로 등록되었습니다."])}
    },
    "favoriteRemove": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["등록 해제"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["즐겨찾기가 해제되었습니다."])}
    }
  },
  "coin": {
    "btc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["비트코인"])},
    "xrp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["리플"])},
    "eth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이더리움"])},
    "sol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["솔리나"])},
    "clay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["클레이튼"])},
    "matic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["폴리곤"])},
    "avax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아발란체"])},
    "usdt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["테더"])},
    "doge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["도지코인"])},
    "arb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["아비트럼"])},
    "sei": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["세이"])}
  },
  "level": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["출금레벨"])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["모든자산의 출금시 계정 인증 레벨에 따라 출금한도에 제한이 있습니다."])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["계정의 인증 단계를 올리면 출금한도를 늘릴 수 있습니다."])},
    "lev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["레벨"])},
    "standard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증기준"])},
    "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1일 출금한도"])},
    "stage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["단계"])},
    "emailor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일 또는 휴대전화"])},
    "emailphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이메일, 휴대전화 모두"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신분증 인증"])},
    "idfail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신분증 인증실패"])},
    "failtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["실패이유"])},
    "levelup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["레벨올리기"])}
  },
  "invitemodal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["친구 초대코드"])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초대코드는 5개까지 설정할 수 있습니다."])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["할인된 수수료는 다음날 00시(UTC)에 각자의 선물지갑으로 입금되고, 거래시 증거금, 수수료로만 사용할 수 있습니다."])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["코드"])},
    "myyoupercent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["당신 / 친구비율"])},
    "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["관리"])},
    "revise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["수정"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["삭제"])},
    "plus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["추가"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["복사"])},
    "code_revise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초대코드 수정"])},
    "code_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초대코드 추가"])},
    "code2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["초대코드 관리"])},
    "mypercent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["당신이 받는 비율"])},
    "youpercent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["친구가 받는 비율"])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["조정할 수 있는 할인률은 최대 30%입니다."])}
  },
  "influencer_result": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인플루언서 심사결과"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신청자 계정"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["레퍼럴 추천코드"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신청정보"])},
    "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["반려사유"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신청취소"])},
    "re": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["재신청"])}
  },
  "idcard": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신분증 인증하기"])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신분증과 함께"])},
    "msg1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["당신의 얼굴을 확인할 수 있는 사진을 올려주세요"])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신분증 사진 올리기"])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이미지 파일을 등록해주세요."])},
    "regist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사진등록"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증 사진 올리기"])},
    "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증신청"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신분증 인증 신청되었습니다."])},
    "msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신분증 사진을 촬영해 신청 후 관리자의 승인을 받으세요."])},
    "cert_picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증사진"])},
    "id_picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신분증사진"])},
    "before_id_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["신분증 사진을 등록해 주세요."])},
    "before_cert_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["인증 사진을 등록해 주세요."])}
  },
  "antiphishing": {
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["변경요청"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["피싱방지코드가 변경되었습니다."])},
    "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["피싱방지코드 변경에 실패하였습니다."])},
    "antiphishing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["피싱방지코드"])},
    "lengthover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["피싱방지코드는 10자 이내여야합니다."])},
    "checkinput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["피싱방지코드를 입력해주세요."])}
  },
  "moneytransfer": {
    "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자금이체"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["보내는 지갑"])},
    "kind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["현물지갑"])},
    "gift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["선물지갑"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["잔액"])},
    "get": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["받는지갑"])},
    "property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이체 자산"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이체 수량"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["전체"])},
    "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["자금이체 수수료는 무료입니다."])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이체가 완료되었습니다."])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["이벤트, 친구초대 보상으로 받은 자산은 이체할 수 없습니다."])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["교차포지션 보유상태에서 자산 이동 시 포지션이 청산 될 수 있으며, 전체자산을 옮기지 못할 수 있습니다. 교환가능한 자산을 정확히 입력해주세요"])}
  },
  "addressmanage": {
    "addsuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지갑주소가 추가되었습니다."])},
    "addfail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지갑주소 등록에 실패했습니다."])},
    "removesuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지갑주소가 삭제되었습니다."])},
    "removefail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["지갑주소 삭제에 실패했습니다."])},
    "addname_null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주소이름을 입력해 주세요."])},
    "outputkey_null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["출금주소를 입력해 주세요."])},
    "destination_null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["데스티네이션 코드를 입력해 주세요."])}
  }
}