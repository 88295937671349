<template lang="">
  <div class="fixed z-10 flex w-screen h-full bg-black bg-opacity-50">
    <div :class="tStyle.inner">
      <div :class="tStyle.point">
        <img src="../../assets/image/path1703.png" alt="" class="dark:hidden" />
        <img
          src="../../assets/image/path1703_d.png"
          alt=""
          class="opacity-0 dark:opacity-100"
        />
      </div>
      <h1 :class="tStyle.title">
        {{ $t('common.setting')}}
      </h1>
      <div :class="tStyle.contents">
        <div>
          <div class="pl-[10px]">{{ $t('common.change_nickname')}}</div>
          <input
            type="text"
            :placeholder= " $t('nickname.change_msg') "
            :class="tStyle.input"
          />
        </div>
        <div>
          <div class="pl-[10px]">UUID</div>
          <input 
            type="text"
            :placeholder= " $t('common.uuid') "
            :class="tStyle.input" />
        </div>
        <div>
          <div class="pl-[10px]">{{ $t('common.phone')}}</div>
          <div :class="tStyle.country">+82</div>
          <input
            type="text"
            placeholder="+01012345678"
            :class="tStyle.number_input"
          />
          <button :class="tStyle.button">
            {{ $t('common.certification')}}
          </button>
        </div>
        <div>
          <div class="pl-[10px]">{{ $t('common.email')}}</div>
          <input
            type="text"
            placeholder="mo2022@gmail.com"
            :class="tStyle.email_input"
          />
          <button :class="tStyle.button">
            {{ $t('common.certification')}}
          </button>
        </div>
      </div>

      <div :class="tStyle.bt_box">
        <button :class="tStyle.cancel">{{ $t('common.cancel')}}</button>
        <button :class="tStyle.confirm">{{ $t('common.ok')}}</button>
      </div>

      <button :class="tStyle.close_bt">
        <img src="../../assets/image/group2322.png" alt="" />
      </button>
    </div>
  </div>
</template>
<script>
import { nationNumModal } from "@/store/store";
import create from "vue-zustand";

export default {
  name: "NicknameSetting_2",
  data() {
    return {
      tStyle: {
        inner: 'bg-white w-[550px] h-[700px] m-auto rounded border border-[#C9C9C9] px-[20px] py-[15px] relative dark:bg-[#1c1d22] dark:border-[#606060] dark:text-[#c9c9c9]',
        point: 'absolute left-[-1px] top-[-1px]',
        title: 'text-center text-[30px] mt-[40px]',
        contents: 'w-full mt-[32px] text-[20px] overflow-hidden',
        input: 'w-full h-[55px] border border-[#c9c9c9] pl-[10px] mb-[30px] text-[18px] rounded-[3px] dark:bg-[#3b3b43] dark:border-[#606060] dark:placeholder:text-[#fff]',
        country: 'w-[19%] h-[55px] border border-[#c9c9c9] pl-[10px] text-[18px] text-[#9f9f9f] leading-[55px] rounded-[3px] mr-[2%] float-left dark:bg-[#25252c] dark:text-[#c9c9c9] dark:border-[#606060]',
        number_input: 'w-[58%] h-[55px] border border-[#c9c9c9] pl-[10px] text-[18px] rounded-[3px] float-left dark:bg-[#3b3b43] dark:border-[#606060] dark:placeholder:text-[#fff]',
        email_input: 'w-[79%] h-[55px] border border-[#c9c9c9] pl-[10px] text-[18px] rounded-[3px] float-left dark:bg-[#3b3b43] dark:border-[#606060] dark:placeholder:text-[#fff]',
        button: 'w-[19%] h-[55px] bg-[#cfe4ff] rounded-[3px] text-[18px] float-left text-center leading-[55px] ml-[2%] mb-[30px] dark:text-[#333] dark:bg-[#ffb500]',

        bt_box: ' mt-[10px] text-center text-[22px] dark:text-[#333]',
        cancel: 'w-[249px] h-[60px] bg-[#e8eff7] rounded-[3px] dark:bg-[#fff8e8]',
        confirm: 'w-[249px] h-[60px] bg-[#cfe4fe] rounded-[3px] ml-[10px] dark:bg-[#fdd26a]',

        close_bt: 'absolute right-[20px] top-[18px] font-light text-[30px]',


      },
    };
  },
  setup() {
    const useNationModal = create(nationNumModal);
    const { setModalClose } = useNationModal();

    return{
      setModalClose,
    }
  },
};
</script>
<style lang=""></style>
