import { createApp } from 'vue';
import App from './App.vue';
import './assets/tailwind.css';
import index from './router/index';
import i18n from './i18n';
import VueMobileDetection from 'vue-mobile-detection';


const app = createApp(App);

app
  .use(VueMobileDetection)
  .use(i18n)
  .use(index)
  .mount('#app');
