import { createWebHistory, createRouter } from 'vue-router';

import { isLogin } from '@/store/store';

var { getState } = isLogin;

const index = createRouter({
  history: createWebHistory(),

  routes: [
    // path별 component를 추가한다.
    {
      path: '/',
      name: 'MainHome',
      component: () =>
        import(/* webpackChunkName: "main" */ '@/components/Main/MainHome.vue')
    },
    {
      path: '/side',
      name: 'SideMenu',
      component: () =>
        import(/* webpackChunkName: "side" */ '@/components/Main/SideMenu.vue')
    },
    {
      path: '/login',
      name: 'UserLogin',
      component: () =>
        import(
          /* webpackChunkName: "login" */ '@/components/Login/UserLogin.vue'
        ),
      beforeEnter: (to, from, next) => {
        if (getState().loginState === true) {
          return next('/');
        } else {
          next();
        }
      }
    },
    {
      path: '/pwchange',
      name: 'PwChange',
      component: () =>
        import(
          /* webpackChunkName: "change" */ '@/components/Login/PwChange.vue'
        ),
      beforeEnter: (to, from, next) => {
        if (getState().loginState === true) {
          return next('/');
        } else {
          next();
        }
      }
    },
    {
      path: '/login/:type',
      name: 'LoginCert',
      component: () =>
        import(
          /* webpackChunkName: "login" */ '@/components/Login/LoginCert.vue'
        ),
      beforeEnter: (to, from, next) => {
        if (getState().loginState === true) {
          return next('/');
        } else {
          next();
        }
      }
    },
    {
      path: '/pwchange/:type',
      name: 'PwChangeCert',
      component: () =>
        import(
          /* webpackChunkName: "login" */ '@/components/Login/PwChangeCert.vue'
        ),
      beforeEnter: (to, from, next) => {
        if (getState().loginState === true) {
          return next('/');
        } else {
          next();
        }
      }
    },
    {
      path: '/pwchange/input',
      name: 'PwInput',
      component: () =>
        import(
          /* webpackChunkName: "change" */ '@/components/Login/PwInput.vue'
        ),
      beforeEnter: (to, from, next) => {
        if (getState().loginState === true) {
          return next('/');
        } else {
          next();
        }
      }
    },
    {
      path: '/pwinput/success',
      name: 'PwChangeSuccess',
      component: () =>
        import(
          /* webpackChunkName: "change" */ '@/components/Login/PwChangeSuccess.vue'
        ),
      beforeEnter: (to, from, next) => {
        if (getState().loginState === true) {
          return next('/');
        } else {
          next();
        }
      }
    },
    {
      path: '/account',
      name: 'UserAccount',
      component: () =>
        import(
          /* webpackChunkName: "account" */ '@/components/Account/UserAccount.vue'
        ),
      beforeEnter: (to, from, next) => {
        if (getState().loginState === true) {
          return next('/');
        } else {
          next();
        }
      }
    },
    {
      path: '/gaccount',
      name: 'GoogleAccount',
      component: () =>
        import(
          /* webpackChunkName: "account" */ '@/components/Account/GoogleAccount.vue'
        ),
      beforeEnter: (to, from, next) => {
        if (getState().loginState === true) {
          return next('/');
        } else {
          next();
        }
      }
    },
    {
      path: '/account/agree',
      name: 'UserAgree',
      component: () =>
        import(
          /* webpackChunkName: "account" */ '@/components/Account/UserAgree.vue'
        ),
      beforeEnter: (to, from, next) => {
        if (getState().loginState === true) {
          return next('/');
        } else {
          next();
        }
      }
    },
    {
      path: '/guide',
      name: 'GuideBook',
      component: () =>
        import(
          /* webpackChunkName: "cs" */ '@/components/UserCenter/GuideBook.vue'
        )
    },
    {
      path: '/guide/:id',
      name: 'GuideDetail',
      component: () =>
        import(
          /* webpackChunkName: "cs" */ '@/components/UserCenter/GuideDetail.vue'
        )
    },
    {
      path: '/notice',
      name: 'NoticeView',
      component: () =>
        import(
          /* webpackChunkName: "cs" */ '@/components/UserCenter/NoticeView.vue'
        )
    },
    {
      path: '/notice/:id',
      name: 'NoticeDetail',
      component: () =>
        import(
          /* webpackChunkName: "cs" */ '@/components/UserCenter/NoticeDetail.vue'
        )
    },
    {
      path: '/inquiry',
      name: 'InquiryList',
      component: () =>
        import(
          /* webpackChunkName: "cs" */ '@/components/UserCenter/InquiryList.vue'
        ),
      beforeEnter: (to, from, next) => {
        if (getState().loginState === false) {
          getState().setLoginModal();
          return next(from.path);
        } else {
          next();
        }
      }
    },
    {
      path: '/inquiry/register',
      name: 'InquiryReg',
      component: () =>
        import(
          /* webpackChunkName: "cs" */ '@/components/UserCenter/InquiryReg.vue'
        ),
      beforeEnter: (to, from, next) => {
        if (getState().loginState === false) {
          getState().setLoginModal();
          return next('/login');
        } else {
          next();
        }
      }
    },
    {
      path: '/promotion/event',
      name: 'ProEvent',
      component: () =>
        import(
          /* webpackChunkName: "promotion" */ '@/components/Promotion/ProEvent.vue'
        )
    },
    {
      path: '/promotion/event/:id',
      name: 'EventDetail',
      component: () =>
        import(
          /* webpackChunkName: "promotion" */ '@/components/Promotion/EventDetail.vue'
        )
    },
    {
      path: '/promotion/invite',
      name: 'ProInvite',
      component: () =>
        import(
          /* webpackChunkName: "promotion" */ '@/components/Promotion/ProInvite.vue'
        ),
      beforeEnter: (to, from, next) => {
        if (getState().loginState === false) {
          getState().setLoginModal();
          return next(from.path);
        } else {
          next();
        }
      }
    },
    {
      path: '/mypage/setting',
      name: 'UserSetting',
      component: () =>
        import(
          /* webpackChunkName: "mypage" */ '@/components/MyPage/UserSetting.vue'
        ),
      beforeEnter: (to, from, next) => {
        if (getState().loginState === false) {
          getState().setLoginModal();
          return next(from.path);
        } else {
          next();
        }
      }
    },
    {
      path: '/mypage/active',
      name: 'UserActivity',
      component: () =>
        import(
          /* webpackChunkName: "mypage" */ '@/components/MyPage/UserActivity.vue'
        ),
      beforeEnter: (to, from, next) => {
        if (getState().loginState === false) {
          getState().setLoginModal();
          return next(from.path);
        } else {
          next();
        }
      }
    },
    {
      path: '/mypage/auth',
      name: 'AuthCenter',
      component: () =>
        import(
          /* webpackChunkName: "mypage" */ '@/components/MyPage/AuthCenter.vue'
        ),
      beforeEnter: (to, from, next) => {
        if (getState().loginState === false) {
          getState().setLoginModal();
          return next(from.path);
        } else {
          next();
        }
      }
    },
    {
      path: '/orderlist',
      name: 'RecentOrder',
      component: () =>
        import(
          /* webpackChunkName: "investlist" */ '@/components/Invest/RecentOrder.vue'
        ),
      beforeEnter: (to, from, next) => {
        if (getState().loginState === false) {
          getState().setLoginModal();
          return next(from.path);
        } else {
          next();
        }
      }
    },
    {
      path: '/history',
      name: 'OrderHistory',
      component: () =>
        import(
          /* webpackChunkName: "investlist" */ '@/components/Invest/OrderHistory.vue'
        ),
      beforeEnter: (to, from, next) => {
        if (getState().loginState === false) {
          getState().setLoginModal();
          return next(from.path);
        } else {
          next();
        }
      }
    },
    {
      path: '/loss',
      name: 'ProfitLoss',
      component: () =>
        import(
          /* webpackChunkName: "investlist" */ '@/components/Invest/ProfitLoss.vue'
        ),
      beforeEnter: (to, from, next) => {
        if (getState().loginState === false) {
          getState().setLoginModal();
          return next(from.path);
        } else {
          next();
        }
      }
    },
    {
      path: '/viewwallet',
      name: 'ViewWallet',
      component: () =>
        import(
          /* webpackChunkName: "mywallet" */ '@/components/MyWallet/ViewWallet.vue'
        ),
      beforeEnter: (to, from, next) => {
        if (getState().loginState === false) {
          getState().setLoginModal();
          return next(from.path);
        } else {
          next();
        }
      }
    },
    {
      path: '/deposit',
      name: 'DepositWallet',
      component: () =>
        import(
          /* webpackChunkName: "mywallet" */ '@/components/MyWallet/DepositWallet.vue'
        ),
      beforeEnter: (to, from, next) => {
        if (getState().loginState === false) {
          getState().setLoginModal();
          return next(from.path);
        } else {
          next();
        }
      }
    },
    {
      path: '/exchange',
      name: 'AssetExchange',
      component: () =>
        import(
          /* webpackChunkName: "mywallet" */ '@/components/MyWallet/AssetExchange.vue'
        ),
      beforeEnter: (to, from, next) => {
        if (getState().loginState === false) {
          getState().setLoginModal();
          return next(from.path);
        } else {
          next();
        }
      }
    },
    {
      path: '/withdraw',
      name: 'AssetWithdraw',
      component: () =>
        import(
          /* webpackChunkName: "mywallet" */ '@/components/MyWallet/AssetWithdraw.vue'
        ),
      beforeEnter: (to, from, next) => {
        if (getState().loginState === false) {
          getState().setLoginModal();
          return next(from.path);
        } else {
          next();
        }
      }
    },
    {
      path: '/position',
      name: 'PositionOpen',
      component: () =>
        import(
          /* webpackChunkName: "deal" */ '@/components/Deal/PositionOpen.vue'
        )
    },
    {
      path: '/manageAddress',
      name: 'ManageAddress',
      component: () =>
        import(
          /* webpackChunkName: "mywallet" */ '@/components/MyWallet/AddressManage.vue'
        )
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  }
});

export default index;
