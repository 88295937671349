<template lang="">
  <div class="fixed flex w-screen h-full sm:bg-black sm:bg-opacity-50 bg-[#f2f4f5] bg-opacity-80">
    <div :class="tStyle.inner">
      <div :class="tStyle.point">
        <img src="../../assets/image/path1709.png" alt="" class="dark:hidden" />
        <img
          src="../../assets/image/path1709_d.png"
          alt=""
          class="opacity-0 dark:opacity-100"
        />
      </div>
      <div :class="tStyle.title">
        <h2 class="sm:text-[30px] text-[20px]">{{ alertData.title }}</h2>
        <p class="sm:text-[20px] text-[15px] mt-[10px] sm:font-light">
          {{ alertData.desc }}
        </p>
      </div>
      <div :class="tStyle.bt_box">
        <button :class="tStyle.confirm" @click="closeModal">
          {{ $t('common.ok') }}
        </button>
      </div>
      <button :class="tStyle.close_bt" @click="setAlertOpen">
        <img src="../../assets/image/group2322.png" alt="" />
      </button>
    </div>
  </div>
</template>
<script>
import { modalControl , refresh } from '@/store/store';
import { orderConfirm } from '@/store/positionStore';
import create from 'vue-zustand';

export default {
  name: 'AlertModal',
  data() {
    return {
      tStyle: {
        inner:
          'bg-white w-[370px] m-auto rounded sm:border border-[#C9C9C9] px-6 sm:px-[40px] sm:pt-[15px] sm:pb-[40px] relative dark:bg-[#1c1d22] dark:border-[#606060] dark:text-[#c9c9c9] max-sm:flex fold:flex flex-col',
        title: 'text-center sm:mt-[50px] mt-10',
        point: 'absolute left-[-1px] top-[-1px] max-sm:hidden fold:hidden',
        overflow_box:
          'w-full mt-[20px] overflow-y-auto text-[13px] text-center text-[#9f9f9f]',
        order_list:
          'w-[203.2px] font-normal bg-[#f7f8fa] h-[44px] border-b border-b-[#c9c9c9]',
        table_list: 'h-[44px] border-b border-b-[#e5e5e5]',
        bt_box: 'sm:h-[35px] py-6 sm:py-0 max-sm:mt-auto fold:mt-auto sm:mt-[35px] text-center text-[15px]',
        confirm:
          'sm:w-[117px] w-full h-[35px] bg-[#cfe4fe] rounded-[3px] dark:text-[#333333] dark:bg-[#ffb500]',

        close_bt: 'absolute right-[20px] top-[18px] font-light text-[30px] max-sm:hidden fold:hidden'
      }
    };
  },
  setup() {
    const useModalControl = create(modalControl);
    const useOrderConfirm = create(orderConfirm);
    const {
      setModalControl,
      alertData,
      setModalName,
      setAlertData,
      setAlertOpen
    } = useModalControl();
    const { setApiCall } = useOrderConfirm();
    const useRefresh = create(refresh)
    const { setRefresh } = useRefresh()

    return {
      setModalControl,
      alertData,
      setModalName,
      setAlertData,
      setAlertOpen,
      setApiCall,
      setRefresh
      
    };
  },
  methods: {
    closeModal() {
      //isReload가 true일 경우 새로고침
      if(this.alertData.isReload){
        location.reload();
        return;
      }

      if (this.alertData?.type === 'order') {
        this.setApiCall();
        this.setAlertOpen();
        return;
      } else if (
        this.alertData?.type &&
        this.alertData.type !== '' &&
        this.alertData.type !== 'order'
      ) {
        this.setModalName(this.alertData.type);
        return;
      }

      let popupName =
        this.alertData.popupName &&
        this.alertData.popupName !== '' &&
        this.alertData.popupName;
      let beforeData = this.alertData.beforeData && this.alertData.beforeData;
      
      if(popupName || beforeData){
        if(popupName) this.setModalName(popupName);
        if(beforeData) this.setAlertData(beforeData);
        this.setAlertOpen()
      } 
      else {
        this.setAlertData({});
        this.setAlertOpen();
      }
    }
  }
};
</script>
<style lang=""></style>
