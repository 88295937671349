export default {
  "common": {
    "nation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dân tộc"])},
    "nation_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn một quốc gia"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm kiếm"])},
    "search2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vui lòng nhập cụm từ tìm kiếm."])},
    "search3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm kiếm"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ví"])},
    "asset_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quản lý tài sản"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày"])},
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hôm nay"])},
    "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7 ngày"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tháng"])},
    "1month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 ngày"])},
    "3month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["90 ngày"])},
    "mywallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ví tiền"])},
    "viewwallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tổng quan"])},
    "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiền đặt cọc"])},
    "exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chuyển đổi"])},
    "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["thu hồi"])},
    "margin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Margin"])},
    "operate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hành động"])},
    "deposit2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiền đặt cọc"])},
    "exchange2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chuyển đổi"])},
    "withdraw2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["thu hồi"])},
    "trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mậu dịch"])},
    "coinsearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm những đồng xu có sẵn để gởi tiền"])},
    "networkselection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mạng"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thời gian"])},
    "division": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["loại"])},
    "condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trạng thái"])},
    "completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoàn thành"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hủy bỏ"])},
    "progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đang chờ xử lý"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giá"])},
    "commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fee"])},
    "invest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lịch sử"])},
    "recentorder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lệnh chờ"])},
    "orderhistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sổ giao dịch"])},
    "profitloss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PNL"])},
    "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ký hiệu"])},
    "leverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leverage"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lọc"])},
    "invest_limits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sự chỉ định"])},
    "invest_marketprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["phố chợ"])},
    "invest_conditional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["điều kiện"])},
    "orderprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giá"])},
    "orderquantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giá trị"])},
    "contract_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thực hiện"])},
    "contract_amount2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số lượng buộc"])},
    "contract_Average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average"])},
    "contract_Average2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average closing price"])},
    "target_loss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TP / SL"])},
    "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phía"])},
    "indefinitely": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trường cửu"])},
    "crossbuy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross Long"])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã gửi"])},
    "part_contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điền một phần"])},
    "contract_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chi tiết"])},
    "contract_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kết luận"])},
    "trigger-clue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["điều kiện kích hoạt"])},
    "trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điều kiện kích hoạt"])},
    "trigger_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giá kích hoạt"])},
    "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sự kiện"])},
    "event_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chi tiết sự kiện"])},
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giới thiệu"])},
    "usercenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help Center"])},
    "guidebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sách hướng dẫn"])},
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông báo"])},
    "contact us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gửi yêu cầu"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiêu đề"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày"])},
    "writedate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày"])},
    "mypage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tài khoản"])},
    "usersetting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hồ sơ"])},
    "setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biên tập"])},
    "resetting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biên tập"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điện thoại"])},
    "authcenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bảo vệ"])},
    "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoạt động"])},
    "phone_auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xác minh SMS"])},
    "email_auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["xác thực Email"])},
    "auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["xác thực"])},
    "google2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTP"])},
    "otp_auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTP Trình xác thực"])},
    "change_nickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thay đổi biệt danh"])},
    "uuid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter UUID"])},
    "retouch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sửa chữa"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["xác nhận"])},
    "deauthorization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hủy cấp phép"])},
    "certification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["xác thực"])},
    "removeauthfail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quá trình xác thực đã thất bại."])},
    "removeauthsuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xác thực đã bị thu hồi."])},
    "certificated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hoàn thành"])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cài đặt"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Futures Sự cân bằng"])},
    "30deal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khối lượng giao dịch 30 ngày"])},
    "google_auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTP xác minh"])},
    "notice2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông báo"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiếp tục"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log In"])},
    "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
    "whole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["toàn thể"])},
    "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biểu tượng"])},
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tiếng Anh"])},
    "korean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["người Triều Tiên"])},
    "initialization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khởi tạo"])},
    "delet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xoá"])},
    "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ví của tôi."])},
    "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["thất bại."])},
    "failfetch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không lấy được dữ liệu."])},
    "user_level_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General member"])},
    "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sự áp dụng"])},
    "findby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm theo"])},
    "googlecert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["google Xác thực"])},
    "nodata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dữ liệu không có sẵn."])},
    "stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cổ phiếu"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Địa chỉ"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiếp theo"])},
    "eventword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sự kiện"])},
    "referer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giới thiệu"])}
  },
  "login": {
    "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gia nhập hội viên"])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vui lòng nhập thông tin đăng ký của bạn."])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ít nhất một ký tự chữ thường/chữ hoa và một số."])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ít nhất một ký tự đặc biệt."])},
    "msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tối thiểu 8 ký tự."])},
    "re": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lại"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referral ID"])},
    "msg5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vui lòng nhập mã thư mời."])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lựa chọn"])},
    "msg6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tôi đã đọc và đồng ý"])},
    "msg7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điều khoản dịch vụ và Chính sách bảo mật"])},
    "msg8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tạo tài khoản"])},
    "msg9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Định dạng tài khoản email không hợp lệ"])},
    "msg10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Định dạng mật khẩu không hợp lệ"])},
    "msg11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mật khẩu không nhất quán"])},
    "msg12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đây là một email có sẵn."])},
    "msg13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mật khẩu không đúng"])},
    "msg14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dịch vụ này khả dụng sau khi đăng nhập."])},
    "msg15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phiên đăng nhập của bạn đã hết hạn."])},
    "msg16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["đăng kí của bạn đã hoàn tất."])},
    "msg17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vui lòng đăng nhập để bắt đầu giao dịch."])},
    "msg18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["đăng nhập"])},
    "msg19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng nhập thành công"])},
    "msg20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mã xác thực không hợp lệ!"])},
    "msg21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xác thực đã hết thời gian chờ."])},
    "msg22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng ký hoàn tất."])},
    "msg23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhập lại mật khẩu"])},
    "msg24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn là thành viên chưa đăng ký hoặc có ID hoặc mật khẩu khác."])},
    "loginword1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lưu ID"])},
    "msg25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Định dạng số điện thoại di động không chính xác."])},
    "msg26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chính sách điều khoản"])}
  },
  "topmenu": {
    "procenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sự quảng bá"])},
    "mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chủ đề"])},
    "sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Âm thanh"])},
    "layout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Layout"])}
  },
  "footer": {
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giao dịch trong các phụ hợp của tiền điện tử đòi hỏi những rủi ro lớn."])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giao dịch với Tài sản Kỹ thuật số có thể không thể hoàn tác, và, do đó, các thiệt hại do đó có thể xảy ra"])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Các giao dịch gian lận hoặc vô tình có thể không được khôi phục."])},
    "introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giới thiệu công ty."])},
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông báo"])},
    "center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trung tâm Bảo vệ Đầu tư"])},
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Câu hỏi thường gặp"])},
    "kakao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KakaoTalk Contact"])},
    "inquiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1:1 Contact Us"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Công ty"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hỗ trợ khách hàng"])},
    "word1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서울시 강남구 테헤란로 1길 23, 4층"])},
    "word2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주식회사 푸빗"])},
    "word3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대표 김푸빗"])},
    "word4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사업자 등록번호 123-45-67890"])},
    "word5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가상 사업자 등록번호 123-45-67890"])},
    "word6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sau khi đăng nhập, bạn có thể sử dụng nhiều dịch vụ khác nhau trên Fu-bit."])}
  },
  "mainhome": {
    "word": {
      "startdeal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bắt đầu giao dịch"])},
      "populardeal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nóng"])},
      "bigchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khối lượng hàng đầu"])},
      "highprofit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cổ phiếu tăng giá hàng đầu"])},
      "marketstatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chợ"])},
      "newcoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danh sách mới"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giá(USDT)"])},
      "ratechange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thay Đổi(24H)"])},
      "highprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giá cao nhất (24H)"])},
      "lowprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giá thấp nhất (24H)"])},
      "viewmore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hơn"])},
      "guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hướng dẫn mới bắt đầu"])},
      "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiền gửi"])},
      "trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buôn bán"])},
      "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hỗ trợ 24/7"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đóng"])},
      "inspect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quan sát"])}
    },
    "msg": {
      "mainbannermsg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giao dịch với Fubit"])},
      "mainbannermsg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiếm tiền nhanh chóng và an toàn."])},
      "mainbannermsg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tham gia ngay, nhận 30 USDT"])},
      "middlebannermsg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đổi xu đơn giản và nhanh chóng"])},
      "middlebannermsg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trao đổi tài sản kỹ thuật số đơn giản và nhanh chóng từ thông tin tiền xu để đặt hàng"])},
      "bottomtit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bắt đầu bây giờ! Kiếm lợi nhuận giao dịch của bạn"])},
      "bottom1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "bottom1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dễ hiểu cho mọi người"])},
      "bottom2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quà tặng 10 USDT cho khoản tiền gửi đầu tiên"])},
      "bottom2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "bottom3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tổng giải thưởng 100 USDT,"])},
      "bottom3_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tham gia và bắt đầu"])},
      "bottom4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yêu cầu chúng tôi bất cứ điều gì"])},
      "bottom4_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nó mở cửa cho bạn suốt ngày đêm."])},
      "modalmsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["đừng xem nữa."])},
      "inputtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhập email của bạn."])}
    }
  },
  "trade": {
    "modal": {
      "margin_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chế độ ký quỹ vĩnh viễn"])},
      "cross": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đi qua"])},
      "isolated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bị cô lập"])},
      "cross2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chế độ ký quỹ chéo"])},
      "isolated2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chế độ ký quỹ biệt lập"])},
      "adjust": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điều chỉnh ký quỹ"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm vào"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Di dời"])},
      "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Có thể thêm tối đa"])},
      "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kích cỡ"])},
      "est": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ước tính Liq.Price"])},
      "profit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chốt lãi"])},
      "loss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giá từ bỏ"])},
      "entryprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giá nhập cảnh"])},
      "liq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liq.Price"])},
      "size2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số lượng"])},
      "pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PNL ước tính sẽ là"])},
      "basis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn cơ sở giá"])},
      "tp_sl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TP/SL cho toàn bộ vị thế"])},
      "markprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["đánh dấu giá"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tải xuống"])},
      "liq2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đóng Vị thế"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["số lượng đơn hàng"])}
    },
    "msg": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chuyển đổi chế độ ký quỹ sẽ chỉ áp dụng nó cho hợp đồng đã chọn."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả các vị trí chéo trong cùng một tài sản ký quỹ có cùng số dư ký quỹ chéo tài sản."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trong trường hợp thanh lý, toàn bộ số dư ký quỹ của tài sản của bạn cùng với mọi vị thế mở còn lại của tài sản có thể bị mất."])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quản lý rủi ro của bạn đối với các vị thế riêng lẻ bằng cách hạn chế số tiền ký quỹ được phân bổ cho mỗi vị thế."])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nếu tỷ lệ ký quỹ của một vị thế đạt 100%, thì vị thế đó sẽ được thanh lý. Có thể thêm hoặc bớt tiền ký quỹ vào các vị thế bằng chế độ này."])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nếu vị thế đang mở, bạn không thể thay đổi chế độ Ký quỹ"])}
    }
  },
  "mywallet": {
    "walletview": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nhà > TRANG CHỦ > Tổng quan"])},
      "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trốn"])},
      "totalasset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tổng số dư"])},
      "checkboxtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không hỗ trợ giao dịch / che giấu tài sản nhỏ"])},
      "loss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PNL chưa thực hiện"])},
      "possibleasset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sự cân bằng"])},
      "inkindasset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điểm"])},
      "coin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coin"])},
      "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Có sẵn"])},
      "freez": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["theo thứ tự"])},
      "bitcoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitcoin"])},
      "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhập khẩu"])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xuất khẩu"])},
      "forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tài sản tặng"])},
      "mobilelist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quà tặng"])},
      "mobilelist2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chi tiết cổ phiếu"])},
      "futurelist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lịch sử ví hợp đồng tương lai"])}
    },
    "depositwallet": {
      "word": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nhà > Ví tiền > Tiền đặt cọc"])},
        "assetselection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Coin"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Địa chỉ gửi tiền"])},
        "precautions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông báo"])},
        "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sao chép"])},
        "destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["đích đến"])},
        "depositdetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danh tiền gửi"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số lượng tiền gửi"])},
        "copy2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bản sao hoàn thành"])},
        "inoutlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inflow/Outflow"])}
      },
      "msg": {
        "precautions_msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Hãy chọn tài sản và mạng lưới mà bạn muốn gửi và đảm bảo rằng nó đúng"])},
        "precautions_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Chụp ảnh địa chỉ tiền gửi dưới dạng mã QR, hoặc dán nó vào ví rút tiền của bạn và yêu cầu rút tiền."])},
        "precautions_msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Vui lòng đợi cho đến khi truyền mạng chuỗi khối được xác nhận."])},
        "precautions_msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. Fubit gửi tài sản đến địa chỉ ví sau khi mạng chuỗi khối xác nhận truyền tin."])},
        "precautions_msg5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["※ Bạn có thể có tài sản yêu cầu thêm dữ liệu hoặc thẻ đích bên cạnh địa chỉ."])},
        "precautions_msg6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["※ Phúc không chịu trách nhiệm về bất kỳ vấn đề nào do nhập sai thông tin tiền gửi."])},
        "address_msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "address_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chép và nhập địa chỉ gởi tiền"])},
        "alertmsg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ngày kết thúc không thể sớm hơn ngày bắt đầu"])}
      }
    },
    "exchange": {
      "word": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nhà > Ví tiền > Chuyển đổi"])},
        "before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Từ"])},
        "exchange_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giá trị"])},
        "after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đến"])},
        "possible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Có sẵn"])},
        "expect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn sẽ nhận được"])},
        "coinprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giá"])},
        "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xin mời vào"])},
        "amount_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giới hạn còn lại 24H"])},
        "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danh trao đổi"])},
        "exchanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thực hiện"])},
        "price_check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xem thử chuyển đổi"])},
        "countLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["một phần hai"])},
        "countlimit2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Làm mới sau"])}
      },
      "msg": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["thành công trong việc trao đổi"])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sự trao đổi thất bại"])},
        "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hãy nhập số lượng"])},
        "notenough": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thiếu tài sản có thể trao đổi"])}
      }
    },
    "withdraw": {
      "word": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nhà > Ví tiền > thu hồi"])},
        "location2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nhà > Ví tiền > Rút ra > Quản lý địa chỉ"])},
        "address_manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quản lý địa chỉ"])},
        "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select coin"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quản lý địa chỉ"])},
        "address_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["diễn thuyết"])},
        "coinsearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm những đồng tiền có thể rút ra được"])},
        "address2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn sổ địa chỉ"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giá trị"])},
        "withdrawpossible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rút số lượng có sẵn"])},
        "withdrawpossible2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["số lượng có thể sử dụng"])},
        "withdrawdetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danh rút tiền"])},
        "precautions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hạng mục chú ý rút tiền"])},
        "minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số tiền rút tối thiểu"])},
        "amount_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["giới hạn rút quân 24 giờ"])},
        "asset2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Coin"])},
        "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Địa chỉ rút tiền"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhập trực tiếp"])},
        "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mạng"])},
        "minimum2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số tiền rút tối thiểu: 0.001"])},
        "24withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số tiền có thể rút tối đa trong 24 giờ"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tên địa chỉ"])},
        "withdrawaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Địa chỉ rút tiền"])},
        "adresslist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chi tiết địa chỉ"])},
        "adressplus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm địa chỉ"])},
        "noadress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có địa chỉ đăng ký nào tồn tại."])},
        "adressmsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhập tối đa 4-5 ký tự"])},
        "mylevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cấp độ của mình"])},
        "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bước"])},
        "levelbtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nâng cao trình độ"])},
        "destination_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhập mã đích"])},
        "addr_err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lỗi địa chỉ"])},
        "io_check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sự bảo dưỡng"])},
        "over_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tài sản bất thường"])},
        "inquiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tư vấn khách hàng"])},
        "notag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag không được nhập"])},
        "treble": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiểm tra treble-rule"])}
      },
      "msg": {
        "withdraw_msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Vui lòng đảm bảo rằng tài sản được chọn để rút và địa chỉ rút tiền đã nhập là cùng một tài sản."])},
        "withdraw_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Tài sản rút đã chọn chỉ có thể nhận được tại địa chỉ nơi tài sản có thể được gửi."])},
        "withdraw_msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Sau khi đơn xin rút hoàn tất, thường mất khoảng một giờ để kiểm tra chi tiết trong chiếc ví nhận được tiền đặt cọc."])},
        "withdraw_msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Xử lý rút tiền có thể bị trì hoãn tùy thuộc vào các trường hợp như mạng lưới chuỗi khối."])},
        "withdraw_msg5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Nếu bạn đang gửi tiền vào một ví đổi tiền khác, hãy chắc chắn kiểm tra chính sách đổi tiền."])},
        "withdraw_msg6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Sau khi đơn xin rút được hoàn tất, các thủ tục tiếp theo sẽ được xử lý trong mạng lưới chuỗi khối."])},
        "withdraw_msg7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Do đặc tính của chuỗi khối, một khi bắt đầu rút, nó không thể bị hủy bỏ."])},
        "withdraw_msg8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Vì việc rút tiền được tiến hành trong hệ thống của Fubit, địa chỉ rút tiền có thể khác nhau tùy theo tình huống."])},
        "withdraw_msg9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Tất cả thiệt hại do nhập sai thông tin rút tiền hoặc không kiểm tra chính sách của ví nhận hoặc trao đổi là trách nhiệm của người sử dụng, và nếu nghi ngờ các giao dịch lừa đảo hoặc bất thường, Fubit có thể hạn chế rút tiền."])},
        "withdraw_msg10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhập địa chỉ rút tiền."])},
        "withdraw_msg11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có đủ tài sản để rút tiền."])}
      }
    }
  },
  "invest": {
    "recentorder": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nhà > chi tiết đầu tư > Thứ tự hiện hành"])}
    },
    "orderhistory": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nhà > chi tiết đầu tư > sổ giao dịch"])}
    },
    "profitloss": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nhà > chi tiết đầu tư > Lãi và lỗ cuối kỳ"])},
      "position_clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanh lý vị trí"])},
      "position_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["thiết lập vị trí"])},
      "average_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giá nhập"])},
      "liquidation_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giá thanh lý"])},
      "loss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lợi nhuận đã thực hiện"])}
    }
  },
  "promotion": {
    "event": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nhà > Thăng tiến > Tham gia sự kiện"])},
      "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quà chào mừng"])},
      "bonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiền thưởng gia nhập hội viên"])},
      "bonus_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trợ cấp tiền gửi từ $10.000 trở lên trong tháng tám"])},
      "mision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bước đầu"])},
      "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["đang tiến hành"])},
      "acquire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giành được"])},
      "acquire_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phần thưởng nhiệm vụ đã được chuyển vào ví quà."])},
      "completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoàn thành nhiệm vụ"])},
      "participate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tham gia nhiệm vụ"])},
      "participate_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Từ bây giờ nhiệm vụ sẽ được bắt đầu."])},
      "event_compensation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giải thưởng sự kiện"])},
      "mision_clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhiệm vụ hoàn thành"])},
      "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điều khoản"])},
      "terms1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điều khoản phục vụ"])},
      "terms2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chính sách bảo mật"])},
      "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sao chép"])},
      "noticedetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông báo chi tiết"])},
      "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trong danh sách"])},
      "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xem"])},
      "rewarded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "rewarded2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["đã được gửi vào ví của bạn như một khoản bồi thường"])},
      "unlimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vô hạn"])},
      "term1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sự kiện của Fu bit nhắm vào khách hàng đã đăng ký thành viên."])},
      "term2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trong trường hợp tham gia sự kiện bằng cách bất hợp pháp hoặc gây cản trở cho hoạt động của sự kiện, người chơi có thể bị loại khỏi danh sách đủ điều kiện và thậm chí bị thu hồi phần thưởng sau khi trúng thưởng."])},
      "term3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phần thưởng sẽ được cấp sau khi sự kiện kết thúc hoặc tại Trung tâm Khuyến mãi của đối tác. Phần thưởng phải được nhận bằng tay."])},
      "term4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phần thưởng nhận được thông qua việc giới thiệu bạn bè sẽ tự động được cấp vào lúc 00:00 (UTC) hôm sau."])},
      "term5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tất cả các người tham gia đều phải tuân thủ các điều khoản sử dụng dịch vụ của Fu bit. Fu bit có quyền loại bỏ người chơi vi phạm hoặc thực hiện các hoạt động gian lận hoặc vi phạm pháp luật hoặc các hoạt động liên quan đến mục đích bất hợp pháp, bất lợi hoặc có hại khác, để nhận thêm phần thưởng."])},
      "term6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phần thưởng chỉ có thể sử dụng cho giao dịch quà tặng và không thể rút trực tiếp."])},
      "term7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phần thưởng có thể được sử dụng làm tiền đặt cọc hoặc để thanh toán cho phí và lỗ của giao dịch."])},
      "term8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người dùng có thể rút lại toàn bộ lợi nhuận tích lũy từ các giao dịch thành công bằng việc sử dụng phần thưởng."])},
      "term9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fu bit có quyền thay đổi các quy định của sự kiện này mà không cần thông báo trước."])},
      "term10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fu bit giữ quyền tối cao trong việc giải thích các điều khoản của sự kiện này. Nếu bạn có bất kỳ câu hỏi nào, vui lòng liên hệ với hỗ trợ khách hàng."])}
    },
    "invite": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nhà > Thăng tiến > Mời bạn bè"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông tin mời của mình"])},
      "info_msg_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn có thể mời bạn bè và trả phí."])},
      "info_msg_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dựa trên khoản phí phát sinh do giao dịch của người bạn mà bạn mời, bạn tôi và tôi sẽ nhận được khoản thù lao chia nhau."])},
      "info_msg_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mã mời bạn bè xác lập tỷ lệ phần trăm phí bạn và bạn của bạn sẽ nhận được trả lại."])},
      "info_msg_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Để biết thêm thông tin, hãy nhấp vào Quản lý Mã Lời mời."])},
      "ranking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giải thưởng Xếp hạng Lời mời Bạn bè"])},
      "ranking_msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đây là bảng xếp hạng của người mời nhiều bạn bè nhất trong một tháng."])},
      "ranking_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phần thưởng sẽ tự động được gửi vào ví quà tặng của bạn vào lúc 0 giờ (UTC) ngày 1 tháng sau cho vị trí từ 1 đến 10 trong bảng xếp hạng Lời mời Bạn bè. "])},
      "ranking_msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phần thưởng được trả như phần thưởng xếp hạng có thể được sử dụng làm biên độ và lệ phí cần thiết cho giao dịch, nhưng không thể rút ra bên ngoài."])},
      "expect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["phần thưởng"])},
      "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coin"])},
      "compensation2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tổng phần thưởng"])},
      "person2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liên kết giới thiệu"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mã giới thiệu"])},
      "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chia sẻ"])},
      "invite_rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ban lãnh đạo"])},
      "rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thứ hạng"])},
      "rank2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["st"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "influencer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ứng dụng có ảnh hưởng"])},
      "influencerreview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sàng lọc người ảnh hưởng"])},
      "influencerinquire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tra cứu người ảnh hưởng"])},
      "compensation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phần thưởng"])},
      "invite_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn bè được mời"])},
      "invite_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thời gian"])},
      "compensation3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hôm nay đền bù."])},
      "invite_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thời gian"])},
      "invite_friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User ID"])},
      "reward_join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tham gia phần thưởng"])},
      "reward_deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phần thưởng tiền gửi"])},
      "reward_deal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phần thưởng giao dịch"])},
      "reward_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coin"])},
      "title_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lời mời kết bạn"])},
      "info_msg_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã sao chép liên kết hoặc mã."])},
      "code_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vui lòng thiết lập mã mời trong quản lý mã mời."])}
    }
  },
  "usercenter": {
    "guidebook": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nhà > Help Center > sách hướng dẫn"])},
      "location2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nhà > Help Center > sách hướng dẫn > Guide details"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sách hướng dẫn Tìm kiếm"])},
      "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hướng dẫn viên details"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hướng dẫn"])},
      "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["danh sách"])},
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xem ảnh"])},
      "list_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cách gửi tài sản"])},
      "nodata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sách hướng dẫn đã đăng ký không tồn tại."])},
      "linkcopysuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sao chép vào bảng nháp."])}
    },
    "notice": {
      "view": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nhà > Help Center >Thông báo"])}
      },
      "detail": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nhà > Help Center >Thông báo"])}
      },
      "nonotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có thông báo đã đăng ký."])}
    },
    "inquiry": {
      "list": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nhà > Help Center > Gửi yêu cầu > Yêu cầu"])},
        "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yêu cầu"])},
        "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["đăng ký"])},
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loại điều tra"])},
        "situation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trạng thái"])},
        "answer_wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chờ đợi"])},
        "answer_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chấm dứt"])},
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sự giải thích đáng kể"])},
        "nolist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không điều tra gì được nữa."])},
        "answer_wait_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đang chờ câu trả lời của anh."])}
      },
      "reg": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nhà > Help Center > Gửi yêu cầu > đăng ký"])},
        "usercenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help Center"])},
        "guidebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sách hướng dẫn"])},
        "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông báo"])},
        "inquiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1:1 Liên lạc với chúng tôi"])},
        "inquirylist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yêu cầu"])},
        "inquiry_regist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng ký"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chọn kiểu"])},
        "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hãy chọn chủng loại."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiêu đề"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điều tra"])},
        "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn có thể viết từ 30 ký tự trở xuống."])},
        "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn có thể nhập nhiều hơn 30 ký tự và ít hơn 500 ký tự."])},
        "att_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đính kèm tập tin"])},
        "att_file2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sự gắn bó"])},
        "find_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm"])},
        "regist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng ký"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã có các cuộc điều tra."])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thất bại."])},
        "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["món hàng được chọn"])},
        "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trả lời nội dung"])},
        "msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vui lòng nhập tiêu đề."])},
        "msg5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vui lòng nhập nội dung."])}
      }
    }
  },
  "mypage": {
    "usersetting": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nhà > Trang của tôi > Cá nhân hoá"])},
      "location2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nhà > Trang của tôi > Cá nhân hoá > thiết lập"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đổi mật khẩu"])},
      "password2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm mật khẩu của bạn"])},
      "google_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Người xác thực"])},
      "google_not_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bỏ liên kết Google"])},
      "login_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng nhập xác minh"])},
      "recent_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lần đăng nhập cuối cùng"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sự đăng ký"])},
      "mycommission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Học phí của tôi"])},
      "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chiết khấu phí giao dịch"])},
      "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lệ phí chuẩn"])},
      "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mức chiết khấu"])},
      "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tỷ lệ chiết khấu lệ phí"])},
      "discount_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phí tiêu chuẩn sẽ được áp dụng cho giao dịch và số tiền chiết khấu sẽ được gửi vào ví quà tặng vào lúc 0 giờ (UTC) một ngày sau ngày giao dịch."])},
      "screen_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Layout"])},
      "notification_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thiết lập thông báo"])},
      "sound_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thiết lập âm thanh"])},
      "setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sự sắp đặt"])},
      "removegooglelink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn có chắc muốn tắt Google Interlink không?"])},
      "removeotp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liên kết Google đã bị vô hiệu hóa."])},
      "toggle": {
        "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông báo"])},
        "darkmode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sắc thái"])},
        "whitemode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chế độ trắng"])},
        "marketstatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tình trạng thị trường"])},
        "favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Được ưa thích"])},
        "recentdeal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nghề nghiệp"])},
        "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sổ lệnh"])},
        "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cân bằng"])},
        "e_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Login"])},
        "e_liquidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thư điện tử cưỡng chế thanh lý"])},
        "e_withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rút thư điện tử"])},
        "e_deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gửi tiền qua email"])},
        "sms_wd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông báo SMS vào/ra"])},
        "ordercomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đơn đặt hàng đã gửi"])},
        "trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thứ tự kích hoạt"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã hủy đơn hàng"])},
        "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đặt hàng hoàn tất"])},
        "conclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điền đơn đặt hàng"])},
        "liquidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cưỡng chế thanh toán"])}
      }
    },
    "authcenter": {
      "word": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nhà > Trang của tôi > Trung tâm chứng nhận"])},
        "accountlevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mức độ rủi ro tài khoản : "])},
        "safe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An toàn"])},
        "normal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nói chung"])},
        "danger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cao"])},
        "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTP Người xác thực "])},
        "anti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mã chống giả mạo"])},
        "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biến động"])},
        "anti2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bộ luật chống đánh bắt cá : "])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trong vòng 10 ký tự"])}
      },
      "msg": {
        "level_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Để đảm bảo an toàn cho tài khoản, vui lòng thiết lập xác thực bảo mật."])},
        "level_msg_m1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vì một tài khoản an toàn"])},
        "level_msg_m2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vui lòng thêm xác thực bảo mật."])},
        "email_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liên kết địa chỉ email của bạn với tài khoản của bạn để xác nhận khôi phục và rút mật khẩu"])},
        "phone_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liên kết số điện thoại di động của bạn với tài khoản của bạn để nhận mã xác thực qua SMS"])},
        "google_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTP  được sử dụng để xác minh an toàn khi bạn đăng nhập, rút hoặc thay đổi cài đặt an toàn."])},
        "usdt_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng ký chứng nhận và nhận 50 USDT như một phần thưởng."])},
        "security_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email được gửi sẽ chứa mã chống giả mạo của bạn để bảo vệ khỏi các cuộc tấn công giả mạo. "])},
        "login_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hãy chắc chắn rằng trình duyệt web của bạn hiển thị một trong các URL SSL chính xác"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mã xác thực đã được gửi."])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không gửi được mã xác thực."])},
        "noemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email đã đăng ký không tồn tại."])},
        "nophone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điện thoại di động đã đăng ký không tồn tại."])}
      }
    },
    "activity": {
      "word": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nhà > Trang của tôi > Hoạt động kế toán"])},
        "record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lịch sử đăng nhập gần đây"])},
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghi ra khỏi mọi thiết bị"])},
        "logout2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghi tên ra"])},
        "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thời gian"])},
        "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Người làm mẫu"])},
        "browser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trình duyệtName"])},
        "ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ip"])},
        "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Địa điểm"])},
        "model2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chủng loại/Trình duyệt"])},
        "ip2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vị trí/IP"])},
        "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Membership Withdrawal"])},
        "withdrawfail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không thể rút lại tư cách thành viên."])},
        "withdraw2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rút ra"])},
        "android": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Android"])},
        "korea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hàn Quốc"])},
        "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sau khi hủy thành viên, bạn sẽ không thể đăng ký lại với cùng thông tin trong vòng 1 tháng.\nBạn có muốn tiếp tục hủy thành viên không?"])}
      },
      "msg": {
        "logout_msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đây là lịch sử đăng nhập Fubit của bạn. "])},
        "logout_msg1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nếu bạn có tiền sử không đăng nhập bản thân,"])},
        "logout_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vui lòng thay đổi mật khẩu tài khoản của bạn ngay lập tức"])},
        "logout_msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["và đăng xuất khỏi tất cả các thiết bị."])},
        "logout_msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "withdraw_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông tin tài khoản sẽ bị xóa ngay lập tức và có thể không thể khôi phục được."])}
      }
    }
  },
  "Influencer": {
    "application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xin một người có ảnh hưởng"])},
    "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kết quả sàng lọc người có ảnh hưởng"])},
    "referer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["người quản lý chuyển giới thiệu"])},
    "referer2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sự giới thiệu"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giấy chứng nhận của người nộp đơn"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mã giới thiệu người có ảnh hưởng"])},
    "entercode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhập mã giới thiệu"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông tin ứng dụng"])},
    "enterinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhập thông tin ứng dụng"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["áp dụng"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sự hủy đơn"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["áp dụng lại"])},
    "noresult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lý do bị từ chối"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["thành công."])},
    "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["thất bại."])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])}
  },
  "nickname": {
    "change_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hãy nhập vào biệt danh bạn muốn thay đổi."])},
    "nickname_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đây là một biệt danh sẵn có."])},
    "phone_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số điện thoại sẵn sàng."])},
    "email_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email này có sẵn."])},
    "authemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email này đã được đăng ký trước."])},
    "authphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số điện thoại này đã được xác thực."])},
    "authfail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xác thực thất bại."])},
    "nicknamefail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lỗi thay đổi biệt danh."])},
    "nicknamesuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biệt danh của bạn đã được thay đổi."])},
    "emailtypefail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Định dạng email không hợp lệ."])},
    "emptyemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vui lòng nhập email để xác thực."])},
    "emptyphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vui lòng nhập số điện thoại để xác thực."])},
    "auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xác thực"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])},
    "beforeauthphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vui lòng hoàn tất xác minh điện thoại di động."])},
    "beforeauthmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vui lòng tiến hành xác minh email."])}
  },
  "contractdetail": {
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chi tiết thắt chặt"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thời gian thắt chặt"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liq.Price"])}
  },
  "withdraworder": {
    "cancel_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn có chắc muốn hủy đơn đặt hàng không?"])}
  },
  "feeinfo": {
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông tin lệ phí"])},
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cấp"])},
    "maker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lệ phí nhà sản xuất"])},
    "taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thu phí"])}
  },
  "authgoogle": {
    "auth_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vui lòng kiểm tra trước khi tiến hành chứng nhận."])},
    "auth_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã cài đặt ứng dụng OTP của Google chưa?"])},
    "googleplay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Play"])},
    "appstore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App Store"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Login"])},
    "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTP yêu cầu thất bại."])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liên kết OTP"])}
  },
  "googleqr": {
    "qr_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quét mã QR bên dưới bằng ứng dụng Google OTP"])},
    "qr_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nếu không thể quét, hãy tự nhập mã."])},
    "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Xác thực"])}
  },
  "googlenumber": {
    "number_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nhập mã 6 chữ số từ Google Authenticator."])},
    "fail_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["!! Mã xác minh không chính xác"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thiết lập xác thực Google OTP đã hoàn tất."])}
  },
  "googlecomplete": {
    "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTP Certificate hoàn tất"])},
    "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xác lập xác thực của Google OTP là"])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trọn vẹn."])}
  },
  "password": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Password"])},
    "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mật khẩu cũ"])},
    "now_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hãy nhập mật khẩu cũ"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mật khẩu mới"])},
    "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mật khẩu"])},
    "new2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xác nhận mật khẩu mới"])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Độ thẳng đứng"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gửi lại"])},
    "resend2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gửi lại"])},
    "authcheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["được chứng nhận."])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đặt lại mật khẩu hoàn tất."])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vui lòng đăng nhập để bắt đầu giao dịch."])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Định dạng mật khẩu không chính xác."])},
    "msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nó không giống một mật khẩu mới."])},
    "msg5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vui lòng kiểm tra mật khẩu hiện tại."])},
    "msg6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vui lòng kiểm tra mật khẩu mới."])},
    "msg7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vui lòng kiểm tra mật khẩu."])}
  },
  "layout": {
    "setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Layout Settings"])}
  },
  "email": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vui lòng nhập 6 mã xác thực nhận nhận được qua email."])},
    "auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["một phần hai"])},
    "auth1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Có sẵn sau"])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vui lòng nhập mã xác thực 6 chữ số được gửi đến điện thoại di động của bạn."])},
    "authsuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã xác minh."])}
  },
  "phone": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vui lòng nhập 6 mã xác thực mà Điện thoại nhận được."])},
    "authsuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nó đã được chứng nhận."])},
    "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thất bại."])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gửi lại"])},
    "resendfail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gửi lại thất bại."])}
  },
  "passwordforgot": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anh quên mật khẩu rồi à?"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vui lòng nhập email đã đăng ký của bạn."])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không tìm thấy thông tin thành viên đã nhập."])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nếu cài đặt lại mật khẩu thì sẽ bị hạn chế rút tiền trong vòng 24 giờ."])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quên mật khẩu"])},
    "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vui lòng nhập mật khẩu mới."])},
    "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hãy nhập mật khẩu mới"])},
    "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vui lòng nhập lại mật khẩu mới."])},
    "text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anh quên mật khẩu rồi à? Vui lòng nhập số điện thoại đã đăng ký của bạn."])},
    "text8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gửi số xác thực"])},
    "text9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không gửi được số xác thực. Xin hãy thử lại."])},
    "text10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are an unreBạn là thành viên chưa đăng ký hoặc có ID hoặc mật khẩu khác. Vui lòng kiểm tra và thử lại.gistered member or have a different ID or password. Please check and try again."])},
    "text11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anh quên mật khẩu rồi à? Vui lòng nhập email đã đăng ký của bạn."])},
    "text12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vui lòng nhập số điện thoại đã đăng ký."])}
  },
  "orderconfirm": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xác nhận đơn đặt hàng"])},
    "deal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giao dịch"])},
    "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mệnh lệnh"])},
    "market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Lệnh thị trường "])},
    "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lệnh giới hạn"])},
    "c_market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["giá thị trường có điều kiện"])},
    "c_designation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giá giới hạn có điều kiện"])},
    "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giá đích"])},
    "lender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cắt giảm tổn thất"])},
    "liquidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thanh lý bắt buộc"])},
    "liquidation2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["một chất lỏng bắt buộc"])},
    "trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xử lý"])}
  },
  "signup": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["đăng ký"])},
    "promotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mã khuyến nghị hoặc khuyến nghị (chọn)"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thỏa thuận và chính sách bảo mật"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tôi xác nhận và đồng ý với"])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã có một tài khoản?"])},
    "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vui lòng nhập email của bạn"])},
    "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email này đã được đăng ký trước."])},
    "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Định dạng tài khoản email không hợp lệ"])},
    "text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vui lòng nhập số điện thoại di động của bạn."])},
    "text8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đó là một chiếc điện thoại di động đã được đăng ký trước."])},
    "text9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông tin đã nhập không khớp với định dạng số điện thoại"])},
    "text10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vui lòng nhập mật khẩu.."])},
    "text11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mật khẩu khác với xác minh mật khẩu."])},
    "text12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mật khẩu đã nhập không khớp với định dạng"])},
    "text13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vui lòng nhập lại mật khẩu mới."])},
    "text14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mật khẩu khác với xác minh mật khẩu."])},
    "text15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mật khẩu đã nhập không khớp với định dạng"])},
    "text16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mã không có sẵn"])},
    "text17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thay đổi ảnh hồ sơ"])},
    "text18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biệt danh này đã được sử dụng."])},
    "text19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biệt danh"])},
    "text20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mã khuyến nghị hoặc khuyến nghị (chọn)"])}
  },
  "withdraw_detail": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông tin chi tiết về đơn xin rút tiền"])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mạng"])},
    "commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chi phí mạng"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số tiền nhận được từ ngân sách"])},
    "execution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thi hành án rút quân"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tên"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đơn đã hoàn tất"])},
    "successdesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đơn xin rút tiền của bạn đã được đăng ký thành công."])},
    "authFail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số xác thực không khớp."])},
    "getQty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["số lượng nhận được"])}
  },
  "loginconfirm": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mục này cần đăng nhập."])},
    "desc2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn có muốn đăng nhập?"])}
  },
  "deal": {
    "closeLong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close Short"])},
    "closeShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close Long"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số dư"])},
    "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chỉ số"])},
    "24change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24H Change"])},
    "countdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đếm ngược"])},
    "funding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funding"])},
    "fundingfee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phí vay vốn"])},
    "valume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volume"])},
    "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High"])},
    "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Low"])},
    "marketprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giá thị trường"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giá"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coin"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["toàn thể"])},
    "trades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["giao dịch"])},
    "scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tỉ lệ"])},
    "scale2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tỉ lệ"])},
    "posopen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mở vị thế"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đóng vị thế"])},
    "market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["thị trưởng"])},
    "trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["điều kiện"])},
    "cross": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đi qua"])},
    "isolate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bị cô lập"])},
    "long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dài"])},
    "short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ngắn"])},
    "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thực thi"])},
    "margin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lề"])},
    "balange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số dư"])},
    "available2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Có sẵn"])},
    "pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unrealized PNL"])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit"])},
    "openshort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ngắn"])},
    "openlong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dài"])},
    "openlong2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buying"])},
    "openshort2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["selling"])},
    "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiền gửi"])},
    "convert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chuyển thành"])},
    "favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yêu thích"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tìm kiếm"])},
    "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ký hiệu"])},
    "lastprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["giá cuối cùng"])},
    "24h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24h"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["số lượng"])},
    "available3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Có sẵn"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hủy"])},
    "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khối lượng nắm giữ"])},
    "pnl2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PNL"])},
    "openorder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lệnh chờ"])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sổ giao dịch"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ví"])},
    "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symbol"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hành động"])},
    "tpsl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TP/SL"])},
    "pnl3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PNL"])},
    "liq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liq,Price"])},
    "side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hướng"])},
    "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giá hiện thời"])},
    "leverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đòn bẩy"])},
    "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["số lượng"])},
    "entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giá nhập"])},
    "leverage2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đòn bẩy"])},
    "leverage3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tỷ số đòn bẩy"])},
    "perpetual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trường cửu"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sửa đổi"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chia sẻ"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thời gian"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiểu"])},
    "allcancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["toàn bộ sự huỷ bỏ"])},
    "shortselling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số lượng bán khống đã thanh lý"])},
    "shortsale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số lượng bán hàng ngắn đã thanh lý"])},
    "market2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giá thị trường"])},
    "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giới hạn"])},
    "averagemarket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giá thị trường bình quân"])},
    "fillprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giá mới nhất"])},
    "trigger2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["điều kiện"])},
    "liq2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit"])},
    "immediate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Market"])},
    "conditionalmarket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chợ có điều kiện"])},
    "conditionallimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chỉ định có điều kiện"])},
    "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giá đích"])},
    "sl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SL"])},
    "liquidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquidation"])},
    "orderprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giá đặt hàng"])},
    "trigger3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["điều kiện"])},
    "completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoàn thành"])},
    "spotwallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ví điểm"])},
    "future": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Future"])},
    "freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["đông cứng"])},
    "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chuyển"])},
    "exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sở giao dịch"])},
    "deposit2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chuyển vào"])},
    "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sự rút tiền"])},
    "ordercondition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điều kiện"])},
    "trigger4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["điều kiện"])},
    "completion2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chi tiết"])},
    "liquidation2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquidation"])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lợi nhuận và lỗ chưa thực hiện và lợi nhuận trên vốn chủ sở hữu dựa trên giá trị trung bình của thị trường."])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khi đạt được giá mục tiêu và giá lỗ, hợp đồng sẽ được thực hiện. Bạn có thể xác định giá mục tiêu bằng cách xác lập các chi tiết (giá mục tiêu dựa trên giá trung bình thị trường)."])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vui lòng nhập số lượng."])},
    "msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vui lòng nhập giá."])},
    "msg5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không có đủ tài sản để đặt hàng."])},
    "msg6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vui lòng nhập giá khởi điểm."])},
    "msg7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã có lệnh."])},
    "msg8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đơn đặt hàng của bạn đã bị hủy."])},
    "msg9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vui lòng nhập giá mục tiêu / giảm tổn thất"])},
    "msg10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trong trường hợp vị thế ngắn, giá mục tiêu phải cao hơn giá ký gần đây."])},
    "msg11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trong trường hợp vị thế ngắn, giá tổn thất nên thấp hơn giá ký kết gần đây"])},
    "msg12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trong trường hợp vị trí lâu dài, giá mục tiêu phải thấp hơn giá đóng cửa gần đây."])},
    "msg13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trong trường hợp vị trí lâu dài, giá tổn thất phải cao hơn giá đóng cửa gần đây."])},
    "msg14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chúng tôi không có đủ khả năng thanh lý."])},
    "msg15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tổng tài sản = số dư ví + biên độ + PNL chưa thực hiện"])},
    "msg16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cho biết số tiền người dùng sử dụng cho một hợp đồng tương lai không xác định."])},
    "msg17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cho biết số dư ví hiện có để thực hiện hợp đồng."])},
    "msg18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lợi nhuận và lợi nhuận chưa thực hiện trên vốn chủ sở hữu dựa trên giá trị trung bình của thị trường."])},
    "msg19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số lượng đặt hàng không đủ."])},
    "chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["đồ thị"])},
    "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mệnh lệnh"])},
    "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chuyển"])},
    "order2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trạng thái đặt hàng"])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đã gửi"])},
    "orderFail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lỗi đặt hàng"])},
    "msg20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hợp đồng sử dụng USDT làm biên độ, nhưng được đại diện bởi số lượng tài sản của cổ phiếu."])},
    "msg21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cho biết biên độ phân bổ cho hợp đồng."])},
    "msg22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giá mua/bán trung bình mà tại đó hợp đồng được thực hiện"])},
    "msg23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nếu tỷ lệ này là dương, thương nhân dài hạn sẽ phải trả một khoản phí tài chính cho vị trí ngắn. Nếu tỷ lệ này là âm, người giao dịch ngắn sẽ trả phí tài chính cho vị trí dài. Phí tài trợ được tính tám giờ một lần."])},
    "msg24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nếu giá trung bình thị trường đạt mức đó, hợp đồng sẽ bị thanh lý."])},
    "msg25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hợp đồng chấm dứt ngay lập tức theo giá bình quân thị trường."])},
    "msg26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kích thước vị thế tối đa cho đòn bẩy hiện tại"])}
  },
  "alert": {
    "orderSuccess": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đặt hàng thành công"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đặt hàng thành công"])}
    },
    "positionOrder": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dịch vụ này yêu cầu đăng nhập."])}
    },
    "favoriteAdd": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Đăng ký thành công"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn đã được đăng ký như là một yêu thích."])}
    },
    "favoriteRemove": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hủy đăng ký"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mục yêu thích của bạn đã bị tắt."])}
    }
  },
  "coin": {
    "btc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitcoin"])},
    "xrp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripple"])},
    "eth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ethereum"])},
    "sol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solana"])},
    "clay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klaytn"])},
    "matic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polygon"])},
    "avax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avalanche"])},
    "usdt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tether"])},
    "doge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dogecoin"])}
  },
  "level": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rút ra level"])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Khi rút toàn bộ tài sản, hạn mức rút sẽ bị giới hạn tùy theo cấp độ xác thực của tài khoản."])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn có thể tăng giới hạn rút tiền bằng cách tăng mức xác thực cho tài khoản của mình."])},
    "lev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["level"])},
    "standard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tiêu chí chứng nhận"])},
    "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["giới hạn rút tiền hàng ngày"])},
    "stage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sân khấu"])},
    "emailor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e-mail or mobile phone"])},
    "emailphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail, cell phone."])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID xác minh"])},
    "idfail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID quá trình xác thực đã thất bại"])},
    "failtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lý do thất bại"])},
    "levelup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["levelup"])}
  },
  "invitemodal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mã mời kết bạn"])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bạn có thể thiết lập tối đa năm mã mời."])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phí chiết khấu sẽ được gửi vào từng ví quà tặng vào lúc 00:00 (UTC) ngày hôm sau và chỉ có thể được sử dụng làm tiền gửi hoặc phí cho các giao dịch."])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "myyoupercent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tôi / Bạn"])},
    "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sự quản lý"])},
    "revise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chữa"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["xóa"])},
    "plus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bổ sung"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["copy"])},
    "code_revise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sửa mã mời"])},
    "code_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thêm mã mời"])},
    "code2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quản lý hợp âm"])},
    "mypercent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["phần trăm bạn nhận được"])},
    "youpercent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tỷ lệ mà một người bạn nhận được"])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tỷ lệ chiết khấu có thể điều chỉnh lên tới 30%."])}
  },
  "influencer_result": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kết quả đánh giá người có ảnh hưởn"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["của người nộp đơn"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mã khuyến nghị giới thiệu"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thông tin ứng dụng"])},
    "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lý do trả lại"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hủy đơn"])},
    "re": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["đăng ký lại"])}
  },
  "idcard": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["xác minh ID"])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["với ID của bạn"])},
    "msg1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vui lòng tải lên một bức ảnh nơi bạn có thể nhìn thấy khuôn mặt của mình"])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tải lên ảnh ID"])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vui lòng đăng ký tệp hình ảnh."])},
    "regist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["đăng ký ảnh"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tải ảnh xác minh lên"])},
    "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["việc đăng kí"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xác minh danh tính đã được yêu cầu."])},
    "msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chụp ảnh ID của bạn và đăng ký phê duyệt từ quản trị viên của bạn."])},
    "cert_picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ảnh xác minh"])},
    "id_picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ảnh chứng minh nhân dân"])},
    "before_id_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vui lòng tải lên hình ảnh giấy tờ tùy thân của bạn."])},
    "before_cert_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vui lòng tải lên hình ảnh xác minh."])}
  },
  "antiphishing": {
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thay đổi yêu cầu"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mã bảo vệ lừa đảo đã được thay đổi."])},
    "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Không thể thay đổi mã chống lừa đảo."])},
    "antiphishing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mã chống lừa đảo"])},
    "lengthover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mã chống lừa đảo không được quá 10 ký tự."])},
    "checkinput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vui lòng nhập mã chống lừa đảo."])}
  },
  "moneytransfer": {
    "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chuyển khoản"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Từ"])},
    "kind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Điểm"])},
    "gift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tương lai"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số dư"])},
    "get": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ĐẾN"])},
    "property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coin"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Số lượng"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["toàn thể"])},
    "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chuyển khoản nội bộ là miễn phí."])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chuyển khoản đã hoàn tất."])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sự kiện, tài sản nhận được bồi thường khách hàng không thể chuyển nhượng."])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nếu bạn di chuyển tài sản trong khi đang giữ vị thế chéo, vị thế đó có thể bị thanh lý và bạn không thể di chuyển tất cả tài sản. Vui lòng nhập chính xác tài sản trao đổi."])}
  },
  "addressmanage": {
    "addsuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Địa chỉ đã được thêm vào"])},
    "addfail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["đăng ký địa chỉ thất bại"])},
    "removesuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Địa chỉ đã bị xóa"])},
    "removefail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["xóa địa chỉ thất bại"])},
    "addname_null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vui lòng nhập tên địa chỉ."])},
    "outputkey_null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vui lòng nhập địa chỉ rút tiền."])},
    "destination_null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vui lòng nhập mã điểm đến."])}
  }
}