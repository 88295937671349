export default {
  "common": {
    "nation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nación"])},
    "nation_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar un país"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar"])},
    "search2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, de búsqueda"])},
    "search3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moneda"])},
    "asset_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestión de activos"])},
    "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha"])},
    "today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoy"])},
    "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1S"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mes"])},
    "1month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1M"])},
    "3month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3M"])},
    "mywallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartera"])},
    "viewwallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resumen"])},
    "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depositar"])},
    "exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convertir"])},
    "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirar"])},
    "margin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Margen"])},
    "operate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acción"])},
    "deposit2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depositar"])},
    "exchange2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convertir"])},
    "withdraw2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirar"])},
    "trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negociar"])},
    "coinsearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar monedas disponibles para depósito"])},
    "networkselection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Red"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hora"])},
    "division": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
    "condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
    "completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completado"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
    "progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendiente"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio"])},
    "commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comisión"])},
    "invest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historial"])},
    "recentorder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orden abierta"])},
    "orderhistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activos"])},
    "profitloss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PNL"])},
    "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Símbolo"])},
    "leverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apalancamiento"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtro"])},
    "invest_limits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Límite"])},
    "invest_marketprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercado"])},
    "invest_conditional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gatillo"])},
    "orderprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio"])},
    "orderquantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
    "contract_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fijado"])},
    "contract_amount2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad de cierre rápido"])},
    "contract_Average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medio"])},
    "contract_Average2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio de cierre Medio"])},
    "target_loss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TP / SL"])},
    "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lado"])},
    "indefinitely": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perpetuo"])},
    "crossbuy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Largo cruzado"])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviado"])},
    "part_contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parcialmente lleno"])},
    "contract_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalle"])},
    "contract_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conclusión"])},
    "trigger-clue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["condición de gatillo"])},
    "trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condición de gatillo"])},
    "trigger_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio de gatillo"])},
    "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evento"])},
    "event_detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles del evento"])},
    "invite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referir"])},
    "usercenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help Center"])},
    "guidebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guía para principiantes"])},
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuncio"])},
    "contact us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar una solicitud"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Título"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha"])},
    "writedate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha"])},
    "mypage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta"])},
    "usersetting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil"])},
    "setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
    "resetting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléfono"])},
    "authcenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguridad"])},
    "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actividad"])},
    "phone_auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificación SMS"])},
    "email_auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificación de correo"])},
    "auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["autenticación"])},
    "google2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTP"])},
    "otp_auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticador de Google OTP"])},
    "change_nickname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar apodo"])},
    "uuid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese UUID"])},
    "retouch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corregir"])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])},
    "deauthorization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desautorización"])},
    "certification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración"])},
    "removeauthfail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La autenticación falló."])},
    "removeauthsuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La autenticación ha sido revocada."])},
    "certificated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["completado"])},
    "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rechazado"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo de futuros"])},
    "30deal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volumen de comercio de 30 días"])},
    "google_auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificación de Google OTP"])},
    "notice2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aviso"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["continuar"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión"])},
    "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google"])},
    "whole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["todo"])},
    "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["símbolo"])},
    "english": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inglés"])},
    "korean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coreano"])},
    "initialization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicialización"])},
    "delet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
    "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi billetera"])},
    "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["falló"])},
    "failfetch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo obtener los datos."])},
    "user_level_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General member"])},
    "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["suscribirse"])},
    "findby": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar por"])},
    "googlecert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["autenticación de Google"])},
    "nodata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los datos no están disponibles."])},
    "stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["acciones"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["next"])},
    "eventword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evento"])},
    "referer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referencia"])}
  },
  "login": {
    "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrarse"])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, introduzca su información de registro."])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al menos una letra minúscula / mayúscula y un número."])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al menos un carácter especial."])},
    "msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mínimo 8 caracteres."])},
    "re": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de nuevo"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de referencia"])},
    "msg5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, introduzca el código de invitación."])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar"])},
    "msg6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["He leído y acepto los"])},
    "msg7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Términos de Servicio y Política de Privacidad"])},
    "msg8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crear cuenta"])},
    "msg9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato de cuenta de correo electrónico no válido"])},
    "msg10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato de contraseña no válido"])},
    "msg11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña inconsistente"])},
    "msg12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este es un correo electrónico disponible."])},
    "msg13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña incorrecta"])},
    "msg14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este servicio está disponible después de iniciar sesión."])},
    "msg15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su sesión de inicio de sesión ha expirado."])},
    "msg16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su registro se ha completado."])},
    "msg17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, inicie sesión para comenzar la transacción."])},
    "msg18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["iniciar sesión"])},
    "msg19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio de sesión exitoso"])},
    "msg20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡El código de autenticación no es válido!"])},
    "msg21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo de espera de autenticación agotado."])},
    "msg22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha completado el registro."])},
    "msg23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vuelva a introducir la contraseña"])},
    "msg24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usted es un miembro no registrado o tiene un ID o contraseña diferente."])},
    "loginword1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["guardar ID"])},
    "msg25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El formato del número de teléfono móvil es incorrecto."])},
    "msg26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Términos y Políticas"])}
  },
  "topmenu": {
    "procenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promoción"])},
    "mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tema"])},
    "sound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonido"])},
    "layout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diseño"])}
  },
  "footer": {
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operar con derivados de criptomonedas conlleva grandes riesgos."])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las transacciones con activos digitales pueden ser irreversibles, por lo tanto, las pérdidas"])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["debidas a transacciones fraudulentas o accidentales pueden no ser recuperables."])},
    "introduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presentación de la empresa"])},
    "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avisos"])},
    "center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centro de protección de inversiones"])},
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preguntas frecuentes"])},
    "kakao": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacto de KakaoTalk"])},
    "inquiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacta con nosotros"])},
    "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empresa"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soporte al cliente"])},
    "word1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["서울시 강남구 테헤란로 1길 23, 4층"])},
    "word2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["주식회사 푸빗"])},
    "word3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["대표 김푸빗"])},
    "word4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["사업자 등록번호 123-45-67890"])},
    "word5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["가상 사업자 등록번호 123-45-67890"])},
    "word6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puede acceder a una variedad de servicios en Fu-bit después de iniciar sesión."])}
  },
  "mainhome": {
    "word": {
      "startdeal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comenzar a operar"])},
      "populardeal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caliente"])},
      "bigchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mayor Volumen"])},
      "highprofit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mayores Ganancias"])},
      "marketstatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercado"])},
      "newcoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva lista"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio(USDT)"])},
      "ratechange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar(24H)"])},
      "highprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alto(24H)"])},
      "lowprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bajo(24H)"])},
      "viewmore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más"])},
      "guide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guía para principiantes"])},
      "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depositar"])},
      "trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Futuros"])},
      "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soporte 24/7"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar"])},
      "inspect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inspeccionar"])}
    },
    "msg": {
      "mainbannermsg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opere con Fubit"])},
      "mainbannermsg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gane de forma rápida y segura."])},
      "mainbannermsg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Únase ahora y obtenga 30 USDT"])},
      "middlebannermsg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercambio de monedas simple y rápido"])},
      "middlebannermsg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercambio de activos digitales simple y rápido desde la información de la moneda hasta la orden"])},
      "bottomtit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡Comience ahora! Haga sus operaciones rentables"])},
      "bottom1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "bottom1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fácil de entender para todos"])},
      "bottom2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regalo de 10 USDT para el primer depósito"])},
      "bottom2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "bottom3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bote de premios de 100 USDT,"])},
      "bottom3_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Únete y comienza"])},
      "bottom4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pregúntenos cualquier cosa"])},
      "bottom4_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estamos a su disposición las 24 horas."])},
      "modalmsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No volver a mostrar."])},
      "inputtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca su correo electrónico."])}
    }
  },
  "trade": {
    "modal": {
      "margin_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modo de margen perpetuo"])},
      "cross": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cruce"])},
      "isolated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aislado"])},
      "cross2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modo de margen cruzado"])},
      "isolated2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modo de margen aislado"])},
      "adjust": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajustar margen"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar"])},
      "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remover"])},
      "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Máximo agregable"])},
      "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamaño"])},
      "est": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio de liquidación estimado"])},
      "profit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objetivo"])},
      "loss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cortado"])},
      "entryprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio de entrada"])},
      "liq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio de liquidación"])},
      "size2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamaño"])},
      "pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ganancia estimada"])},
      "basis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar base de precio"])},
      "tp_sl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TP / SL para toda la posición"])},
      "markprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio de marcado"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descargar"])},
      "liq2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar posición"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad de orden"])}
    },
    "msg": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar el modo de margen solo se aplicará al contrato seleccionado."])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todas las posiciones cruzadas bajo el mismo margen de activos comparten el mismo balance de margen de activos cruzados."])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En caso de liquidación, se puede perder el saldo completo de margen de activos y las posiciones abiertas restantes bajo el activo."])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administre su riesgo en posiciones individuales restringiendo la cantidad de margen asignado a cada una."])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si la relación de margen de una posición alcanza el 100%, se liquidará la posición. Se puede agregar o eliminar margen a las posiciones utilizando este modo."])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si la posición está abierta, no se puede cambiar el modo de margen."])}
    }
  },
  "mywallet": {
    "walletview": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INICIO > cartera > resumen"])},
      "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocultar"])},
      "totalasset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo total"])},
      "checkboxtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sin soporte de transacciones / ocultación de activos pequeños"])},
      "loss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PNL no realizado"])},
      "possibleasset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo"])},
      "inkindasset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al contado"])},
      "coin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["total"])},
      "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible"])},
      "freez": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En orden"])},
      "bitcoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitcoin"])},
      "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depositar"])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirar"])},
      "forward": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activos futuros"])},
      "mobilelist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detall de regalo"])},
      "mobilelist2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detall de alimento"])},
      "futurelist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historial de billetera de futuros"])}
    },
    "depositwallet": {
      "word": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INICIO > cartera > Depositar"])},
        "assetselection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar moneda"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de depósito"])},
        "precautions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aviso"])},
        "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar"])},
        "destination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destino"])},
        "depositdetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rista de depósito"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad a depositar"])},
        "copy2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copia completada"])},
        "inoutlist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depósito y retiro"])}
      },
      "msg": {
        "precautions_msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Elija el activo y la red a la que desea depositar, y asegúrese de que sea correcto."])},
        "precautions_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Tome una foto de la dirección de depósito como un código QR o péguela en su billetera de retiro y solicite un retiro."])},
        "precautions_msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Espere hasta que se confirme la transmisión de la red blockchain."])},
        "precautions_msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4. Fubit envía los activos a la dirección de la billetera después de que la red blockchain confirma la transmisión."])},
        "precautions_msg5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["※ Es posible que tenga activos que requieran datos adicionales o etiquetas de destino además de las direcciones."])},
        "precautions_msg6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["※ Fubit no se hace responsable de los problemas causados por la introducción incorrecta de información de depósito."])},
        "address_msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "address_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar e ingresar la dirección de depósito"])},
        "alertmsg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La fecha de finalización no puede ser anterior a la fecha de inicio."])}
      }
    },
    "exchange": {
      "word": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INICIO > monedero > Convertir"])},
        "before": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De"])},
        "exchange_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
        "after": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A"])},
        "possible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible"])},
        "expect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recibirá"])},
        "coinprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio"])},
        "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor ingrese"])},
        "amount_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Límite restante de 24 horas"])},
        "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rista de cambio"])},
        "exchanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ejecutado"])},
        "price_check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vista previa de la conversión"])},
        "countLimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["segundos"])},
        "countlimit2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar después de"])}
      },
      "msg": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha sido intercambiado."])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fallo en el intercambio."])},
        "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese la cantidad."])},
        "notenough": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activos intercambiables insuficientes."])}
      }
    },
    "withdraw": {
      "word": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INICIO > billetera > Retirar"])},
        "location2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INICIO > billetera > Retirar > Administrar dirección"])},
        "address_manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrar dirección"])},
        "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar moneda"])},
        "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrar dirección"])},
        "address_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dirección"])},
        "coinsearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar monedas para retirar"])},
        "address2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar Libreta de direcciones"])},
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
        "withdrawpossible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad disponible para retirar"])},
        "withdrawpossible2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad disponible"])},
        "withdrawdetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rista de fondos"])},
        "precautions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precauciones para el retiro"])},
        "minimum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad mínima de retiro"])},
        "amount_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Límite de retiro de 24 horas"])},
        "asset2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar moneda"])},
        "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de retiro"])},
        "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrada directa"])},
        "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Red"])},
        "minimum2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad mínima de retiro: 0.001"])},
        "24withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Máximo monto retirables en 24 horas"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de la dirección"])},
        "withdrawaddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección de retiro"])},
        "adresslist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["detalles de dirección"])},
        "adressplus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar dirección"])},
        "noadress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No existen direcciones registradas."])},
        "adressmsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese hasta 4-5 caracteres"])},
        "mylevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi nivel de retiro"])},
        "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nivel"])},
        "levelbtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aumentar el nivel"])},
        "destination_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese el código de destino"])},
        "addr_err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["error de dirección"])},
        "io_check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mantenimiento"])},
        "over_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activo anormal"])},
        "inquiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["consulta al cliente"])},
        "notag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag no ingresado"])},
        "treble": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmación de Treble-Rule"])}
      },
      "msg": {
        "withdraw_msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Asegúrate de que el activo seleccionado para el retiro y la dirección de retiro ingresada sean del mismo activo."])},
        "withdraw_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- El activo de retiro seleccionado solo se puede recibir en una dirección donde se pueda depositar el activo."])},
        "withdraw_msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Después de que se complete la solicitud de retiro, generalmente tarda alrededor de una hora verificar los detalles en la billetera donde se recibió el depósito."])},
        "withdraw_msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- El procesamiento del retiro puede retrasarse según las circunstancias, como la red blockchain."])},
        "withdraw_msg5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Si estás depositando en otra billetera de intercambio, asegúrate de verificar la política de intercambio."])},
        "withdraw_msg6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Después de que se completa la solicitud de retiro, los procedimientos posteriores se procesan en la red blockchain."])},
        "withdraw_msg7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Debido a la naturaleza de la blockchain, una vez que comienza el retiro, no se puede cancelar."])},
        "withdraw_msg8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Como los retiros se procesan en el sistema de Fubit, la dirección de retiro puede variar según la situación."])},
        "withdraw_msg9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["- Todos los daños causados ​​por ingresar incorrectamente la información de retiro o no verificar las políticas de la billetera o el intercambio receptor son responsabilidad del usuario, y si se sospecha de transacciones fraudulentas o anormales, Fubit puede restringir el retiro."])},
        "withdraw_msg10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingresa la dirección de retiro."])},
        "withdraw_msg11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activos insuficientes disponibles para retiro."])}
      }
    }
  },
  "invest": {
    "recentorder": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INICIO > Historial > Órdenes abiertas"])}
    },
    "orderhistory": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INICIO > Historial > Activos"])}
    },
    "profitloss": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INICIO > Historial > PNL"])},
      "position_clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquidación de posición"])},
      "position_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración de posición"])},
      "average_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio de entrada"])},
      "liquidation_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio"])},
      "loss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beneficio realizado"])}
    }
  },
  "promotion": {
    "event": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INICIO > Promoción > Evento"])},
      "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regalo de bienvenida"])},
      "bonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago de bonificación por registro de membresía"])},
      "bonus_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pago de bonificación por depósitos de $10,000 o más en agosto"])},
      "mision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misión iniciada"])},
      "ongoing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En marcha"])},
      "acquire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adquisición"])},
      "acquire_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La recompensa de la misión ha sido depositada en la cartera de regalos."])},
      "completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminación"])},
      "participate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Participación en la misión"])},
      "participate_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La misión comienza ahora."])},
      "event_compensation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premio del evento"])},
      "mision_clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Misión completa"])},
      "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Términos"])},
      "terms1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Términos del servicio"])},
      "terms2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de privacidad"])},
      "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar enlace"])},
      "noticedetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles del aviso"])},
      "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En una lista"])},
      "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver"])},
      "rewarded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "rewarded2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["se ha depositado en su billetera como compensación."])},
      "unlimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ilimitado"])},
      "term1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El evento de Fubit es para clientes que se hayan registrado como miembros."])},
      "term2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si participa en el evento de manera anormal o ilegal, puede ser excluido de la selección del ganador y puede ser cancelado incluso después de ganar."])},
      "term3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las recompensas se pagarán en el centro de promoción objetivo después de que el evento esté en marcha o termine. La compensación debe recibirse manualmente."])},
      "term4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La recompensa que obtenga por la invitación de su amigo se pagará automáticamente a las 00:00 (UTC) del día siguiente."])},
      "term5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todos los participantes deben cumplir con los términos y condiciones de Fubit. Fubit se reserva el derecho de descalificar a los participantes por actividades fraudulentas o violatorias durante el evento, incluida la inscripción de una cuenta por lotes para recibir bonificaciones adicionales y otras actividades relacionadas con fines ilegales, ilegales y perjudiciales."])},
      "term6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las recompensas solo se pueden utilizar para el comercio de futuros y no se pueden retirar directamente."])},
      "term7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las recompensas se pueden utilizar como margen y también se pueden utilizar para pagar tarifas y pérdidas de transacción."])},
      "term8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las recompensas se pueden utilizar para retirar todas las ganancias acumuladas como resultado de una transacción exitosa."])},
      "term9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiene el derecho de cambiar los reglamentos de este evento sin previo aviso de Fubit."])},
      "term10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fubit tendrá la interpretación final de este evento. Si tiene alguna pregunta, comuníquese con el servicio de atención al cliente."])}
    },
    "invite": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INICIO > Promoción > Referencia"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi estado de referencia"])},
      "info_msg_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes invitar amigos y recibir reembolsos de tarifas."])},
      "info_msg_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basado en la tarifa incurrida por la transacción del amigo que invitaste, mi amigo y yo recibiremos un reembolso dividido."])},
      "info_msg_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El código de invitación de amigo establece el porcentaje de la tarifa que tú y tu amigo recibirán como reembolso."])},
      "info_msg_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para obtener más información, haz clic en Gestionar código de invitación."])},
      "ranking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recompensa de clasificación de invitación de amigos"])},
      "ranking_msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta es la clasificación de la persona que invitó a la mayoría de amigos en un mes."])},
      "ranking_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las recompensas se depositarán automáticamente en tu billetera de regalos a las 00:00 (UTC) del primer día del mes siguiente para el primer al décimo lugar en la clasificación de Invitar a un amigo."])},
      "ranking_msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las recompensas pagadas como recompensas de clasificación se pueden utilizar como márgenes y tarifas requeridas para la negociación, pero no se pueden retirar externamente."])},
      "expect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recompensa"])},
      "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moneda"])},
      "compensation2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recompensa total"])},
      "person2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enlace de referencia"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de referencia"])},
      "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compartir"])},
      "invite_rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabla de clasificación"])},
      "rank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clasificación"])},
      "rank2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["º"])},
      "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
      "influencer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitud de influenciador"])},
      "influencerreview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selección de influenciadores"])},
      "influencerinquire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar influenciador"])},
      "compensation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recompensa"])},
      "invite_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amigos invitados"])},
      "invite_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hora"])},
      "compensation3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recompensa de hoy"])},
      "invite_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hora"])},
      "invite_friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de usuario"])},
      "reward_join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recompensa por unirse"])},
      "reward_deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recompensa por depósito"])},
      "reward_deal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recompensa por negociación"])},
      "reward_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moneda"])},
      "title_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invitación de amigos"])},
      "info_msg_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El enlace o código ha sido copiado."])},
      "code_none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, establezca el Código de Invitación en Gestionar código de invitación."])}
    }
  },
  "usercenter": {
    "guidebook": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INICIO > Help Center > Guía para Principiantes"])},
      "location2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INICIO > Help Center > Guía para Principiantes > Detalles de la guía"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Búsqueda de guía"])},
      "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles de la guía"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información de la guía"])},
      "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver lista"])},
      "image": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver imagen"])},
      "list_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cómo depositar activos"])},
      "nodata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No existe guía registrada."])},
      "linkcopysuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiado al portapapeles."])}
    },
    "notice": {
      "view": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INICIO > Help Center > Anuncios"])}
      },
      "detail": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INICIO > Help Center > Anuncios"])}
      },
      "nonotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay ningún aviso registrado."])}
    },
    "inquiry": {
      "list": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INICIO > Help Center > Enviar una solicitud > Solicitudes"])},
        "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitudes"])},
        "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis solicitudes"])},
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
        "situation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
        "answer_wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esperando respuesta"])},
        "answer_complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respuesta completa"])},
        "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cuenta"])},
        "nolist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay ninguna consulta."])},
        "answer_wait_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esperando su respuesta."])}
      },
      "reg": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INICIO > Help Center > Enviar una solicitud > Mis solicitudes"])},
        "usercenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help Center"])},
        "guidebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manual de usuario"])},
        "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aviso"])},
        "inquiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulta 1:1"])},
        "inquirylist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulta 1:1"])},
        "inquiry_regist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis solicitudes"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar tipo"])},
        "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccione un tipo."])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Título"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenido de la consulta"])},
        "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se pueden escribir 30 caracteres o menos."])},
        "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se pueden ingresar más de 30 caracteres y menos de 500 caracteres."])},
        "att_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjuntar archivo"])},
        "att_file2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["archivo adjunto"])},
        "find_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivo"])},
        "regist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro"])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La consulta ha sido realizada."])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falló."])},
        "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleccionar elemento"])},
        "answer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenido de respuesta"])},
        "msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese un título."])},
        "msg5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese el contenido."])}
      }
    }
  },
  "mypage": {
    "usersetting": {
      "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INICIO > Cuenta > perfil"])},
      "location2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INICIO > Cuenta > perfil > configuración"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar contraseña"])},
      "password2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encontrar tu contraseña"])},
      "google_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticador de Google"])},
      "google_not_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desvincular Google"])},
      "login_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificación de inicio de sesión"])},
      "recent_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Última sesión"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro"])},
      "mycommission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis tarifas"])},
      "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["descuento en las tarifas de transacción"])},
      "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tarifa estándar"])},
      "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grado de descuento"])},
      "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tasa de descuento de tarifas"])},
      "discount_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se aplicará la tarifa estándar a la transacción, y la cantidad con descuento se depositará en la billetera de regalo a las 0:00 (UTC) del día siguiente a la fecha de la transacción."])},
      "screen_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diseño"])},
      "notification_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuración de notificaciones"])},
      "sound_setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sonido"])},
      "setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["configuración"])},
      "removegooglelink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Estás seguro de que quieres desactivar el vínculo con Google?"])},
      "removeotp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El enlace de Google ha sido desactivado."])},
      "toggle": {
        "notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anuncio"])},
        "darkmode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tema"])},
        "whitemode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modo blanco"])},
        "marketstatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
        "favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorito"])},
        "recentdeal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operaciones"])},
        "call": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orden"])},
        "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balance"])},
        "e_login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio de sesión por correo electrónico"])},
        "e_liquidate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquidación forzada por correo electrónico"])},
        "e_withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirada por correo electrónico"])},
        "e_deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depósito por correo electrónico"])},
        "sms_wd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notificación SMS de entrada/salida"])},
        "ordercomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orden enviada"])},
        "trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orden de activación"])},
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orden cancelada"])},
        "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orden completada"])},
        "conclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orden ejecutada"])},
        "liquidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquidación forzada"])}
      }
    },
    "authcenter": {
      "word": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INICIO > Cuenta > Seguridad"])},
        "accountlevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nivel de riesgo de la cuenta: "])},
        "safe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguro"])},
        "normal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En general"])},
        "danger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alto"])},
        "otp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTP Authenticator"])},
        "anti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código Anti-phishing"])},
        "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar"])},
        "anti2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código Anti-phishing: "])},
        "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dentro de 10 caracteres"])}
      },
      "msg": {
        "level_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para la seguridad de la cuenta, por favor establezca la autenticación de seguridad."])},
        "level_msg_m1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para una cuenta segura"])},
        "level_msg_m2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, añada la autenticación de seguridad."])},
        "email_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vincule su dirección de correo electrónico a su cuenta para recuperar la contraseña y confirmar la retirada."])},
        "phone_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vincule su número de móvil a su cuenta para recibir códigos de verificación vía SMS."])},
        "google_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google OTP se utiliza como verificación de seguridad para iniciar sesión, retirar o cambiar la configuración de seguridad."])},
        "usdt_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registre su certificación y reciba 50 USDT como recompensa."])},
        "security_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los correos electrónicos enviados contendrán su código anti-phishing para proteger contra ataques de phishing."])},
        "login_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, asegúrese de que su navegador web muestra una de las URLs SSL correctas."])},
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El número de autenticación ha sido enviado."])},
        "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se ha podido enviar el código de verificación."])},
        "noemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El correo electrónico registrado no existe."])},
        "nophone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El teléfono móvil registrado no existe."])}
      }
    },
    "activity": {
      "word": {
        "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INICIO > Cuenta > Actividad"])},
        "record": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historial de inicio de sesión reciente"])},
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar sesión en todos los dispositivos"])},
        "logout2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar sesión"])},
        "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo de conexión"])},
        "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelo"])},
        "browser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navegador"])},
        "ip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP"])},
        "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubicación"])},
        "model2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modelo/Navegador"])},
        "ip2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubicación/IP"])},
        "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retiro de membresía"])},
        "withdrawfail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo retirar la membresía."])},
        "withdraw2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirar"])},
        "android": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Android"])},
        "korea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corea"])},
        "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puede registrarse con la misma información durante 1 mes después de retirarse de la membresía. \n¿Desea proceder con el retiro de la membresía?"])}
      },
      "msg": {
        "logout_msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta es tu historial de inicio de sesión en Fubit."])},
        "logout_msg1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si tienes un historial de inicio de sesión que no reconoces,"])},
        "logout_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cambia tu contraseña de inmediato"])},
        "logout_msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["y cierra sesión en todos los dispositivos."])},
        "logout_msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "withdraw_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La información de la cuenta se eliminará inmediatamente y puede que no sea recuperable."])}
      }
    }
  },
  "Influencer": {
    "application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitud de influencer"])},
    "result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultados de la selección de influencers"])},
    "referer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gerente de referencias"])},
    "referer2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["referencias"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información del solicitante"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de recomendación del influencer"])},
    "entercode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese el código de referencia"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información de la solicitud"])},
    "enterinfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese la información de la solicitud"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplicar"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar solicitud"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reaplicar"])},
    "noresult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivo de rechazo"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["se ha completado exitosamente."])},
    "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ha fallado."])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])}
  },
  "nickname": {
    "change_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor ingresa el apodo que deseas cambiar."])},
    "nickname_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este apodo está disponible."])},
    "phone_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Números de teléfono disponibles."])},
    "email_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este correo electrónico está disponible."])},
    "authemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este correo electrónico ya ha sido suscrito."])},
    "authphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este número de teléfono ya ha sido autenticado."])},
    "authfail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falló la autenticación."])},
    "nicknamefail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falló al cambiar el apodo."])},
    "nicknamesuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu apodo ha sido cambiado."])},
    "emailtypefail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El formato de correo electrónico no es válido."])},
    "emptyemail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor ingresa un correo electrónico para autenticar."])},
    "emptyphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor ingresa un número de teléfono para autenticar."])},
    "auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticar"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["correo electrónico"])},
    "beforeauthphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, complete la verificación del teléfono móvil.."])},
    "beforeauthmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, proceda con la verificación de correo electrónico."])}
  },
  "contractdetail": {
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fastening details"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tightening time"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liq.Price"])}
  },
  "withdraworder": {
    "cancel_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estás seguro de que quieres cancelar el pedido?"])}
  },
  "feeinfo": {
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información de tarifas"])},
    "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nivel"])},
    "maker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarifa de fabricante"])},
    "taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarifa de tomador"])}
  },
  "authgoogle": {
    "auth_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, revise antes de proceder con la certificación."])},
    "auth_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Está instalada la aplicación de Google OTP?"])},
    "googleplay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google Play"])},
    "appstore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["App Store"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio de sesión en Google"])},
    "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La solicitud de Google OTP falló."])},
    "btn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["interconexión OTP"])}
  },
  "googleqr": {
    "qr_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escanee el código QR a continuación con Google OTP APP"])},
    "qr_msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si no es posible escanear, ingrese el código manualmente."])},
    "google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticación de Google"])}
  },
  "googlenumber": {
    "number_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese el código de 6 dígitos de Google Authenticator."])},
    "fail_msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡¡Código de verificación incorrecto!!"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La configuración de autenticación de Google OTP se ha completado."])}
  },
  "googlecomplete": {
    "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificación de Google OTP completada"])},
    "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La configuración de autenticación de Google OTP está"])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["completa."])}
  },
  "password": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Número de teléfono"])},
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar contraseña"])},
    "now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña anterior"])},
    "now_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese la contraseña anterior"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nueva contraseña"])},
    "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])},
    "new2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar nueva contraseña"])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificación"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reenviar"])},
    "resend2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reenviar"])},
    "authcheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verificado."])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambio de contraseña completado."])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, inicie sesión para comenzar la transacción."])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El formato de la contraseña no es correcto."])},
    "msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La nueva contraseña no es correcta."])},
    "msg5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, revise la contraseña actual."])},
    "msg6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, revise la nueva contraseña."])},
    "msg7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, revise la contraseña."])}
  },
  "layout": {
    "setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajustes de Diseño"])}
  },
  "email": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese el código de verificación de 6 dígitos recibido por correo electrónico."])},
    "auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["segundos"])},
    "auth1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible después de"])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese el código de autenticación de 6 dígitos enviado a su teléfono móvil."])},
    "authsuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificado."])}
  },
  "phone": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese el código de verificación de 6 dígitos recibido por teléfono."])},
    "authsuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Está certificado."])},
    "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falló."])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reenviar"])},
    "resendfail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reenvío fallido."])}
  },
  "passwordforgot": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Olvidaste tu contraseña?"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese su correo electrónico registrado."])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo encontrar la información de membresía ingresada."])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los retiros, las ventas P2P y los servicios de pago se desactivarán durante 24 horas después de realizar este cambio para proteger su cuenta."])},
    "title2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olvidé la Contraseña"])},
    "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese una nueva contraseña."])},
    "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese la nueva contraseña."])},
    "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese la nueva contraseña de nuevo."])},
    "text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Olvidaste tu contraseña? Ingrese su número de teléfono móvil registrado."])},
    "text8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar número de autenticación"])},
    "text9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo enviar el número de autenticación. Por favor, inténtelo de nuevo."])},
    "text10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No eres un miembro registrado o tienes un ID o contraseña diferente. Por favor, verifique y vuelva a intentarlo."])},
    "text11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Olvidaste tu contraseña? Ingrese su correo electrónico registrado."])},
    "text12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingrese su teléfono móvil registrado."])}
  },
  "orderconfirm": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmación del pedido"])},
    "deal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transacción"])},
    "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["orden"])},
    "market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mercado"])},
    "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Límite"])},
    "c_market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["precio de mercado condicional"])},
    "c_designation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["precio límite condicional"])},
    "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio objetivo"])},
    "lender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corte de pérdidas"])},
    "liquidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquidación obligatoria"])},
    "liquidation2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["un liquidador obligatorio"])},
    "trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disparador"])}
  },
  "signup": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro"])},
    "promotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de promoción o recomendación (Seleccione)"])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Términos de servicio y política de privacidad"])},
    "text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmo y acepto los"])},
    "text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Ya tienes una cuenta?"])},
    "text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor ingrese su correo electrónico."])},
    "text5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este correo electrónico ya ha sido suscrito."])},
    "text6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato de cuenta de correo electrónico no válido."])},
    "text7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor ingrese su número de teléfono móvil."])},
    "text8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este teléfono móvil ya está registrado."])},
    "text9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La información ingresada no coincide con el formato del número de teléfono."])},
    "text10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor ingrese una contraseña."])},
    "text11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La contraseña es diferente de la verificación de contraseña."])},
    "text12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La contraseña ingresada no coincide con el formato."])},
    "text13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor ingrese la nueva contraseña de nuevo."])},
    "text14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La contraseña es diferente de la verificación de contraseña."])},
    "text15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La contraseña ingresada no coincide con el formato."])},
    "text16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código no disponible"])},
    "text17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar foto de perfil"])},
    "text18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este apodo ya está en uso."])},
    "text19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apodo"])},
    "text20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de promoción o recomendación (Seleccione)"])}
  },
  "withdraw_detail": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles de solicitud de retiro"])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Red"])},
    "commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarifas de red"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monto recibido"])},
    "execution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ejecución de retiro"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitud completada"])},
    "successdesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Su solicitud de retiro ha sido registrada exitosamente."])},
    "authFail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Los números de autenticación no coinciden."])},
    "getQty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cantidad recibida"])}
  },
  "loginconfirm": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iniciar sesión"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este elemento requiere iniciar sesión."])},
    "desc2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Quieres iniciar sesión?"])}
  },
  "deal": {
    "closeLong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar Corto"])},
    "closeShort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar Largo"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo"])},
    "index": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Índice"])},
    "24change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambio en 24 horas"])},
    "countdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta atrás"])},
    "funding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financiamiento"])},
    "fundingfee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarifa de financiación"])},
    "valume": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volumen"])},
    "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alto"])},
    "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bajo"])},
    "marketprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercado"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todo"])},
    "trades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operaciones"])},
    "scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escala"])},
    "scale2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escala"])},
    "posopen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrir"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerrar"])},
    "market": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercado"])},
    "trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disparador"])},
    "cross": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cruz"])},
    "isolate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aislado"])},
    "long": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Largo"])},
    "short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corto"])},
    "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ejecutado"])},
    "margin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Margen"])},
    "balange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo"])},
    "available2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible"])},
    "pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PNL no realizado"])},
    "unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unidad"])},
    "openshort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corto"])},
    "openlong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Largo"])},
    "openlong2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprar"])},
    "openshort2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vender"])},
    "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depositar"])},
    "convert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convertir"])},
    "favorite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorito"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar"])},
    "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Símbolos"])},
    "lastprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Último precio"])},
    "24h": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24h"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamaño"])},
    "available3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
    "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posición"])},
    "pnl2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PNL"])},
    "openorder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orden abierta"])},
    "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historial de órdenes"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activo"])},
    "contract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Símbolo"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acción"])},
    "tpsl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TP/SL"])},
    "pnl3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PNL"])},
    "liq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["liquidación"])},
    "side": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lado"])},
    "current": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mercado"])},
    "leverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apalancamiento"])},
    "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamaño"])},
    "entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["precio medio"])},
    "leverage2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apalancamiento"])},
    "leverage3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ratio de apalancamiento"])},
    "perpetual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perpetuo"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editar"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compartir"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiempo"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
    "allcancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar todo"])},
    "shortselling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad de venta en corto liquidada"])},
    "shortsale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad de venta en corto liquidada"])},
    "market2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercado"])},
    "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Límite"])},
    "averagemarket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio de mercado promedio"])},
    "fillprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio de liquidación"])},
    "trigger2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disparador"])},
    "liq2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Límit"])},
    "immediate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercado"])},
    "conditionalmarket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio de mercado condicional"])},
    "conditionallimit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Límite condicional"])},
    "target": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio objetivo"])},
    "sl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SL"])},
    "liquidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquidación"])},
    "orderprice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio de orden"])},
    "trigger3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disparador"])},
    "completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completado"])},
    "spotwallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cartera spot"])},
    "future": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Futuro"])},
    "freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congelar"])},
    "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mover"])},
    "exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intercambiar"])},
    "deposit2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depósito"])},
    "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retiro"])},
    "ordercondition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condición de la orden"])},
    "trigger4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disparador"])},
    "completion2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalle"])},
    "liquidation2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liquidación"])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las ganancias y pérdidas no realizadas y el retorno sobre el patrimonio se basan en el promedio del mercado."])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuando se alcanzan el precio objetivo y el precio de pérdida, se ejecuta el conjunto de contrato. Puede establecer el precio objetivo configurando los detalles (el precio objetivo se basa en el precio promedio del mercado)."])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor ingrese la cantidad."])},
    "msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor ingrese el precio."])},
    "msg5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay suficientes activos disponibles para hacer un pedido."])},
    "msg6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor ingrese el precio de invocación."])},
    "msg7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["se han realizado pedidos."])},
    "msg8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha cancelado su pedido."])},
    "msg9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor ingrese el precio objetivo / corte de pérdidas"])},
    "msg10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En el caso de la posición corta, el precio objetivo debe ser mayor que el precio de firma reciente."])},
    "msg11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En el caso de la posición corta, el precio de pérdida debe ser menor que el precio de firma reciente."])},
    "msg12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En el caso de posiciones largas, el precio objetivo debe ser menor que el precio de cierre reciente."])},
    "msg13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En el caso de posiciones largas, el precio de pérdida debe ser mayor que el precio de cierre reciente."])},
    "msg14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tenemos suficiente liquidación disponible."])},
    "msg15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activos totales = saldo de billetera + margen + Ganancias y pérdidas no realizadas"])},
    "msg16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indica la cantidad utilizada para un conjunto de contratos futuros indefinidos establecidos por el usuario."])},
    "msg17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indica el saldo de billetera disponible actualmente para la ejecución del contrato."])},
    "msg18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las ganancias y pérdidas no realizadas y el retorno sobre el patrimonio se basan en el promedio del mercado."])},
    "msg19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La cantidad disponible para realizar pedidos es insuficiente."])},
    "chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gráfico"])},
    "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pedido"])},
    "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferencia"])},
    "order2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviado"])},
    "orderFail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pedido fallido"])},
    "msg20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El contrato utiliza USDT como margen, pero se representa por la cantidad de activos de la acción."])},
    "msg21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indica el margen asignado al contrato."])},
    "msg22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precio promedio de compra/venta al que se ejecutó el contrato"])},
    "msg23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si la relación es positiva, el operador largo pagará una tarifa de financiación por la posición corta. Si la relación es negativa, el operador corto pagará una tarifa de financiación por la posición larga. Las tarifas de financiamiento se calculan cada ocho horas."])},
    "msg24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si el precio promedio de mercado alcanza ese precio, el contrato será liquidado."])},
    "msg25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El contrato se termina inmediatamente al precio promedio de mercado."])},
    "msg26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tamaño máximo de posición para el apalancamiento actual"])}
  },
  "alert": {
    "orderSuccess": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orden realizada con éxito"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orden realizada con éxito"])}
    },
    "positionOrder": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicio de sesión"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este servicio requiere inicio de sesión."])}
    },
    "favoriteAdd": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro exitoso"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha registrado como favorito."])}
    },
    "favoriteRemove": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar registro"])},
      "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se han desactivado tus favoritos."])}
    }
  },
  "coin": {
    "btc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitcoin"])},
    "xrp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ripple"])},
    "eth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ethereum"])},
    "sol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solana"])},
    "clay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klaytn"])},
    "matic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polygon"])},
    "avax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avalanche"])},
    "usdt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tether"])},
    "doge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dogecoin"])}
  },
  "level": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nivel de retiro"])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al retirar todos los activos, el límite de retiro está limitado según el nivel de autenticación de la cuenta."])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes aumentar el límite de retiro elevando el nivel de autenticación de tu cuenta."])},
    "lev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nivel"])},
    "standard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criterios de certificación"])},
    "withdraw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["un límite de retiro diario"])},
    "stage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etapa"])},
    "emailor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["correo electrónico o teléfono móvil"])},
    "emailphone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico, teléfono celular."])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificación de identidad"])},
    "idfail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La autenticación de identidad falló"])},
    "failtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivo del fallo"])},
    "levelup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aumentar nivel"])}
  },
  "invitemodal": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de invitación de amigos"])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puedes establecer hasta cinco códigos de invitación."])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tarifa con descuento se depositará en cada billetera de regalo a las 00:00 (UTC) del día siguiente, y solo se puede usar como depósito o tarifa para transacciones."])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código"])},
    "myyoupercent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["relación tuya/amigo"])},
    "manage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administración"])},
    "revise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
    "plus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adicional"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiar"])},
    "code_revise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificar código de invitación"])},
    "code_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agregar código de invitación"])},
    "code2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrarse como influyente"])},
    "mypercent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["el porcentaje que recibes"])},
    "youpercent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["la tasa a la que un amigo recibe"])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las tarifas de descuento ajustables son de hasta el 30%."])}
  },
  "influencer_result": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultados de revisión de influyentes"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta del solicitante"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de recomendación de referencia"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información de solicitud"])},
    "return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["razones de devolución"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancelación de solicitud"])},
    "re": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["volver a aplicar"])}
  },
  "idcard": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verificación de identificación"])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["con su identificación"])},
    "msg1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, suba una foto donde se pueda ver su cara"])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subir foto de identificación"])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registro de archivos de imagen"])},
    "regist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["registro de fotos"])},
    "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subir foto de verificación"])},
    "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmar"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha solicitado la verificación de identificación."])},
    "msg4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tome una foto de su identificación y solicite la aprobación de su administrador."])},
    "cert_picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foto de autenticación"])},
    "id_picture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foto de identificación"])},
    "before_id_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Por favor, suba una foto de su identificación."])},
    "before_cert_upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, suba una foto de verificación."])}
  },
  "antiphishing": {
    "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solicitud de cambio"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El código de protección contra phishing ha sido cambiado."])},
    "fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se pudo cambiar el código de protección contra phishing."])},
    "antiphishing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código de protección contra phishing"])},
    "lengthover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El código de protección contra phishing no debe tener más de 10 caracteres."])},
    "checkinput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, ingrese el código de protección contra phishing."])}
  },
  "moneytransfer": {
    "transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transferencia"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desde"])},
    "kind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spot"])},
    "gift": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Futuros"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo"])},
    "get": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para"])},
    "property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Criptomoneda"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todo"])},
    "msg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las transferencias internas son gratuitas."])},
    "msg1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La transferencia se ha completado."])},
    "msg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puedes transferir activos recibidos como compensación por eventos e invitaciones de amigos."])},
    "msg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si mueve activos mientras mantiene una posición cruzada, es posible que la posición se liquide y es posible que no pueda mover todos los activos. Ingrese el activo intercambiable con precisión."])}
  },
  "addressmanage": {
    "addsuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La dirección de la billetera ha sido añadida."])},
    "addfail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al registrar la dirección de la billetera."])},
    "removesuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La dirección de la billetera ha sido eliminada."])},
    "removefail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error al eliminar la dirección de la billetera."])},
    "addname_null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, ingrese el nombre de la dirección.."])},
    "outputkey_null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, ingrese la dirección de retiro."])},
    "destination_null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, ingrese el código de destino."])}
  }
}