<template lang="">
  <div
    class="fixed z-10 flex w-screen h-full sm:bg-black sm:bg-opacity-50 bg-[#f2f4f5] bg-opacity-80 px-6 sm:px-0"
  >
    <div :class="tStyle.inner">
      <div :class="tStyle.point">
        <img src="../../assets/image/path1703.png" alt="" class="dark:hidden" />
        <img
          src="../../assets/image/path1703_d.png"
          alt=""
          class="opacity-0 dark:opacity-100"
        />
      </div>
      <div :class="tStyle.title">
        <h2 class="sm:text-[30px] text-[20px] font-bold">
          {{ $t('common.google_auth') }}
        </h2>
        <p
          class="sm:text-[20px] text-[15px] sm:mt-[30px] mt-4 max-sm:leading-[22px] fold:leading-[22px] max-sm:text-left fold:text-left sm:font-light"
        >
          {{ $t('authgoogle.auth_msg') }}<br />{{ $t('authgoogle.auth_msg2') }}
        </p>
      </div>
      <div :class="tStyle.bt_box">
        <button :class="tStyle.google" @click="openPlayStore">
          {{ $t('authgoogle.googleplay') }}
        </button>
        <button :class="tStyle.appstore" @click="openAppStore">
          {{ $t('authgoogle.appstore') }}
        </button>
        <button :class="tStyle.confirm" @click="requestGtpRegist">
          {{ $t('authgoogle.btn') }}
        </button>
      </div>

      <button :class="tStyle.close_bt" @click="setModalControl">
        <img src="../../assets/image/group2322.png" alt="" />
      </button>
    </div>
  </div>
</template>

<script>
import { nationNumModal, modalControl, apiLanguage } from '@/store/store';
import Request from '@/utils/Request';
import create from 'vue-zustand';

export default {
  name: 'AuthenticationGoogle',
  data() {
    return {
      tStyle: {
        inner:
          'bg-white w-full sm:w-[600px] sm:h-[500px] m-auto sm:rounded sm:border border-[#C9C9C9] px-[40px] py-[15px] relative dark:bg-[#1c1d22] dark:border-[#606060] dark:text-[#c9c9c9] max-sm:p-6',
        title:
          'text-center sm:mt-[50px] max-sm:dark:text-[#e5e5e5] fold:dark:text-[#e5e5e5]',
        point: 'absolute left-[-1px] top-[-1px] max-sm:hidden fold:hidden',

        bt_box: 'sm:mt-[35px] text-center sm:text-[22px] text-[15px] mt-5',
        google:
          'w-full sm:h-[60px] border sm:border-[#c9c9c9] py-1 sm:py-0 sm:rounded-[3px] sm:dark:bg-[#3b3b43] sm:dark:border-[#606060] border-[#cfe4fe] dark:bg-[#fff] max-sm:dark:text-[#333] fold:dark:text-[#333] sm:font-regular font-medium dark:border-[#fff]',
        appstore:
          'w-full sm:h-[60px] border sm:border-[#c9c9c9] py-1 sm:py-0 sm:rounded-[3px] sm:mt-[20px] mt-4 sm:dark:bg-[#3b3b43] sm:dark:border-[#606060] border-[#cfe4fe] dark:bg-[#fff] max-sm:dark:text-[#333] fold:dark:text-[#333] sm:font-regular font-medium dark:border-[#fff]',
        confirm:
          'w-full sm:h-[60px] bg-[#cfe4fe] py-1 sm:py-0 sm:rounded-[3px] sm:mt-[40px] sm:dark:bg-[#ffb500] dark:text-[#333] mt-8 dark:bg-[#fdd26a]',

        close_bt:
          'absolute right-[20px] top-[18px] font-light text-[30px] max-sm:hidden fold:hidden'
      }
    };
  },
  setup() {
    const useNationModal = create(nationNumModal);
    const useModalControl = create(modalControl);
    const { setModalClose } = useNationModal();
    const { setModalControl, setAlertData, setModalName, setAlertOpen } =
      useModalControl();
    const useApiLanguage = create(apiLanguage);
    const { apiLangCode } = useApiLanguage();

    return {
      setModalClose,
      setModalControl,
      setAlertData,
      setModalName,
      setAlertOpen,
      apiLangCode
    };
  },
  methods: {
    //구글 OTP 등록요청
    async requestGtpRegist() {
      let { t } = this.$i18n;
      let result = await Request(
        '/api/exs/mypage/v1/request_gtp_regist',
        'post',
        this.apiLangCode[this.$i18n.locale]
      );
      if (!result) {
        this.openAlertModal(t('common.otp_auth'), t('authgoogle.fail'));
        return;
      }

      let { API_CODE } = result;

      //session closed
      if (API_CODE.status === 'L402') {
        this.setAlertData({
          title: '',
          desc: this.$t('login.msg15')
        });
        this.setAlertOpen();
        localStorage.removeItem('fubit_token');
        localStorage.removeItem('fubit_rtoken');
        this.setLogout();
        this.goMainPage();
        return;
      }
      //success
      if (API_CODE.status === '0000') {
        let { code, qrUrl } = result.otp;

        //구글 OTP QR Code 모달 띄우기
        this.setAlertData({ code, qrUrl });
        this.setModalName('GoogleQr');
      } else {
        alert(API_CODE.message);
      }
    },
    openAlertModal(title, desc) {
      this.setAlertData({ title, desc });
      this.setAlertOpen();
    },
    openPlayStore() {
      //안드로이드는 테스트 필요
      let url =
        'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2';
      window.open(url);
    },
    openAppStore() {
      let url =
        'https://apps.apple.com/kr/app/google-authenticator/id388497605';
      window.open(url);
    },
    goMainPage() {
      this.$router.push({
        name: 'MainHome'
      });
    }
  }
};
</script>
<style lang=""></style>
