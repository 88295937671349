<template lang="">
  <div class="fixed z-10 flex w-screen h-full bg-black bg-opacity-50">
    <div :class="tStyle.inner">
      <div :class="tStyle.point">
        <img src="../../assets/image/path1703.png" alt="" class="dark:hidden" />
        <img
          src="../../assets/image/path1703_d.png"
          alt=""
          class="opacity-0 dark:opacity-100"
        />
      </div>
      <div :class="tStyle.title">
        <h2 class="text-[30px] font-bold">{{ $t('passwordforgot.title2') }}</h2>
        <p class="text-[20px] mt-[30px] font-light">
          {{ $t('passwordforgot.text4') }}
        </p>
      </div>
      <div class="w-full">
        <input
          class="w-full h-[66px] text-[20px] border border-[#c9c9c9] mt-[60px] rounded px-[20px] dark:bg-[#1c1d22] dark:border-[#606060] dark:placeholder:text-[#c9c9c9]"
          :placeholder= "$t('passwordforgot.text5')"
        />
        <input
          class="w-full h-[66px] text-[20px] border border-[#c9c9c9] mt-[20px] rounded px-[20px] dark:bg-[#1c1d22] dark:border-[#606060] dark:placeholder:text-[#c9c9c9]"
          :placeholder= "$t('passwordforgot.text6')"
        />
      </div>

      <button :class="tStyle.button">{{ $t('common.completion') }}</button>

      <button :class="tStyle.close_bt">
        <img src="../../assets/image/group2322.png" alt="" />
      </button>
    </div>
  </div>
</template>
<script>
import { nationNumModal } from '@/store/store';
import create from 'vue-zustand';

export default {
  name: 'PasswordReset',
  data() {
    return {
      tStyle: {
        inner:
          'bg-white w-[600px] h-[540px] m-auto rounded border border-[#C9C9C9] px-[40px] py-[15px] relative text-center dark:bg-[#1c1d22] dark:border-[#606060] dark:text-[#c9c9c9]',
        title: 'mt-[50px]',
        point: 'absolute left-[-1px] top-[-1px]',
        fail: 'text-center text-[#e7224b] text-[22px]',
        cancel:
          'w-[249px] h-[60px] border border-[#cfe4fe] rounded-[3px] dark:bg-[#fff8e8] dark:border-[#fff8e8] ',
        confirm:
          'w-[249px] h-[60px] bg-[#cfe4fe] rounded-[3px] ml-[10px] dark:bg-[#ffb500]',
        button:
          'w-full h-[60px] bg-[#cfe4fe] rounded-[3px] mt-[60px] dark:bg-[#ffb500] dark:text-[#333]',
        close_bt: 'absolute right-[20px] top-[18px] font-light text-[30px]'
      }
    };
  },
  setup() {
    const useNationModal = create(nationNumModal);
    const { setModalClose } = useNationModal();

    return {
      setModalClose
    };
  }
};
</script>
<style lang=""></style>
