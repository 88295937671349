<template lang="">
  <div
    class="fixed z-30 max-sm:top-0 fold:top-0 flex w-screen h-full sm:bg-black sm:bg-opacity-50 max-sm:dark:bg-[#111119] fold:dark:bg-[#111119] bg-white sm:overflow-y-visible overflow-y-scroll"
  >
    <div
      class="bg-white w-full max-sm:h-full fold:h-full sm:w-[500px] m-auto sm:rounded sm:border border-[#C9C9C9] sm:px-[40px] sm:pt-[30px] relative sm:dark:bg-[#1c1d22] dark:bg-[#111119] dark:border-[#606060] dark:text-[#c9c9c9] max-sm:flex max-sm:flex-col fold:flex fold:flex-col"
    >
      <!-- point image -->
      <div class="absolute left-[-1px] top-[-1px] max-sm:hidden fold:hidden">
        <img src="../../assets/image/path1703.png" alt="" class="dark:hidden" />
        <img
          src="../../assets/image/path1703_d.png"
          alt=""
          class="opacity-0 dark:opacity-100"
        />
      </div>
      <!-- mobile header -->
      <div
        class="flex items-center sm:justify-center w-full px-4 justify-between max-sm:fixed fold:fixed max-sm:dark:bg-[#111119] max-sm:bg-white fold:dark:bg-[#111119] fold:bg-white py-3 sm:py-0"
      >
        <button class="sm:hidden" @click="setModalControl">
          <img
            src="@/assets/image/ion_chevron_back_outline.png"
            alt=""
            class="w-[30px] dark:hidden"
          />
          <img
            src="@/assets/image/ion_chevron_back_outline_d.png"
            alt=""
            class="w-[30px] opacity-0 dark:opacity-100 mt-[-30px] dark:mt-0"
          />
        </button>
        <div
          class="text-[25px] text-center sm:text-[30px] sm:leading-[58px] sm:text-[#606060] sm:dark:text-[#c9c9c9] text-[#444] dark:text-[#e5e5e5]"
        >
          {{ $t('idcard.title') }}
        </div>
        <div class="w-[30px] sm:hidden"></div>
      </div>
      <div class="text-center px-[30px] mt-[10px] pt-[56px] sm:pt-0">
        <img
          src="@/assets/image/img_idcard.png"
          alt=""
          class="inline mt-10 max-sm:w-[70%] fold:w-[70%]"
        />
        <p
          class="sm:text-[18px] text-center mt-4 sm:mt-0 text-[15px] max-sm:leading-[24px] max-sm:dark:text-[#e5e5e5] fold:leading-[24px] fold:dark:text-[#e5e5e5]"
        >
          {{ $t('idcard.msg1') }} <span class="sm:hidden"><br /></span>
          {{ $t('idcard.msg1_1') }}
        </p>
      </div>
      <div
        class="sm:text-[18px] text-[15px] sm:mt-6 mt-[50px] px-4 sm:px-0 max-sm:dark:text-[#e5e5e5] fold:dark:text-[#e5e5e5]"
      >
        <p class="sm:pl-4">
          {{ $t('idcard.msg2') }} <span class="text-[#fc2a36]">*</span>
        </p>
        <div class="flex">
          <input
            class="flex-1 placeholder-shown:truncate border sm:rounded bg-[#f7f8fa] text-[#9f9f9f] sm:leading-[50px] sm:px-4 px-2 sm:dark:bg-[#3b3b43] dark:border-[#606060] max-sm:dark:placeholder:text-[#9f9f9f] fold:dark:placeholder:text-[#9f9f9f] text-[13px] dark:bg-[#1c1d22]"
            :placeholder="$t('idcard.msg3')"
            ref="inputFile1"
            disabled
          />
          <label
            for="fileUploader_1"
            class="bg-[#cfe4ff] shrink-0 sm:rounded py-2 px-4 ml-2 dark:text-[#333] sm:dark:bg-[#ffb500] dark:bg-[#fdd26a]"
            style="cursor: pointer"
            >{{ $t('idcard.regist') }}</label
          >
          <input
            type="file"
            id="fileUploader_1"
            @change="onChangeFile"
            style="
              position: absolute;
              height: 0;
              width: 0;
              padding: 0;
              overflow: hidden;
              border: 0;
            "
          />
        </div>
      </div>
      <div
        class="sm:text-[18px] text-[15px] mt-4 mb-10 px-4 sm:px-0 max-sm:dark:text-[#e5e5e5] fold:dark:text-[#e5e5e5]"
      >
        <p class="sm:pl-4">
          {{ $t('idcard.upload') }} <span class="text-[#fc2a36]">*</span>
        </p>
        <div class="flex">
          <input
            class="flex-1 border sm:rounded bg-[#f7f8fa] text-[#9f9f9f] sm:leading-[50px] px-2 sm:px-4 sm:dark:bg-[#3b3b43] dark:border-[#606060] max-sm:dark:placeholder:text-[#9f9f9f] fold:dark:placeholder:text-[#9f9f9f] text-[13px] dark:bg-[#1c1d22] placeholder-shown:truncate"
            :placeholder="$t('idcard.msg3')"
            ref="inputFile2"
            disabled
          />
          <label
            for="fileUploader_2"
            class="shrink-0 bg-[#cfe4ff] sm:rounded py-2 px-4 ml-2 dark:text-[#333] sm:dark:bg-[#ffb500] dark:bg-[#fdd26a]"
            style="cursor: pointer"
            >{{ $t('idcard.regist') }}</label
          >
          <input
            type="file"
            id="fileUploader_2"
            @change="onChangeFile2"
            style="
              position: absolute;
              height: 0;
              width: 0;
              padding: 0;
              overflow: hidden;
              border: 0;
            "
          />
        </div>
      </div>

      <!-- button -->
      <div
        class="flex sm:mb-10 dark:text-[#333] pb-4 sm:pb-0 max-sm:mt-auto max-sm:text-[15px] fold:mt-auto fold:text-[15px] px-4 sm:px-0"
      >
        <button
          class="basis-1/2 border border-[#cfe4ff] mr-2 rounded py-3 sm:dark:border-[#fff8e8] sm:dark:bg-[#fff8e8] dark:bg-[#fff] dark:border-[#fff]"
          @click="setModalControl"
        >
          {{ $t('common.cancel') }}
        </button>
        <button
          class="basis-1/2 bg-[#cfe4ff] rounded py-3 sm:dark:bg-[#ffb500] dark:bg-[#fdd26a]"
          @click="sendIdCard"
        >
          {{ $t('idcard.request') }}
        </button>
      </div>

      <!-- close button -->
      <button
        class="absolute right-[20px] top-[18px] font-light text-[30px] max-sm:hidden fold:hidden"
        @click="setModalControl"
      >
        <img src="../../assets/image/group2322.png" alt="" />
      </button>
    </div>
  </div>
</template>
<script>
import {
  nationNumModal,
  modalControl,
  isLogin,
  apiLanguage,
  serverLoad
} from '@/store/store';
import Request from '@/utils/Request';
import create from 'vue-zustand';

export default {
  name: 'IdVerification',
  data() {
    return {
      photo: new FormData(),
      tStyle: {}
    };
  },
  setup() {
    const useNationModal = create(nationNumModal);
    const useModalControl = create(modalControl);
    const useIsLogin = create(isLogin);
    const { setModalClose } = useNationModal();
    const { setModalControl, setModalName, setAlertData, setAlertOpen } =
      useModalControl();
    const { setLogout } = useIsLogin();
    const useApiLanguage = create(apiLanguage);
    const { apiLangCode } = useApiLanguage();
    const useServerLoad = create(serverLoad);
    const { setServerLoading, setServerLoadingEnd } = useServerLoad();
    return {
      setModalClose,
      setModalControl,
      setModalName,
      setAlertData,
      setLogout,
      setAlertOpen,
      apiLangCode,
      setServerLoading,
      setServerLoadingEnd
    };
  },
  methods: {
    async sendIdCard() {
      if (!this.photo.get('photo1')) {
        this.setAlertData({
          title: '',
          desc: this.$t('idcard.before_id_upload')
        });
        this.setAlertOpen();
        return;
      }
      

      if (!this.photo.get('photo2')) {
        this.setAlertData({
          title: '',
          desc: this.$t('idcard.before_cert_upload')
        });
        this.setAlertOpen();
        return;
      }
      
      this.setServerLoading()

      let result = await Request(
        '/api/exs/mypage/v1/send_idcard',
        'post',
        this.apiLangCode[this.$i18n.locale],
        this.photo,
        { 'Content-type': 'multipart/form-data' }
      );

      if (!result) {
        this.openAlertModal('', this.$t('common.fail'));
        return;
      }

      let { API_CODE } = result;
      this.setServerLoadingEnd()
      //session closed
      if (API_CODE.status === 'L402') {
        this.setAlertData({
          title: '',
          desc: this.$t('login.msg15')
        });
        this.setAlertOpen();
        localStorage.removeItem('fubit_token');
        localStorage.removeItem('fubit_rtoken');
        this.setLogout();
        this.goMainPage();
      } else if (API_CODE.status === '0000') {
        
        //success
        this.openAlertModal('', this.$t('idcard.success'), true);
        this.setModalControl();
        
      } else {
        this.openAlertModal('', this.$t('common.fail'));
      }
    },
    onChangeFile(e) {
      let file = e.target.files[0];

      this.photo.append('photo1', file);
      console.log(this.photo)
      this.$refs.inputFile1.value = file.name;
    },
    onChangeFile2(e) {
      let file = e.target.files[0];

      this.photo.append('photo2', file);
      this.$refs.inputFile2.value = file.name;
    },
    goMainPage() {
      this.$router.push({
        name: 'MainHome'
      });
    },
    openAlertModal(title, desc, isReload) {
      this.setAlertData({ title, desc, isReload });
      this.setAlertOpen();
    }
  }
};
</script>
<style lang=""></style>
