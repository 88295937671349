<template lang="">
  <div
    class="fixed z-10 flex w-screen h-full bg-black bg-opacity-50 max-sm:top-0 fold:top-0"
  >
    <div :class="tStyle.inner">
      <div :class="tStyle.point">
        <img src="../../assets/image/path1703.png" alt="" class="dark:hidden" />
        <img
          src="../../assets/image/path1703_d.png"
          alt=""
          class="opacity-0 dark:opacity-100"
        />
      </div>
      <div :class="tStyle.title">
        <div
          class="flex justify-center w-full px-[24px] max-sm:hidden fold:hidden"
        >
          <div
            class="sm:mt-[23.5px] flex-1 -ml-4 text-[28px] text-center text-[#444] dark:text-[#c9c9c9]"
          >
            {{ $t('common.google_auth') }}
          </div>
        </div>
        <!-- 모바일 타이틀 -->
        <div
          class="fixed flex items-center justify-between w-full px-4 py-3 bg-white sm:hidden dark:bg-[#111119]"
        >
          <button @click="closeModal">
            <img
              src="@/assets/image/ion_chevron_back_outline.png"
              alt=""
              class="w-[30px] dark:hidden"
            />
            <img
              src="@/assets/image/ion_chevron_back_outline_d.png"
              alt=""
              class="w-[30px] opacity-0 dark:opacity-100 mt-[-30px] dark:mt-0"
            />
          </button>
          <div class="text-[25px] text-center text-[#444] dark:text-[#e5e5e5]">
            {{ $t('common.google_auth') }}
          </div>
          <div class="w-[30px]"></div>
        </div>
        <!-- 회원프로필이미지? -->
        <div class="pt-40 sm:hidden">
          <div class="flex justify-center mx-auto rounded-full">
            <img
              src="@/assets/image/thmb_user.png"
              alt=""
              class="w-[20%] inline dark:hidden"
            />
            <img
              src="@/assets/image/thmb_user_d.png"
              alt=""
              class="w-[20%] inline -ml-[20%] dark:ml-0 opacity-0 dark:opacity-100"
            />
          </div>
        </div>
        <div></div>
        <p class="sm:text-[20px] sm:mt-[30px] mt-[10px] sm:font-light text-[15px]">
          {{ $t('googlenumber.number_msg') }}
        </p>

        <div :class="tStyle.num_box">
          <input
            type="text"
            ref="input"
            maxLength="6"
            v-model="inputNum"
            class="absolute text-white max-sm:w-full w-[520px] max-sm:h-[500px] h-[250px] mt-[-170px] cursor-pointer opacity-0 z-[10] focus:outline-none"
          />

          <input
            disabled
            @click="focusInput"
            type="text"
            v-for="(a, i) in 6"
            :key="i"
            v-model="inArr[i]"
            maxLength="1"
            :class="[
              inputNum.length === i
                ? tStyle.numY
                : !isFail
                ? tStyle.num
                : tStyle.numInputFail
            ]"
          />
        </div>
        <!-- <div
          class="sm:text-left text-[15px] sm:text-[#005cd3] dark:text-[#ffb500] text-[#606060] text-right pr-6 sm:pr-0"
        >
          <button class="underline max-sm:text-[15px] fold:text-[15px]">
            Google OTP {{ $t('common.initialization') }}
          </button>
        </div> -->
        <p v-if="isFail" :class="tStyle.fail">
          {{ $t('googlenumber.fail_msg') }}
        </p>
      </div>

      <div :class="tStyle.bt_box" style="grid-gap: 10px">
        <button :class="tStyle.confirm">{{ $t('common.ok') }}</button>
      </div>

      <button :class="tStyle.close_bt" @click="closeModal">
        <img src="../../assets/image/group2322.png" alt="" />
      </button>
    </div>
  </div>
</template>
<script>
import {
  nationNumModal,
  modalControl,
  apiLanguage,
  refresh
} from '@/store/store';
import Request from '@/utils/Request';
import create from 'vue-zustand';
import AesEncrypt from '@/components/Security/AesEncrypt';

export default {
  name: 'GoogleNumber2',
  data() {
    return {
      inputNum: '',
      inArr: [],
      isFail: false,
      tStyle: {
        inner:
          'bg-white w-full h-full sm:w-[600px] sm:h-[540px] m-auto sm:rounded sm:border border-[#C9C9C9] sm:px-[40px] sm:py-[15px] relative sm:dark:bg-[#1c1d22] dark:border-[#606060] dark:text-[#c9c9c9] dark:bg-[#111119]',

        title: 'text-center sm:mt-[50px] ',
        point: 'absolute left-[-1px] top-[-1px] max-sm:hidden fold:hidden',
        num_box:
          'w-full sm:h-[90px] relative flex justify-between sm:mt-[88px] mt-[5%] children:last-child:border-[#005CB9] px-[20px] sm:px-0',
        numInput:
          'disabled:bg-white cursor-pointer sm:w-[90px] sm:h-[90px] w-[14.6666%] h-[13.5vw] border border-[#C9C9C9] border-b-[4px] border-b-[#626060] text-center dark:bg-[#1c1d22] dark:border-[#606060]',
        num: 'disabled:bg-white cursor-pointer sm:w-[90px] sm:h-[90px] w-[14.6666%] h-[13.5vw] border border-[#C9C9C9] border-b-4 border-b-[#626060] text-center dark:bg-[#1c1d22] dark:border-[#606060] dark:text-white',
        // numY: 'disabled:bg-white cursor-pointer w-12 h-12 sm:w-[80px] sm:h-[80px] border border-[#C9C9C9] border-b-[#005CD3] dark:border-b-[#ffb500] dark:bg-[#1c1d22] dark:border-[#606060] border-b-[4px] text-center',
        numY: 'disabled:bg-white cursor-pointer sm:w-[90px] sm:h-[90px] w-[14.6666%] h-[13.5vw] border border-[#C9C9C9] border-b-[#005CD3] dark:border-b-[#ffb500] dark:bg-[#1c1d22] dark:border-[#606060] border-b-4 text-center dark:text-white',
        numInputFail:
          'disabled:bg-white cursor-pointer sm:w-[90px] sm:h-[90px] w-[14.6666%] h-[13.5vw] border border-[#C9C9C9] border-b-[4px] border-b-[#FC0D1B] text-center dark:bg-[#1c1d22]',
        fail: 'text-center text-[#e7224b] sm:text-[22px] text-[15px]',
        bt_box: ' mt-[48px] flex text-center text-[22px] dark:text-[#333]',

        cancel:
          'w-full h-[60px] border border-[#cfe4fe] rounded-[3px] dark:bg-[#fff8e8] dark:border-[#fff8e8] ',
        confirm:
          'w-full h-[60px] bg-[#cfe4fe] rounded-[3px] dark:bg-[#ffb500] dark:text-[#333] max-sm:hidden fold:hidden',

        close_bt:
          'absolute right-[20px] top-[18px] font-light text-[30px] max-sm:hidden fold:hidden'
      }
    };
  },
  setup() {
    const useNationModal = create(nationNumModal);
    const useModalControl = create(modalControl);
    const { setModalClose } = useNationModal();
    const {
      setModalControl,
      alertData,
      setAlertData,
      setModalName,
      setAlertOpen
    } = useModalControl();
    const useApiLanguage = create(apiLanguage);
    const { apiLangCode } = useApiLanguage();
    const useRefresh = create(refresh);
    const { setRefresh } = useRefresh();

    return {
      setModalClose,
      setModalControl,
      alertData,
      setAlertData,
      setModalName,
      setAlertOpen,
      apiLangCode,
      setRefresh
    };
  },
  mounted() {
    this.focusInput();
    
  },
  watch: {
    inputNum(a, b) {
      var blankCheck = /[\s]/g;
      if (isNaN(a) == true) {
        this.inputNum = b;
      } else if (blankCheck.test(a) == true) {
        this.inputNum = b;
      } else {
        this.inArr = Array.from(this.inputNum);

        if (this.inputNum.length === 6) {
          if (this.alertData.key == undefined) {
            
            this.removeGtp(this.inputNum);
          } else {
            this.confirmGtpRegister(this.inputNum);
          }
        } else if (this.inputNum.length < 6) {
          this.tStyle.numInput =
            'disabled:bg-white cursor-pointer sm:w-[90px] sm:h-[90px] w-[14.6666%] h-[13.5vw] border border-[#C9C9C9] border-b-4 border-b-[#626060] text-center';
        }
      }
    }
  },
  methods: {
    //구글 OTP 등록
    async confirmGtpRegister(auth) {
      let data = JSON.stringify({
        data: AesEncrypt({
          key: this.alertData.key,
          code: auth,
          qrUrl: this.alertData.qrUrl
        })
      });

      let result = await Request(
        '/api/exs/mypage/v1/confirm_gtp_regist',
        'post',
        this.apiLangCode[this.$i18n.locale],
        data
      );

      if (!result) {
        this.isFail = true;
        return;
      }

      let { API_CODE } = result;

      //session closed
      if (API_CODE.status === 'L402') {
        this.setAlertData({
          title: '',
          desc: this.$t('login.msg15')
        });
        this.setAlertOpen();
        localStorage.removeItem('fubit_token');
        localStorage.removeItem('fubit_rtoken');
        this.setLogout();
        this.goMainPage();
      }
      //success
      else if (API_CODE.status === '0000') {
        this.openAlertModal('Google OTP', this.$t('googlenumber.success'));
        this.setModalControl();
        this.setRefresh('otpResist');
      }
      //fail
      else {
        this.isFail = true;
      }
    },
    focusInput() {
      this.$refs.input.focus();
    },
    openAlertModal(title, desc) {
      this.setAlertData({ title, desc });
      this.setAlertOpen();
    },
    closeModal() {
      this.setAlertData({});
      this.setModalControl();
    },
    //구글 OTP 해제
    async removeGtp(auth) {
      
      let data = JSON.stringify({
        data: AesEncrypt({
          code: auth
        })
      });

      let result = await Request(
        '/api/exs/mypage/v1/remove_gtp',
        'post',
        this.apiLangCode[this.$i18n.locale],
        data
      );

      if (!result) {
        this.openAlertModal('', this.$t('common.removeauthfail'));
        return;
      }

      let { status } = result.API_CODE;
      
      if (status === 'L402') {
        this.setAlertData({
          title: '',
          desc: this.$t('login.msg15')
        });
        this.setAlertOpen();
        localStorage.removeItem('fubit_token');
        localStorage.removeItem('fubit_rtoken');
        this.goMainPage();
        this.setLogout();
      } else if (status === '0000') {
        this.setModalControl();
        this.openAlertModal('', this.$t('common.removeauthsuccess'));
        this.setRefresh('otpResist');
      } else {
        this.isFail = true;
      }
    }
  }
};
</script>
<style lang=""></style>
